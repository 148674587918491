import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useToast } from '@/components/ui/use-toast';
import { label } from '@/utils/label';
import { LabelGroup } from '@/features/label/types';

const QUERY_KEY = 'invitations';

export type RevokeInvitationDTO = {
  invitationId: number;
};

export const revokeInvitation = ({ invitationId }: RevokeInvitationDTO): Promise<unknown> => {
  return axios.put(`/api/invitation/revoke/${invitationId}`);
};

type UseRevokeInvitationOptions = {
  organizationId: number;
  config?: MutationConfig<typeof revokeInvitation>;
};

export const useRevokeInvitation = ({ config, organizationId }: UseRevokeInvitationOptions) => {
  const { toast } = useToast();

  return useMutation({
    onError: (_: any, __: any, ___: any) => {
      toast({
        variant: 'destructive',
        title: label('something_went_wrong', LabelGroup.GLOBAL),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEY, organizationId]);
      queryClient.refetchQueries([QUERY_KEY, organizationId]);

      toast({
        variant: 'success',
        title: label('invitation_revoked', LabelGroup.ORGANIZER_ORGANIZATION_EDIT),
      });
    },
    ...config,
    mutationFn: ({ invitationId }: any) =>
      revokeInvitation({
        invitationId,
      }),
  });
};
