import { AvatarFallback } from '@/components/ui/avatar';
import { TimelineConnector, TimelineHeader, TimelineItem, TimelineTime, TimelineTitle } from '@/components/ui/timeline';
import { EventLog, LogEventTime } from '@/features/event/types';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { UserType } from '@/features/user';
import { cn } from '@/utils';
import { convertPascalToCamel } from '@/utils/convert-pascal-to-camel-case';
import { convertUTCtoLocal } from '@/utils/convertUtcToLocal';
import { formateTime } from '@/utils/formatTime';
import { Avatar } from '@radix-ui/react-avatar';
import { CalendarPlus, ClockIcon } from 'lucide-react';

const action = {
  delete: 'delete',
  add: 'add',
  update: 'update',
} as const;

export function TimeTimelineItem({ log }: { log: EventLog }) {
  const { logText, inserted, user } = log;
  const data = convertPascalToCamel<LogEventTime>(JSON.parse(logText ?? '{}'));

  const startDate = data?.start ? new Date(data?.start) : 'N/A';
  const endDate = data?.end ? new Date(data?.end) : 'N/A';
  const createdAtTime = formateTime(convertUTCtoLocal(inserted));

  const dateString =
    new Date(startDate).toDateString() === new Date(endDate).toDateString()
      ? `${new Date(startDate).toLocaleDateString('sv-SE', {
          weekday: 'long',
          day: 'numeric',
          month: 'short',
          hour: '2-digit',
          minute: '2-digit',
        })}  - ${new Date(endDate).toLocaleTimeString('sv-SE', { hour: '2-digit', minute: '2-digit' })}`
      : `${new Date(startDate).toLocaleDateString('sv-SE')} ${new Date(startDate).toLocaleTimeString('sv-SE', {
          hour: '2-digit',
          minute: '2-digit',
        })} - ${new Date(endDate).toLocaleDateString('sv-SE')} ${new Date(endDate).toLocaleTimeString('sv-SE', {
          hour: '2-digit',
          minute: '2-digit',
        })}`;

  return (
    <TimelineItem>
      <TimelineConnector className="bg-gray-medium/20" />
      <TimelineHeader>
        <CalendarPlus
          className={'-translate-x-2 flex items-center rounded-md gap-x-2 bg-green-500 w-8 h-8 p-2 text-white'}
        />
        <TimelineTitle>
          <div className="flex gap-x-2 items-center">
            <Avatar className="h-8 w-8 text-sm">
              <AvatarFallback
                className={cn(
                  'font-semibold bg-gray-medium text-white',
                  user?.userType === UserType.ORGANIZER ? 'bg-brand-medium text-brand' : 'bg-brand'
                )}
              >
                {user?.username?.substring(0, 2)?.toUpperCase() ?? 'U'}
              </AvatarFallback>
            </Avatar>
            {user?.username ?? <Label name="deleted_user" groupName={LabelGroup.GLOBAL} />}{' '}
            {log?.category?.startsWith('Draft') && (
              <span className="text-xs bg-sky-500 text-white p-1 rounded-md">
                <Label name="draft_version" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
              </span>
            )}{' '}
            <Label
              name={`event_time_${action[data.action as keyof typeof action]}`}
              groupName={LabelGroup.MANAGER_EVENT_HANDLING}
            />
            <span className=" italic font-semibold bg-card p-2 rounded-md border">{dateString}</span>
          </div>
        </TimelineTitle>
        <TimelineTime className="translate-x-24 md:-translate-x-20 flex items-center gap-x-2">
          <ClockIcon className="w-4 h-4" />
          {createdAtTime}
        </TimelineTime>
      </TimelineHeader>
    </TimelineItem>
  );
}
