// Utility function to convert a UTC date string to a local time zone
function convertUTCtoLocal(dateString: string) {
  // Convert the UTC date string to a Date object
  const isoDateString = dateString.endsWith('Z') ? dateString : `${dateString}Z`;

  // Convert to local date and time
  const localDate = new Date(isoDateString);

  // Format the date using the options
  const formattedDate = localDate.toLocaleDateString('sv-SE', {
    timeZone: 'Europe/Stockholm',
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
  // Format the local date as a string
  return formattedDate;
}

export { convertUTCtoLocal };
