import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Checkbox } from '@/components/ui/checkbox';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';

interface DigitalEventSectionProps {
  form: any;
  defaultCollapsed?: boolean;
}

const ACCESSABILITY_OPTIONS = ['accessibilityText', 'accessibilityWheelchairVenue', 'accessibilityWheelchairToilet', 'accessibilityVisualInterpretation', 'accessibilitySignLanguage', 'accessibilityTeleloop'];

export function AccessabilitySection({ form, defaultCollapsed = false }: DigitalEventSectionProps) {
  return (
    <div className="space-y-6">
      <Card>
        <Accordion type="single" className="w-full" collapsible defaultValue={defaultCollapsed ? '' : 'accessability'}>
          <AccordionItem className="[&[data-state=open]>.card-header]:bg-gray-medium [&[data-state=open]>.card-header]:text-white [&[data-state=open]>.card-header]:rounded-t-md" value="accessability">
            <CardHeader className="py-3">
              <AccordionTrigger className="py-0 text-md">
                <CardTitle className="text-left flex  items-center gap-x-2">
                  <Label name="accessability_title" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />
                </CardTitle>
              </AccordionTrigger>
            </CardHeader>
            <AccordionContent>
              <CardContent className="pt-6 text-sm space-y-6">
                <FormItem className="">
                  <div className="space-y-4">
                    {ACCESSABILITY_OPTIONS.map(item => (
                      <FormField
                        key={item}
                        control={form.control}
                        name={item}
                        render={({ field }) => {
                          return (
                            <FormItem key={item} className="flex flex-row items-start space-x-3 space-y-0">
                              <FormControl>
                                <Checkbox className="mt-0.5" checked={field.value} onCheckedChange={checked => field.onChange(checked)} />
                              </FormControl>
                              <div>
                                <FormLabel className="font-semibold">
                                  <Label name={item + '_title'} groupName={LabelGroup.ORGANIZER_EVENT_FORM} />
                                </FormLabel>
                                <FormDescription className="text-sm">
                                  <Label name={`${item}_description`} groupName={LabelGroup.ORGANIZER_EVENT_FORM} />
                                </FormDescription>
                              </div>
                            </FormItem>
                          );
                        }}
                      />
                    ))}
                  </div>

                  <FormMessage />
                </FormItem>
              </CardContent>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </Card>
    </div>
  );
}
