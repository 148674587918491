import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { ManagerEvent } from '../types';

const QUERY_KEY = 'archived-events';

export const getEvents = (): Promise<ManagerEvent[]> => {
  return axios.get(`/manager/event/archive`);
};

type QueryFnType = typeof getEvents;

type UseEventOption = {
  config?: QueryConfig<QueryFnType>;
};

export const useArchivedEvents = ({ config }: UseEventOption) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: [QUERY_KEY],
    queryFn: () => getEvents(),
    ...config
  });
};
