import { EventOrganizer, TEvent } from '@/features/event/types';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { FormLabel } from '@/components/ui/form';
import { label } from '@/utils/label';
import { EventTime } from '@/features/event/types';
import { useLocations } from '@/features/location/api/getLocations';
import { LabelGroup } from '@/features/label/types';
import { Label } from '@/features/label/components/label';
import { useFormContext } from 'react-hook-form';
import { cn } from '@/utils';
import { formatDate } from '@/utils/formatDate';
import { formateTime } from '@/utils/formatTime';
import _ from 'lodash';
import { ArrowRight } from 'lucide-react';

interface VersionComparerProps {
  original: TEvent;
}

const labels = {
  title: 'event_title',
  socialIssue: 'event_social_issue',
  description: 'event_description',
  digitalStreamingUrl: 'digital_streaming_url',
  locationId: 'event_location',
  digitalArchiveUrl: 'archive_url',
  digitalMeetingUrl: 'digital_meeting_url',
  times: 'event_days',
  organizers: 'event_organizers'
};

export function VersionComparer({ original }: VersionComparerProps) {
  const { data: locations } = useLocations({
    active: true
  });

  const form = useFormContext();

  const changeKeys = ['title', 'socialIssue', 'description', 'digitalStreamingUrl', 'locationId', 'digitalArchiveUrl', 'digitalMeetingUrl', 'times', 'organizers'];

  const modifiedFields = Object.entries(form.getValues())
    .filter(([key, value]) => {
      console.log(key, value, original[key as keyof TEvent]);

      if (key === 'times') {
        return !_.isEqual(formatDate(value?.toString() ?? ''), formatDate(original[key as keyof TEvent]?.toString() ?? ''));
      }

      if (key === 'organizers') {
        console.log(value, original[key as keyof TEvent]);

        const modifiedOrganizers = (value as EventOrganizer[])?.map(organizer => organizer.organizerName)?.join(', ');
        const originalOrganizers = (original[key as keyof TEvent] as EventOrganizer[])?.map(organizer => organizer.organizerName)?.join(', ');

        return !_.isEqual(modifiedOrganizers, originalOrganizers);
      }

      if (changeKeys.includes(key) && !_.isEqual(value, original[key as keyof TEvent])) {
        return true;
      }
      return false;
    })
    .map(([key, value]) => ({
      original: { key, value: original[key as keyof TEvent] },
      modified: {
        key,
        value: value
      }
    }));

  const parseValue = (key: keyof TEvent, value: any) => {
    if (key === 'times') {
      const times = value as EventTime[];
      return times.map(time => `${formatDate(time.eventStart)} - ${formateTime(formatDate(time.eventEnd))}`).join(', ');
    }
    if (key === 'organizers') {
      const organizers = value as EventOrganizer[];
      return organizers.map(organizer => organizer.organizerName).join(', ');
    }
    if (key === 'locationId') {
      if (value === -100) return label('location_confirmed_later', LabelGroup.MANAGER_EVENT_HANDLING);
      return locations?.find(location => location.locationId === value)?.name;
    }

    if (value === null || value === '') return 'Tomt';

    return value;
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          <Label name="version_comparer" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
        </CardTitle>
        <CardDescription>
          <Label name="version_comparer_description" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
        </CardDescription>
      </CardHeader>
      <CardHeader className="grid grid-cols-2">
        <CardTitle>
          <Label name="changes" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
        </CardTitle>
      </CardHeader>
      <CardContent className={cn('text-sm flex-col w-full flex divide-y space-y-4')}>
        {modifiedFields?.map(({ original, modified }) => (
          <div key={original.key} className="space-y-4 w-full py-2">
            <FormLabel className="font-bold">{label(labels[original.key as keyof typeof labels], LabelGroup.MANAGER_EVENT_HANDLING)}</FormLabel>
            <div className="grid grid-cols-3 max-w-4xl">
              <span className="italic text-left">{parseValue(original.key as keyof TEvent, original.value)}</span>
              <span className="text-muted-foreground w-24 flex justify-center">
                <ArrowRight className="w-6 h-6" />
              </span>
              <span className="font-bold bg-brand-medium px-2 w-fit max-w-full break-words">{parseValue(modified.key as keyof TEvent, modified.value)}</span>
            </div>
          </div>
        ))}
      </CardContent>
    </Card>
  );
}
