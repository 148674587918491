import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { User, UserType } from '../types';

export const getUsers = ({ userType }: { userType: UserType }): Promise<User[]> => {
  return axios.get(`/user/list`, { params: { type: userType } });
};

type QueryFnType = typeof getUsers;

type UserUsersOption = {
  userType: UserType;
  config?: QueryConfig<QueryFnType>;
};

export const useUsers = ({ userType, config }: UserUsersOption) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['users', userType],
    queryFn: () => getUsers({ userType }),
    ...config,
  });
};
