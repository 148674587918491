import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { Label, LabelGroup } from '../types';
import { useToast } from '@/components/ui/use-toast';
import { label } from '@/utils/label';

const QUERY_KEY = 'labels';

export type CreateLabelDTO = {
  data: {
    labelName: string;
    languageCode: string;
    value: string;
    groupName: string;
  };
};

export const createLabel = ({ data }: CreateLabelDTO): Promise<Label> => {
  return axios.post(
    `/label`,
    {},
    {
      params: Object.entries(data).reduce((acc, [key, value]) => {
        if (value) {
          acc[key] = value;
        }
        return acc;
      }, {} as Record<string, string>),
    }
  );
};

type UseCreateLabelOptions = {
  config?: MutationConfig<typeof createLabel>;
};

export const useCreateLabel = ({ config }: UseCreateLabelOptions) => {
  const { toast } = useToast();

  return useMutation({
    onMutate: async (next) => {
      await queryClient.cancelQueries([QUERY_KEY]);

      const prev = queryClient.getQueryData<Label[]>([QUERY_KEY]);

      queryClient.setQueryData([QUERY_KEY], [...(prev || []), next.data]);

      return { prev };
    },
    onError: (_, __, context: any) => {
      if (context?.prev) {
        queryClient.setQueryData([QUERY_KEY], context.prev);
      }
      toast({
        variant: 'destructive',
        title: label('something_went_wrong', LabelGroup.GLOBAL),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEY]);
      queryClient.refetchQueries([QUERY_KEY]);

      toast({
        variant: 'success',
        title: label('label_created', LabelGroup.ADMIN_LABEL),
      });
    },
    ...config,
    mutationFn: (data: any) =>
      createLabel({
        data,
      }),
  });
};
