import { z } from 'zod';

const defaultProperties = z.object({
  organizationType: z.string().optional(),
  eventType: z.string().optional(),
  category: z.string().optional(),
  topic: z.string().optional(),
  topic2: z.string().optional(),
  languages: z.array(z.string()).optional(),
  times: z
    .array(
      z.object({
        eventTimeId: z.number(),
        eventId: z.number(),
        eventStart: z.union([z.string(), z.date()]),
        eventEnd: z.union([z.string(), z.date()]),
        inserted: z.string().optional(),
        updated: z.string().optional(),
      })
    )
    .optional(),

  locationId: z.number().nullable().optional(),
  locationDescription: z.string().optional(),
  title_lastVersion: z.string().optional(),
  title: z.string().optional(),
  socialIssue: z.string().optional(),
  socialIssue_lastVersion: z.string().optional(),
  description_lastVersion: z.string().optional(),
  description: z
    .string()
    .max(1000, {
      message: 'description_max_validation_text',
    })
    .optional(),
  organizers: z
    .array(
      z.object({
        eventId: z.number(),
        eventOrganizerId: z.number().optional(),
        organizerName: z.string(),
        inserted: z.string().optional(),
        updated: z.string().optional(),
      })
    )
    .optional(),
  contactPerson1Name: z.string().optional(),
  contactPerson1Organization: z.string().optional(),
  contactPerson1Telephone: z.string().optional(),
  contactPerson1Email: z.string().optional(),
  persons: z
    .array(
      z.object({
        eventId: z.number().optional(),
        eventPersonId: z.number(),
        firstName: z.string(),
        lastName: z.string().optional(),
        title: z.string().optional(),
        organizationName: z.string().optional(),
        party: z.string().optional().nullable(),
        inserted: z.string().optional(),
        updated: z.string().optional(),
      })
    )
    .optional(),
  contactPerson1Title: z.string().optional(),
  contactPerson2Name: z.string().optional(),
  contactPerson2Title: z.string().optional(),
  contactPerson2Organization: z.string().optional(),
  contactPerson2Telephone: z.string().optional(),
  contactPerson2Email: z.string().optional(),
  showEmail: z.boolean().optional(),
  showTelephone: z.boolean().optional(),
  url1: z.string().optional(),
  url2: z.string().optional(),
  url3: z.string().optional(),
  facebookUrl: z.string().optional(),
  xurl: z.string().optional(),
  linkedInUrl: z.string().optional(),
  digitalStreamingType: z.string().optional(),
  digitalStreamingUrl: z.string().optional(),
  digitalMeetingUrl: z.string().optional(),
  digitalMeetingDescription: z
    .string()
    .max(150, {
      message: 'digital_meeting_description_max_validation_text',
    })
    .optional(),
  digitalArchiveUrl: z.string().optional(),

  accessibilityText: z.boolean().optional(),
  accessibilityTeleloop: z.boolean().optional(),
  accessibilityVisualInterpretation: z.boolean().optional(),
  accessibilityWheelchairToilet: z.boolean().optional(),
  accessibilityWheelchairVenue: z.boolean().optional(),
  accessibilitySignLanguage: z.boolean().optional(),
  environmentStationary: z.boolean().optional(),
  environmentPrint: z.boolean().optional(),
  environmentFlyer: z.boolean().optional(),
  environmentBattery: z.boolean().optional(),
  environmentPlastic: z.boolean().optional(),
  environmentRecycling: z.boolean().optional(),
  environmentDisposable: z.boolean().optional(),
  environmentSourceSorting: z.boolean().optional(),
  environmentFood: z.boolean().optional(),
  environmentFoodEcological: z.boolean().optional(),
  environmentFoodLocallyProduced: z.boolean().optional(),
  environmentFoodEthical: z.boolean().optional(),
  environmentWater: z.boolean().optional(),
  environmentNoFood: z.boolean().optional(),
  environmentServiceQuestion: z.boolean().optional(),
  environmentServiceElectricity: z.boolean().optional(),
  environmentServiceTravel: z.boolean().optional(),
  environmentServiceCooking: z.boolean().optional(),
  organizerId: z.number(),
  eventId: z.number(),
  yearId: z.number(),
  status: z.number(),
  inserted: z.string(),
  titleComment: z.string().optional(),
  descriptionComment: z.string().optional(),
  socialIssueComment: z.string().optional(),
  generalComment: z.string().optional(),
  noteComment: z.string().optional(),
  returnDue: z.number().nullable(),
  returnDate: z.string().nullable(),
  noCost: z.boolean().nullable(),
  noCostDeadline: z.string().nullable(),
  notify: z.boolean().optional().nullable(),
});

const eventReviewSchema = z.object({
  ...defaultProperties.shape,
});

export { eventReviewSchema };
