import * as React from 'react';
import { Check, ChevronDown } from 'lucide-react';

import { cn } from '@/utils';
import { Button } from '@/components/ui/button';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { label } from '@/utils/label';
import { LabelGroup } from '@/features/label/types';
import { Label } from '@/features/label/components/label';

interface ComboboxProps {
  options?: {
    value: any;
    label: string;
    prefix?: string;
    suffix?: string;
  }[];
  value: string | any;
  onChange: (value: any) => void;
  groups?: Record<
    string,
    {
      value: number;
      label: string;
    }[]
  >;
  disabled?: boolean;
  popoverContentClassName?: string;
  buttonClassName?: string;
  PlaceholderComponent?: React.ReactNode | string;
  filter?: (value: string, search: string) => number;
}

export function Combobox({
  options,
  value,
  onChange,
  groups,
  disabled,
  filter = (value: string, search: string) =>
    value.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) ? 1 : 0,
  PlaceholderComponent,
  popoverContentClassName = '',
  buttonClassName = '',
}: ComboboxProps) {
  const [open, setOpen] = React.useState(false);

  const source = options ?? Object.values(groups ?? {}).flat();

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild className="w-full">
        <Button
          disabled={disabled}
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn('w-full rounded-none justify-between min-w-[100px]', buttonClassName)}
        >
          <span className="truncate font-semibold">
            {value ? (
              source.find((option) => option.value.toString() === value.toString())?.label
            ) : (
              <span className="text-muted-foreground ">
                {PlaceholderComponent ?? <Label name="select_option" groupName={LabelGroup.GLOBAL} />}
              </span>
            )}
          </span>
          <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className={cn('w-auto p-0 rounded-none', popoverContentClassName)} align="end">
        <Command shouldFilter filter={filter}>
          <CommandInput placeholder={label('search_placeholder', LabelGroup.GLOBAL)} />
          <CommandEmpty>
            <Label name="no_search_results" groupName={LabelGroup.GLOBAL} />
          </CommandEmpty>
          {options && options.length > 0 && (
            <CommandGroup className="max-h-64 overflow-y-auto">
              {options.map((option) => (
                <CommandItem
                  key={option.value.toString()}
                  value={option.label.toString()}
                  onSelect={(_) => {
                    onChange(option.value);
                    setOpen(false);
                  }}
                >
                  <Check className={cn('mr-2 h-4 w-4', value === option.value ? 'opacity-100' : 'opacity-0')} />
                  {option.label}
                  {option?.suffix && <span className="text-xs text-muted-foreground ml-2">{option.suffix}</span>}
                </CommandItem>
              ))}
            </CommandGroup>
          )}
          {groups && Object.keys(groups).length > 0 && (
            <div className=" max-h-72 overflow-y-auto">
              {Object.entries(groups).map(([key, group]) => (
                <CommandGroup key={key} heading={key} className="">
                  {group?.map((option) => (
                    <CommandItem
                      key={option.value.toString()}
                      value={option.label.toString()}
                      onSelect={(_) => {
                        onChange(option.value);
                        setOpen(false);
                      }}
                    >
                      <Check className={cn('mr-2 h-4 w-4', value === option.value ? 'opacity-100' : 'opacity-0')} />
                      {option.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              ))}
            </div>
          )}
        </Command>
      </PopoverContent>
    </Popover>
  );
}
