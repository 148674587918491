import { useOrganizerStore } from '@/stores/organizerStore';
import { useRegsiterInvoicePayment } from '../api/register-invoice-payment';
import { useOrganization } from '@/features/organizer/api/getOrganization';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { orgNoSchema } from '@/features/misc/data/orgno-schema';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { useUpdateOrganization } from '@/features/organizer/api/updateOrganization';
import { Input } from '@/components/ui/input';
import { Spinner } from '@/components/ui/spinner';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { NothingToPay } from '../components/nothing-to-pay';
import { Skeleton } from '@/components/ui/skeleton';

const formSchema = z.object({
  invoiceOrgNo: orgNoSchema,
  invoiceName: z.string().min(1, {
    message: 'required'
  }),
  invoiceAddress1: z.string().min(1, {
    message: 'required'
  }),
  invoiceZip: z
    .string()
    .min(1, {
      message: 'required'
    })
    .refine(value => /^[0-9]{3}\s?[0-9]{2}$/.test(value), {
      message: 'invalid_zip_code'
    }),
  invoiceCity: z.string().min(1, {
    message: 'required'
  }),
  invoiceGln: z.string().optional(),
  invoiceAddress2: z.string().optional(),
  invoiceEmail: z.string().email().optional().or(z.literal('')),
  invoiceRef: z.string().optional()
});

export function InvoicePayment() {
  const [hasNothingToPay, setHasNothingToPay] = useState(false);
  const organizationId = useOrganizerStore(state => state.organizationId);

  const navigate = useNavigate();

  const { data: organization, isLoading } = useOrganization({
    organizerId: organizationId,
    config: {
      enabled: !!organizationId
    }
  });

  const { mutateAsync: registerInvoicePayment, isLoading: isRegistering } = useRegsiterInvoicePayment({
    organizerId: organizationId,
    config: {}
  });
  const { mutateAsync: updateOrganization, isLoading: isUpdating } = useUpdateOrganization({
    config: {
      onSuccess: () => {}
    }
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    values: {
      invoiceOrgNo: organization?.invoiceOrgNo ?? organization?.orgNo ?? '',
      invoiceName: organization?.invoiceName ?? organization?.organizerName ?? '',
      invoiceAddress1: organization?.invoiceAddress1 ?? '',
      invoiceZip: organization?.invoiceZip ?? '',
      invoiceCity: organization?.invoiceCity ?? '',
      invoiceGln: organization?.invoiceGln ?? '',
      invoiceAddress2: organization?.invoiceAddress2 ?? '',
      invoiceEmail: organization?.invoiceEmail ?? '',
      invoiceRef: organization?.invoiceRef ?? ''
    }
  });

  const handleSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      await updateOrganization({ ...organization, ...values } as any);
      const response = await registerInvoicePayment(organizationId as any);
      const paymentId = response as string | number;
      paymentId === '' ? setHasNothingToPay(true) : navigate(`/payment/confirmation/${paymentId}#post`);
    } catch (error) {}
  };

  if (isLoading) return <Skeleton className="rounded-md h-96 w-full" />;

  if (hasNothingToPay) {
    return <NothingToPay />;
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          <Label name="invoice_details" groupName={LabelGroup.ORGANIZER_PAYMENT} />
        </CardTitle>
      </CardHeader>

      <Form {...form}>
        <form onKeyDown={event => event.key === 'Enter' && event.preventDefault()} onSubmit={form.handleSubmit(handleSubmit)} className="space-y-8">
          <CardContent className="grid md:grid-cols-2 gap-4">
            <FormField
              control={form.control}
              name="invoiceOrgNo"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>
                    <Label name="organizer_invoice_org_no" groupName={LabelGroup.ORGANIZER_PAYMENT} />
                    <span className="text-red-500 ml-2">*</span>
                  </FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="invoiceName"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>
                    <Label name="organizer_invoice_name" groupName={LabelGroup.ORGANIZER_PAYMENT} />
                    <span className="text-red-500 ml-2">*</span>
                  </FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="invoiceAddress1"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>
                    <Label name="organizer_invoice_address1" groupName={LabelGroup.ORGANIZER_PAYMENT} />
                    <span className="text-red-500 ml-2">*</span>
                  </FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="invoiceAddress2"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>
                    <Label name="organizer_invoice_address2" groupName={LabelGroup.ORGANIZER_PAYMENT} />
                  </FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="invoiceZip"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>
                    <Label name="organizer_invoice_zip" groupName={LabelGroup.ORGANIZER_PAYMENT} />
                    <span className="text-red-500 ml-2">*</span>
                  </FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="invoiceCity"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>
                    <Label name="organizer_invoice_city" groupName={LabelGroup.ORGANIZER_PAYMENT} />
                    <span className="text-red-500 ml-2">*</span>
                  </FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="invoiceGln"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>
                    <Label name="organizer_invoice_gln" groupName={LabelGroup.ORGANIZER_PAYMENT} />
                  </FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="invoiceEmail"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>
                    <Label name="organizer_invoice_email" groupName={LabelGroup.ORGANIZER_PAYMENT} />
                  </FormLabel>
                  <FormControl>
                    <Input {...field} type="email" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="invoiceRef"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>
                    <Label name="organizer_invoice_ref" groupName={LabelGroup.ORGANIZER_PAYMENT} />
                  </FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </CardContent>
          <CardFooter>
            <Button disabled={isUpdating || isRegistering || form.formState.isSubmitting} type="submit">
              {isUpdating || (isRegistering && <Spinner className="mr-2 text-primary-foreground h-4 w-4" />)}
              <Label name={isUpdating || isRegistering ? 'updating' : 'confirm'} groupName={LabelGroup.ORGANIZER_PAYMENT} />
            </Button>
          </CardFooter>
        </form>
      </Form>
    </Card>
  );
}
