import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useToast } from '@/components/ui/use-toast';
import { label } from '@/utils/label';
import { Label, LabelGroup } from '../types';

const QUERY_KEY = 'labels';

export const deleteLabel = ({ labelId }: { labelId: number }) => {
  return axios.delete(`/label/${labelId}`);
};

type DeleteLabelOptions = {
  config?: MutationConfig<typeof deleteLabel>;
};

export const useDeleteLabel = ({ config }: DeleteLabelOptions) => {
  const { toast } = useToast();

  return useMutation({
    onMutate: async (deletedLabel) => {
      await queryClient.cancelQueries([QUERY_KEY, deletedLabel.labelId]);

      const previousLabels = queryClient.getQueryData<Label[]>([QUERY_KEY]);

      queryClient.setQueryData(
        [QUERY_KEY],
        previousLabels?.filter((user) => user?.labelId !== deletedLabel.labelId)
      );

      return { previousLabels };
    },
    onError: (_, __, context: any) => {
      if (context?.previousLabels) {
        queryClient.setQueryData([QUERY_KEY], context.previousLabels);
      }
      toast({
        variant: 'destructive',
        title: label('something_went_wrong', LabelGroup.GLOBAL),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEY]);
      queryClient.refetchQueries([QUERY_KEY]);

      toast({
        variant: 'success',
        title: label('label_deleted_successfully', LabelGroup.ADMIN_LABEL),
      });
    },
    ...config,
    mutationFn: ({ labelId }) => deleteLabel({ labelId }),
  });
};
