import { ColumnDef } from '@tanstack/react-table';
import { Label as TLabel, LabelGroup } from '../types';
import { DataTableColumnHeader } from '@/components/ui/data-table-column-header';
import { LabelCellInput } from './label-cell-input';
import { LabelTableRowActions } from './label-table-row-actions';
import { Label } from './label';
import { labelGroups } from '../data/label-groups';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { Info } from 'lucide-react';

export const columns: ColumnDef<TLabel>[] = [
  {
    accessorKey: 'labelGroup',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        TitleComponent={<Label name="LabelGroup" groupName={LabelGroup.ADMIN_LABEL} />}
      />
    ),
    filterFn: (row, id, value) => {
      return value.toString().includes(row.getValue(id)?.toString());
    },
    cell: ({ row }) => {
      return (
        <span className="font-semibold flex items-center gap-x-1">
          {labelGroups.find((group) => group.group === row.original.labelGroup)?.name ?? (
            <span className="font-semibold text-red-500">Unknown: {row.original.labelGroup}</span>
          )}
          <Tooltip>
            <TooltipTrigger asChild>
              <Info className="h-4 w-4 ml-1 text-sky-500" />
            </TooltipTrigger>
            <TooltipContent>
              <p>
                {labelGroups.find((group) => group.group === row.original.labelGroup)?.description ?? 'No description'}
              </p>
            </TooltipContent>
          </Tooltip>
        </span>
      );
    },
  },
  {
    accessorKey: 'labelName',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        TitleComponent={<Label name="LabelName" groupName={LabelGroup.ADMIN_LABEL} />}
      />
    ),
  },

  {
    accessorKey: 'value',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        TitleComponent={<Label name="Value" groupName={LabelGroup.ADMIN_LABEL} />}
      />
    ),
    cell: ({ row }) => <LabelCellInput label={row.original} path="value" />,
  },
  {
    accessorKey: 'action',
    header: '',
    cell: ({ row }) => <LabelTableRowActions row={row} />,
  },
];
