import { convertUTCtoLocal } from '@/utils/convertUtcToLocal';
import { EventComment, EventStatus } from '../types';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { AlertTriangle, Info, X } from 'lucide-react';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { cn } from '@/utils';

interface CommentProps {
  comment?: EventComment | null;
  IconComponent?: React.ReactNode | JSX.Element | any;
  className?: string;
  label?: string;
  status?: EventStatus;
}

export function Comment({ comment, status, label = 'action_required', IconComponent = null, className }: CommentProps) {
  if (!comment) return null;

  const icon = (() => {
    switch (status) {
      case EventStatus.ActionRequired:
        return <AlertTriangle className="w-4 h-4 mt-1" />;
      case EventStatus.Approved:
        return <Info className="w-4 h-4 mt-1" />;
      case EventStatus.Rejected:
        return <X className="w-4 h-4 mt-1" />;
      default:
        return null;
    }
  })();

  const colors = (() => {
    switch (status) {
      case EventStatus.ActionRequired:
        return 'bg-amber-50 border-amber-300 text-amber-900 [&>svg]:text-amber-600';
      case EventStatus.Approved:
        return 'bg-sky-50 border-sky-300 text-sky-900 [&>svg]:text-sky-600';
      case EventStatus.Rejected:
        return 'bg-red-50 border-red-300 text-red-900 [&>svg]:text-red-600';
    }
  })();

  return (
    <Alert className={cn('border rounded-none ', className, colors)}>
      {IconComponent ?? icon}
      <AlertTitle>
        <Label name={label} groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
      </AlertTitle>
      <AlertDescription className="flex flex-col gap-1">
        <div dangerouslySetInnerHTML={{ __html: comment?.logText?.text }} />
        <span className="italic">{comment?.inserted && convertUTCtoLocal(comment.inserted).toLocaleString()}</span>
      </AlertDescription>
    </Alert>
  );
}
