import { axios } from '@/lib/axios';
import { ExportFilters } from '../types';
import fileSaver from 'file-saver';

export const getExcelExportByFilters = async (filters: ExportFilters): Promise<void> => {
  const params = new URLSearchParams();

  // Convert boolean values to 'true' and others to themselves
  Object.entries(filters).forEach(([key, value]) => {
    params.append(key, value ? 'true' : 'false');
  });

  return axios
    .get(`/export/filter?${params.toString()}`, {
      responseType: 'arraybuffer',
    })
    .then((response: any) => {
      const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fileSaver.saveAs(blob, 'statistik.xlsx');
    });
};
