import { Link, useParams } from 'react-router-dom';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Button } from '@/components/ui/button';
import { BadgeCheck } from 'lucide-react';
import { useState } from 'react';
import { restorePassword } from '../api/restorePassword';
import { useToast } from '@/components/ui/use-toast';
import { Card } from '@/components/ui/card';
import { label } from '@/utils/label';
import { PasswordInput } from '@/components/password-input';
import { LabelGroup } from '@/features/label/types';
import { Label } from '@/features/label/components/label';

const passwordSchema = z.string().min(6, { message: 'password_min_length_6' });

const formSchema = z
  .object({
    password: passwordSchema,
    confirmPassword: passwordSchema,
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'password_does_not_match',
    path: ['confirmPassword'],
  });

export function RestorePassword() {
  const [isRestored, setIsRestored] = useState(false);

  const { toast } = useToast();

  const { id } = useParams();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  async function handleSubmit(values: z.infer<typeof formSchema>) {
    if (!id) return;
    try {
      await restorePassword({ password: values.password, token: id as string });
      setIsRestored(true);
    } catch (error) {
      toast({
        variant: 'destructive',
        title: label('something_went_wrong', LabelGroup.GLOBAL),
      });
    }
  }

  if (isRestored) {
    return (
      <div className="h-screen w-full flex flex-col items-center justify-center">
        <div className="space-y-4 text-center">
          <div className="space-y-2  flex flex-col items-center">
            <BadgeCheck size={48} />
            <h3 className="text-lg font-medium">
              <Label name="password_restored" groupName={LabelGroup.RESTORE_PASSWORD} />
            </h3>
          </div>
          <div>
            <Link to="/login">
              <Button>
                <Label name="login_button_text" groupName={LabelGroup.RESTORE_PASSWORD} />
              </Button>
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="h-screen w-full flex flex-col items-center justify-center">
      <Card className="max-w-md w-full p-6 space-y-4 h-auto bg-card shadow-md">
        <h3 className="text-lg font-medium">
          <Label name="restore_password_title" groupName={LabelGroup.RESTORE_PASSWORD} />
        </h3>
        <Form {...form}>
            <form onKeyDown={(event) => event.key === 'Enter' && event.preventDefault()} onSubmit={form.handleSubmit(handleSubmit)} className="space-y-8">
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>
                    <Label name="password" groupName={LabelGroup.RESTORE_PASSWORD} />
                  </FormLabel>
                  <FormControl>
                    <PasswordInput {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="confirmPassword"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>
                    <Label name="confirm_password" groupName={LabelGroup.RESTORE_PASSWORD} />
                  </FormLabel>
                  <FormControl>
                    <PasswordInput {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button type="submit">
              <Label name="save_button_text" groupName={LabelGroup.RESTORE_PASSWORD} />
            </Button>
          </form>
        </Form>
      </Card>
    </div>
  );
}
