import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { useSettings } from '../api/getSettings';
import { Setting } from '../types';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { Button } from '@/components/ui/button';
import { useUpdateSetting } from '../api/update-setting';
import { useToast } from '@/components/ui/use-toast';
import { label } from '@/utils/label';
import { CalendarPopover } from '@/components/ui/calendar-popver';

const schema = z.object({
  settings: z.array(
    z.object({
      settingId: z.number(),
      value: z.string(),
      name: z.string(),
      sortOrder: z.number()
    })
  )
});

export function EventDaysSettings() {
  const { data: settings } = useSettings({});

  const eventDaysSettings = Object.values(settings?.day || []).map((value: Setting) => ({
    ...value
  }));

  const settingMutation = useUpdateSetting({});
  const { toast } = useToast();

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    values: {
      settings: eventDaysSettings ?? []
    }
  });

  const handleSubmit = async (values: z.infer<typeof schema>) => {
    try {
      const data = values.settings.map(setting => ({
        ...setting,
        data: null
      }));

      const promises = data.map(setting => settingMutation.mutateAsync(setting));
      const results = await Promise.allSettled(promises);
      const allIsFulfilled = results.every(result => result.status === 'fulfilled');

      const responseTitle = allIsFulfilled ? 'settings_updated_successfully' : 'error_updating_settings';

      toast({
        variant: allIsFulfilled ? 'success' : 'destructive',
        title: label(responseTitle, LabelGroup.ADMIN_SETTINGS)
      });
    } catch (error) {
      console.error('Error updating settings', error);
    }
  };

  if (!settings?.day) return null; // Handle missing data gracefully

  return (
    <Form {...form}>
      <form onKeyDown={event => event.key === 'Enter' && event.preventDefault()} onSubmit={form.handleSubmit(handleSubmit)} className="space-y-8">
        <Card className="w-[600px]">
          <CardHeader>
            <CardTitle>
              <Label name="EventDaySettingsCardTitle" groupName={LabelGroup.ADMIN_SETTINGS} />
            </CardTitle>
            <CardDescription>
              <Label name="EventDaySettingsCardDescription" groupName={LabelGroup.ADMIN_SETTINGS} />
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-2">
            <div className="space-y-4">
              {form.watch('settings').map((setting, index) => (
                <div key={setting.settingId} className="grid grid-cols-2">
                  <div>
                    <FormField
                      control={form.control}
                      name={`settings.${index}.value`}
                      render={({ field }) => (
                        <FormItem className="flex gap-x-2 items-center">
                          <FormLabel className="w-24">Dag {index + 1}</FormLabel>
                          <FormControl>
                            <CalendarPopover
                              mode="single"
                              date={new Date(field.value)}
                              selected={new Date(field.value)}
                              defaultMonth={new Date(field.value)}
                              onSelect={date =>
                                date &&
                                field.onChange(
                                  date
                                    .toLocaleDateString('sv-SE', {
                                      day: 'numeric',
                                      month: 'numeric',
                                      year: 'numeric'
                                    })
                                    .toString()
                                )
                              }
                              initialFocus
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
              ))}
            </div>
          </CardContent>
          <CardFooter>
            <Button disabled={!form.formState.isDirty} type="submit">
              <Label name="Save" groupName={LabelGroup.GLOBAL} />
            </Button>
          </CardFooter>
        </Card>
      </form>
    </Form>
  );
}
