import { LabelGroup } from '../types';

export const labelGroups = [
  {
    name: 'Admin / Meny',
    description: 'Texter som används i admin-meny och handläggarmenyn',
    group: LabelGroup.ADMIN_MENU,
  },
  {
    name: 'Admin / Användare',
    description: 'Texter som används i användarhantering för admin',
    group: LabelGroup.ADMIN_USER,
  },
  {
    name: 'Admin / Systemtexter',
    description: 'Sidorna som hanterar systemtexter i admingränssnittet',
    group: LabelGroup.ADMIN_LABEL,
  },
  {
    name: 'Admin / Platslista',
    description: 'Texter som används i platslistan för admin',
    group: LabelGroup.ADMIN_LOCATION_LIST,
  },
  {
    name: 'Admin / Redigera plats',
    description: 'Texter som används i redigering av plats för admin',
    group: LabelGroup.ADMIN_LOCATION_EDIT,
  },
  {
    name: 'Admin / Användarlista',
    description: 'Texter som används i användarlistan för admin',
    group: LabelGroup.ADMIN_USER_LIST,
  },
  {
    name: 'Admin / Redigera användare',
    description: 'Texter som används i redigering av användare för admin',
    group: LabelGroup.ADMIN_USER_EDIT,
  },
  {
    name: 'Admin / Skapa användare',
    description: 'Texter som används i skapande av användare för admin',
    group: LabelGroup.ADMIN_USER_CREATE,
  },
  {
    name: 'Manager / Översikt',
    description: 'Texter som används i översikten för handläggare',
    group: LabelGroup.MANAGER_DASHBOARD,
  },
  {
    name: 'Handläggare / Evenemang / Handläggning',
    description: 'Texter som används på handläggninssidan för handläggare',
    group: LabelGroup.MANAGER_EVENT_HANDLING,
  },
  {
    name: 'Handläggare / Evenemang / Lista',
    description: 'Texter som används i evenemangslistan för handläggare',
    group: LabelGroup.MANAGER_EVENT_LIST,
  },
  {
    name: 'Handläggare / Evenemang / Statusar',
    description: 'Texter som används i statuslistan för evenemang för handläggare',
    group: LabelGroup.MANAGER_EVENT_STATUSES,
  },
  {
    name: 'Arrangör / Meny',
    description: 'Texter som används i arrangörsmeny',
    group: LabelGroup.ORGANIZER_MENU,
  },
  {
    name: 'Arrangör / Översikt',
    description: 'Texter som används i översikten för arrangör',
    group: LabelGroup.ORGANIZER_DASHBOARD,
  },
  {
    name: 'Arrangör / Organisation / Lista',
    description: 'Texter som används i organisationlistan för arrangör',
    group: LabelGroup.ORGANIZER_ORGANIZATION_LIST,
  },
  {
    name: 'Arrangör / Organisation / Skapa',
    description: 'Texter som används i skapande av organisation för arrangör',
    group: LabelGroup.ORGANIZER_ORGANIZATION_CREATE,
  },
  {
    name: 'Arrangör / Organisation / Gå med',
    description: 'Texter som används i gå med i organisation för arrangör',
    group: LabelGroup.ORGANIZER_ORGANIZATION_JOIN,
  },
  {
    name: 'Arrangör / Organisation / Redigera',
    description: 'Texter som används i redigering av organisation för arrangör',
    group: LabelGroup.ORGANIZER_ORGANIZATION_EDIT,
  },
  {
    name: 'Arrangör / Evenemang / Lista',
    description: 'Texter som används i evenemangslistan för arrangör',
    group: LabelGroup.ORGANIZER_EVENT_LIST,
  },
  {
    name: 'Arrangör / Evenemang / Skapa',
    description: 'Texter som används i skapande av evenemang för arrangör',
    group: LabelGroup.ORGANIZER_EVENT_CREATE,
  },
  {
    name: 'Arrangör / Evenemang / Formulär',
    description: 'Texter som används i formuläret för arrangör',
    group: LabelGroup.ORGANIZER_EVENT_FORM,
  },
  {
    name: 'Arrangör / Evenemang / Statusar',
    description: 'Texter som används i statuslistan för evenemang för arrangör',
    group: LabelGroup.ORGANIZER_EVENT_STATUSES,
  },
  {
    name: 'Arrangör / Betalning',
    description: 'Texter som används i betalningssidan för arrangör',
    group: LabelGroup.ORGANIZER_PAYMENT,
  },
  {
    name: 'Arrangör / Betalning / Lista',
    description: 'Texter som används i betalningslistan för arrangör',
    group: LabelGroup.ORGANIZER_PAYMENT_LIST,
  },
  {
    name: 'Arrangör / Betalning / Kvitto',
    description: 'Texter som används i kvittosidan för arrangör',
    group: LabelGroup.ORGANIZER_PAYMENT_RECEIPT,
  },
  {
    name: 'Validering',
    description: 'Texter som används i valideringmeddelanden genom hela applikationen',
    group: LabelGroup.VALIDATION,
  },
  {
    name: 'Profil',
    description: 'Texter som används på profilsidan för en inloggade användare, denna appliceras för alla användare',
    group: LabelGroup.PROFILE,
  },
  {
    name: 'Logga in',
    description: 'Texter som används på inloggningssidan',
    group: LabelGroup.LOGIN,
  },
  {
    name: 'Registrera',
    description: 'Texter som används på registreringssidan',
    group: LabelGroup.REGISTER,
  },
  {
    name: 'Glömt lösenord',
    description: 'Texter som används på sidan för att återställa lösenord',
    group: LabelGroup.FORGOT_PASSWORD,
  },
  {
    name: 'Bekräfta e-post',
    description: 'Texter som används på sidan för att bekräfta e-post',
    group: LabelGroup.CONFIRM_EMAIL,
  },
  {
    name: 'Återställ lösenord',
    description: 'Texter som används på sidan för att återställa lösenord',
    group: LabelGroup.RESTORE_PASSWORD,
  },
  {
    name: 'Globala texter',
    description:
      "Texter som används globalt i hela applikationen, kan vara ogeneriska texter som 'Spara' eller 'Avbryt'",
    group: LabelGroup.GLOBAL,
  },
];
