import { ArrowDown, ArrowUp, ChevronDownIcon, EyeOff } from 'lucide-react';
import { Column } from '@tanstack/react-table';
import { cn } from '@/utils';
import { Button } from './button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from './dropdown-menu';
import { LabelGroup } from '@/features/label/types';
import { Label } from '@/features/label/components/label';

interface DataTableColumnHeaderProps<TData, TValue> extends React.HTMLAttributes<HTMLDivElement> {
  column: Column<TData, TValue>;
  TitleComponent: React.ReactNode | string;
}

export function DataTableColumnHeader<TData, TValue>({ column, TitleComponent, className }: DataTableColumnHeaderProps<TData, TValue>) {
  if (!column.getCanSort()) {
    return <div className={cn(className)}>{TitleComponent}</div>;
  }

  const ascLabel = (() => {
    if (column.id.toLowerCase().includes('date')) {
      return 'asc_date_sorting';
    }
    return 'asc_column_sorting';
  })();

  const descLabel = (() => {
    if (column.id.toLowerCase().includes('date')) {
      return 'desc_date_sorting';
    }
    return 'desc_column_sorting';
  })();

  return (
    <div className={cn('flex items-center space-x-2', className)}>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="sm" className="-ml-3 h-8 data-[state=open]:bg-accent">
            <span>{TitleComponent}</span>
            {column.getIsSorted() === 'desc' ? <ArrowDown className="ml-2 h-4 w-4" /> : column.getIsSorted() === 'asc' ? <ArrowUp className="ml-2 h-4 w-4" /> : <ChevronDownIcon className="ml-2 h-4 w-4" />}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start">
          <DropdownMenuItem onClick={() => column.toggleSorting(false)}>
            <ArrowUp className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
            <Label name={ascLabel} groupName={LabelGroup.GLOBAL} />
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => column.toggleSorting(true)}>
            <ArrowDown className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
            <Label name={descLabel} groupName={LabelGroup.GLOBAL} />
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={() => column.toggleVisibility(false)}>
            <EyeOff className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
            <Label name="hide_column" groupName={LabelGroup.GLOBAL} />
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
