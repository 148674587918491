import { Skeleton } from '@/components/ui/skeleton';

export function EditEventSkeleton() {
  return (
    <div className="flex  items-start h-screen w-full ">
      <div className="w-2/5  justify-end hidden h-full lg:flex p-4">
        <Skeleton className="w-96 h-4/5" />
      </div>

      <div className="max-h-[calc(100lvh_-_60px)] overflow-y-hidden p-4 w-full flex-col  xl:w-4/5 flex ">
        <div className="space-y-2 xl:max-w-[80%] max-w-full">
          <Skeleton className="h-48 w-full" />
          <Skeleton className="h-48 w-full" />
          <Skeleton className="h-[600px] w-full" />
        </div>
      </div>
    </div>
  );
}
