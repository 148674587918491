export const SWEDISH_PARTIES = [
  {
    name: 'Socialdemokraterna',
    abbreviation: 'S',
    color: '#E30613',
  },
  {
    name: 'Moderaterna',
    abbreviation: 'M',
    color: '#006A52',
  },
  {
    name: 'Sverigedemokraterna',
    abbreviation: 'SD',
    color: '#006598',
  },
  {
    name: 'Centerpartiet',
    abbreviation: 'C',
    color: '#006A52',
  },
  {
    name: 'Liberalerna',
    abbreviation: 'L',
    color: '#FFCC00',
  },
  {
    name: 'Kristdemokraterna',
    abbreviation: 'KD',
    color: '#4B8E0B',
  },
  {
    name: 'Vänsterpartiet',
    abbreviation: 'V',
    color: '#D50032',
  },
  {
    name: 'Miljöpartiet',
    abbreviation: 'MP',
    color: '#6EBB46',
  },
  // Ungdomsförbund
  {
    name: 'Socialdemokratiska ungdomsförbundet',
    abbreviation: 'SSU',
    color: '#E30613',
  },
  {
    name: 'Moderata ungdomsförbundet',
    abbreviation: 'MUF',
    color: '#006A52',
  },
  {
    name: 'Sverigedemokratisk ungdom',
    abbreviation: 'SDU',
    color: '#006598',
  },
  {
    name: 'Centerpartiets ungdomsförbund',
    abbreviation: 'CUF',
    color: '#006A52',
  },
  {
    name: 'Liberala ungdomsförbundet',
    abbreviation: 'LUF',
    color: '#FFCC00',
  },
  {
    name: 'Kristdemokratiska ungdomsförbundet',
    abbreviation: 'KDU',
    color: '#4B8E0B',
  },
  {
    name: 'Vänsterpartiets ungdomsförbund',
    abbreviation: 'VUF',
    color: '#D50032',
  },
  {
    name: 'Gröna ungdomar',
    abbreviation: 'GU',
    color: '#6EBB46',
  },
];
