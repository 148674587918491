import { Layout } from '@/features/misc/components/layout';
import { RouteObject } from 'react-router-dom';
import { ConfirmEmail } from '@/features/auth/routes/confirm-email';
import { ForgotPassword } from '@/features/auth/routes/forgot-password';
import { Login } from '@/features/auth/routes/login';
import { Register } from '@/features/auth/routes/register';
import { RestorePassword } from '@/features/auth/routes/restore-password';

export const publicRoutes: RouteObject[] = [
  {
    path: '/',
    element: <Layout />,
    children: [
      { path: '*', element: <Login /> },
      { path: '/', element: <Login /> },
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '/register',
        element: <Register />,
      },
      { path: '/forgot-password', element: <ForgotPassword /> },
      { path: '/restore-password/:id', element: <RestorePassword /> },
      { path: '/confirm-email', element: <ConfirmEmail /> },
    ],
  },
];
