import { EventLog, LogCategory } from '@/features/event/types';
import { UpdatedTimelineItem } from './updated-timeline-item';
import { CommentTimelineItem } from './comment-timeline-item';
import { StatusTimelineItem } from './status-timeline-item';
import { TimeTimelineItem } from './time-timeline-item';
import { OrganizerTimelineItem } from './organizer-timeline-item';
import { CopyTimelineItem } from './copy-timeline-item';
import { ManagerAssignedTimelineItem } from './manager-assigned-timeline-item';
import { PersonTimelineItem } from './person-timeline-item';
import { PaymentTimelineItem } from './payment-timeline-item';
import { CreatedTimelineItem } from './created-timeline-item';
import { ChangeDraftTimelineItem } from './change-draft-timeline';
// import { ReviewTimeline } from './review-timeline';

interface HistoryTimelineItemProps {
  log: EventLog;
}

export function HistoryTimelineItem({ log }: HistoryTimelineItemProps) {
  const { category } = log;

  switch (category !== LogCategory.CHANGE_DRAFT ? category?.replace('Draft', '') : category) {
    case LogCategory.UPDATED:
      return <UpdatedTimelineItem log={log} />;
    case LogCategory.COMMENT:
      return <CommentTimelineItem log={log} />;
    case LogCategory.ASSIGNED:
      return <ManagerAssignedTimelineItem log={log} />;
    case LogCategory.STATUS:
      return <StatusTimelineItem log={log} />;
    case LogCategory.EVENT_COPY:
      return <CopyTimelineItem log={log} />;
    case LogCategory.EVENTTIME:
      return <TimeTimelineItem log={log} />;
    case LogCategory.ORGANIZER:
      return <OrganizerTimelineItem log={log} />;
    case LogCategory.EVENTPERSON:
      return <PersonTimelineItem log={log} />;
    case LogCategory.PAYMENT:
      return <PaymentTimelineItem log={log} />;
    case LogCategory.CREATED:
      return <CreatedTimelineItem log={log} />;
    case LogCategory.CHANGE_DRAFT:
      return <ChangeDraftTimelineItem log={log} />;
    // case LogCategory.REVIEW:
    // case LogCategory.REVIEW_END:
    //   return <ReviewTimeline log={log} />;
    default:
      return null
  }
}
