import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';

import { useToast } from '@/components/ui/use-toast';
import { TEvent } from '../types';
import { label } from '@/utils/label';
import { LabelGroup } from '@/features/label/types';

const QUERY_KEY = 'event';

export const activateEvent = ({ eventId }: { eventId: number }) => {
  return axios.put(`/event/activate/${eventId}`);
};

type ActivateEventOptions = {
  config?: MutationConfig<typeof activateEvent>;
};

export const useActivateEvent = ({ config }: ActivateEventOptions) => {
  const { toast } = useToast();

  return useMutation({
    onMutate: async (deletedUser) => {
      await queryClient.cancelQueries([QUERY_KEY, deletedUser.eventId]);

      const previousUsers = queryClient.getQueryData<TEvent[]>([QUERY_KEY]);

      queryClient.setQueryData(
        [QUERY_KEY],
        previousUsers?.filter((user) => user?.eventId !== deletedUser.eventId)
      );

      return { previousUsers };
    },
    onError: (_, __, context: any) => {
      if (context?.previousUsers) {
        queryClient.setQueryData([QUERY_KEY], context.previousUsers);
      }
      toast({
        variant: 'destructive',
        title: label('something_went_wrong', LabelGroup.GLOBAL),
      });
    },
    onSuccess: () => {
      // queryClient.invalidateQueries([QUERY_KEY]);
      // queryClient.refetchQueries([QUERY_KEY]);

      toast({
        variant: 'success',
        title: label('event_activated_successfully', LabelGroup.ORGANIZER_EVENT_LIST),
      });
    },
    ...config,
    mutationFn: ({ eventId }) => activateEvent({ eventId }),
  });
};
