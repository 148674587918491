import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Setting } from '../types';

const QUERY_KEY = 'settings';

export const getSettings = (): Promise<{
  [key: string]: Setting[];
}> => {
  return axios.get(`/settings`);
};

type QueryFnType = typeof getSettings;

type UseSettingOption = {
  config?: QueryConfig<QueryFnType>;
};

export const useSettings = ({ config }: UseSettingOption) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: [QUERY_KEY],
    queryFn: getSettings,
    ...config,
  });
};
