import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Form } from '@/components/ui/form';
import { Button } from '@/components/ui/button';
import { useSettings } from '@/features/settings/api/getSettings';
import { useCreateEvent } from '../api/createEvent';
import { useOrganizerStore } from '@/stores/organizerStore';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useEventStore } from '@/stores/eventStore';
import { CreateEventInfoCard } from '../components/(info)/create-event-info-card';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { Sidebar } from '../components/sidebar';
import { cn } from '@/utils';
import { EventCategory, EventStatus } from '../types';
import { sections } from '../data/sections';
import { ArrowLeft } from 'react-bootstrap-icons';
import { NavigationTabs } from '../components/navigation-tabs';
import { SendSection } from '../components/(sections)/send-section';
import { useSendEvent } from '../api/send-event';
import { CreateEventDueInfoCard } from '../components/(info)/create-event-due-info-card';
import { CreateEventClosedInfoCard } from '../components/(info)/create-event-closed-info-card';
import { baseEventSchema } from '../data/base-event-schema';
import { sendEventSchema } from '../data/send-event-schema';
import { EditEventSkeleton } from '../components/edit-event-placeholder';
import { useConfirm } from '@/providers/confirm';
import { NewMainContactTerms } from '@/features/organizer/components/new-main-contact-termts';
import { useOrganization } from '@/features/organizer/api/getOrganization';
import { useYear } from '@/features/settings/api/get-year';
import { useMe } from '@/features/auth/api/get-me';
import { useTimePeriodRestrictions } from '@/hooks/use-time-period-restrictions';

const DEFAULT_VALUES = {
  sendToReview: false,
  title: '',
  socialIssue: '',
  organizers: [],
  persons: [],
  languages: [],
  category: '',
  contactPerson1Email: '',
  topic: '',
  status: EventStatus.Draft,
  eventType: '',
  organizationType: '',
  contactPerson1Name: '',
  contactPerson1Telephone: '',
  contactPerson1Organization: '',
  contactPerson2Email: '',
  locationId: 0,
  eventId: 0,
  times: [
    {
      eventTimeId: 0,
      eventId: 0,
      eventStart: '',
      eventEnd: '',
      inserted: ''
    }
  ]
};

export function CreateEvent() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const organizationId = useOrganizerStore(state => state.organizationId);

  const scrollAreaRef = useRef<HTMLDivElement>(null);

  const setIsAssigned = useEventStore(state => state.setIsAssigned);
  const setEventComments = useEventStore(state => state.setComments);

  useEffect(() => {
    setIsAssigned(false);
    setEventComments([]);
  }, []);

  const form = useForm<z.infer<typeof baseEventSchema>>({
    resolver: zodResolver(baseEventSchema),
    defaultValues: DEFAULT_VALUES
  });

  const navigate = useNavigate();

  const { data: organization } = useOrganization({
    organizerId: Number(organizationId),
    config: {
      enabled: !!organizationId
    }
  });

  const { data: user } = useMe({});
  const { data: activeYear } = useYear({});
  const { isFetched } = useSettings({});
  const { mutateAsync: createEvent, isLoading } = useCreateEvent({
    organizerId: organizationId
  });
  const { mutateAsync: sendEvent, isLoading: isSending } = useSendEvent({
    eventId: -100
  });

  async function handleSubmit(values: z.infer<typeof baseEventSchema>) {
    if (values.sendToReview) {
      try {
        sendEventSchema.parse(values);
      } catch (err) {
        if (err instanceof z.ZodError) {
          err.errors.forEach(error => {
            // Set errors for each path (field) in the form
            form.setError(error.path[0] as keyof z.infer<typeof sendEventSchema>, {
              type: 'manual',
              message: error.message
            });
          });

          // Trigger form validation again
          return form.trigger();
        }
      }
    }

    const newEvent = await createEvent({ ...values } as any);

    const newEventId = newEvent?.eventId as number;

    if (values.sendToReview) {
      const sendResponse = await sendEvent(newEventId);

      if (sendResponse.paymentRequired) {
        navigate(`/payment`);
      } else {
        navigate(`/events`);
      }
    } else {
      navigate(`/events`);
    }
  }

  useConfirm(
    {
      title: 'there_are_unsaved_changes',
      silent: !form?.formState?.isDirty,
      onConfirm: () => {}
    },
    form.formState.isDirty
  );

  useEffect(() => {
    const handleBeforeUnload = (_: any) => {
      if (form.formState.isDirty && !form.formState.isSubmitted && !form.formState.isSubmitting) {
        // Execute your custom function silently
        _.returnValue = 'Du har osparade ändringar. Är du säker på att du vill lämna sidan?';

        return 'Du har osparade ändringar. Är du säker på att du vill lämna sidan?';
      }

      // Don't show the confirmation dialog
      // In some browsers, returning an empty string might reduce the appearance of the dialog.
      return undefined; // This is sometimes needed for compatibility with older browsers
    };

    // Assign the handler to onbeforeunload
    window.onbeforeunload = handleBeforeUnload;

    // Cleanup function to remove the handler
    return () => {
      window.onbeforeunload = null;
    };
  }, [form.formState.isDirty, form.formState.isSubmitted, form.formState.isSubmitting]);

  const filteredSections = sections.filter(({ id }) => (id === 'persons-section' && form.watch('category') === EventCategory.TRAFFPUNKT ? false : true));

  const { isEventPeriodClosed, isEventPeriodOpen } = useTimePeriodRestrictions();

  const isMainContact = organization?.users?.some(({ mainContact, userId: _userId }) => user?.userId === _userId && mainContact);
  const hasApproval = isMainContact ? organization?.logs.some(log => log.category === 'ConfirmTerms' && log.logText?.toString() === activeYear?.toString() && log.userId === user?.userId) : true;
  const hasAdminApproval = organization?.logs.some(log => log.category === 'ConfirmTerms' && log.logText?.toString() === activeYear?.toString());

  if (!hasApproval && organization && isMainContact) {
    return (
      <div className="max-w-[1440px] mx-auto my-8">
        <NewMainContactTerms organizer={organization} />;
      </div>
    );
  }

  if (!isFetched) {
    return <EditEventSkeleton />;
  }

  if (isEventPeriodClosed) {
    return <CreateEventDueInfoCard />;
  }

  if (!isEventPeriodOpen || !hasAdminApproval) {
    return <CreateEventClosedInfoCard />;
  }

  return (
    <div className="relative w-full overflow-hidden font-form">
      <Form {...form}>
        <form onKeyDown={event => event.key === 'Enter' && event.preventDefault()} onSubmit={form.handleSubmit(handleSubmit)}>
          <div className="flex w-full gap-4 ">
            {windowWidth >= 1024 && (
              <div className={cn('w-2/5 md:min-w-[400px]  justify-end hidden lg:flex max-h-[calc(100lvh_-_121px)]  overflow-y-auto overflow-x-hidden')}>
                <Sidebar isUpdating={isLoading} />
              </div>
            )}
            <div ref={scrollAreaRef} className="processing-view max-h-[calc(100lvh_-_52px)]  lg:max-h-[calc(100lvh_-_121px)] overflow-y-auto lg:p-4 w-full overflow-x-hidden xl:w-4/5 xl:max-w-[calc(100vw_-_430px)] flex ">
              <div className="space-y-2  w-full max-w-6xl">
                {windowWidth <= 1024 && <NavigationTabs scrollAreaRef={scrollAreaRef.current} isUpdating={isLoading} />}
                <div className="p-4 space-y-4 ">
                  <Link to="/" className="block lg:hidden">
                    <Button variant="outline">
                      <ArrowLeft className="h-4 w-4 mr-2" />
                      <Label name="back_to_event_list_button_text" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />
                    </Button>
                  </Link>
                  <div className={cn('space-y-4 md:space-y-8 pb-6')}>
                    <CreateEventInfoCard />
                    <div className={cn('space-y-4 md:space-y-8 section')}>
                      {filteredSections.map(({ id, component: Component = () => null }) => (
                        <div id={id}>
                          <Component form={form} defaultCollapsed={id !== 'category-section'} />
                        </div>
                      ))}
                    </div>
                    <SendSection isUpdating={isLoading} isSending={isSending} isFreeOfCharge={false} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
}
