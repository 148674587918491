import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { DashboardSummary } from '../types';

const QUERY_KEY = 'dashboard-summary';

export const getDashboardSummary = async (): Promise<DashboardSummary> => {
  return axios.get(`/statistic/dashboard`);
};

type QueryFnType = typeof getDashboardSummary;

type UseDashboardSummaryOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useDashboardSummary = ({ config }: UseDashboardSummaryOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: [QUERY_KEY],
    queryFn: getDashboardSummary,
  });
};
