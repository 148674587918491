import { useState } from 'react';
import { Checkbox } from './ui/checkbox';
import { LabelGroup } from '@/features/label/types';
import { Label } from '@/features/label/components/label';
import { cn } from '@/utils';

interface TermsOfAgreementProps {
  BodyComponent: React.ReactNode;
  LabelComponent?: React.ReactNode;
  onAccept: () => void;
  accepted: boolean;
}

export function TermsOfAgreement({ BodyComponent, LabelComponent, onAccept, accepted = false }: TermsOfAgreementProps) {
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(accepted);
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;

    // Check if the scroll position is close to the bottom
    const isCloseToBottom = Math.abs(scrollTop + clientHeight - scrollHeight) <= 5;

    if (!isScrolledToBottom) {
      setIsScrolledToBottom(isCloseToBottom);
    }
  };

  return (
    <div className="rounded-md border divide-y flex flex-col h-18">
      <div className="max-h-[200px] terms  overflow-y-auto  p-4" onScroll={handleScroll}>
        {BodyComponent}
      </div>
      <div className="flex items-center space-x-4 w-full  p-4 ">
        <Checkbox
          id="terms"
          disabled={!isScrolledToBottom || accepted}
          className={cn(
            'data-[state=checked]:bg-green-600 relative data-[state=checked]:text-white data-[state=checked]:border-green-600',
            accepted && 'opacity-[100_!important]'
          )}
          onCheckedChange={() => isScrolledToBottom && !accepted && onAccept()}
        />
        <label
          htmlFor="terms"
          className={cn(
            'text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
            accepted && 'opacity-[100_!important]'
          )}
        >
          {LabelComponent ??
            (!accepted ? (
              <Label name="accept" groupName={LabelGroup.GLOBAL} />
            ) : (
              <Label name="accepted" groupName={LabelGroup.GLOBAL} />
            ))}
        </label>
      </div>
    </div>
  );
}
