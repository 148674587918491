import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { CheckCircle } from 'react-bootstrap-icons';

export function NothingToPay() {
  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center">
          <CheckCircle className="mr-2 text-green-500 h-6" />
          <Label name="nothing_to_pay" groupName={LabelGroup.ORGANIZER_PAYMENT} />
        </CardTitle>
      </CardHeader>
      <CardContent>
        <p>
          <Label name="nothing_to_pay_description" groupName={LabelGroup.ORGANIZER_PAYMENT} />
        </p>
      </CardContent>
    </Card>
  );
}
