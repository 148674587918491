import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Organization } from '../types';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { MoreHorizontal } from 'lucide-react';
import { cn } from '@/utils';
import { useOrganizerStore } from '@/stores/organizerStore';

interface OrganizationListItemProps {
  organization: Organization;
  onDelete: () => void;
}

export function OrganizationListItem({ organization }: OrganizationListItemProps) {
  const navigate = useNavigate();

  // const { data: user } = useMe({});

  // const userId = user?.userId;

  // const isOwner = organization?.users?.some(({ userId: _userId, mainContact }) => userId === _userId && mainContact) || organization?.users?.length === 1;

  const setOrganizerId = useOrganizerStore(state => state.setOrganizationId);

  return (
    <Card className="flex items-center max-w-full justify-between">
      <CardHeader>
        <CardTitle className="flex items-center gap-x-2 text-lg">{organization.organizerName}</CardTitle>
        <div className="flex flex-col gap-y-2 text-sm">
          <span className="text-muted-foreground flex gap-x-1 ">
            {organization?.users?.length}
            <span>
              <Label name="organization_members_count" groupName={LabelGroup.ORGANIZER_ORGANIZATION_LIST} />
            </span>
          </span>
          <div className="flex">
            {organization?.users?.map(({ user, mainContact }, index) => (
              <Tooltip>
                <TooltipTrigger>
                  <Avatar
                    key={user.userId}
                    className="size-8 font-semibold text-xs"
                    style={{
                      zIndex: organization?.users?.length - index,
                      transform: `translateX(-${index * 10}px)`
                    }}>
                    <AvatarFallback className={cn(' border-card border-2  font-semibold', mainContact ? 'bg-brand text-white ' : 'bg-brand-medium text-brand')}>{user.username?.[0].toUpperCase()}</AvatarFallback>
                  </Avatar>
                </TooltipTrigger>
                <TooltipContent className="p-2">{user.username}</TooltipContent>
              </Tooltip>
            ))}
          </div>
        </div>
      </CardHeader>
      <CardContent className="flex items-center p-4 space-x-4"></CardContent>
      <CardFooter className="p-4">
        <DropdownMenu>
          <DropdownMenuTrigger className="block md:hidden">
            <MoreHorizontal className="w-4 h-4" />
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem>
              <Link to={`/organizations/edit/${organization.organizerId}`}>
                <Label name="edit_organization_button_text" groupName={LabelGroup.ORGANIZER_ORGANIZATION_LIST} />
              </Link>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
        <div className="space-x-2 hidden md:flex items-center ">
          <Button
            variant="outline"
            size="sm"
            onClick={() => {
              setOrganizerId(organization.organizerId);
              navigate('/events/create');
            }}>
            <Label name="create_event_button_text" groupName={LabelGroup.ORGANIZER_ORGANIZATION_LIST} />
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => {
              setOrganizerId(organization.organizerId);
              navigate(`/organizations/edit/${organization.organizerId}`);
            }}>
            <Label name="edit_organization_button_text" groupName={LabelGroup.ORGANIZER_ORGANIZATION_LIST} />
          </Button>
{/* 
          {isOwner && (
            <DeleteButton
              onDelete={onDelete}
              TitleComponent={<Label name="delete_organization_title" groupName={LabelGroup.ORGANIZER_ORGANIZATION_LIST} />}
              DescriptionComponent={<Label name="delete_organization_description" groupName={LabelGroup.ORGANIZER_ORGANIZATION_LIST} />}
              ButtonComponent={
                <Button variant="outline" size="sm">
                  <Label name="delete_organization_button_text" groupName={LabelGroup.ORGANIZER_ORGANIZATION_LIST} />
                </Button>
              }
            />
          )} */}
        </div>
      </CardFooter>
    </Card>
  );
}
