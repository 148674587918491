import { DataTableFacetedFilter } from '@/components/ui/data-table-faceted-filter';
import { statuses } from '../../data/event-data';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { useEvents } from '../../api/getEvents';
import { useManagers } from '../../api/getManagers';

interface LocationFiltersProps {
  table: any;
}

export function EventFilters({ table }: LocationFiltersProps) {
  const { data: events = [] } = useEvents({
    assignedToMe: false,
  });

  const { data: _managers = [] } = useManagers({});

  const organizers = [...new Set(events.map((event) => event.organizerName))];
  const managers = _managers.reduce((acc: any, event) => {
    if (!acc.some((manager: any) => manager.value === event.managerId)) {
      acc.push({ value: event.managerId, label: event.username });
    }
    return acc;
  }, []);

  return (
    <div className="space-x-2 mx-2">
      <DataTableFacetedFilter
        column={table.getColumn('status')}
        TitleComponent={<Label name="status" groupName={LabelGroup.MANAGER_EVENT_LIST} />}
        options={statuses}
      />
      {/* <DataTableFacetedFilter
        column={table.getColumn('category')}
        TitleComponent={<Label name="categories" groupName={LabelGroup.MANAGER_EVENT_LIST} />}
        options={categories}
      /> */}
      <DataTableFacetedFilter
        column={table.getColumn('organizerName')}
        TitleComponent={<Label name="organizer" groupName={LabelGroup.MANAGER_EVENT_LIST} />}
        options={
          organizers?.map((organizer) => ({
            value: organizer,
            label: organizer,
          })) || []
        }
      />
      <DataTableFacetedFilter
        column={table.getColumn('assignedUserId')}
        TitleComponent={<Label name="managers" groupName={LabelGroup.MANAGER_EVENT_LIST} />}
        options={managers}
      />
    </div>
  );
}
