import Axios, { AxiosRequestConfig } from 'axios';
import { API_URL } from '@/config';
import storage from '@/utils/storage';
import { queryClient } from './react-query';
import { jwtDecode } from 'jwt-decode';
// import storage from '@/utils/storage';

const DISABLED_INTERCEPTORS = ['/refresh-token', '/login', '/logout'];

let isRefreshing = false;

export async function getRefreshToken() {
  try {
    isRefreshing = true;
    const response = await axios.post('/refresh-token', {
      refreshToken: storage.getRefreshToken()
    });

    const accessToken = (
      response as
        | {
            accessToken: string;
            refreshToken: string;
          }
        | any
    )?.accessToken;

    const refreshToken = (
      response as
        | {
            accessToken: string;
            refreshToken: string;
          }
        | any
    )?.refreshToken;

    if (accessToken) {
      storage.setAccessToken(accessToken);
      storage.setRefreshToken(refreshToken);
    } // Store the refreshed token

    return accessToken;
  } catch (error) {
    console.error('Error refreshing token:', error);
    storage.clearAccessToken(); // Clear token if refresh fails
    throw error;
  } finally {
    isRefreshing = false;
  }
}

async function authRequestInterceptor(config: AxiosRequestConfig) {
  const token = storage.getAccessToken();
  const refreshToken = storage.getRefreshToken();

  if (!config.headers) config.headers = {};

  if (token) {
    config.headers.authorization = `Bearer ${token}`;
  }

  config.headers.Accept = 'application/json';

  if (((token && hasTokenExpired(token)) || (!token && refreshToken)) && !DISABLED_INTERCEPTORS.includes(config.url!) && !isRefreshing) {
    try {
      const newToken = await getRefreshToken();
      config.headers.authorization = `Bearer ${newToken}`;
    } catch (error) {
      throw error;
    }
  }

  return config;
}

export function hasTokenExpired(token: string): boolean {
  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);

    if (!decodedToken.exp) return true;

    // Check if the token will expire within a certain time window (e.g., 5 minutes)
    return decodedToken.exp < currentTime;
  } catch (error) {
    console.error('Error checking token expiration:', error);
    return true; // Assume expired if we can't verify
  }
}

export const axios = Axios.create({
  baseURL: API_URL
});

axios.interceptors.request.use(authRequestInterceptor as any);
axios.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    if (error.response?.status === 400 && !error.config.url?.includes('session')) {
      return;
    }
    if (error.response?.status === 401 && !error.config.url?.includes('login')) {
      storage.clearAccessToken();
      storage.clearRefreshToken();
      queryClient.invalidateQueries('me');

      window.location.pathname = '/login';
    } 
    // else if (error.response?.status === 404) {
    //   if (window.location.pathname !== '/not-found' && error.config.method !== 'get') {
    //     window.location.pathname = '/not-found';
    //   }
    // } 
    else {
      return Promise.reject(error);
    }
  }
);
