import { DataTableSkeleton } from '@/components/data-table-skeleton';
import { DataTable } from '@/components/ui/data-table';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { useAllOrganizations } from '@/features/organizer/api/get-all-organizations';
import { useAllPayments } from '@/features/payment/api/get-all-payments';
import { paymentColumns } from '@/features/payment/components/payment-columns';
import { OrderRow, PayType } from '@/features/payment/types';
import { getKeyByValue } from '@/utils/get-key-by-value';
import { label } from '@/utils/label';
import { Row } from '@tanstack/react-table';
import { PaymentFilters } from '../components/(filters)/payment-filters';

export function Payments() {
  const { data: payments = [], isLoading } = useAllPayments({});
  const { data: organizations = [] } = useAllOrganizations({});

  const extendedPayments = payments?.map(payment => ({
    ...payment,
    organizerName: organizations.find(org => org.organizerId === payment.payment?.organizerId)?.organizerName ?? '',
    orgNo: organizations.find(org => org.organizerId === payment.payment?.organizerId)?.orgNo ?? '',
    invoiceNo: organizations.find(org => org.organizerId === payment.payment?.organizerId)?.invoiceOrgNo ?? '',
    productType: payment.productType ?? 0
  }));

  return (
    <div className="space-y-6 flex flex-col h-full">
      <div className="flex w-full items-center justify-between">
        <div>
          <div className="flex gap-x-1 items-center">
            <h3 className="text-xl font-semibold">
              <Label name="page_title" groupName={LabelGroup.ADMIN_PAYMENT} />
            </h3>
          </div>
        </div>
      </div>
      <div className="space-y-2">
        {!isLoading ? (
          <DataTable
            columns={paymentColumns}
            data={extendedPayments}
            columnVisibility={{
              productType: false
            }}
            FacetedFilters={PaymentFilters}
            config={{
              maxHeight: 'calc(100lvh - 250px)',
              getSearchFields: row => [
                (row as Row<OrderRow>).original?.name.toString(),
                (row as Row<OrderRow>).original.payment?.payDate.toString(),
                (row as Row<OrderRow>).original.payment?.paymentId.toString(),
                (
                  row as Row<
                    OrderRow & {
                      invoiceNo: string;
                    }
                  >
                ).original?.invoiceNo.toString(),
                (
                  row as Row<
                    OrderRow & {
                      orgNo: string;
                    }
                  >
                ).original?.orgNo.toString(),
                (row as Row<OrderRow>).original.payment?.organizerId.toString(),
                (row as Row<any>).original.payment?.organizerName?.toLowerCase()?.toString(),
                label(getKeyByValue(PayType, (row as Row<OrderRow>).original.payment?.payType) ?? '', LabelGroup.ADMIN_PAYMENT),
                (row as Row<OrderRow>).original?.amount.toString()
              ]
            }}
          />
        ) : (
          <DataTableSkeleton />
        )}
      </div>
    </div>
  );
}
