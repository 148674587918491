import { RouteObject } from 'react-router-dom';
import { Dashboard } from '@/features/admin/routes/dashboard';
import { Layout } from '@/features/admin/components/Layout';
import { ManagerEvents } from '@/features/admin/routes/manager-events';
import { ManageEvent } from '@/features/admin/routes/manage-event';
import { Profile } from '@/features/user/routes/profile';
import { Locations } from '@/features/location/routes/Locations';
import { EditLocation } from '@/features/location/routes/EditLocation';
import { Organizers } from '@/features/admin/routes/organizers';
import { Payments } from '@/features/admin/routes/payments';
import { CreateLocation } from '@/features/location/routes/create-location';
import { OrganizerDetails } from '@/features/admin/routes/organizer-details';

export const managerRoutes: RouteObject[] = [
  {
    path: '/',
    element: <Layout />,
    children: [
      { path: '/', element: <Dashboard /> },
      { path: '*', element: <Dashboard /> },
      { path: '/dashboard', element: <Dashboard /> },
      { path: '/events', element: <ManagerEvents /> },
      { path: '/events/manage/:id', element: <ManageEvent /> },
      { path: '/profile', element: <Profile /> },
      { path: '/locations', element: <Locations /> },
      { path: '/locations/edit/:id', element: <EditLocation /> },
      { path: '/locations/create', element: <CreateLocation /> },

      { path: '/organizations', element: <Organizers /> },
      { path: '/organizations/:id', element: <OrganizerDetails /> },

      { path: '/payments', element: <Payments /> },
    ],
  },
];
