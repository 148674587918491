import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { createUserSchema } from '../data';
import { useCreateUser } from '../api/create-user';
import { UserType } from '../types';
import { Checkbox } from '@/components/ui/checkbox';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { roles } from '../data/data';
import { debounce } from 'lodash';
import { checkUsername } from '../api/check-username';
import { Check, X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { emailSchema } from '@/features/auth/data/email-schema';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';

export function CreateUser() {
  const { mutateAsync: createUser, isLoading: isCreating } = useCreateUser({});
  const navigate = useNavigate();

  const form = useForm<z.infer<typeof createUserSchema>>({
    resolver: zodResolver(createUserSchema),
    defaultValues: {
      username: '',
      telephone: '',
      name: '',
      role: UserType.ADMIN,
      emailVerified: true,
    },
  });

  const handleSubmit = async (values: z.infer<typeof createUserSchema>) => {
    try {
      const user = await createUser(values);
      navigate(`/users/${user.userId}`);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    form.setValue('username', e.target.value, { shouldDirty: true });
    checkUsernameAvailability(e.target.value);
  };

  const checkUsernameAvailability = debounce(async (username: string) => {
    // Replace this URL with your actual API endpoint
    const { available } = await checkUsername(username);

    const isEmail = emailSchema.safeParse(username);

    if (available === false) {
      form.setError('username', {
        type: 'manual',
        message: 'username_not_available_validation_text',
      });
      form.setValue('usernameVerified', false, { shouldDirty: true });
    } else if (!isEmail.success) {
      form.setError('username', {
        type: 'manual',
        message: 'username_invalid',
      });
      form.setValue('usernameVerified', false, { shouldDirty: true });
    } else {
      form.clearErrors('username');
      form.setValue('usernameVerified', true, { shouldDirty: true });
    }
  }, 1000);

  return (
    <div className="w-full max-w-md">
      <Card>
        <CardHeader>
          <CardTitle>
            <Label name="user_details_card_title" groupName={LabelGroup.ADMIN_USER_CREATE} />
          </CardTitle>
        </CardHeader>
        <CardContent>
          <Form {...form}>
              <form onKeyDown={(event) => event.key === 'Enter' && event.preventDefault()} onSubmit={form.handleSubmit(handleSubmit)} className="space-y-8">
              <FormField
                control={form.control}
                name="username"
                render={({ field }) => (
                  <FormItem className="w-full relative">
                    <FormLabel>
                      <Label name="username" groupName={LabelGroup.ADMIN_USER_CREATE} />
                    </FormLabel>
                    <FormControl>
                      <Input {...field} onChange={handleUsernameChange} />
                    </FormControl>
                    <FormMessage />
                    {form.watch('usernameVerified') ? (
                      <Check className="absolute w-4 h-4 right-4 top-9 text-green-500" />
                    ) : form.watch('usernameVerified') === false ? (
                      <X className="absolute w-4 h-4 right-4 top-9 text-red-500" />
                    ) : null}
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>
                      <Label name="name" groupName={LabelGroup.ADMIN_USER_CREATE} />
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="telephone"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>
                      <Label name="telephone" groupName={LabelGroup.ADMIN_USER_CREATE} />
                    </FormLabel>
                    <FormControl>
                      <Input {...field} type="telephone" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="role"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <Label name="user_role" groupName={LabelGroup.ADMIN_USER_CREATE} />
                    </FormLabel>
                    <Select onValueChange={(value) => field.onChange(Number(value))} value={field.value.toString()}>
                      <FormControl>
                        <SelectTrigger className="[&>span]:text-left ">
                          <SelectValue />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {roles.map(({ value }) => (
                          <SelectItem key={value} value={value.toString()}>
                            <Label name={'user_role_' + value} groupName={LabelGroup.ADMIN_USER_CREATE} />
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormItem>
                <FormField
                  control={form.control}
                  name="emailVerified"
                  render={({ field }) => (
                    <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
                      <FormControl>
                        <Checkbox checked={!!field.value} onChange={(checked) => field.onChange(checked)} />
                      </FormControl>
                      <div className="space-y-1 leading-none">
                        <FormLabel>
                          <Label name="email_verified" groupName={LabelGroup.ADMIN_USER_CREATE} />
                        </FormLabel>
                        <FormDescription>
                          <Label name="email_verified_description" groupName={LabelGroup.ADMIN_USER_CREATE} />
                        </FormDescription>
                      </div>
                    </FormItem>
                  )}
                />
                <FormMessage />
              </FormItem>
              <Button
                disabled={
                  isCreating || form.formState.isSubmitting || !form.formState.isDirty || !form.formState.isValid
                }
                type="submit"
              >
                <Label name={isCreating ? 'creating' : 'create'} groupName={LabelGroup.ADMIN_USER_CREATE} />
              </Button>
            </form>
          </Form>
        </CardContent>
      </Card>
    </div>
  );
}
