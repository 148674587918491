import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useToast } from '@/components/ui/use-toast';
import { label } from '@/utils/label';
import { LabelGroup } from '@/features/label/types';

export const registerInvoicePayment = async (organizationId?: number): Promise<number | null | string> => {
  return axios.put(`/payment/registerinvoicepayment/${organizationId}`);
};

type UseOptions = {
  organizerId: number;
  config?: MutationConfig<typeof registerInvoicePayment>;
};

export const useRegsiterInvoicePayment = ({ config, organizerId }: UseOptions) => {
  const { toast } = useToast();

  return useMutation({
    onError: () => {
      toast({
        variant: 'destructive',
        title: label('something_went_wrong', LabelGroup.GLOBAL)
      });
    },
    onSuccess: data => {
      if ((data as string) !== '') {
        toast({
          variant: 'success',
          title: label('invoice_updated_successfully', LabelGroup.ORGANIZER_ORGANIZATION_EDIT)
        });
      }

      queryClient.invalidateQueries(['order-rows', organizerId]);
    },
    ...config,
    mutationFn: organizationId => registerInvoicePayment(organizationId)
  });
};
