import { Asterisk } from '@/components/ui/asterisk';
import { FormControl, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { label } from '@/utils/label';
import { EventCategory, EventTime } from '../types';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Setting } from '@/features/settings/types';
import { Button } from '@/components/ui/button';
import { PlusIcon, Trash2 } from 'lucide-react';
import { useMemo } from 'react';
import { Combobox } from '@/components/ui/combobox';
import storage from '@/utils/storage';
import { UserType } from '@/features/user';
import { LabelGroup } from '@/features/label/types';
import { Label } from '@/features/label/components/label';
import { useMe } from '@/features/auth/api/get-me';
import { formatDate } from '@/utils/formatDate';
import { formateTime } from '@/utils/formatTime';

interface EventTimeInputProps {
  field: any;
  form: any;
  days: Setting[];
}

const TIME_INTERVAL = 5;
const START_TIME = 7 * 60;
const PARTY_HELD_TIMES = ['12:00', '12:05', '12:10', '12:15', '12:20', '12:25', '19:00', '19:05', '19:10', '19:15', '19:20', '19:25'];
const THRESHOLDS = {
  TRAFFPUNKT: 2 * 60 * 60 * 1000,
  HETA_STOLEN: 15 * 60 * 1000,
  SEMINARIUM: 30 * 60 * 1000
} as unknown as {
  [key in EventCategory]: number;
};

const parseDate = (date: string) => {
  return date.includes('T') ? date.split('T')[0] : date?.split(' ')[0];
};

const formatTime = (date: string) => {
  const dateString = date.includes('T') ? date.split('T')[1] : date?.split(' ')[1];
  const timeString = dateString?.split(':')?.slice(0, 2).join(':');

  return timeString;
};

export function EventTimeInput({ field, form, days }: EventTimeInputProps) {
  const category = form.watch('category');

  const organizationType = form.watch('organizationType');

  const timeslots = useMemo(() => {
    const timeSlots = [];
    const TIME_INTERVAL = 5 * 60 * 1000; // 5 minutes in milliseconds

    const firstDay = days?.[0]?.value; // The first day in the days array
    const lastDay = days?.[days.length - 1]?.value; // The last day in the days array

    let startDate = new Date(firstDay);
    startDate.setHours(7, 0, 0, 0); // Start at 07:00 on the first day

    let endDate = new Date(lastDay);

    endDate.setHours(23, 55, 0, 0); // End at 23:55 on the last day

    // Loop through each day
    while (startDate <= endDate) {
      // Get the current day in YYYY-MM-DD format
      const date = startDate.toLocaleDateString('sv-SE');

      const hours = startDate.getHours().toString().padStart(2, '0');
      const minutes = startDate.getMinutes().toString().padStart(2, '0');
      const timeSlot = `${hours}:${minutes}`;
      const timeValue = `${hours}:${minutes}`;

      // Add the time slot object with the day, slot time, and tick (timestamp)
      timeSlots.push({
        date,
        hours: parseInt(hours),
        minutes: parseInt(minutes),
        value: timeSlot,
        label: timeValue,
        ticks: +startDate // Tick in milliseconds
      });

      // Move the start time forward by 5 minutes
      if (startDate.getHours() === 23 && startDate.getMinutes() === 55) {
        startDate.setHours(0, 0, 0, 0);
        startDate.setDate(startDate.getDate() + 1);
      } else {
        startDate = new Date(startDate.getTime() + TIME_INTERVAL);
      }

      // Check if we've reached 23:55 and move to the next day if necessary
    }

    return timeSlots;
  }, [days]);

  const startTimes = (date: string) => {
    const startDate = parseDate(date);

    return timeslots.filter(({ hours, minutes, date, value }) => {
      if (date !== startDate) return false;

      const isFirstDay = startDate === days[0].value;

      if (organizationType !== 'Riksdagsparti' && !isFirstDay && PARTY_HELD_TIMES.includes(value)) {
        return false;
      }

      // Restrict start times to 07:00 to 23:55
      const timeValue = hours * 60 + minutes;
      const startTimeValue = START_TIME;
      const endTimeValue = START_TIME + 24 * 60 - TIME_INTERVAL;

      return timeValue >= startTimeValue && timeValue <= endTimeValue;
    });
  };

  const endTimes = (date: string) => {
    const startTime = formatTime(date);
    const startDate = new Date(parseDate(date) + 'T' + startTime);

    if (!startTime) return [];

    // Calculate maximum allowed duration in milliseconds (23 hours 55 minutes)
    const maxDurationMs = 24 * 60 * 60 * 1000 - 5 * 60 * 1000;

    return timeslots
      .filter(({ ticks }) => {
        switch (category) {
          case EventCategory.TRAFFPUNKT: // Larger than 2 hours and within max duration
            return +ticks >= +startDate + 2 * 60 * 60 * 1000 && +ticks <= +startDate + maxDurationMs;
          case EventCategory.HETA_STOLEN: // Larger than 15 minutes and less than 30 minutes and within max duration
            return +ticks >= +startDate + 15 * 60 * 1000 && +ticks <= +startDate + 30 * 60 * 1000 && +ticks <= +startDate + maxDurationMs;
          case EventCategory.SEMINARIUM: // Larger than 30 minutes and within max duration
            return +ticks >= +startDate + 30 * 60 * 1000 && +ticks <= +startDate + maxDurationMs;
          default:
            return +ticks >= +startDate + 2 * 60 * 60 * 1000 && +ticks <= +startDate + maxDurationMs;
        }
      })
      .map(timeslot => ({
        ...timeslot,
        suffix: timeslot.date !== startDate.toLocaleDateString('sv-SE') ? timeslot.date : ''
      }));
  };

  const handleDateChange = ({ key, value, eventTimeId }: { key: 'eventStartTime' | 'eventEndTime' | 'eventDay'; value: string; eventTimeId: number }) => {
    const times = field.value;
    const index = times.findIndex((time: EventTime) => time.eventTimeId === eventTimeId);

    if (index === -1) return console.error('EventTimeId not found');

    if (key === 'eventDay') {
      times[index].eventStart = value + ' ' + (times[index].eventStart?.split(' ')[1] !== undefined ? times[index].eventStart?.split(' ')[1] : '');
      times[index].eventEnd = value + ' ' + (times[index].eventEnd?.split(' ')[1] !== undefined ? times[index].eventEnd?.split(' ')[1] : '');
    }

    if (key === 'eventStartTime') {
      let updatedStartTime;

      if (times[index].eventStart.includes('T')) {
        updatedStartTime = times[index].eventStart.split('T')[0] + ' ' + value;
      } else {
        updatedStartTime = times[index].eventStart.split(' ')[0] + ' ' + value;
      }

      // Update eventStart with the new value
      times[index].eventStart = updatedStartTime;

      // Check if eventEnd is earlier or equal to the new start time
      const newStartDate = +new Date(updatedStartTime);
      const eventEndDate = +new Date(times[index].eventEnd);

      const threshold = THRESHOLDS[category as EventCategory] ?? 2 * 60 * 60 * 1000;

      if (threshold && Math.abs(eventEndDate - newStartDate) < threshold) {
        // Clear eventEnd and set to the date portion of the new start
        times[index].eventEnd = times[index].eventStart.split(' ')[0];
      }
    }

    if (key === 'eventEndTime') {
      const endTime = typeof value === 'string' && value.includes(':') ? value.split(':') : null;
      if (!endTime) return; // Check for valid endTime

      // const endTimeHours = parseInt(endTime[0]);
      // const endTimeMinutes = parseInt(endTime[1]);

      const eventStart = times?.[index]?.eventStart;

      const startTime = eventStart?.includes('T') ? eventStart?.split('T')?.[1]?.split(':') : eventStart?.split(' ')?.[1]?.split(':');

      if (!startTime) return;

      const startTimeHours = parseInt(startTime?.[0]);
      const startTimeMinutes = parseInt(startTime?.[1]);

      if (isNaN(startTimeHours) || isNaN(startTimeMinutes)) return;

      // if (endTimeHours < startTimeHours || (endTimeHours === startTimeHours && endTimeMinutes < startTimeMinutes)) {
      //   const newEndTime = new Date(+new Date(times[index].eventStart) + 24 * 60 * 60 * 1000).toLocaleDateString('sv-SE');

      //   times[index].eventEnd = parseDate(newEndTime.toString()) + ' ' + value;

      // }

      if (times?.[index]?.eventEnd?.includes('T')) {
        times[index].eventEnd = (times[index].eventEnd?.split('T')[0] ?? times[index].eventStart?.split('T')[0]) + ' ' + value;
      } else {
        times[index].eventEnd = (times[index].eventEnd?.split(' ')[0] ?? times[index].eventStart?.split(' ')[0]) + ' ' + value;
      }
    }

    const formattedTimes = times.map((time: EventTime) => ({
      ...time,
      eventStart: time.eventStart,
      eventEnd: time.eventEnd
    }));

    field.onChange(formattedTimes);
  };

  const handleDeleteDate = (eventTimeId: number) => {
    field.onChange(field.value.filter((time: EventTime) => time.eventTimeId !== eventTimeId));
  };

  const handleAddDate = () => {
    const eventTimeIds =
      form
        .getValues('times')
        .filter((time: EventTime) => time.eventTimeId < 0)
        .map((time: EventTime) => time.eventTimeId) || [];

    const newEventTimeId = eventTimeIds.length > 0 ? (Math.min(...eventTimeIds) ?? 0) - 1 : -1;

    const eventId = form.watch('eventId');

    field.onChange(
      field.value.concat({
        eventTimeId: newEventTimeId,
        eventId: eventId,
        inserted: '',
        eventStart: '',
        eventEnd: ''
      })
    );
  };

  const { data: user } = useMe({ config: { enabled: !!storage.getAccessToken() } });
  const userType = user?.role;

  const labelGroup = userType === UserType.ADMIN || userType === UserType.MANAGER ? LabelGroup.MANAGER_EVENT_HANDLING : LabelGroup.ORGANIZER_EVENT_FORM;

  return (
    <FormItem id="event_time" className="flex items-start w-full md:flex-row flex-col gap-4">
      <FormLabel className="w-48 mt-5 font-semibold">
        <Label name="days" groupName={labelGroup} />
        <Asterisk className="ml-2" />
      </FormLabel>
      <div className="flex w-full flex-col md:w-auto gap-4">
        {field.value?.map((date: EventTime) => (
          <div className="space-y-2 w-full" key={date?.eventTimeId}>
            <div className="flex md:flex-row flex-col gap-4" key={date.eventTimeId}>
              <Select
                onValueChange={value =>
                  handleDateChange({
                    key: 'eventDay',
                    value,
                    eventTimeId: date.eventTimeId
                  })
                }
                value={parseDate(formatDate(date.eventStart.toString()))}>
                <FormControl className="w-full md:w-40 max-w-96 md:max-w-40">
                  <SelectTrigger className="mt-[0_!important]">
                    <SelectValue placeholder={label('select_days_placeholder', labelGroup)} />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {days.map(({ value }) => (
                    <SelectItem key={value} value={value}>
                      {value}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <div>
                <div className="flex items-center gap-x-1 max-w-96">
                  <Combobox
                    options={startTimes(date.eventStart?.toString() ?? '')}
                    value={date?.eventStart?.includes('T') ? formateTime(formatDate(date.eventStart?.toString() ?? '')) : date.eventStart?.split(' ')[1] ?? ''}
                    disabled={!date.eventStart}
                    PlaceholderComponent="00:00"
                    filter={(value: string, search: string) => (value.startsWith(search) ? 1 : 0)}
                    onChange={value =>
                      handleDateChange({
                        key: 'eventStartTime',
                        value: value,
                        eventTimeId: date.eventTimeId
                      })
                    }
                  />
                  <span className="text-lg">-</span>

                  <Combobox
                    options={endTimes(formatDate(date.eventStart?.toString() ?? ''))}
                    value={date?.eventEnd?.includes('T') ? formateTime(formatDate(date.eventEnd?.toString() ?? '')) : date.eventEnd?.split(' ')[1] ?? ''}
                    PlaceholderComponent="00:00"
                    disabled={!(formateTime(date.eventStart?.toString() ?? '') ?? null)}
                    filter={(value: string, search: string) => (value.startsWith(search) ? 1 : 0)}
                    onChange={value =>
                      handleDateChange({
                        key: 'eventEndTime',
                        value: value,
                        eventTimeId: date.eventTimeId
                      })
                    }
                  />
                </div>
              </div>
              {field.value.length > 1 && (
                <Button type="button" variant="ghost" className="cursor-pointer w-fit" aria-label="Delete date" onClick={() => handleDeleteDate(date.eventTimeId)}>
                  <Trash2 className="h-4 w-4" />
                </Button>
              )}
            </div>
          </div>
        ))}
        <div>
          <FormMessage className="mb-2" />
          {category === 'Träffpunkt' && (
            <Button type="button" variant="outline" onClick={handleAddDate}>
              <PlusIcon className="h-4 w-4 mr-2" />
              <Label name="add_date" groupName={labelGroup} />
            </Button>
          )}
        </div>
      </div>
    </FormItem>
  );
}
