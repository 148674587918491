import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { label } from '@/utils/label';
import storage from '@/utils/storage';
import { confirmEmail } from '../api/confirmEmail';
import { AlertTriangle, BadgeCheck } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Spinner } from '@/components/ui/spinner';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { LabelGroup } from '@/features/label/types';
import { Label } from '@/features/label/components/label';

export function ConfirmEmail() {
  const [error, setError] = useState<string | null>(null);
  const [confirmed, setConfirmed] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const confirmationToken = searchParams.get('token')?.replace(/\s/g, '+');

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        if (!confirmationToken) return;

        const isLoggedIn = storage.getAccessToken();

        if (isLoggedIn) {
          navigate('/');
        } else {
          // const token = await confirmEmail({ token: confirmationToken });
          await confirmEmail({ token: confirmationToken });
          // // storage.setAccessToken(token as unknown as string);

          setConfirmed(true);
        }
      } catch (error) {
        setError(label('invalid_token', LabelGroup.CONFIRM_EMAIL));
      }
    })();
  }, [confirmationToken]);

  if (!confirmed && !error) {
    return (
      <div className="h-screen w-full flex items-center justify-center text-md">
        <div className="flex items-center">
          <Spinner className="w-4 h-4 mr-2" />
          <Label name="confirming_email" groupName={LabelGroup.CONFIRM_EMAIL} />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="h-screen w-full flex items-center justify-center text-md">
        <Alert className="w-fit [&>svg]:text-red-500 bg-red-50 border-red-100 border text-red-900">
          <AlertTriangle className="h-4 w-4 " />
          <AlertTitle>
            <Label name="invalid_token" groupName={LabelGroup.CONFIRM_EMAIL} />
          </AlertTitle>
          <AlertDescription className="space-x-1">
            <Label name="invalid_token_description" groupName={LabelGroup.CONFIRM_EMAIL} />
            <Link to="/login" className="underline text-sm">
              <Label name="login" groupName={LabelGroup.CONFIRM_EMAIL} />
            </Link>
          </AlertDescription>
        </Alert>
      </div>
    );
  }

  return (
    <div className="h-screen w-full flex flex-col items-center justify-center text-md space-y-4">
      <div className="flex flex-col space-y-4 justify-center items-center p-4">
        <BadgeCheck className="w-12 h-12 " />
        <span className="text-md text-center font-semibold">
          <Label name="email_confirmed" groupName={LabelGroup.CONFIRM_EMAIL} />
        </span>
      </div>
      <div>
        <Button onClick={() => navigate('/login')}>
          <Label name="login" groupName={LabelGroup.CONFIRM_EMAIL} />
        </Button>
      </div>
    </div>
  );
}
