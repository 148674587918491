import { FormControl, FormDescription, FormField, FormItem, FormLabel } from '@/components/ui/form';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Textarea } from '@/components/ui/textarea';
import { Asterisk } from '@/components/ui/asterisk';
import { EventCategory } from '../../types';
import { Comment } from '../comment';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { cn } from '@/utils';
import { useEventStore } from '@/stores/eventStore';
import { CommentField } from '@/features/admin/components/CommentField';
import { UserType } from '@/features/user';
import { LabelGroup } from '@/features/label/types';
import { Label } from '@/features/label/components/label';
import { useMe } from '@/features/auth/api/get-me';
import { useFormContext } from 'react-hook-form';

interface ContentSectionProps {
  defaultCollapsed?: boolean;
}

export function ContentSection({ defaultCollapsed = false }: ContentSectionProps) {
  const form = useFormContext();

  const maxDescriptionLength = form.watch('category') === EventCategory.TRAFFPUNKT ? 500 : 1000;

  const { data: user } = useMe({});
  const userType = user?.role;

  const labelGroup = userType === UserType.ADMIN || userType === UserType.MANAGER ? LabelGroup.MANAGER_EVENT_HANDLING : LabelGroup.ORGANIZER_EVENT_FORM;

  const comments = useEventStore(state => state.comments);

  const status = form.watch('status');

  const titleComments = comments?.filter(comment => comment.logText.field === 'title');
  const socialIssueComments = comments?.filter(comment => comment.logText.field === 'social');
  const descriptionComments = comments?.filter(comment => comment.logText.field === 'description');

  return (
    <div className={cn('space-y-6 ')}>
      <Card>
        <Accordion type="single" collapsible defaultValue={defaultCollapsed ? '' : 'content'}>
          <AccordionItem value="content" className="[&[data-state=open]>.card-header]:bg-gray-medium [&[data-state=open]>.card-header]:text-white [&[data-state=open]>.card-header]:rounded-t-md ">
            <CardHeader className="py-3">
              <AccordionTrigger className="py-0 text-md">
                <CardTitle className="text-left ">
                  <Label name="event_content_title" groupName={labelGroup} />
                  <Asterisk className="ml-2 asterisk" />
                </CardTitle>
              </AccordionTrigger>
            </CardHeader>
            <AccordionContent className="pt-4">
              <CardContent className="pt-6 text-sm space-y-6">
                <FormField
                  control={form.control}
                  name="title"
                  render={({ field }) => (
                    <FormItem id="event_title">
                      <FormLabel className="font-semibold">
                        <Label name="event_title" groupName={labelGroup} /> <Asterisk className="ml-2" />
                      </FormLabel>
                      <FormDescription>
                        <Label name="event_title_description" groupName={labelGroup} />
                      </FormDescription>
                      <FormDescription className="italic">
                        <Label name="event_title_validation_100" groupName={labelGroup} />
                      </FormDescription>
                      <FormControl>
                        <Textarea {...field} className="w-full" maxLength={100} rows={1} />
                      </FormControl>
                      <FormDescription>
                        <Label name="you_have_letters_left" groupName={labelGroup} /> {100 - (field?.value?.length ?? 0)} <Label name="letters_left" groupName={labelGroup} />
                      </FormDescription>
                      {/* <FormMessage /> */}
                    </FormItem>
                  )}
                />
                {userType !== UserType.ORGANIZER ? (
                  <>
                    {form.watch('title_lastVersion') !== '' && (
                      <div className="space-y-2 border-l-4 p-4 border-l-amber-500  bg-zinc-50 rounded-tr-md rounded-br-md shadow-sm border">
                        <FormLabel className="font-semibold">
                          <Label name="event_title_last_version" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
                        </FormLabel>
                        <div className="pointer-events-none">{form.watch('title_lastVersion')}</div>
                      </div>
                    )}

                    <FormField
                      control={form.control}
                      name="titleComment"
                      render={({ field }) => (
                        <FormItem className="bg-zinc-100 rounded-md p-4 px-4 border">
                          <FormLabel>
                            <Label name="comment_for_title" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
                          </FormLabel>
                          <FormControl className="w-[300px]">
                            <CommentField field={field} comments={titleComments ?? []} />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                  </>
                ) : (
                  <Comment status={status} comment={titleComments?.[0]} />
                )}

                <FormField
                  control={form.control}
                  name="socialIssue"
                  render={({ field }) => (
                    <FormItem id="social_issue">
                      <FormLabel className="font-semibold">
                        <Label name="event_social_issue_title" groupName={labelGroup} /> <Asterisk className="ml-2" />
                      </FormLabel>
                      <FormDescription>
                        <Label name="event_social_issue_subtitle" groupName={labelGroup} />
                      </FormDescription>
                      <FormDescription className="italic">
                        <Label name="event_social_issue_validation" groupName={labelGroup} />
                      </FormDescription>
                      <FormControl>
                        <Textarea {...field} className="w-full" maxLength={300} rows={4} />
                      </FormControl>
                      <FormDescription className="italic">
                        <Label name="you_have_letters_left" groupName={labelGroup} /> {300 - (field?.value?.length ?? 0)} <Label name="letters_left" groupName={labelGroup} />
                      </FormDescription>
                      {/* <FormMessage /> */}
                    </FormItem>
                  )}
                />

                {/* TODO: make comment component reusable with conditional rendering */}
                {userType !== UserType.ORGANIZER ? (
                  <>
                    {form.watch('socialIssue_lastVersion') !== '' && (
                      <div className="space-y-2 border-l-4 p-4 border-l-amber-500  bg-zinc-50 rounded-tr-md rounded-br-md shadow-sm border">
                        <FormLabel>
                          <Label name="event_social_issue_last_version" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
                        </FormLabel>
                        <div className="pointer-events-none">{form.watch('socialIssue_lastVersion')}</div>
                      </div>
                    )}
                    <FormField
                      control={form.control}
                      name="socialIssueComment"
                      render={({ field }) => (
                        <FormItem className="bg-zinc-100 rounded-md p-4 px-4 border">
                          <FormLabel className="font-semibold">
                            <Label name="comment_for_social_issue" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
                          </FormLabel>
                          <FormControl className="w-[300px]">
                            <CommentField field={field} comments={socialIssueComments ?? []} />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                  </>
                ) : (
                  <Comment status={status} comment={socialIssueComments?.[0]} />
                )}

                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem id="event_description">
                      <FormLabel className="font-semibold">
                        <Label name="event_description_title" groupName={labelGroup} />
                      </FormLabel>
                      <FormDescription>
                        <Label name="event_description_subtitle" groupName={labelGroup} />
                      </FormDescription>
                      <FormDescription className="italic">
                        <Label name={`event_description_validation_${maxDescriptionLength}`} groupName={labelGroup} />
                      </FormDescription>
                      <FormControl>
                        <Textarea {...field} className="w-full" maxLength={maxDescriptionLength} rows={maxDescriptionLength / 100} />
                      </FormControl>
                      <FormDescription>
                        <Label name="you_have_letters_left" groupName={labelGroup} /> {maxDescriptionLength - (field?.value?.length ?? 0)} <Label name="letters_left" groupName={labelGroup} />
                      </FormDescription>
                    </FormItem>
                  )}
                />
                {userType !== UserType.ORGANIZER ? (
                  <>
                    {form.watch('description_lastVersion') !== '' && (
                      <div className="space-y-2 border-l-4 p-4 border-l-amber-500  bg-zinc-50 rounded-tr-md rounded-br-md shadow-sm border">
                        <FormLabel>
                          <Label name="event_description_last_version" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
                        </FormLabel>
                        <div className="pointer-events-none opacity-50 ">{form.watch('description_lastVersion')}</div>
                      </div>
                    )}
                    <FormField
                      control={form.control}
                      name="descriptionComment"
                      render={({ field }) => (
                        <FormItem className="bg-zinc-100 rounded-md p-4 px-4 border">
                          <FormLabel>
                            <Label name="comment_for_description" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />{' '}
                          </FormLabel>
                          <FormControl className="w-[300px]">
                            <CommentField field={field} comments={descriptionComments ?? []} />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                  </>
                ) : (
                  <Comment status={status} comment={descriptionComments?.[0]} />
                )}
              </CardContent>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </Card>
    </div>
  );
}
