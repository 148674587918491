import { useState } from 'react';
import { Input } from './ui/input';
import { Button } from './ui/button';
import { Eye, EyeOff } from 'lucide-react';

interface PasswordInputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

export function PasswordInput({ ...props }: PasswordInputProps) {
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  return (
    <div className="relative">
      <Input {...props} useDebounce={false}  type={passwordVisible ? 'text' : 'password'} className="pr-8" />
      <Button
        type="button"
        onClick={() => setPasswordVisible(!passwordVisible)}
        variant="ghost"
        size="xs"
        className="absolute right-1 top-1/2 -translate-y-1/2"
      >
        {passwordVisible ? <EyeOff className="w-4 h-4" /> : <Eye className="w-4 h-4" />}
      </Button>
    </div>
  );
}
