import { ColumnDef } from '@tanstack/react-table';
import { TLocation } from '../types';
import { DataTableColumnHeader } from '@/components/ui/data-table-column-header';
import { Check, X } from 'lucide-react';
import { LabelGroup } from '@/features/label/types';
import { Label } from '@/features/label/components/label';
import { LocationTableRowActions } from './location-table-row-action';

export const columns: ColumnDef<TLocation>[] = [
  {
    accessorKey: 'name',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        TitleComponent={
          <Label name="table_header_cell_title_location_name" groupName={LabelGroup.ADMIN_LOCATION_LIST} />
        }
      />
    ),
  },
  {
    accessorKey: 'longitude',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        TitleComponent={
          <Label name="table_header_cell_title_location_longitude" groupName={LabelGroup.ADMIN_LOCATION_LIST} />
        }
      />
    ),
  },
  {
    accessorKey: 'latitude',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        TitleComponent={
          <Label name="table_header_cell_title_location_latitude" groupName={LabelGroup.ADMIN_LOCATION_LIST} />
        }
      />
    ),
  },
  {
    accessorKey: 'category',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        TitleComponent={
          <Label name="table_header_cell_title_location_category" groupName={LabelGroup.ADMIN_LOCATION_LIST} />
        }
      />
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: 'active',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        TitleComponent={<Label name="table_header_cell_title_active" groupName={LabelGroup.ADMIN_LOCATION_LIST} />}
      />
    ),
    cell: ({ row }) =>
      row.original.active ? (
        <Check className="table_header_cell_title_h-4 w-4 text-emerald-500" />
      ) : (
        <X className="w-4 text-destructive" />
      ),
    filterFn: (row, id, value) => {
      return value.toString() === row?.getValue(id)?.toString();
    },
  },
  {
    accessorKey: 'action',
    header: '',
    enableHiding: false,
    cell: ({ row }) => <LocationTableRowActions row={row} />,
  },
];
