import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { useToast } from '@/components/ui/use-toast';
import { label } from '@/utils/label';
import { LabelGroup } from '@/features/label/types';

const API_PATH = '/manager/assign/organization-events/';

type UpdateDto = {
  data: {
    organizerId: number;
    managerId: number;
  };
};

export const assignManager = ({ data }: UpdateDto): Promise<void> => {
  return axios.patch(
    `${API_PATH}${data.organizerId}`,
    {},
    {
      params: {
        managerId: data.managerId,
      },
    }
  );
};

type UseUpdateOptions = {
  config?: MutationConfig<typeof assignManager>;
};

export const useAssignManagersToAllOrganizationEvents = ({ config }: UseUpdateOptions) => {
  const { toast } = useToast();

  return useMutation({
    onSuccess: (_: any) => {
      toast({ variant: 'success', title: label('manager_assigned', LabelGroup.MANAGER_EVENT_HANDLING) });
    },
    ...config,
    mutationFn: assignManager,
  });
};
