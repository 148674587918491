import * as React from 'react';

import { cn } from '@/utils';
import { debounce } from 'lodash';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  useDebounce?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, useDebounce = true, ...props }, ref) => {
  const [isTriggered, setIsTriggered] = React.useState(false);
  const [value, setValue] = React.useState<string | number | readonly string[] | undefined>(props.value ?? '');

  const debouncedOnChange = React.useMemo(
    () =>
      debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onChange) {
          props.onChange(event);
          setIsTriggered(false);
        }
      }, 500),
    [props.onChange]
  );

  React.useEffect(() => {
    if (!isTriggered && props.value !== value) {
      setValue(props.value);
    }
  }, [props.value]);

  React.useEffect(() => {
    // Cleanup the debounce on unmount to prevent memory leaks
    return () => {
      debouncedOnChange.cancel();
    };
  }, [debouncedOnChange]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setIsTriggered(true);
    setValue(newValue); // Update the UI immediately
    debouncedOnChange(event); // Debounce the side-effect
  };

  return (
    <input
      type={type}
      className={cn(
        'flex h-10 w-full rounded-none border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
        className
      )}
      ref={ref}
      {...props}
      name={props.name}
      id={props.id}
      maxLength={props.maxLength}
      value={value ?? props.value}
      onChange={useDebounce ? handleChange : props.onChange}
    />
  );
});

Input.displayName = 'Input';

export { Input };
