import * as XLSX from 'xlsx';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Download } from 'lucide-react';
import { Button } from './button';
import { ExcelIcon } from '../excel-icon';
import { LabelGroup } from '@/features/label/types';
import { Label } from '@/features/label/components/label';

interface DataExportDropdownProps {
  table: any;
}

export function DataExportDropdown({ table }: DataExportDropdownProps) {
  const rows = table.getRowModel()?.rows ?? [];

  const exportToExcel = () => {
    // Convert the table instance's data to JSON
    const jsonData = rows?.map((row: any) => ({
      ...row.original,
    }));

    // Convert JSON to worksheet
    const ws = XLSX.utils.json_to_sheet(jsonData);

    // Generate a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Write the workbook to a file
    XLSX.writeFile(wb, 'export.xlsx');
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Button variant="outline" className="items-center flex">
          <Download className="mr-2 w-4 h-4" />
          <Label name="export" groupName={LabelGroup.GLOBAL} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>
          <Label name="file_type" groupName={LabelGroup.GLOBAL} />
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={exportToExcel} disabled={rows?.length === 0}>
          <ExcelIcon />
          Excel
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
