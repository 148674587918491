import { Skeleton } from '@/components/ui/skeleton';
import { useLabels } from '@/features/label/api/getLabels';
import * as React from 'react';

type LabelProviderProps = {
  children: React.ReactNode;
};

export const LabelProvier = ({ children }: LabelProviderProps) => {
  const { isLoading } = useLabels({
    languageCode: 'swe',
    config: {}
  });

  if (isLoading) {
    return (
      <div className="w-screen h-screen flex items-center justify-between">
        <Skeleton className="max-w-xl h-96" />
      </div>
    );
  }

  return children;
};
