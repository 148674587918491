import { useSettings } from '@/features/settings/api/getSettings';

export function useTimePeriodRestrictions() {
  const { data: settings = {} } = useSettings({});

  const currentDate = +new Date(
    new Date().toLocaleDateString('sv-SE', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    })
  );

  const lastCreateDate = settings?.deadline?.find(deadline => deadline.value === 'LastCreateDate')?.data as string;
  const firstCreateDate = settings?.deadline?.find(deadline => deadline.value === 'FirstCreateDate')?.data as string;
  const lastDeleteDate = settings?.deadline?.find(deadline => deadline.value === 'LastDeleteDate')?.data as string;
  const closeDate = settings?.deadline?.find(deadline => deadline.value === 'CloseDate')?.data as string;

  const isPortalClosed = closeDate
    ? currentDate >=
      +new Date(
        new Date(closeDate?.toString()).toLocaleDateString('sv-SE', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        })
      )
    : false;

  const isEventPeriodClosed = lastCreateDate
    ? currentDate >
      +new Date(
        new Date(lastCreateDate?.toString()).toLocaleDateString('sv-SE', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        })
      )
    : false;

  const isEventPeriodOpen = firstCreateDate
    ? currentDate >=
      +new Date(
        new Date(firstCreateDate?.toString()).toLocaleDateString('sv-SE', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        })
      )
    : false;

  const isDeleteEnabled = lastDeleteDate
    ? currentDate <=
      +new Date(
        new Date(lastDeleteDate?.toString()).toLocaleDateString('sv-SE', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        })
      )
    : false;

  return {
    isPortalClosed,
    isEventPeriodClosed,
    isEventPeriodOpen,
    isDeleteEnabled
  };
}
