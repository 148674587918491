import { FormField, FormItem } from '@/components/ui/form';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { UserType } from '@/features/user';
import { LabelGroup } from '@/features/label/types';
import { Label } from '@/features/label/components/label';
import { useFormContext } from 'react-hook-form';
import { EventPersonInput } from '../EventPersonInput';
import { useMe } from '@/features/auth/api/get-me';

export function EventPersonSection({ defaultCollapsed = false }: { defaultCollapsed?: boolean }) {
  const form = useFormContext();
  const { data: user } = useMe({});
  const userType = user?.role;

  const labelGroup = userType === UserType.ADMIN || userType === UserType.MANAGER ? LabelGroup.MANAGER_EVENT_HANDLING : LabelGroup.ORGANIZER_EVENT_FORM;

  return (
    <Card>
      <Accordion type="single" collapsible defaultValue={defaultCollapsed ? '' : 'event_persons'}>
        <AccordionItem className="[&[data-state=open]>.card-header]:bg-gray-medium [&[data-state=open]>.card-header]:text-white [&[data-state=open]>.card-header]:rounded-t-md" value="event_persons">
          <CardHeader className="py-3">
            <AccordionTrigger className="py-0 text-md">
              <CardTitle>
                <Label name={'event_persons_title'} groupName={labelGroup} />
              </CardTitle>
            </AccordionTrigger>
          </CardHeader>
          <AccordionContent>
            <CardContent className="pt-6 text-sm space-y-6">
              <FormField
                control={form.control}
                name="persons"
                render={({ field }) => (
                  <FormItem className="w-auto">
                    <EventPersonInput form={form} field={field} />
                  </FormItem>
                )}
              />
            </CardContent>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </Card>
  );
}
