import * as React from 'react';

import { cn } from '@/utils';
import { debounce } from 'lodash';

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  autoHeight?: boolean;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(({ className, autoHeight = false, ...props }, ref) => {
  const [value, setValue] = React.useState(props.value || null);

  const debouncedOnChange = React.useMemo(
    () =>
      debounce(
        (
          event:
            | React.ChangeEvent<HTMLTextAreaElement>
            | {
                target: {
                  value: string;
                };
              }
        ) => {
          if (props.onChange) {
            props.onChange({
              target: {
                value: (event.target as HTMLTextAreaElement).value.replace(/<br \/>/g, '\n') // Replace <br /> with newline
              }
            } as React.ChangeEvent<HTMLTextAreaElement>);
          }
        },
        500
      ),
    [props.onChange]
  );

  React.useEffect(() => {
    // Cleanup the debounce on unmount to prevent memory leaks
    return () => {
      debouncedOnChange.cancel();
    };
  }, [debouncedOnChange]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value?.replace(/<br \/>/g, '\n');
    setValue(newValue); // Update the UI immediately
    debouncedOnChange(event); // Debounce the side-effect
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      setValue(prev => `${prev}\n`); // Add a newline character
      debouncedOnChange({
        target: {
          ...e.target,
          value: `${value}\n`
        } as any
      });
      if (autoHeight) {
        const textarea = e.target as HTMLTextAreaElement;
        textarea.style.height = 'auto'; // Reset height
        textarea.style.height = `${textarea.scrollHeight + 29}px`; // Adjust height based on content
      }
      e.preventDefault(); // Prevent default behavior of creating a new line
    }
  };

  const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (autoHeight) {
      const textarea = e.target;
      textarea.style.height = 'auto'; // Reset height
      textarea.style.height = `${textarea.scrollHeight + 5}px`; // Adjust height based on content
    }
    handleChange(e); // Handle the actual change
  };

  return (
    <textarea
      className={cn(
        'flex min-h-[80px] w-full rounded-none border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
        className
      )}
      ref={ref}
      aria-invalid={props['aria-invalid']}
      aria-describedby={props['aria-describedby']}
      onKeyDown={handleKeyDown} // Handling the enter key event
      name={props.name}
      rows={props.rows}
      id={props.id}
      onInput={handleInput} // Adjust height on input
      maxLength={props.maxLength}
      // {...props}
      onChange={handleChange}
      value={(value ?? props.value)?.toString().replace(/\\n/g, '<br />')}
    />
  );
});
Textarea.displayName = 'Textarea';

export { Textarea };
