export function formatDate(dateString: string) {
  // Ensure dateString is in ISO 8601 format and is marked as UTC

  if (!dateString.includes('T')) {
    return dateString;
  }
  
  const isoDateString = dateString.endsWith('Z') ? dateString : `${dateString}Z`;

  // Convert to local date and time
  const localDate = new Date(isoDateString);

  // Format the date using the options
  const formattedDate = localDate.toLocaleDateString('sv-SE', {
    timeZone: 'Europe/Stockholm',
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });

  return formattedDate;
}
