import { Button } from '@/components/ui/button';
import { eventReviewSchema } from '../data/event-review-schema';
import { UseFormReturn } from 'react-hook-form';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { useState } from 'react';
import { Checkbox } from '@/components/ui/checkbox';
import { FormField, FormItem } from '@/components/ui/form';

interface NotifyChangeProps {
  form: UseFormReturn<Zod.infer<typeof eventReviewSchema>>;
  onSubmit: any;
  dialog: boolean;
}

export function NotifyChange({ form, onSubmit, dialog = false }: NotifyChangeProps) {
  const [noifyChange, setNotifyChange] = useState(false);

  const handleSubmit = () => {
    form.setValue('notify', noifyChange, { shouldDirty: false });
    form.trigger();
    onSubmit();
  };

  if (!dialog) {
    return (
      <FormField
        control={form.control}
        name="notify"
        render={({ field }) => (
          <FormItem className="flex flex-row items-start space-x-3 space-y-0">
            <div className="items-top flex space-x-2">
              <Checkbox id="notifyChange" className='mt-1'  checked={!!field.value} onCheckedChange={field.onChange} />
              <div className="grid gap-1.5 leading-none">
                <label
                  htmlFor="notifyChange"
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  <Label name="notify_change_terms" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
                </label>
              </div>
            </div>
          </FormItem>
        )}
      />
    );
  }

  return (
    <div className="fixed h-screen w-screen z-40 left-0 top-0 ">
      <div className="bg-black/70 w-screen h-screen"></div>
      <Card className="absolute max-w-md left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
        <CardHeader>
          <CardTitle>
            <Label name="notify_change" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
          </CardTitle>
          <CardDescription>
            <Label name="rnotify_change_description" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="items-top flex space-x-2">
            <Checkbox
              id="notifyChange"
              checked={noifyChange}
              onCheckedChange={(checked: boolean) => setNotifyChange(checked)}
              className="peer h-5 w-5 text-brand mt-1 focus:ring-brand"
            />
            <div className="grid gap-1.5 leading-none">
              <label
                htmlFor="notifyChange"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                <Label name="notify_change_terms" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
              </label>
            </div>
          </div>
        </CardContent>

        <CardFooter className="flex justify-end gap-x-2">
          <Button onClick={handleSubmit}>
            <Label name="send" groupName={LabelGroup.GLOBAL} />
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}
