import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { useSettings } from '../api/getSettings';
import { Setting } from '../types';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Form, FormControl, FormField, FormItem, FormLabel } from '@/components/ui/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { Button } from '@/components/ui/button';
import { useUpdateSetting } from '../api/update-setting';
import { useToast } from '@/components/ui/use-toast';
import { label } from '@/utils/label';
import { Checkbox } from '@/components/ui/checkbox';
import { Input } from '@/components/ui/input';

const schema = z.object({
  settings: z.array(
    z.object({
      settingId: z.number(),
      value: z.string(),
      name: z.string(),
      sortOrder: z.number(),
      data: z.nullable(z.boolean()),
    })
  ),
});

export function GroundLocationSettings() {
  const { data: settings } = useSettings({});

  const groundLocationSettings = Object.values(settings?.streetAddress || []).map((value: Setting) => ({
    ...value,
    data: typeof value.data === 'string' ? JSON.parse(value.data) : value.data, // Ensure data conforms to the expected structure
  }));

  const settingMutation = useUpdateSetting({});
  const { toast } = useToast();

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    values: {
      settings: groundLocationSettings ?? [],
    },
  });

  const handleSubmit = async (values: z.infer<typeof schema>) => {
    try {
      const data = values.settings.map((setting) => ({
        ...setting,
        data: null,
      }));

      const promises = data.map((setting) => settingMutation.mutateAsync(setting));
      const results = await Promise.allSettled(promises);
      const allIsFulfilled = results.every((result) => result.status === 'fulfilled');

      const responseTitle = allIsFulfilled ? 'settings_updated_successfully' : 'error_updating_settings';

      toast({
        variant: allIsFulfilled ? 'success' : 'destructive',
        title: label(responseTitle, LabelGroup.ADMIN_SETTINGS),
      });
    } catch (error) {
      console.error('Error updating settings', error);
    }
  };

  if (!settings?.day) return null; // Handle missing data gracefully

  return (
    <Form {...form}>
      <form
        onKeyDown={(event) => event.key === 'Enter' && event.preventDefault()}
        onSubmit={form.handleSubmit(handleSubmit)}
        className="space-y-8"
      >
        <Card className="w-[600px]">
          <CardHeader>
            <CardTitle>
              <Label name="ground_location_settings_title" groupName={LabelGroup.ADMIN_SETTINGS} />
            </CardTitle>
            <CardDescription>
              <Label name="ground_location_settings_description" groupName={LabelGroup.ADMIN_SETTINGS} />
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-2">
            <div className="space-y-4">
              {form.watch('settings').map((setting, index) => (
                <div key={setting.settingId} className="flex gap-4 items-center">
                  <FormField
                    control={form.control}
                    name={`settings.${index}.value`}
                    render={({ field }) => (
                      <FormItem className="flex items-center w-full">
                        <FormControl>
                          <Input {...field} className="w-full" maxLength={100} />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name={`settings.${index}.data`}
                    render={({ field }) => {
                      return (
                        <FormItem className="flex flex-row items-center space-x-3 space-y-0">
                          <FormControl>
                            <Checkbox
                              checked={field.value === false}
                              onCheckedChange={() => field.onChange(!!field.value ? !field.value : false)}
                            />
                          </FormControl>
                          <FormLabel className="font-normal">
                            <Label name={'hidden'} groupName={LabelGroup.ADMIN_SETTINGS} />
                          </FormLabel>
                        </FormItem>
                      );
                    }}
                  />
                </div>
              ))}
            </div>
          </CardContent>
          <CardFooter>
            <Button disabled={!form.formState.isDirty} type="submit">
              <Label name="Save" groupName={LabelGroup.GLOBAL} />
            </Button>
          </CardFooter>
        </Card>
      </form>
    </Form>
  );
}
