const PRODUCTS_AND_MARKETING_OPTIONS = ['environmentStationary', 'environmentPrint', 'environmentFlyer', 'environmentBattery'];

const WASTE_AND_RECYCLING_OPTIONS = ['environmentPlastic', 'environmentRecycling', 'environmentDisposable', 'environmentSourceSorting'];

const FOOD_OPTIONS = ['environmentNoFood', 'environmentFood', 'environmentFoodEcological', 'environmentFoodLocallyProduced', 'environmentFoodEthical', 'environmentWater'];

const APPROVED_FOOD_OPTIONS = ['environmentFoodEcological', 'environmentFoodLocallyProduced', 'environmentFoodEthical', 'environmentWater'];

const SERVICE_OPTIONS = ['environmentServiceQuestion', 'environmentServiceElectricity', 'environmentServiceTravel', 'environmentServiceCooking'];

export { PRODUCTS_AND_MARKETING_OPTIONS, WASTE_AND_RECYCLING_OPTIONS, FOOD_OPTIONS, SERVICE_OPTIONS, APPROVED_FOOD_OPTIONS };
