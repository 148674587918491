import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { useSettings } from '../api/getSettings';
import { Setting } from '../types';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { Button } from '@/components/ui/button';
import { useUpdateSetting } from '../api/update-setting';
import { useToast } from '@/components/ui/use-toast';
import { label } from '@/utils/label';
import { CalendarPopover } from '@/components/ui/calendar-popver';

const schema = z.object({
  settings: z.array(
    z.object({
      settingId: z.number(),
      value: z.string(),
      name: z.string(),
      sortOrder: z.number(),
      data: z.string()
    })
  )
});

export function DeadlineSettings() {
  const { data: settings } = useSettings({});

  const deadlineSettings = Object.values(settings?.deadline || []).map((value: Setting) => ({
    ...value,
    data: value?.data?.toString() ?? '' // Ensure data conforms to the expected structure
  }));

  const settingMutation = useUpdateSetting({});
  const { toast } = useToast();

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    values: {
      settings: deadlineSettings?.sort((a, b) => a.sortOrder - b.sortOrder) ?? []
    }
  });

  const handleSubmit = async (values: z.infer<typeof schema>) => {
    try {
      const data = values.settings.map(setting => ({
        ...setting
      }));

      const promises = data.map(setting => settingMutation.mutateAsync(setting));
      const results = await Promise.allSettled(promises);
      const allIsFulfilled = results.every(result => result.status === 'fulfilled');

      const responseTitle = allIsFulfilled ? 'settings_updated_successfully' : 'error_updating_settings';

      toast({
        variant: allIsFulfilled ? 'success' : 'destructive',
        title: label(responseTitle, LabelGroup.ADMIN_SETTINGS)
      });
    } catch (error) {
      console.error('Error updating settings', error);
    }
  };

  if (!settings?.deadline) return null; // Handle missing data gracefully

  return (
    <Form {...form}>
      <form onKeyDown={event => event.key === 'Enter' && event.preventDefault()} onSubmit={form.handleSubmit(handleSubmit)} className="space-y-8">
        <Card className="w-[600px]">
          <CardHeader>
            <CardTitle>
              <Label name="DeadlineSettingsCardTitle" groupName={LabelGroup.ADMIN_SETTINGS} />
            </CardTitle>
            <CardDescription>
              <Label name="DeadlineSettingsCardDescription" groupName={LabelGroup.ADMIN_SETTINGS} />
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-2">
            <div className="space-y-4">
              {form
                .watch('settings')
                .sort((a, b) => a.sortOrder - b.sortOrder)
                .map((setting, index) => (
                  <div key={setting.settingId} className="grid grid-cols-2">
                    <div>
                      <FormField
                        control={form.control}
                        name={`settings.${index}.data`}
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>
                              <Label name={setting.value} groupName={LabelGroup.ADMIN_SETTINGS} />
                            </FormLabel>
                            <FormControl>
                              <CalendarPopover
                                date={new Date(field.value)}
                                mode="single"
                                selected={new Date(field.value)}
                                onSelect={date =>
                                  date &&
                                  field.onChange(
                                    date
                                      .toLocaleDateString('sv-SE', {
                                        day: 'numeric',
                                        month: 'numeric',
                                        year: 'numeric'
                                      })
                                      .toString()
                                  )
                                }
                                initialFocus
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                  </div>
                ))}
            </div>
          </CardContent>
          <CardFooter>
            <Button disabled={!form.formState.isDirty} type="submit">
              <Label name="Save" groupName={LabelGroup.GLOBAL} />
            </Button>
          </CardFooter>
        </Card>
      </form>
    </Form>
  );
}
