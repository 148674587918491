import { createLabel } from '@/features/label/api/createLabel';
import { Label as TLabel } from '@/features/label/types';
import { queryClient } from '@/lib/react-query';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { MouseEvent, useEffect, useState } from 'react';
import { ENV } from '@/config';
import { labelGroups } from '../data/label-groups';
import createDOMPurify from 'dompurify';
import { parse } from 'marked';

const DOMPurify = createDOMPurify(window);

interface LabelProps {
  name: string;
  groupName: string;
}

export function Label({ name, groupName }: LabelProps) {
  const [showDetails, setShowDetails] = useState(false);

  const labels = queryClient.getQueryData<
    (TLabel & {
      isCreating?: boolean;
    })[]
  >(['labels']);

  const label = (labels ?? []).find(
    (label: TLabel) =>
      label?.labelName?.toLowerCase() === name?.toLowerCase() &&
      label?.labelGroup?.toLowerCase() === groupName?.toLowerCase()
  );

  const labelText = label?.value;

  useEffect(() => {
    // Check if a label with the same name and group already exists or is being created
    const existingLabel = (labels ?? []).find(
      (label: TLabel) =>
        label?.labelName.toLowerCase() === name?.toLowerCase() &&
        label?.labelGroup?.toLowerCase() === groupName?.toLowerCase()
    );

    const isCreating = localStorage.getItem(`label-${name}-${groupName}`);

    if (!existingLabel && !isCreating && ENV === 'debug' && name && groupName) {
      // Create a new label

      localStorage.setItem(`label-${name}-${groupName}`, 'true');

      createLabel({
        data: {
          labelName: name,
          languageCode: 'swe',
          value: name,
          groupName: groupName,
        },
      }).then((response) => {
        queryClient.setQueryData(['labels'], labels?.concat(response));
        localStorage.removeItem(`label-${name}-${groupName}`);
      });
    }
  }, [name, groupName]);

  const handleShowLabelDetails = (event: MouseEvent<HTMLButtonElement>) => {
    if (event.ctrlKey && event.shiftKey) {
      event.stopPropagation();

      //if event children is a disbale the link

      setShowDetails(true);
    }
  };

  if (!name || !groupName) {
    return <p></p>;
  }

  return (
    <Popover open={showDetails} onOpenChange={(isOpen) => isOpen === false && showDetails && setShowDetails(false)}>
      <PopoverTrigger
        className="system-label"
        asChild
        role="definition"
        style={{
          cursor: 'unset',
          textDecoration: 'inherit',
          textAlign: 'inherit',
          fontStyle: 'inherit',
          fontWeight: 'inherit',
          width: 'fit-content',
          display: 'inline-block',
        }}
        onMouseEnter={handleShowLabelDetails}
        onMouseLeave={() => setShowDetails(false)}
        onClick={undefined}
      >
        <div
          className="s-label"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(parse(labelText ?? `${name} (${groupName})`) as string),
          }}
        />
      </PopoverTrigger>
      <PopoverContent>
        Tillhör systemtextgruppen{' '}
        <strong>'{labelGroups.find((group) => group.group === groupName)?.name ?? groupName}'</strong> och har namnet{' '}
        <strong>'{name}'</strong>
      </PopoverContent>
    </Popover>
  );
}
