import { Skeleton } from '@/components/ui/skeleton';

export function EventListSkeleton() {
  return (
    <div className="space-y-4 w-full h-full">
      <div className="flex gap-2 flex-wrap ">
        <Skeleton className="h-8 w-20 rounded-full" />
        <Skeleton className="h-8 w-20 rounded-full" />
        <Skeleton className="h-8 w-20 rounded-full" />
      </div>
      <div className="space-y-2 w-full h-full">
        <Skeleton className="h-64 w-full" />
        <Skeleton className="h-64 w-full" />
      </div>
    </div>
  );
}
