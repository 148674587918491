export const WarningIcon = () => (
  <div className="w-[80px] h-[80px]">
    <svg xmlns="http://www.w3.org/2000/svg" id="Lager_2" data-name="Lager 2" viewBox="0 0 125.72 110">
      <g id="Lager_1-2" data-name="Lager 1">
        <path
          style={{
            fill: '#fff'
          }}
          d="m124.42,96.12L70.56,4.46c-2.44-4.25-7.87-5.72-12.12-3.28-1.36.78-2.5,1.91-3.28,3.28L1.29,96.12c-3.59,6.11.71,13.88,7.7,13.88h107.73c6.98,0,11.3-7.78,7.7-13.88Zm-55.88,1.61h-11.28v-10.91h11.28v10.91Zm-.64-18.8h-10.18l-.92-46.31h12.1l-1.01,46.31Z"
        />
      </g>
    </svg>
  </div>
);
