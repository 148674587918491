import * as z from 'zod';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { login } from '@/features/auth/api/login';
import { useState } from 'react';
import { Spinner } from '../../../components/ui/spinner';
import { Eye, EyeOff } from 'lucide-react';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { useNavigate } from 'react-router-dom';

const formSchema = z.object({
  username: z.string().min(1, 'Required'),
  password: z.string().min(1, 'Required'),
});

export const LoginForm = () => {
  const [isLoading, setIloading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const navigate = useNavigate();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: '',
      password: '',
    },
  });

  async function handleSubmit(values: z.infer<typeof formSchema>) {
    try {
      form.clearErrors(); // Clear existing errors
      setIloading(true);

      await login(values);

      navigate('/');

      // if (window.location.pathname === '/login') {
      //   window.location.href = '/';
      // } else {
      //   window.location.href = window.location.href;
      // }
    } catch (error) {
      //Set error and clear it if form is changning after the submit
      form.setError('root', {
        type: 'manual',
        message: 'invalid_credentials',
      });
    } finally {
      setIloading(false);
    }
  }

  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
          <div className="space-y-8">
            <FormField
              control={form.control}
              name="username"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    <Label name="user_name" groupName={LabelGroup.LOGIN} />
                  </FormLabel>
                  <FormControl>
                    <Input useDebounce={false} placeholder="example@gmail.com" autoComplete="username" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>
                    <Label name="password" groupName={LabelGroup.LOGIN} />
                  </FormLabel>
                  <FormControl>
                    <div className="relative">
                      <Input {...field} useDebounce={false} type={passwordVisible ? 'text' : 'password'} className="pr-8" />
                      <Button
                        onClick={() => setPasswordVisible(!passwordVisible)}
                        variant="ghost"
                        size="sm"
                        type="button"
                        className="absolute right-2 top-1/2 -translate-y-1/2 "
                      >
                        {passwordVisible ? <EyeOff className="w-4 h-4" /> : <Eye className="w-4 h-4" />}
                      </Button>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          {form.formState.errors?.root?.message && (
            <div className="text-center m-2 text-sm font-medium text-destructive w-full ">
              <Label name={form.formState.errors?.root?.message} groupName={LabelGroup.LOGIN} />
            </div>
          )}
          <Button disabled={isLoading} size="sm" className="w-full flex justify-center items-center" type="submit">
            <span className="flex items-center justify-center">
              {isLoading && <Spinner className="mr-2 text-primary-foreground h-4 w-4" />}
              <Label name={isLoading ? 'logging_in_button_text' : 'login_button_text'} groupName={LabelGroup.LOGIN} />
            </span>
          </Button>
        </form>
      </Form>
    </div>
  );
};
