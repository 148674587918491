import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type OrganizerStore = {
  organizationId: number;
  setOrganizationId: (organizationId: number) => void;
};

export const useOrganizerStore = create(
  persist<OrganizerStore>(
    (set) => ({
      organizationId: 0,
      setOrganizationId: (organizationId: number) => set({ organizationId }),
    }),
    {
      name: 'organizer-storage',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
