import { ColumnDef } from '@tanstack/react-table';

import { DataTableColumnHeader } from '@/components/ui/data-table-column-header';
import { AssignManager } from './AssignManager';
import { ManagerEvent } from '../types';
import { EventStatusBadge } from '@/features/event/components/event-status-badge';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { formatDate } from '@/utils/formatDate';
import { Link } from 'react-router-dom';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { cn } from '@/utils';

export const columns: ColumnDef<ManagerEvent>[] = [
  {
    accessorKey: 'eventId',
    header: ({ column }) => <DataTableColumnHeader column={column} TitleComponent={<Label name="ID" groupName={LabelGroup.MANAGER_EVENT_LIST} />} />,
    cell: ({ row }) => <span className="font-bold">{row?.original?.draftParentId ?? row.original.eventId}</span>
  },
  {
    accessorKey: 'status',

    header: ({ column }) => <DataTableColumnHeader column={column} TitleComponent={<Label name="Status" groupName={LabelGroup.MANAGER_EVENT_LIST} />} />,
    filterFn: (row, id, value) => {
      return value.toString().includes(row.getValue(id)?.toString());
    },
    cell: ({ row }) => {
      const status = row.original.status;

      return (
        <div className=" flex  gap-2 w-[160px]">
          <EventStatusBadge status={status} className={cn('[&>label]:text-xs w-fit')} />
          {/* 
          {row.original.draftParentId && (
            <div className="bg-brand/10 text-orange-900 font-semibold text-xs flex items-center w-fit p-2 gap-2 px-2 rounded-md">
              <Files className="h-4 w-4  stroke-brand" />
              <Label name="event_draft_version" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
            </div>
          )} */}
        </div>
      );
    }
  },
  {
    accessorKey: 'sentDate',
    header: ({ column }) => <DataTableColumnHeader column={column} TitleComponent={<Label name="sent_date" groupName={LabelGroup.MANAGER_EVENT_LIST} />} />,
    cell: ({ row }) => row.original.sentDate && <span className="break-words text-sm max-w-[130px] block">{formatDate(row.original.sentDate)}</span>
  },
  {
    accessorKey: 'title',
    header: ({ column }) => <DataTableColumnHeader column={column} TitleComponent={<Label name="Title" groupName={LabelGroup.MANAGER_EVENT_LIST} />} />,
    cell: ({ row }) => (
      <Link to={`/events/manage/${row.original.eventId}`} className="underline">
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="break-words max-w-48 block truncate">{row.original.title}</span>
          </TooltipTrigger>
          <TooltipContent>
            <p>{row.original.title}</p>
          </TooltipContent>
        </Tooltip>
      </Link>
    )
  },
  {
    accessorKey: 'organizerContactEmail',
    header: ({ column }) => <DataTableColumnHeader column={column} TitleComponent={<Label name="OrganizerContactEmail" groupName={LabelGroup.MANAGER_EVENT_LIST} />} />,
    enableHiding: true,
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    }
  },
  {
    accessorKey: 'returnDate',
    header: ({ column }) => <DataTableColumnHeader column={column} TitleComponent={<Label name="ReturnDate" groupName={LabelGroup.MANAGER_EVENT_LIST} />} />,
    cell: ({ row }) => <span className="break-words max-w-48 block truncate">{row.original.returnDate && formatDate(row.original.returnDate)?.substring(0, 11)}</span>,
    filterFn: (row, id, value) => {
      return value.toString().includes(row.getValue(id)?.toString());
    }
  },
  {
    accessorKey: 'organizerName',
    header: ({ column }) => <DataTableColumnHeader column={column} TitleComponent={<Label name="Organizer" groupName={LabelGroup.MANAGER_EVENT_LIST} />} />,
    cell: ({ row }) => (
      <Link to={`/organizations/${row.original.organizerId}`} className="underline">
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="break-words max-w-48 block truncate">{row.original.organizerName}</span>
          </TooltipTrigger>
          <TooltipContent>
            <p>{row.original.organizerName}</p>
          </TooltipContent>
        </Tooltip>
      </Link>
    ),
    filterFn: (row, id, value) => {
      return value.toString().includes(row.getValue(id)?.toString());
    }
  },

  {
    accessorKey: 'assignedUserId',
    header: ({ column }) => <DataTableColumnHeader column={column} TitleComponent={<Label name="AssignedManager" groupName={LabelGroup.MANAGER_EVENT_LIST} />} />,
    cell: ({ row }) => {
      return <AssignManager event={row.original} />;
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id)) || value.toString().includes(row.getValue(id)?.toString());
    }
  },
  {
    accessorKey: 'category',
    header: ({ column }) => <DataTableColumnHeader column={column} TitleComponent={<Label name="Category" groupName={LabelGroup.MANAGER_EVENT_LIST} />} />,
    enableHiding: true,
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    }
  }

  //   {
  //     accessorKey: 'latitude',
  //     header: ({ column }) => <DataTableColumnHeader column={column} TitleComponent={<Label name="latitude" />,
  //   },
  //   {
  //     accessorKey: 'category',
  //     header: ({ column }) => <DataTableColumnHeader column={column} TitleComponent={<Label name="category" />,
  //     filterFn: (row, id, value) => {
  //       return value.includes(row.getValue(id));
  //     },
  //   },
  //   {
  //     accessorKey: 'active',
  //     header: ({ column }) => <DataTableColumnHeader column={column} TitleComponent={<Label name="Active" />,
  //     cell: ({ row }) => (row.original.active ? <Check className="h-4 w-4 text-emerald-500" /> : null),
  //     filterFn: (row, id, value) => {
  //       return value.toString() === row?.getValue(id)?.toString();
  //     },
  //   },
];
