import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Switch } from '@/components/ui/switch';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent } from '@/components/ui/card';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { GOOGLE_MAPS_API_KEY } from '@/config';
import { APIProvider, ControlPosition, Map, Marker } from '@vis.gl/react-google-maps';
import { Circle } from '@/features/event/components/(map)/circle';
import { AutocompleteInput } from '@/features/event/components/(map)/auto-complete-input';
import { useCreateLocation } from '../api/createLocation';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { categories } from '../data/data';
import { LocationCategory } from '../types';

const DEFAULT_CENTER = { lat: 57.6397398, lng: 18.286428 };

const formSchema = z.object({
  name: z.string(),
  active: z.boolean(),
  category: z.string().optional(),
  latitude: z.string(),
  longitude: z.string(),
  sortOrder: z.number().optional(),
  tag: z.string().optional(),
});

export function CreateLocation() {
  const { mutateAsync: createLocation } = useCreateLocation({});

  const navigate = useNavigate();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    values: {
      name: '',
      active: false,
      latitude: '',
      longitude: '',
      category: LocationCategory.MARKPLATSER,
    },
  });

  const changeCenter = async (newCenter: google.maps.LatLng | null) => {
    if (!newCenter) return;

    try {
      form.setValue('latitude', newCenter.lat().toString());
      form.setValue('longitude', newCenter.lng().toString());

      // const address = await fetchAddress(newCenter.lat(), newCenter.lng());

      // form.setValue('name', address);
    } catch (error) {}
  };

  const handleAutoCompleteChange = (place: google.maps.places.PlaceResult | null) => {
    if (place && place.geometry?.location) {
      form.setValue('latitude', place.geometry?.location.lat().toString());
      form.setValue('longitude', place.geometry?.location.lng().toString());

      // const streetName = place.address_components?.find((component) => component.types.includes('route'))?.long_name;
      // const streetNumber = place.address_components?.find((component) =>
      //   component.types.includes('street_number')
      // )?.long_name;

      // form.setValue('name', [streetName, streetNumber].filter((value) => value).join(' ') ?? '');
    }
  };

  async function handleSubmit(values: z.infer<typeof formSchema>) {
    try {
      const response = await createLocation({ ...values } as any);

      if (response.locationId) {
        navigate(`/locations/edit/${response.locationId}`);
      }
    } catch (error) {}
  }

  const position =
    form.watch('latitude') && form.watch('longitude')
      ? { lat: parseFloat(form.watch('latitude')), lng: parseFloat(form.watch('longitude')) }
      : null;

  return (
    <div className="space-y-6 flex flex-col p-6">
      <div className="space-y-2">
        <Card className="max-w-lg">
          <CardContent className="p-6">
            <Form {...form}>
              <form
                onKeyDown={(event) => event.key === 'Enter' && event.preventDefault()}
                onSubmit={form.handleSubmit(handleSubmit)}
                className="space-y-8"
              >
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormLabel>
                        <Label name="location_name" groupName={LabelGroup.ADMIN_LOCATION_EDIT} />
                      </FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="category"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        <Label name="category" groupName={LabelGroup.ADMIN_LOCATION_EDIT} />
                      </FormLabel>
                      <Select
                        onValueChange={(value) =>
                          field.onChange(value === LocationCategory.LOKALER_TRADGARDAR_FARTYG ? '' : value)
                        }
                        value={field.value === '' ? LocationCategory.LOKALER_TRADGARDAR_FARTYG : field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {categories.map(({ value, label }) => (
                            <SelectItem
                              key={value}
                              value={value === '' ? LocationCategory.LOKALER_TRADGARDAR_FARTYG : value}
                            >
                              {label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="latitude"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormLabel>
                        <Label name="latitude" groupName={LabelGroup.ADMIN_LOCATION_EDIT} />
                      </FormLabel>
                      <FormControl>
                        <Input type="number" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="longitude"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormLabel>
                        <Label name="longitude" groupName={LabelGroup.ADMIN_LOCATION_EDIT} />
                      </FormLabel>
                      <FormControl>
                        <Input type="number" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <APIProvider apiKey={GOOGLE_MAPS_API_KEY}>
                  <div className="bg-slate-50 p-4 space-y-2 rounded-md border">
                    <h4>
                      <Label name="location_search" groupName={LabelGroup.ADMIN_LOCATION_EDIT} />
                    </h4>
                    <AutocompleteInput center={position || DEFAULT_CENTER} onPlaceSelect={handleAutoCompleteChange} />
                    <p className="text-muted-foreground italic">
                      <Label name="location_search_description" groupName={LabelGroup.ADMIN_LOCATION_EDIT} />
                    </p>
                  </div>

                  <Map
                    className="w-full h-96"
                    defaultZoom={16}
                    zoomControlOptions={{
                      position: ControlPosition.RIGHT_BOTTOM,
                    }}
                    // center={position || DEFAULT_CENTER}
                    defaultCenter={position || DEFAULT_CENTER}
                    gestureHandling={'greedy'}
                    disableDefaultUI={true}
                  >
                    <Marker
                      position={position || DEFAULT_CENTER}
                      draggable
                      onDrag={(e) => {
                        form.setValue('latitude', e.latLng?.lat().toString() ?? '0');
                        form.setValue('longitude', e.latLng?.lng().toString() ?? '0');
                      }}
                    />
                    <Circle
                      center={position || DEFAULT_CENTER}
                      onCenterChanged={changeCenter}
                      strokeColor={'#0c4cb3'}
                      strokeOpacity={1}
                      strokeWeight={3}
                      fillColor={'#3b82f6'}
                      fillOpacity={0.3}
                      draggable
                    />
                  </Map>
                </APIProvider>
                <FormField
                  control={form.control}
                  name="active"
                  render={({ field }) => (
                    <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                      <div className="space-y-0.5">
                        <FormLabel className="text-base">
                          <Label name="location_active" groupName={LabelGroup.ADMIN_LOCATION_EDIT} />
                        </FormLabel>
                        <FormDescription>
                          <Label name="location_active_description" groupName={LabelGroup.ADMIN_LOCATION_EDIT} />
                        </FormDescription>
                      </div>
                      <FormControl>
                        <Switch checked={field.value} onCheckedChange={field.onChange} />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <Button type="submit">
                  <Label name="create_location_button_text" groupName={LabelGroup.ADMIN_LOCATION_EDIT} />
                </Button>
              </form>
            </Form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
