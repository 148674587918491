import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useToast } from '@/components/ui/use-toast';
import { label } from '@/utils/label';
import { LabelGroup } from '@/features/label/types';

const QUERY_KEY = 'organizations';

export type UpdateMainContactDto = {
  organizerUserId: number;
};

export const updateMainContact = ({ organizerUserId }: UpdateMainContactDto): Promise<unknown> => {
  return axios.put(`/organizer/update-main-contact/${organizerUserId}`);
};

type UseUpdateMainContactOptions = {
  config?: MutationConfig<typeof updateMainContact>;
};

export const useUpdateMainContact = ({ config }: UseUpdateMainContactOptions) => {
  const { toast } = useToast();

  return useMutation({
    onError: (_: any, __: any, ___: any) => {
      toast({
        variant: 'destructive',
        title: label('something_went_wrong', LabelGroup.GLOBAL),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEY]);
      queryClient.refetchQueries([QUERY_KEY]);

      toast({
        variant: 'success',
        title: label('organization_main_contact_updated', LabelGroup.ORGANIZER_ORGANIZATION_EDIT),
      });
    },
    ...config,
    mutationFn: ({ organizerUserId }: any) =>
      updateMainContact({
        organizerUserId,
      }),
  });
};
