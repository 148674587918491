import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Plus } from 'lucide-react';
// import { useCreateEvent } from '../api/createEvent';
// import { useDeleteEvent } from '../api/deleteEvent';
import { useOrganizations } from '../api/getOrganizations';
import { OrganizationListItem } from '../components/organizer-list-item';
import { useDeleteOrganization } from '../api/deleteOrganization';
// import { JoinOrganizationDialog } from '@/features/invitation/components/join-organization-dialog';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { useMe } from '@/features/auth/api/get-me';

export function Organizations() {
  const { data: user } = useMe({});

  const { data: organizations, isLoading } = useOrganizations({
    userId: user?.userId ?? 0,
    config: {
      enabled: !!user?.userId,
    },
  });

  const { mutateAsync: deleteOrganization } = useDeleteOrganization({});

  return (
    <div className="space-y-6 flex flex-col  h-full">
      <div className="flex w-full items-center justify-between">
        <div>
          <div className="flex flex-col gap-1 items-start">
            <h3 className="text-xl font-medium">
              <Label name="organizations_page_title" groupName={LabelGroup.ORGANIZER_EVENT_LIST} />
            </h3>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          {/* <JoinOrganizationDialog /> */}
          <Link to={`/organizations/create`} className="flex items-center justify-center">
            <Button variant="default" size="sm">
              <Plus className="h-4 w-4 mr-1" />
              <Label name="create_organization_button_text" groupName={LabelGroup.ORGANIZER_EVENT_LIST} />
            </Button>
          </Link>
        </div>
      </div>
      <div className="space-y-2">
        {!isLoading ? (
          organizations
            ?.sort((a, b) => a.organizerName.localeCompare(b.organizerName))
            ?.map((organization) => (
              <OrganizationListItem
                key={organization.organizerId}
                organization={organization}
                onDelete={() =>
                  deleteOrganization({
                    organizerId: organization.organizerId,
                  })
                }
              />
            ))
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}
