import CryptoJS from 'crypto-js';

export function encryptString(text: string) {
  const key = CryptoJS.lib.WordArray.random(16).toString();
  const wordArrayKey = CryptoJS.enc.Utf8.parse(key);
  const iv = CryptoJS.lib.WordArray.create([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

  const encrypted = CryptoJS.AES.encrypt(text, wordArrayKey, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  return {
    key,
    ciphertext: CryptoJS.enc.Base64.stringify(encrypted.ciphertext),
  };
}
