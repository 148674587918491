import tinymce from 'tinymce/tinymce';
import 'tinymce/skins/ui/oxide/content.inline.min.css';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
import 'tinymce/plugins/code';
import 'tinymce/themes/silver';
import 'tinymce/icons/default';
import 'tinymce/models/dom';
import 'tinymce/plugins/image';

tinymce.addI18n('sv_SE', {
  Redo: 'G\u00f6r om',
  Undo: '\u00c5ngra',
  Cut: 'Klipp ut',
  Copy: 'Kopiera',
  Paste: 'Klistra in',
  'Select all': 'Markera allt',
  'New document': 'Nytt dokument',
  Ok: 'Ok',
  Cancel: 'Avbryt',
  'Visual aids': 'Visuella hj\u00e4lpmedel',
  Bold: 'Fet',
  Italic: 'Kursiv',
  Underline: 'Understruken',
  Strikethrough: 'Genomstruken',
  Superscript: 'Upph\u00f6jd',
  Subscript: 'Neds\u00e4nkt',
  'Clear formatting': 'Rensa formatering',
  'Align left': 'V\u00e4nsterjustera',
  'Align center': 'Centrera',
  'Align right': 'H\u00f6gerjustera',
  Justify: 'Verifiera',
  'Bullet list': 'Punktlista',
  'Numbered list': 'Nummerlista',
  'Decrease indent': 'Minska indrag',
  'Increase indent': '\u00d6ka indrag',
  Close: 'St\u00e4ng',
  Formats: 'Format',
  "Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X/C/V keyboard shortcuts instead.":
    'Din webbl\u00e4sare st\u00f6djer inte direkt \u00e5tkomst till klippboken. Anv\u00e4nd kortkommandona Ctrl\u00a0+\u00a0X/C/V i st\u00e4llet.',
  Headers: 'Rubriker',
  'Header 1': 'Rubrik 1',
  'Header 2': 'Rubrik 2',
  'Header 3': 'Rubrik 3',
  'Header 4': 'Rubrik 4',
  'Header 5': 'Rubrik 5',
  'Header 6': 'Rubrik 6',
  Headings: 'Rubriker',
  'Heading 1': 'Rubrik 1',
  'Heading 2': 'Rubrik 2',
  'Heading 3': 'Rubrik 3',
  'Heading 4': 'Rubrik 4',
  'Heading 5': 'Rubrik 5',
  'Heading 6': 'Rubrik 6',
  Preformatted: 'F\u00f6rformaterad',
  Div: 'Div',
  Pre: 'Pre',
  Code: 'Kod',
  Paragraph: 'Avsnitt',
  Blockquote: 'Blockquote',
  Inline: 'Inline',
  Blocks: 'Block',
  'Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.':
    'Klistra in \u00e4r nu i textl\u00e4ge. Inneh\u00e5ll kommer att konverteras till text tills du sl\u00e5r av detta l\u00e4ge.',
  Fonts: 'Typsnitt',
  'Font Sizes': 'Teckenstorlek',
  Class: 'Klass',
  'Browse for an image': 'Bl\u00e4ddra efter en bild',
  OR: 'ELLER',
  'Drop an image here': 'Sl\u00e4pp en bild h\u00e4r',
  Upload: 'Ladda upp',
  Block: 'Block',
  Align: 'Justera',
  Default: 'Original',
  Circle: 'Cirkel',
  Disc: 'Disk',
  Square: 'Fyrkant',
  'Lower Alpha': 'Gemener',
  'Lower Greek': 'Grekiska gemener',
  'Lower Roman': 'Romerska gemener',
  'Upper Alpha': 'Versaler',
  'Upper Roman': 'Romerska versaler',
  'Anchor...': 'Ankare...',
  Name: 'Namn',
  Id: 'Id',
  'Id should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.':
    'Id skall b\u00f6rja med en bokstav och f\u00f6ljande tecken ska vara bokst\u00e4ver, nummer, punkter, understr\u00e4ck eller kolon.',
  'You have unsaved changes are you sure you want to navigate away?':
    'Du har f\u00f6r\u00e4ndringar som du inte har sparat. \u00c4r du s\u00e4ker p\u00e5 att du vill navigera vidare?',
  'Restore last draft': '\u00c5terst\u00e4ll senaste utkast',
  'Special characters...': 'Specialtecken...',
  'Source code': 'K\u00e4llkod',
  'Insert/Edit code sample': 'Infoga/Redigera k\u00e5d exempel',
  Language: 'Spr\u00e5k',
  'Code sample...': 'Kodexempel...',
  'Color Picker': 'F\u00e4rgv\u00e4ljare',
  R: 'R',
  G: 'G',
  B: 'B',
  'Left to right': 'V\u00e4nster till h\u00f6ger',
  'Right to left': 'H\u00f6ger till v\u00e4nster',
  'Emoticons...': 'Emoticons...',
  'Metadata and Document Properties': 'Metadata och dokumentegenskaper',
  Title: 'Titel',
  Keywords: 'Nyckelord',
  Description: 'Beskrivning',
  Robots: 'Robotar',
  Author: 'F\u00f6rfattare',
  Encoding: 'Encoding',
  Fullscreen: 'Fullsk\u00e4rm',
  Action: 'H\u00e4ndelse',
  Shortcut: 'Kortkommando',
  Help: 'Hj\u00e4lp',
  Address: 'Adress',
  'Focus to menubar': 'Fokusera p\u00e5 menyrad',
  'Focus to toolbar': 'Fokusera p\u00e5 verktygsrad',
  'Focus to element path': 'Fokusera p\u00e5 elements\u00f6kv\u00e4gsrad',
  'Focus to contextual toolbar': 'Fokusera p\u00e5 den kontextuella verktygsraden',
  'Insert link (if link plugin activated)': 'Infoga l\u00e4nk (om link-pluginet \u00e4r aktiverat)',
  'Save (if save plugin activated)': 'Spara (om save-pluginet \u00e4r aktiverat)',
  'Find (if searchreplace plugin activated)': 'S\u00f6k (om searchreplace-pluginet \u00e4r aktiverat)',
  'Plugins installed ({0}):': 'Installerade plugins ({0}):',
  'Premium plugins:': 'Premiumplugins:',
  'Learn more...': 'L\u00e4s mer...',
  'You are using {0}': 'Du anv\u00e4nder {0}',
  Plugins: 'Plugins',
  'Handy Shortcuts': 'Kortkommandon',
  'Horizontal line': 'Horisontell linje',
  'Insert/edit image': 'Infoga/redigera bild',
  'Image description': 'Bildbeskrivning',
  Source: 'K\u00e4lla',
  Dimensions: 'Dimensioner',
  'Constrain proportions': 'Begr\u00e4nsa proportioner',
  General: 'Generella',
  Advanced: 'Avancerat',
  Style: 'Stil',
  'Vertical space': 'Vertikaltutrymme',
  'Horizontal space': 'Horisontellt utrymme',
  Border: 'Ram',
  'Insert image': 'Infoga bild',
  'Image...': 'Bild...',
  'Image list': 'Bildlista',
  'Rotate counterclockwise': 'Rotera moturs',
  'Rotate clockwise': 'Rotera medurs',
  'Flip vertically': 'Spegelv\u00e4nd vertikalt',
  'Flip horizontally': 'Spegelv\u00e4nd horisontellt',
  'Edit image': 'Redigera bild',
  'Image options': 'Bild inst\u00e4llningar',
  'Zoom in': 'Zooma in',
  'Zoom out': 'Zooma ut',
  Crop: 'Besk\u00e4r',
  Resize: 'Skala om',
  Orientation: 'Orientera',
  Brightness: 'Ljusstyrka',
  Sharpen: 'Sk\u00e4rpa',
  Contrast: 'Kontrast',
  'Color levels': 'F\u00e4rgniv\u00e5er',
  Gamma: 'Gamma',
  Invert: 'Invertera',
  Apply: 'Applicera',
  Back: 'Tillbaka',
  'Insert date/time': 'Infoga datum/tid',
  'Date/time': 'Datum/tid',
  'Insert/Edit Link': 'Infoga/redigera l\u00e4nk',
  'Insert/edit link': 'Infoga/redigera l\u00e4nk',
  'Text to display': 'Text att visa',
  Url: 'Url',
  'Open link in...': '\u00d6ppna l\u00e4nk i...',
  'Current window': 'Nuvarande f\u00f6nster',
  None: 'Ingen',
  'New window': 'Nytt f\u00f6nster',
  'Remove link': 'Ta bort l\u00e4nk',
  Anchors: 'Bokm\u00e4rken',
  'Link...': 'L\u00e4nk...',
  'Paste or type a link': 'Klistra in eller skriv en l\u00e4nk',
  'The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?':
    'Urlen du angav verkar vara en epost adress. Vill du l\u00e4gga till ett mailto: prefix?',
  'The URL you entered seems to be an external link. Do you want to add the required http:// prefix?':
    'Urlen du angav verkar vara en extern l\u00e4nk. Vill du l\u00e4gga till http:// prefixet?',
  'Link list': 'L\u00e4nklista',
  'Insert video': 'Infoga video',
  'Insert/edit video': 'Infoga/redigera video',
  'Insert/edit media': 'Infoga/redigera media',
  'Alternative source': 'Alternativ k\u00e4lla',
  'Alternative source URL': 'Alternativ k\u00e4llwebbadress',
  'Media poster (Image URL)': 'Mediaposter (bildwebbadress)',
  'Paste your embed code below:': 'Klistra in din inb\u00e4ddningskod nedan:',
  Embed: 'Inb\u00e4ddning',
  'Media...': 'Media...',
  'Nonbreaking space': 'Avbrottsfritt mellanrum',
  'Page break': 'Sidbrytning',
  'Paste as text': 'Klistra in som text',
  Preview: 'F\u00f6rhandsgranska',
  'Print...': 'Skriv ut...',
  Save: 'Spara',
  Find: 'S\u00f6k',
  'Replace with': 'Ers\u00e4tt med',
  Replace: 'Ers\u00e4tt',
  'Replace all': 'Ers\u00e4tt alla',
  Previous: 'F\u00f6reg\u00e5ende',
  Next: 'N\u00e4sta',
  'Find and replace...': 'S\u00f6k och ers\u00e4tt...',
  'Could not find the specified string.': 'Kunde inte hitta den specifierade st\u00e4ngen.',
  'Match case': 'Matcha gemener/versaler',
  'Find whole words only': 'Hitta endast hela ord',
  'Spell check': 'Stavningskontroll',
  Ignore: 'Ignorera',
  'Ignore all': 'Ignorera alla',
  Finish: 'Avsluta',
  'Add to Dictionary': 'L\u00e4gg till i ordlista',
  'Insert table': 'Infoga tabell',
  'Table properties': 'Tabellegenskaper',
  'Delete table': 'Radera tabell',
  Cell: 'Cell',
  Row: 'Rad',
  Column: 'Kolumn',
  'Cell properties': 'Cellegenskaper',
  'Merge cells': 'Sammanfoga celler',
  'Split cell': 'Bryt is\u00e4r celler',
  'Insert row before': 'Infoga rad f\u00f6re',
  'Insert row after': 'Infoga rad efter',
  'Delete row': 'Radera rad',
  'Row properties': 'Radegenskaper',
  'Cut row': 'Klipp ut rad',
  'Copy row': 'Kopiera rad',
  'Paste row before': 'Klista in rad f\u00f6re',
  'Paste row after': 'Klistra in rad efter',
  'Insert column before': 'Infoga kolumn f\u00f6re',
  'Insert column after': 'Infoga kolumn efter',
  'Delete column': 'Radera kolumn',
  Cols: 'Kolumner',
  Rows: 'Rader',
  Width: 'Bredd',
  Height: 'H\u00f6jd',
  'Cell spacing': 'Cellmellanrum',
  'Cell padding': 'Cellpaddning',
  'Show caption': 'Visa bildtext',
  Left: 'V\u00e4nster',
  Center: 'Centrum',
  Right: 'H\u00f6ger',
  'Cell type': 'Celltyp',
  Scope: 'Omf\u00e5ng',
  Alignment: 'Justering',
  'H Align': 'H-justering',
  'V Align': 'V-justering',
  Top: 'Toppen',
  Middle: 'Mitten',
  Bottom: 'Botten',
  'Header cell': 'Huvudcell',
  'Row group': 'Radgrupp',
  'Column group': 'Kolumngrupp',
  'Row type': 'Radtyp',
  Header: 'Huvud',
  Body: 'Kropp',
  Footer: 'Fot',
  'Border color': 'Ramf\u00e4rg',
  'Insert template...': 'Infoga mall...',
  Templates: 'Mallar',
  Template: 'Mall',
  'Text color': 'Textf\u00e4rg',
  'Background color': 'Bakgrundsf\u00e4rg',
  'Custom...': 'Anpassad...',
  'Custom color': 'Anpassad f\u00e4rg',
  'No color': 'Ingen f\u00e4rg',
  'Remove color': 'Ta bort f\u00e4rg',
  'Table of Contents': 'Inneh\u00e5llsf\u00f6rteckning',
  'Show blocks': 'Visa block',
  'Show invisible characters': 'Visa onsynliga tecken',
  'Word count': 'Ordantal',
  'Words: {0}': 'Ord: {0}',
  '{0} words': '{0} ord',
  File: 'Fil',
  Edit: 'Redigera',
  Insert: 'Infoga',
  View: 'Visa',
  Format: 'Format',
  Table: 'Tabell',
  Tools: 'Verktyg',
  'Powered by {0}': 'Powered by {0}',
  'Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help':
    'Textredigerare. Tryck ALT-F9 f\u00f6r menyn. Tryck ALT-F10 f\u00f6r verktygsrader. Tryck ALT-0 f\u00f6r hj\u00e4lp.',
  'Image title': 'Bildtitel',
  'Border width': 'Kantlinjebredd',
  'Border style': 'Kantlinjestil',
  Error: 'Fel',
  Warn: 'Varning',
  Valid: 'Giltig',
  'To open the popup, press Shift+Enter': 'Tryck p\u00e5 Shift\u00a0+\u00a0Enter f\u00f6r att \u00f6ppna popup-f\u00f6nstret',
  'Rich Text Area. Press ALT-0 for help.': 'Omr\u00e5de med formaterad text. Tryck p\u00e5 ALT-0 f\u00f6r hj\u00e4lp.',
  'System Font': 'Systemtypsnitt',
  'Failed to upload image: {0}': 'Kunde inte ladda upp bild: {0}',
  'Failed to load plugin: {0} from url {1}': 'Kunde inte l\u00e4sa in insticksprogram: {0} fr\u00e5n webbadress {1}',
  'Failed to load plugin url: {0}': 'Kunde inte l\u00e4sa in webbadress f\u00f6r insticksprogram: {0}',
  'Failed to initialize plugin: {0}': 'Kunde inte initiera insticksprogram: {0}',
  example: 'exempel',
  Search: 'S\u00f6k',
  All: 'Alla',
  Currency: 'Valuta',
  Text: 'Text',
  Quotations: 'Citattecken',
  Mathematical: 'Matematiskt',
  'Extended Latin': 'Ut\u00f6kad latin',
  Symbols: 'Symboler',
  Arrows: 'Pilar',
  'User Defined': 'Anv\u00e4ndardefinierade',
  'dollar sign': 'dollartecken',
  'currency sign': 'valutatecken',
  'euro-currency sign': 'eurotecken',
  'colon sign': 'kolon',
  'cruzeiro sign': 'cruzeirotecken',
  'french franc sign': 'franctecken',
  'lira sign': 'liratecken',
  'mill sign': 'milltecken',
  'naira sign': 'nairatecken',
  'peseta sign': 'pesetastecken',
  'rupee sign': 'rupeetecken',
  'won sign': 'wontecken',
  'new sheqel sign': 'schekeltecken',
  'dong sign': 'dongtecken',
  'kip sign': 'kiptecken',
  'tugrik sign': 'tugriktecken',
  'drachma sign': 'drachmertecken',
  'german penny symbol': 'tecken f\u00f6r tysk penny',
  'peso sign': 'pesotecken',
  'guarani sign': 'guaranitecken',
  'austral sign': 'australtecken',
  'hryvnia sign': 'hryvniatecken',
  'cedi sign': 'ceditecken',
  'livre tournois sign': 'tecken f\u00f6r livre tournois',
  'spesmilo sign': 'spesmilotecken',
  'tenge sign': 'tengetecken',
  'indian rupee sign': 'tecken f\u00f6r indisk rupee',
  'turkish lira sign': 'tecken f\u00f6r turkisk lira',
  'nordic mark sign': 'tecken f\u00f6r nordisk mark',
  'manat sign': 'manattecken',
  'ruble sign': 'rubeltecken',
  'yen character': 'yentecken',
  'yuan character': 'yuantecken',
  'yuan character, in hong kong and taiwan': 'yuantecken i Hongkong och Taiwan',
  'yen/yuan character variant one': 'yen-/yuantecken variant ett',
  'Loading emoticons...': 'L\u00e4ser in emoticons...',
  'Could not load emoticons': 'Kunde inte l\u00e4sa in emoticons',
  People: 'M\u00e4nniskor',
  'Animals and Nature': 'Djur och natur',
  'Food and Drink': 'Mat och dryck',
  Activity: 'Aktivitet',
  'Travel and Places': 'Resa och platser',
  Objects: 'F\u00f6rem\u00e5l',
  Flags: 'Flaggor',
  Characters: 'Tecken',
  'Characters (no spaces)': 'Tecken (inga mellanrum)',
  'Error: Form submit field collision.': 'Fel: Kollision f\u00e4lt f\u00f6r s\u00e4ndning av formul\u00e4r.',
  'Error: No form element found.': 'Fel: Inget formul\u00e4relement hittades.',
  Update: 'Uppdatera',
  'Color swatch': 'F\u00e4rgpalett',
  Turquoise: 'Turkos',
  Green: 'Gr\u00f6n',
  Blue: 'Bl\u00e5',
  Purple: 'Lila',
  'Navy Blue': 'M\u00f6rkbl\u00e5',
  'Dark Turquoise': 'M\u00f6rkturkos',
  'Dark Green': 'M\u00f6rkgr\u00f6n',
  'Medium Blue': 'Mellanbl\u00e5',
  'Medium Purple': 'Mellanlila',
  'Midnight Blue': 'Midnattsbl\u00e5',
  Yellow: 'Gul',
  Orange: 'Orange',
  Red: 'R\u00f6d',
  'Light Gray': 'Ljusgr\u00e5',
  Gray: 'Gr\u00e5',
  'Dark Yellow': 'M\u00f6rkgul',
  'Dark Orange': 'M\u00f6rkorange',
  'Dark Red': 'M\u00f6rkr\u00f6d',
  'Medium Gray': 'Mellangr\u00e5',
  'Dark Gray': 'M\u00f6rkgr\u00e5',
  Black: 'Svart',
  White: 'Vit',
  'Switch to or from fullscreen mode': 'V\u00e4xla till eller fr\u00e5n fullsk\u00e4rmsl\u00e4ge',
  'Open help dialog': '\u00d6ppna hj\u00e4lpdialogrutan',
  history: 'historik',
  styles: 'stilar',
  formatting: 'formatering',
  alignment: 'justering',
  indentation: 'indragning',
  'permanent pen': 'permanent penna',
  comments: 'kommentarer',
  Anchor: 'Ankare',
  'Special character': 'Specialtecken',
  'Code sample': 'K\u00e5dexempel',
  Color: 'F\u00e4rg',
  Emoticons: 'Emoticons',
  'Document properties': 'Dokumentegenskaper',
  Image: 'Bild',
  'Insert link': 'Infoga l\u00e4nk',
  Target: 'M\u00e5l',
  Link: 'L\u00e4nk',
  Poster: 'Affish',
  Media: 'Media',
  Print: 'Skriv ut',
  Prev: 'F\u00f6reg\u00e5ende',
  'Find and replace': 'S\u00f6k och ers\u00e4tt',
  'Whole words': 'Hela ord',
  Spellcheck: 'R\u00e4ttstava',
  Caption: 'Rubrik',
  'Insert template': 'Infoga mall',
});
