import { DataTable } from '@/components/ui/data-table';
import { useMemo, useState } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { DataTableSkeleton } from '@/components/data-table-skeleton';
import { useUsers } from '../api/get-users';
import { User, UserType } from '../types';
import { adminColumns } from '../components/admin-columns';
import { organizerColumns } from '../components/organizer-columns';
import { managerColumns } from '../components/manager-columns';
import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Plus } from 'lucide-react';
import { Row } from '@tanstack/react-table';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';

export function Users() {
  const [tab, setTab] = useState<UserType>(UserType.ORGANIZER);

  const { data: users = [], isLoading } = useUsers({
    userType: tab as UserType,
  });

  const columns = useMemo(() => {
    switch (tab) {
      case UserType.ADMIN:
        return adminColumns;
      case UserType.MANAGER:
        return managerColumns;
      case UserType.ORGANIZER:
        return organizerColumns;
      default:
        throw [];
    }
  }, [tab]);

  const handleTabChange = (value: string) => {
    switch (value) {
      case UserType.ADMIN.toString():
        return setTab(UserType.ADMIN);
      case UserType.MANAGER.toString():
        return setTab(UserType.MANAGER);
      case UserType.ORGANIZER.toString():
        return setTab(UserType.ORGANIZER);
      default:
        throw new Error(`Unknown UserType value: ${value}`);
    }
  };

  return (
    <div className="space-y-6 flex flex-col h-full">
      <div className="flex w-full items-center justify-between">
        <div>
          <div className="flex gap-x-1 items-center">
            <h3 className="text-xl font-semibold">
              <Label name="users_page_title" groupName={LabelGroup.ADMIN_USER_LIST} />
            </h3>
          </div>
        </div>
        <div>
          <Link to="/users/create" className="flex items-center justify-center">
            <Button variant="default" size="sm">
              <Plus className="h-4 w-4 mr-1" />
              <Label name="create_user_button_text" groupName={LabelGroup.ADMIN_USER_LIST} />
            </Button>
          </Link>
        </div>
      </div>
      <div className="space-y-2">
        <Tabs
          defaultValue={UserType.ORGANIZER.toString()}
          className="w-full"
          onValueChange={handleTabChange}
          value={tab.toString()}
        >
          <TabsList>
            <TabsTrigger value={UserType.ORGANIZER.toString()}>
              <Label name="organizer_tab_text" groupName={LabelGroup.ADMIN_USER_LIST} />
            </TabsTrigger>
            <TabsTrigger value={UserType.MANAGER.toString()}>
              <Label name="manager_tab_text" groupName={LabelGroup.ADMIN_USER_LIST} />
            </TabsTrigger>
            <TabsTrigger value={UserType.ADMIN.toString()}>
              <Label name="admins_tab_text" groupName={LabelGroup.ADMIN_USER_LIST} />
            </TabsTrigger>
          </TabsList>
          {!isLoading ? (
            <>
              <TabsContent value={tab.toString()}>
                <DataTable
                  columns={columns}
                  data={users}
                  config={{
                    maxHeight: 'calc(100lvh - 320px)',
                    getSearchFields: (row) => [
                      (row as Row<User>).original.name,
                      (row as Row<User>).original.username,
                      (row as Row<User>).original.telephone,
                      (row as Row<User>).original.userId.toString(),
                    ],
                  }}
                />
              </TabsContent>
            </>
          ) : (
            <DataTableSkeleton />
          )}
        </Tabs>
      </div>
    </div>
  );
}
