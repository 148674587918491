import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { FormLabel } from '@/components/ui/form';
import { label } from '@/utils/label';
import { EventPerson, EventTime } from '../../types';
import { AccessabilityIcon } from '@/components/accessability-icon';
import { useLocations } from '@/features/location/api/getLocations';
import { checkEnvironmentDiplomaQualification } from '../../utils/check-environment-diploma-qualification';
import { UserType } from '@/features/user';
import { LabelGroup } from '@/features/label/types';
import { Label } from '@/features/label/components/label';
import { useFormContext } from 'react-hook-form';
import { useMe } from '@/features/auth/api/get-me';
import { cn } from '@/utils';
import { Facebook, Linkedin, TwitterX } from 'react-bootstrap-icons';
import { Fragment } from 'react/jsx-runtime';
import { formatDate } from '@/utils/formatDate';
import { formateTime } from '@/utils/formatTime';
import { useEvent } from '../../api/getEvent';
import { useEffect, useRef, useState } from 'react';

export function PreviewSection() {
  const { data: locations } = useLocations({
    active: true
  });
  const [containerWidth, setContainerWidth] = useState<number>(0);

  useEffect(() => {
    if (!containerRef.current) return;

    const resizeObserver = new ResizeObserver(() => {
      // Update the width state when the container's size changes
      if (containerRef.current) {
        setContainerWidth(containerRef.current.clientWidth);
      }
    });

    // Start observing the container element
    resizeObserver.observe(containerRef.current);

    // Cleanup the observer on component unmount
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const containerRef = useRef<HTMLDivElement>(null);
  const form = useFormContext();

  const location = locations?.find(location => location.locationId === form.watch('locationId'));
  const { isQualified: isEnvironmentDiplomaApproved } = checkEnvironmentDiplomaQualification(form.getValues() as any);

  const { data: user } = useMe({});
  const { data: event } = useEvent({
    eventId: form.watch('eventId'),
    config: {
      enabled: false
    }
  });

  const userType = user?.role;

  const labelGroup = userType === UserType.ADMIN || userType === UserType.MANAGER ? LabelGroup.MANAGER_EVENT_HANDLING : LabelGroup.ORGANIZER_EVENT_FORM;

  const contactPerson1Arr = [form.watch('contactPerson1Name'), form.watch('contactPerson1Title'), form.watch('contactPerson1Organization'), form.watch('showTelephone') ? form.watch('contactPerson1Telephone') : null, form.watch('showEmail') ? form.watch('contactPerson1Email') : null].filter(
    item => item
  );
  const contactPerson2Arr = [form.watch('contactPerson2Name'), form.watch('contactPerson2Title'), form.watch('contactPerson2Organization'), form.watch('showTelephone') ? form.watch('contactPerson2Telephone') : null, form.watch('showEmail') ? form.watch('contactPerson2Email') : null].filter(
    item => item
  );

  return (
    <Card ref={containerRef}>
      <CardHeader>
        <CardTitle className="flex gap-2 text-lg font-header font-semibold">
          <Label name="event_preview_title" groupName={labelGroup} />
        </CardTitle>
      </CardHeader>
      <CardContent className={cn('text-sm space-y-2 max-w-4xl')}>
        <div className="pb-8">
          <span className="font-semibold text-lg lg:text-3xl break-words " dangerouslySetInnerHTML={{ __html: form.watch('title')?.replace(/(?:\r\n|\r|\n)/g, '<br />') }} />
        </div>
        <div className={cn(containerWidth < 500 ? 'grid-cols-1 gap-2 w-full   ' : 'grid-cols-2 gap-12 w-2/3 ', 'grid  items-start ')}>
          <FormLabel className="font-bold">
            <Label name="event_organizers" groupName={labelGroup} />
          </FormLabel>
          <div className="flex flex-col items-start gap-1">
            {form.watch('organizers')?.map((organizer: any) => (
              <span key={organizer.organizationName}>
                {form.watch('organizers')?.length > 1 && '•'} {organizer.organizerName}
              </span>
            ))}
          </div>
        </div>
        <div className={cn(containerWidth < 500 ? 'grid-cols-1 gap-2 w-full   ' : 'grid-cols-2 gap-12 w-2/3 ', 'grid  items-center  ')}>
          <FormLabel className="font-bold">
            <Label name="event_days" groupName={labelGroup} />
          </FormLabel>
          <div className="flex flex-col">{form.watch('times')?.map((time: EventTime) => time.eventStart && time.eventEnd && <span key={time.eventTimeId}>{`${formatDate(time?.eventStart)}-${formateTime(formatDate(time.eventEnd))}`}</span>)}</div>
        </div>
        <div className={cn(containerWidth < 500 ? 'grid-cols-1 gap-2 w-full   ' : 'grid-cols-2 gap-12 w-2/3 ', 'grid  items-center  ')}>
          <FormLabel className="font-bold">
            <Label name="event_category" groupName={labelGroup} />
          </FormLabel>
          <div>{form.watch('category')}</div>
        </div>
        <div className={cn(containerWidth < 500 ? 'grid-cols-1 gap-2 w-full   ' : 'grid-cols-2 gap-12 w-2/3 ', 'grid  items-center  ')}>
          <FormLabel className="font-bold">
            <Label name="event_type" groupName={labelGroup} />
          </FormLabel>
          <div>{form.watch('eventType')}</div>
        </div>
        <div className={cn(containerWidth < 500 ? 'grid-cols-1 gap-2 w-full   ' : 'grid-cols-2 gap-12 w-2/3 ', 'grid  items-center  ')}>
          <FormLabel className="font-bold">
            <Label name="event_topic" groupName={labelGroup} />
          </FormLabel>
          <div>{form.watch('topic')}</div>
        </div>
        {form.watch('topic2') && (
          <div className={cn(containerWidth < 500 ? 'grid-cols-1 gap-2 w-full   ' : 'grid-cols-2 gap-12 w-2/3 ', 'grid  items-center  w-full  md:w-2/3')}>
            <FormLabel className="font-bold">
              <Label name="event_topic2" groupName={labelGroup} />
            </FormLabel>
            <div>{form.watch('topic2')}</div>
          </div>
        )}
        <div className={cn(containerWidth < 500 ? 'grid-cols-1 gap-2 w-full   ' : 'grid-cols-2 gap-12 w-2/3 ', 'grid  items-center  ')}>
          <FormLabel className="font-bold">
            <Label name="event_languages" groupName={labelGroup} />
          </FormLabel>
          <div>
            {form
              .watch('languages')
              ?.filter((language: any) => language !== '')
              ?.map((language: any) => label(language, LabelGroup.GLOBAL))
              ?.join(', ')}
          </div>
        </div>
        <div className={cn(containerWidth < 500 ? 'grid-cols-1 gap-2 w-full   ' : 'grid-cols-2 gap-12 w-2/3 ', 'grid  items-center  ')}>
          <FormLabel className="font-bold">
            <Label name="event_location" groupName={labelGroup} />
          </FormLabel>
          <div>{location && form.watch('locationId') !== -100 ? location?.name : form.watch('locationId') === -100 ? <Label name="location_confirmed_later" groupName={labelGroup} /> : <div>{location?.name}</div>}</div>
        </div>
        <div className={cn(containerWidth < 500 ? 'grid-cols-1 gap-2 w-full   ' : 'grid-cols-2 gap-12 w-2/3 ', 'grid  items-center  ')}>
          <FormLabel className="font-bold">
            <Label name="event_location_description" groupName={labelGroup} />
          </FormLabel>
          <div>{location && form.watch('locationId') !== -100 && <div>{form.watch('locationDescription')}</div>}</div>
        </div>
        <div>
          <div className="w-full space-y-2 mt-8  lg:max-w-2xl">
            <FormLabel className="font-bold">
              <Label name="event_social_issue" groupName={labelGroup} />
            </FormLabel>
            {form.watch('socialIssue') && <div className="break-words" dangerouslySetInnerHTML={{ __html: form.watch('socialIssue')?.replace(/(?:\r\n|\r|\n)/g, '<br />') }}></div>}
          </div>
          <div className="w-full space-y-2 my-8  lg:max-w-2xl">
            <FormLabel className="font-bold">
              <Label name="event_description" groupName={labelGroup} />
            </FormLabel>

            {form.watch('description') && <div className="break-words" dangerouslySetInnerHTML={{ __html: form.watch('description')?.replace(/(?:\r\n|\r|\n)/g, '<br />') }}></div>}
          </div>
        </div>

        <div className="flex flex-col items-start gap-2 pb-4">
          <FormLabel className="w-[300px] font-bold">
            <Label name="event_participants" groupName={labelGroup} />
          </FormLabel>
          <div className="w-full flex flex-col ">
            {form.watch('persons')?.map((organizer: EventPerson) => (
              <span key={organizer.eventPersonId}>
                {form.watch('persons')?.length > 1 && '• '}
                {[organizer.firstName, organizer.title ?? null, organizer.organizationName, !organizer.party || organizer.party === 'none' ? null : organizer.party]
                  .map(item => item?.replace(',', ''))
                  .filter(item => item)
                  .join(', ')}
              </span>
            ))}
          </div>
        </div>
        {form.watch('contactPerson1Name') && (
          <div className="flex flex-col items-start gap-2 pb-4">
            <FormLabel className="w-[300px] font-bold">
              <Label name="event_contact_person1" groupName={labelGroup} />
            </FormLabel>
            <div className="w-full flex md:flex-row flex-col">
              {contactPerson1Arr.map((item, index) => (
                <Fragment key={item}>
                  {item.toLowerCase().includes('@') ? (
                    <span className={cn(item && contactPerson2Arr?.[index + 1] && 'mr-1')}>
                      <a href={`mailto:${item}`} className="underline">
                        {item}
                      </a>
                      {item && contactPerson1Arr?.[index + 1] && ', '}
                    </span>
                  ) : (
                    <span className={cn('break-words', contactPerson1Arr?.[index + 1] && 'mr-1')} key={item}>
                      {item && contactPerson1Arr?.[index + 1] ? item + ',' : item}
                    </span>
                  )}
                </Fragment>
              ))}
            </div>
          </div>
        )}
        {form.watch('contactPerson2Name') && (
          <div className="flex flex-col items-start gap-2 pb-4">
            <FormLabel className="w-[300px] font-bold">
              <Label name="event_contact_person2" groupName={labelGroup} />
            </FormLabel>
            <div className="w-full flex md:flex-row flex-col">
              {contactPerson2Arr.map((item, index) => (
                <Fragment key={item}>
                  {item.toLowerCase().includes('@') ? (
                    <span className={cn(item && contactPerson2Arr?.[index + 1] && 'mr-1')}>
                      <a className={cn('break-words underline')} href={`mailto:${item}`}>
                        {item}
                      </a>
                      {item && contactPerson2Arr?.[index + 1] && ', '}
                    </span>
                  ) : (
                    <span className={cn('break-words', item && contactPerson2Arr?.[index + 1] && 'mr-1')} key={item}>
                      {item && contactPerson2Arr?.[index + 1] ? item + ',' : item}
                    </span>
                  )}
                </Fragment>
              ))}
            </div>
          </div>
        )}
        <div className={cn(containerWidth < 500 ? 'grid-cols-1 gap-2 w-full   ' : 'grid-cols-2 gap-12 w-2/3 ', 'grid  items-start  pb-4  ')}>
          <FormLabel className="font-bold w-[300px]">
            <Label name="event_accessability" groupName={labelGroup} />
          </FormLabel>
          <div className="flex w-full flex-col space-y-2">
            {form.watch('accessibilityText') && (
              <div className=" flex gap-x-2 items-start">
                <AccessabilityIcon type="text" />
                <Label name="accessibility_text" groupName={labelGroup} />
              </div>
            )}
            {form.watch('accessibilityTeleloop') && (
              <div className=" flex gap-x-2 items-start">
                <AccessabilityIcon type="teleLoop" />
                <Label name="accessibility_teleloop" groupName={labelGroup} />
              </div>
            )}
            {form.watch('accessibilityVisualInterpretation') && (
              <div className=" flex gap-x-2 items-start">
                <AccessabilityIcon type="visualInterpretation" />
                <Label name="accessibility_visual_interpretation" groupName={labelGroup} />
              </div>
            )}
            {form.watch('accessibilityWheelchairToilet') && (
              <div className=" flex gap-x-2 items-start">
                <AccessabilityIcon type="wheelchairToilet" />
                <Label name="accessibility_wheelchair_toilet" groupName={labelGroup} />
              </div>
            )}
            {form.watch('accessibilityWheelchairVenue') && (
              <div className=" flex gap-x-2 items-start">
                <AccessabilityIcon type="wheelchair" />
                <Label name="accessibility_wheelchair_venue" groupName={labelGroup} />
              </div>
            )}
            {form.watch('accessibilitySignLanguage') && (
              <div className=" flex gap-x-2 items-start">
                <AccessabilityIcon type="signLanguage" />
                <Label name="accessibility_sign_language" groupName={labelGroup} />
              </div>
            )}
            {form.watch('digitalStreamingUrl') && (
              <div className=" flex gap-x-2 items-start">
                <AccessabilityIcon type="streaming" />
                <Label name="digital_streaming" groupName={labelGroup} />
              </div>
            )}
            {isEnvironmentDiplomaApproved && (
              <div className=" flex gap-x-2 items-start">
                <AccessabilityIcon type="environmentDiploma" />
                <Label name="environment_diploma_approved" groupName={labelGroup} />
              </div>
            )}
          </div>
        </div>
        <div className={cn(containerWidth < 500 ? 'grid-cols-1 gap-2 w-full   ' : 'grid-cols-2 gap-12 w-2/3 ', 'grid  mt-4 items-center  ')}>
          <FormLabel className="font-bold">
            <Label name="event_accessability_food" groupName={labelGroup} />
          </FormLabel>
          <div className="flex w-full flex-col space-y-6">
            <Label name={!form.watch('environmentNoFood') && (form.watch('environmentFoodEcological') || form.watch('environmentFoodLocallyProduced') || form.watch('environmentFood') || form.watch('environmentFoodEthical')) ? 'yes' : 'no'} groupName={labelGroup} />
          </div>
        </div>
        <br />
        {form.watch('url1') && (
          <div className={cn(containerWidth < 500 ? 'grid-cols-1 gap-2 w-full   ' : 'grid-cols-2 gap-12 w-2/3 ', 'grid  items-start  ')}>
            <FormLabel className="font-bold">
              <Label name="url1" groupName={labelGroup} />
            </FormLabel>
            <a href={form.watch('url1').startsWith('http') ? form.watch('url1') : `https://${form.watch('url1')}`} className="flex flex-col space-y-6 underline" rel="noreferrer noopener" target="_blank">
              {form.watch('url1')}
            </a>
          </div>
        )}
        {form.watch('url2') && (
          <div className={cn(containerWidth < 500 ? 'grid-cols-1 gap-2 w-full   ' : 'grid-cols-2 gap-12 w-2/3 ', 'grid  items-start  ')}>
            <FormLabel className="font-bold">
              <Label name="url2" groupName={labelGroup} />
            </FormLabel>
            <a href={form.watch('url2').startsWith('http') ? form.watch('url2') : `https://${form.watch('url2')}`} className="flex flex-col space-y-6 underline" target="_blank">
              {form.watch('url2')}
            </a>
          </div>
        )}
        {form.watch('url3') && (
          <div className={cn(containerWidth < 500 ? 'grid-cols-1 gap-2 w-full   ' : 'grid-cols-2 gap-12 w-2/3 ', 'grid  items-start  ')}>
            <FormLabel className="font-bold">
              <Label name="url3" groupName={labelGroup} />
            </FormLabel>
            <a href={form.watch('url3').startsWith('http') ? form.watch('url3') : `https://${form.watch('url3')}`} className="flex flex-col space-y-6 underline" target="_blank">
              {form.watch('url3')}
            </a>
          </div>
        )}
        <br />
        {form.watch('linkedInUrl') && (
          <div className={cn(containerWidth < 500 ? 'grid-cols-1 gap-2 w-full   ' : 'grid-cols-2 gap-12 w-2/3 ', 'grid  items-start  ')}>
            <FormLabel className="font-bold flex items-center gap-x-2">
              <Linkedin className="h-4 w-4" />
              <Label name="linkedInUrl" groupName={labelGroup} />
            </FormLabel>
            <a className="underline" href={form.watch('linkedInUrl').startsWith('http') ? form.watch('linkedInUrl') : `https://${form.watch('linkedInUrl')}`} target="_blank">
              {form.watch('linkedInUrl')}
            </a>
          </div>
        )}
        {form.watch('xurl') && (
          <div className={cn(containerWidth < 500 ? 'grid-cols-1 gap-2 w-full   ' : 'grid-cols-2 gap-12 w-2/3 ', 'grid  items-start  ')}>
            <FormLabel className="font-bold flex items-center gap-x-2">
              <TwitterX className="h-4 w-4" />
              <Label name="xurl" groupName={labelGroup} />
            </FormLabel>
            <a className="underline" href={form.watch('xurl').startsWith('http') ? form.watch('xurl') : `https://${form.watch('xurl')}`} target="_blank">
              {form.watch('xurl')}
            </a>
          </div>
        )}

        {form.watch('facebookUrl') && (
          <div className={cn(containerWidth < 500 ? 'grid-cols-1 gap-2 w-full   ' : 'grid-cols-2 gap-12 w-2/3 ', 'grid  items-start  ')}>
            <FormLabel className="font-bold flex items-center gap-x-2">
              <Facebook className="h-4 w-4" />
              <Label name="facebookUrl" groupName={labelGroup} />
            </FormLabel>
            <a className="underline" href={form.watch('facebookUrl').startsWith('http') ? form.watch('facebookUrl') : `https://${form.watch('facebookUrl')}`} target="_blank">
              {form.watch('facebookUrl')}
            </a>
          </div>
        )}

        {/* <h6 className="py-4">
          <Label name="digital_event_title" groupName={labelGroup} />
        </h6> */}

        {form.watch('digitalStreamingType') && (
          <div className={cn(containerWidth < 500 ? 'grid-cols-1 gap-2 w-full   ' : 'grid-cols-2 gap-12 w-2/3 ', 'grid  items-center  ')}>
            <FormLabel className="font-bold">
              <Label name="digital_streaming_type" groupName={labelGroup} />
            </FormLabel>
            <div className="flex flex-col space-y-6">{form.watch('digitalStreamingType')}</div>
          </div>
        )}
        {form.watch('digitalStreamingUrl') && (
          <div className={cn(containerWidth < 500 ? 'grid-cols-1 gap-2 w-full   ' : 'grid-cols-2 gap-12 w-2/3 ', 'grid  items-start   ')}>
            <FormLabel className="font-bold">
              <Label name="digital_streaming_url" groupName={labelGroup} />
            </FormLabel>
            <a href={form.watch('digitalStreamingUrl').startsWith('http') ? form.watch('digitalStreamingUrl') : `https://${form.watch('digitalStreamingUrl')}`} target="_blank" className="flex flex-col  underline break-all">
              {form.watch('digitalStreamingUrl')}
            </a>
          </div>
        )}
        {form.watch('digitalMeetingUrl') && (
          <div className={cn(containerWidth < 500 ? 'grid-cols-1 gap-2 w-full   ' : 'grid-cols-2 gap-12 w-2/3 ', 'grid     items-start')}>
            <FormLabel className="font-bold">
              <Label name="digital_meeting_url" groupName={labelGroup} />
            </FormLabel>
            <a href={form.watch('digitalMeetingUrl').startsWith('http') ? form.watch('digitalMeetingUrl') : `https://${form.watch('digitalMeetingUrl')}`} target="_blank" className="flex flex-col underline break-all">
              {form.watch('digitalMeetingUrl')}
            </a>
          </div>
        )}
        {form.watch('digitalMeetingDescription') && (
          <div className="">
            <FormLabel className="font-bold">
              <Label name="digital_meeting_description" groupName={labelGroup} />
            </FormLabel>
            <div className="break-words" dangerouslySetInnerHTML={{ __html: form.watch('digitalMeetingDescription') && form.watch('digitalMeetingDescription')?.replace(/(?:\r\n|\r|\n)/g, '<br />') }} />
          </div>
        )}
        {form.watch('digitalArchiveUrl') && (
          <div className={cn(containerWidth < 500 ? 'grid-cols-1 gap-2 w-full   ' : 'grid-cols-2 gap-12 w-2/3 ', 'grid  items-start  ')}>
            <FormLabel className="font-bold">
              <Label name="archive_url" groupName={labelGroup} />
            </FormLabel>
            <a href={form.watch('digitalArchiveUrl').startsWith('http') ? form.watch('digitalArchiveUrl') : `https://${form.watch('digitalArchiveUrl')}`} target="_blank" className="flex flex-col  underline">
              {form.watch('digitalArchiveUrl')}
            </a>
          </div>
        )}
        <div className={cn(containerWidth < 500 ? 'grid-cols-1 gap-2 w-full   ' : 'grid-cols-2 gap-12 w-2/3 ', 'grid    items-center ')}>
          <FormLabel className="font-bold">
            <Label name="event_id" groupName={labelGroup} />
          </FormLabel>
          <div className="flex flex-col">{event?.draftParentId ?? event?.eventId}</div>
        </div>
      </CardContent>
    </Card>
  );
}
