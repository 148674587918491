import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Year } from '../types';

const QUERY_KEY = 'years';

export const getYears = (): Promise<Year[]> => {
  return axios.get(`/year`);
};

type QueryFnType = typeof getYears;

type UseSettingOption = {
  config?: QueryConfig<QueryFnType>;
};

export const useYears = ({ config }: UseSettingOption) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: [QUERY_KEY],
    queryFn: getYears,
    ...config,
  });
};
