import { AlertTriangle } from 'lucide-react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from './ui/alert-dialog';
import { label } from '@/utils/label';
import { LabelGroup } from '@/features/label/types';

interface DeleteButtonProps {
  onDelete: () => void;
  ButtonComponent: React.ReactNode;
  TitleComponent: React.ReactNode | string;
  DescriptionComponent: React.ReactNode | string;
  DeleteButtonTextComponent?: React.ReactNode | string;
}

export function DeleteButton({
  onDelete,
  ButtonComponent,
  TitleComponent,
  DescriptionComponent,
  DeleteButtonTextComponent,
}: DeleteButtonProps) {
  return (
    <AlertDialog>
      <AlertDialogTrigger className="w-fit text-destructive">{ButtonComponent}</AlertDialogTrigger>
      <AlertDialogContent className="space-y-4">
        <AlertDialogHeader>
          <AlertDialogTitle className="flex items-center">
            <AlertTriangle className="w-6 h-6 mr-3" />
            {TitleComponent}
          </AlertDialogTitle>
          <AlertDialogDescription>{DescriptionComponent}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>{label('cancel_button_text', LabelGroup.GLOBAL)}</AlertDialogCancel>
          <AlertDialogAction color="destructive" className="bg-destructive" onClick={onDelete}>
            {DeleteButtonTextComponent ?? label('confirm_deletion_button_text', LabelGroup.GLOBAL)}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
