import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import { BrowserTracing, init, showReportDialog } from '@sentry/browser';

import './index.css';

init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: [],
    }),
    // replayIntegration(),
  ],
  // Session Replay
  // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: 1.0,
  beforeSend(event, _) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception && event.event_id) {
      showReportDialog({ eventId: event.event_id });
    }
    return event;
  },
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
