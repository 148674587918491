// import { useFilePayment } from '@/features/organizer/api/get-file-payment';
// import { useOrganizerStore } from '@/stores/organizerStore';
// import { useOrganizations } from '../api/getOrganizations';
// import storage from '@/utils/storage';
// import { useMe } from '@/features/auth/api/get-me';
// import { CreateOrganization } from './create-organization';
import { Card, CardFooter, CardHeader, CardDescription, CardTitle, CardContent } from '@/components/ui/card';
import { Label } from '@/features/label/components/label';
import { Button } from '@/components/ui/button';
import { CsvIcon } from '@/components/csv-icon';
import { exportFile } from '@/features/organizer/api/export-file';
import { LabelGroup } from '@/features/label/types';
import { ExcelIcon } from '@/components/excel-icon';
import { XmlIcon } from '@/components/xml-icon';
import { useState } from 'react';
import { DownloadProgramTerms } from '../components/(download-program)/download-program-terms';
import { JsonIcon } from '@/components/json-icon';
import { useOrganizerStore } from '@/stores/organizerStore';
import { usePayments } from '@/features/payment/api/get-payments';
import { ProductType } from '@/features/payment/types';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { createFileOrderRow } from '../api/create-file-order-row';
import { queryClient } from '@/lib/react-query';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { validatePhoneNumber } from '@/features/misc/data/phone-number-regex';
import { orgNoSchema } from '@/features/misc/data/orgno-schema';
import { useOrganization } from '../api/getOrganization';
import { Input } from '@/components/ui/input';
import { Separator } from '@/components/ui/separator';
import { useUpdateOrganization } from '../api/updateOrganization';
import { useCreateOrganization } from '../api/create-organization';
import { cn } from '@/utils';
import { Skeleton } from '@/components/ui/skeleton';
import { useYear } from '@/features/settings/api/get-year';
// import { useForm } from 'react-hook-form';
// import { zodResolver } from '@hookform/resolvers/zod';
// import { z } from 'zod';
// import { createFileOrderRow } from '@/features/organizer/api/create-file-order-row';
// import { queryClient } from '@/lib/react-query';
// import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
// import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
// // import { useOrderRows } from '@/features/payment/api/get-order-rows';
// import { Payment } from '@/features/payment/routes/payment';

const programSchema = z.object({
  timePeriod: z.string()
});

const organizationSchema = z.object({
  organizerName: z.string().max(32, {
    message: 'organizer_name_max_length'
  }),
  orgNo: orgNoSchema,
  organizerId: z.number().optional(),
  signerName: z.string().optional(),
  signerEmail: z.string().email().optional().or(z.literal('')),
  signerTelephone: z
    .string()
    .optional()
    .refine(value => !value || validatePhoneNumber(value), {
      message: 'signer_telephone_invalid'
    })
    .or(z.literal(''))
});

export function DownloadProgram() {
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState<boolean>(false);

  const organizationId = useOrganizerStore(state => state.organizationId);
  const setOrganizationId = useOrganizerStore(state => state.setOrganizationId);

  const { data: orderRows } = usePayments({
    organizerId: organizationId,
    config: { enabled: !!organizationId }
  });

  const { data: organization, isLoading } = useOrganization({
    organizerId: organizationId,
    config: { enabled: !!organizationId && organizationId !== 0 }
  });

  const { data: activeYear } = useYear({ config: { enabled: true } });

  const { mutateAsync: createOrganization } = useCreateOrganization({});
  const { mutateAsync: updateOrganization } = useUpdateOrganization({});

  const programForm = useForm<z.infer<typeof programSchema>>({
    resolver: zodResolver(programSchema),
    defaultValues: {
      timePeriod: 'monthly'
    }
  });

  const organizationForm = useForm<z.infer<typeof organizationSchema>>({
    resolver: zodResolver(organizationSchema),
    defaultValues: {
      organizerName: organization?.organizerName ?? '',
      orgNo: organization?.orgNo ?? '',
      signerName: organization?.signerName ?? '',
      signerEmail: organization?.signerEmail ?? '',
      signerTelephone: organization?.signerTelephone ?? ''
    },
    values: {
      organizerId: organizationId,
      organizerName: organization?.organizerName ?? '',
      orgNo: organization?.orgNo ?? '',
      signerName: organization?.signerName ?? '',
      signerEmail: organization?.signerEmail ?? '',
      signerTelephone: organization?.signerTelephone ?? ''
    }
  });

  const handleOrganizationSubmit = async (data: z.infer<typeof organizationSchema>) => {
    try {
      if (organizationId) {
        await updateOrganization({
          ...organization,
          ...data,
          logs: organization?.logs ?? [],
          organizerId: organizationId,
          users: organization?.users ?? []
        });
      } else {
        const newOrganization = await createOrganization({ ...data } as any);
        setOrganizationId(newOrganization.organizerId);
      }
    } catch (error) {}
  };

  const handleProgramSubmit = async (data: z.infer<typeof programSchema>) => {
    try {
      await createFileOrderRow({ ...data, organizerId: organizationId });
      queryClient.invalidateQueries('payments');
    } catch (error) {}
  };

  const hasYearlyAccess = orderRows?.some(({ productType, payment }) => productType === ProductType.ProgramYearly && payment.yearId === activeYear);
  const hasOneTimeAccess = orderRows?.some(({ productType, payment }) => productType === ProductType.ProgramOneTime && payment.yearId === activeYear);

  if (isLoading) {
    return (
      <div className="space-y-6">
        <Skeleton className="w-full h-64" />
        <Skeleton className="w-full h-screen" />
      </div>
    );
  }

  if (hasYearlyAccess || (programForm.formState.isSubmitSuccessful && hasOneTimeAccess)) {
    return (
      <Card className="w-full flex flex-col h-full justify-between">
        <CardHeader>
          <CardTitle className="text-xl">
            <Label name="export_card_title" groupName={LabelGroup.ORGANIZER_DOWNLOAD_PROGRAM} />
          </CardTitle>
          <CardDescription>
            <Label name="export_card_description" groupName={LabelGroup.ORGANIZER_DOWNLOAD_PROGRAM} />
          </CardDescription>
        </CardHeader>
        <CardFooter className="space-x-2">
          <Button onClick={() => exportFile('csv')} size="sm" variant="outline">
            <CsvIcon />
            CSV
          </Button>
          <Button onClick={() => exportFile('xlsx')} size="sm" variant="outline">
            <ExcelIcon />
            Excel
          </Button>
          <Button onClick={() => exportFile('xml')} size="sm" variant="outline">
            <XmlIcon />
            XML
          </Button>
          <Button onClick={() => exportFile('json')} size="sm" variant="outline">
            <JsonIcon />
            JSON
          </Button>
        </CardFooter>
      </Card>
    );
  }

  return (
    <div className="space-y-6">
      <Form {...organizationForm}>
        <form onKeyDown={event => event.key === 'Enter' && event.preventDefault()} onSubmit={organizationForm.handleSubmit(handleOrganizationSubmit)}>
          <Card>
            <CardHeader>
              <CardTitle>
                <Label name="organization_details" groupName={LabelGroup.ORGANIZER_ORGANIZATION_EDIT} />
              </CardTitle>
            </CardHeader>
            <CardContent className="space-y-6">
              <div className="grid md:grid-cols-2 gap-4">
                <FormField
                  control={organizationForm.control}
                  name="organizerName"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormLabel>
                        <Label name="organizer_name" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} />
                      </FormLabel>
                      <FormControl>
                        <Input {...field} maxLength={32} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={organizationForm.control}
                  name="orgNo"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormLabel>
                        <Label name="org_no" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} />
                      </FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              {((organization && (organization?.signerName === '' || organization?.signerEmail === '' || organization?.signerTelephone === '')) || !organization) && (
                <>
                  <Separator className="my-2" />
                  <div className="grid md:grid-cols-2 gap-4">
                    <FormField
                      control={organizationForm.control}
                      name="signerName"
                      render={({ field }) => (
                        <FormItem className="w-full">
                          <FormLabel>
                            <Label name="signer_name" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} />
                          </FormLabel>
                          <FormControl>
                            <Input {...field} maxLength={500} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={organizationForm.control}
                      name="signerEmail"
                      render={({ field }) => (
                        <FormItem className="w-full">
                          <FormLabel>
                            <Label name="signer_email" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} />
                          </FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="grid md:grid-cols-2 gap-4">
                    <FormField
                      control={organizationForm.control}
                      name="signerTelephone"
                      render={({ field }) => (
                        <FormItem className="w-full">
                          <FormLabel>
                            <Label name="signer:telephone" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} />
                          </FormLabel>
                          <FormControl>
                            <Input {...field} maxLength={32} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </>
              )}
            </CardContent>
            <CardFooter>
              <Button size="sm">
                <Label name={!!organization ? 'update_organization_button' : 'create_organization_button'} groupName={LabelGroup.ORGANIZER_DOWNLOAD_PROGRAM} />
              </Button>
            </CardFooter>
          </Card>
        </form>
      </Form>

      <Form {...programForm}>
        <form className={cn(!organization && 'opacity-50 pointer-events-none')} onKeyDown={event => event.key === 'Enter' && event.preventDefault()} onSubmit={programForm.handleSubmit(handleProgramSubmit)}>
          <Card className="w-full flex flex-col h-full justify-between">
            <CardHeader>
              <CardTitle className="text-xl">
                <Label name="download_program_title" groupName={LabelGroup.ORGANIZER_DOWNLOAD_PROGRAM} />
              </CardTitle>
            </CardHeader>
            <CardContent className="space-y-6">
              <DownloadProgramTerms onAcceptTerms={() => setHasAcceptedTerms(true)} />
              <FormField
                control={programForm.control}
                name="timePeriod"
                render={({ field }) => (
                  <FormItem className={cn(!hasAcceptedTerms && 'opacity-50 pointer-events-none', 'space-y-3')}>
                    <FormLabel>
                      <Label name="program_download_type" groupName={LabelGroup.ORGANIZER_DOWNLOAD_PROGRAM} />
                    </FormLabel>
                    <FormControl>
                      <RadioGroup onValueChange={field.onChange} value={field.value} className="flex items-center gap-4">
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="monthly" />
                          </FormControl>
                          <FormLabel className="font-normal">
                            <Label name="one_time_download" groupName={LabelGroup.ORGANIZER_DOWNLOAD_PROGRAM} />
                          </FormLabel>
                        </FormItem>
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="year" />
                          </FormControl>
                          <FormLabel className="font-normal">
                            <Label name="yearly_download" groupName={LabelGroup.ORGANIZER_DOWNLOAD_PROGRAM} />
                          </FormLabel>
                        </FormItem>
                      </RadioGroup>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </CardContent>
            <CardFooter>
              <Button size="sm" disabled={!programForm.watch('timePeriod') || !hasAcceptedTerms || !organization?.organizerName || !organization.orgNo}>
                <Label name="download_program_button" groupName={LabelGroup.ORGANIZER_DOWNLOAD_PROGRAM} />
              </Button>
            </CardFooter>
          </Card>
        </form>
      </Form>
    </div>
  );

  // if (!hasAccess && hasOrderRows) {
  //   return <Payment />;
  // }

  // return (
  //   <div>
  //     <Form {...form}>
  //       <form
  //         onKeyDown={(event) => event.key === 'Enter' && event.preventDefault()}
  //         onSubmit={form.handleSubmit(handleSubmit)}
  //       >
  //         <Card className="w-full flex flex-col h-full justify-between">
  //           <CardHeader>
  //             <CardTitle className="text-xl">
  //               <Label name="no_payment" groupName={LabelGroup.ORGANIZER_DOWNLOAD_PROGRAM} />
  //             </CardTitle>
  //             <CardDescription>
  //               <Label name="no_paymeny_export_description" groupName={LabelGroup.ORGANIZER_DOWNLOAD_PROGRAM} />
  //             </CardDescription>
  //           </CardHeader>
  //           <CardContent>
  //             <FormField
  //               control={form.control}
  //               name="timePeriod"
  //               render={({ field }) => (
  //                 <FormItem className="space-y-3">
  //                   <FormLabel>
  //                     <Label name="file_export_type" groupName={LabelGroup.ORGANIZER_DOWNLOAD_PROGRAM} />
  //                   </FormLabel>
  //                   <FormControl>
  //                     <RadioGroup onValueChange={field.onChange} className="flex flex-col space-y-1">
  //                       <FormItem className="flex items-center space-x-3 space-y-0">
  //                         <FormControl>
  //                           <RadioGroupItem value="month" />
  //                         </FormControl>
  //                         <FormLabel className="font-normal">
  //                           <Label name="monthly_export" groupName={LabelGroup.ORGANIZER_DOWNLOAD_PROGRAM} />
  //                         </FormLabel>
  //                       </FormItem>
  //                       <FormItem className="flex items-center space-x-3 space-y-0">
  //                         <FormControl>
  //                           <RadioGroupItem value="year" />
  //                         </FormControl>
  //                         <FormLabel className="font-normal">
  //                           <Label name="yearly_export" groupName={LabelGroup.ORGANIZER_DOWNLOAD_PROGRAM} />
  //                         </FormLabel>
  //                       </FormItem>
  //                     </RadioGroup>
  //                   </FormControl>
  //                   <FormMessage />
  //                 </FormItem>
  //               )}
  //             />
  //           </CardContent>
  //           <CardFooter>
  //             <Button size="sm">
  //               <Label name="pay" groupName={LabelGroup.ORGANIZER_DOWNLOAD_PROGRAM} />
  //             </Button>
  //           </CardFooter>
  //         </Card>
  //       </form>
  //     </Form>
  //   </div>
  // );
}
