import { validatePhoneNumber } from '@/features/misc/data/phone-number-regex';
import { z } from 'zod';

const basicDetailsSchema = z.object({
  organizationType: z.string().refine(value => value.length > 0 || !!value, {
    message: 'organization_type_is_required',
    params: { id: 'organizationType', section: 'basic-details-section' },
    path: ['organizationType'] // Optional: Specify the path to the field in case of nested schemas
  }),
  eventType: z.string().refine(value => value.length > 0 || !!value, {
    message: 'event_type_is_required',
    params: { id: 'eventType', section: 'basic-details-section' },
    path: ['eventType'] // Optional: Specify the path to the field in case of nested schemas
  }),
  category: z.string().refine(value => value.length > 0 || !!value, {
    message: 'category_is_required',
    params: { id: 'event_category', section: 'category-section' },
    path: ['category'] // Optional: Specify the path to the field in case of nested schemas
  }),
  topic: z.string().refine(value => value.length > 0 || !!value, {
    message: 'topic_is_required',
    params: { id: 'topic', section: 'basic-details-section' },
    path: ['topic'] // Optional: Specify the path to the field in case of nested schemas
  }),
  topic2: z.string().optional(),
  languages: z.array(z.string()).refine(
    value => {
      return value.length === 1 && (value[0] === 'swe' || value[0] === 'eng' || value[0] === 'swe/eng');
    },
    {
      message: 'languages_is_required',
      params: { id: 'languages', section: 'basic-details-section' },
      path: ['languages'] // Optional: Specify the path to the field in case of nested schemas
    }
  ),
  times: z
    .array(
      z.object({
        eventTimeId: z.number(),
        eventId: z.number(),
        eventStart: z.union([z.string(), z.date()]),
        eventEnd: z.union([z.string(), z.date()]),
        inserted: z.string().optional(),
        updated: z.string().optional()
      })
    )
    .refine(
      values => {
        for (const value of values) {
          // Convert eventStart and eventEnd to Date objects if they are strings
          let start = typeof value.eventStart === 'string' ? new Date(value.eventStart) : value.eventStart;

          // Check if both eventStart and eventEnd exist and are valid dates
          if (!start || isNaN(start.getTime())) {
            return false;
          }
        }
        // All checks passed
        return true;
      },
      {
        message: 'starttime_required_validation_text',
        params: { id: 'event_time', section: 'basic-details-section' },
        path: ['times']
      }
    )
    .refine(
      values => {
        for (const value of values) {
          // Convert eventStart and eventEnd to Date objects if they are strings
          let end = typeof value.eventEnd === 'string' ? new Date(value.eventEnd) : value.eventEnd;

          const start = new Date(value.eventStart);

          // Check if both eventStart and eventEnd exist and are valid dates
          if (!end || isNaN(end.getTime()) || end.getTime() < start.getTime()) {
            return false;
          }
        }
        // All checks passed
        return true;
      },
      {
        message: 'endtime_required_validation_text',
        params: { id: 'event_time', section: 'basic-details-section' },
        path: ['times']
      }
    )
    .refine(
      values => {
        // Iterate over each item in the array
        for (const value of values) {
          // Check if both eventStart and eventEnd exist and are valid dates

          const eventStart = new Date(value.eventStart);
          const eventEnd = new Date(value.eventEnd);

          if (!value.eventStart || isNaN(eventStart.getTime())) {
            return false;
          }
          if (!value.eventEnd || isNaN(eventEnd.getTime())) {
            return false;
          }
        }
        // All checks passed
        return true;
      },
      {
        message: 'event_days_are_invalid',
        params: { id: 'event_time', section: 'basic-details-section' },
        path: ['times'] // Optional: Specify the path to the field in case of nested schemas
      }
    )
    .refine(
      values => {
        function doEventsOverlap(start1: Date, end1: Date, start2: Date, end2: Date) {
          return !(end1 <= start2 || end2 <= start1);
        }

        for (let i = 0; i < values.length; i++) {
          for (let j = i + 1; j < values.length; j++) {
            const event1Start = new Date(values[i].eventStart);
            const event1End = new Date(values[i].eventEnd);
            const event2Start = new Date(values[j].eventStart);
            const event2End = new Date(values[j].eventEnd);

            // Check if events overlap
            if (doEventsOverlap(event1Start, event1End, event2Start, event2End)) {
              return false; // Events overlap, validation fails
            }
          }
        }

        // All checks passed
        return true;
      },
      {
        message: 'times_conflicted_validation_text',
        params: { id: 'event_time', section: 'basic-details-section' },
        path: ['times'] // Optional: Specify the path to the field in case of nested schemas
      }
    )
});

const locationSchema = z.object({
  locationId: z
    .number()
    .nullable()
    .refine(value => !!value, {
      message: 'location_is_required',
      params: { id: 'event_location', section: 'location-section' }
    }),
  locationDescription: z.string().optional()
});

const contentSchema = z.object({
  title_lastVersion: z.string().optional(),
  title: z.string().refine(value => value.length > 0, {
    message: 'title_is_required',
    params: { id: 'event_title', section: 'content-section' },
    path: ['title'] // Optional: Specify the path to the field in case of nested schemas
  }),
  socialIssue: z.string().refine(value => value.length > 0, {
    message: 'social_issue_is_required',
    params: { id: 'social_issue', section: 'content-section' },
    path: ['socialIssue'] // Optional: Specify the path to the field in case of nested schemas
  }),
  socialIssue_lastVersion: z.string().optional(),
  description_lastVersion: z.string().optional(),
  description: z
    .string()
    .max(1000, {
      message: 'description_max_validation_text'
    })
    .optional()
});

const organizerSchema = z.object({
  organizers: z
    .array(
      z.object({
        eventId: z.number().optional(),
        eventOrganizerId: z.number().optional(),
        organizerName: z.string(),
        inserted: z.string().optional(),
        updated: z.string().optional()
      })
    )
    .refine(value => value.length > 0, {
      message: 'organizer_is_required',
      params: { id: 'event_organizer', section: 'organizer-section' }
    })
    .refine(
      value => {
        return value.every(organizer => organizer.organizerName.length > 0);
      },
      {
        message: 'organizer_name_is_required',
        params: { id: 'organizer_name', section: 'organizer-section' }
      }
    ),
  contactPerson1Name: z.string().refine(value => value.length > 0, {
    message: 'contact_person1_name_is_required',
    params: { id: 'contact_person1_name', section: 'contact-section' },
    path: ['contactPerson1Name'] // Optional: Specify the path to the field in case of nested schemas
  }),
  contactPerson1Organization: z.string().refine(value => value.length > 0, {
    message: 'contact_person1_organization_is_required',
    params: { id: 'contact_person1_organization', section: 'contact-section' },
    path: ['contactPerson1Organization'] // Optional: Specify the path to the field in case of nested schemas
  }),
  contactPerson1Telephone: z.string().refine(value => value.length > 0 || validatePhoneNumber(value), {
    message: 'contact_person1_telephone_is_required',
    params: { id: 'contact_person1_telephone', section: 'contact-section' },
    path: ['contactPerson1Telephone'] // Optional: Specify the path to the field in case of nested schemas
  }),
  contactPerson1Email: z
    .string()
    .refine(value => value.length > 0, {
      message: 'contact_person1_email_is_required',
      params: { id: 'contact_person1_email', section: 'contact-section' },
      path: ['contactPerson1Email']
    })
    .refine(value => !value || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value), {
      message: 'invalid_email_format_contact_person1_email',
      params: { id: 'contact_person1_email', section: 'contact-section' },
      path: ['contactPerson1Email']
    }),
  persons: z.array(
    z.object({
      eventId: z.number().optional(),
      eventPersonId: z.number(),
      firstName: z.string(),
      lastName: z.string().optional(),
      title: z.string().optional(),
      organizationName: z.string().optional(),
      party: z.string().optional().nullable(),
      inserted: z.string().optional(),
      updated: z.string().optional()
    })
  ),
  contactPerson1Title: z.string().optional(),
  contactPerson2Name: z.string().optional(),
  contactPerson2Title: z.string().optional(),
  contactPerson2Organization: z.string().optional(),
  contactPerson2Telephone: z
    .string()
    .optional()
    .refine(value => !value || validatePhoneNumber(value), {
      message: 'invalid_telephone_format_contact_person2_telephone',
      params: { id: 'contact_person2_telephone', section: 'contact-section' },
      path: ['contactPerson2Telephone'] // Optional: Specify the path to the field in case of nested schemas
    })
    .or(z.literal('')),
  contactPerson2Email: z.string().refine(value => !value || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value), {
    message: 'invalid_email_format',
    params: { id: 'contact_person2_email', section: 'contact-section' },
    path: ['contactPerson2Email']
  }),
  showEmail: z.boolean().optional(),
  showTelephone: z.boolean().optional(),
  url1: z.string().optional(),
  url2: z.string().optional(),
  url3: z.string().optional(),
  facebookUrl: z.string().optional(),
  xurl: z.string().optional(),
  linkedInUrl: z.string().optional()
});

const digitalEventSchema = z.object({
  digitalStreamingType: z.string().optional(),
  digitalStreamingUrl: z.string().optional(),
  digitalMeetingUrl: z.string().optional(),
  digitalMeetingDescription: z
    .string()
    .max(150, {
      message: 'digital_meeting_description_max_validation_text'
    })
    .optional(),
  digitalArchiveUrl: z.string().optional()
});

const accessabilitySchema = z.object({
  accessibilityText: z.boolean().optional(),
  accessibilityTeleloop: z.boolean().optional(),
  accessibilityVisualInterpretation: z.boolean().optional(),
  accessibilityWheelchairToilet: z.boolean().optional(),
  accessibilityWheelchairVenue: z.boolean().optional(),
  accessibilitySignLanguage: z.boolean().optional()
});

const environmentalDiplomaSchema = z.object({
  environmentStationary: z.boolean().optional(),
  environmentPrint: z.boolean().optional(),
  environmentFlyer: z.boolean().optional(),
  environmentBattery: z.boolean().optional(),
  environmentPlastic: z.boolean().optional(),
  environmentRecycling: z.boolean().optional(),
  environmentDisposable: z.boolean().optional(),
  environmentSourceSorting: z.boolean().optional(),
  environmentFoodEcological: z.boolean().optional(),
  environmentFoodLocallyProduced: z.boolean().optional(),
  environmentFoodEthical: z.boolean().optional(),
  environmentWater: z.boolean().optional(),
  environmentNoFood: z.boolean().optional(),
  environmentFood: z.boolean().optional(),
  environmentServiceQuestion: z.boolean().optional(),
  environmentServiceElectricity: z.boolean().optional(),
  environmentServiceTravel: z.boolean().optional(),
  environmentServiceCooking: z.boolean().optional()
});

export const sendEventSchema = z.object({
  eventId: z.number().nullable(),
  yearId: z.number().optional(),
  status: z.number(),
  inserted: z.string().optional(),
  ...basicDetailsSchema.shape,
  ...locationSchema.shape,
  ...contentSchema.shape,
  ...organizerSchema.shape,
  ...digitalEventSchema.shape,
  ...accessabilitySchema.shape,
  ...environmentalDiplomaSchema.shape,
  sendToReview: z.boolean().optional()
});
