import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useToast } from '@/components/ui/use-toast';
import { label } from '@/utils/label';
import { LabelGroup } from '@/features/label/types';

const QUERY_KEY = 'invitations';

export type CreateOrganizationDTO = {
  organizationId?: number;
  recipientEmail: string;
  messageText?: string | undefined;
  resend?: boolean;
  invitationId?: number;
};

export const sendInvitation = ({
  organizationId,
  recipientEmail,
  messageText,
  resend,
  invitationId,
}: CreateOrganizationDTO): Promise<unknown> => {
  if (resend) {
    return axios.post(`/api/invitation/resend/${invitationId}`);
  }

  return axios.post(`/api/invitation/send/${organizationId}`, {
    recipientEmail,
    messageText,
  });
};

type UseSendInvitationOptions = {
  organizationId: number;
  config?: MutationConfig<typeof sendInvitation>;
};

export const useSendInvitation = ({ config, organizationId }: UseSendInvitationOptions) => {
  const { toast } = useToast();

  return useMutation({
    onError: (_, __, ___: any) => {
      toast({
        variant: 'destructive',
        title: label('something_went_wrong', LabelGroup.GLOBAL),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEY, organizationId]);
      queryClient.refetchQueries([QUERY_KEY, organizationId]);

      toast({
        variant: 'success',
        title: label('invitation_sent', LabelGroup.ORGANIZER_ORGANIZATION_EDIT),
      });
    },
    ...config,
    mutationFn: (data: any) =>
      sendInvitation({
        ...data,
        organizationId,
      }),
  });
};
