import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { useState } from 'react';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';

export function CreateEventInfoCard() {
  const [expanded, setExpanded] = useState(false);

  return (
    <Card>
      <CardHeader className=''>
        <CardTitle className="text-md flex items-center">
          <Label name="create_event_info_title" groupName={LabelGroup.ORGANIZER_EVENT_CREATE} />
        </CardTitle>
      </CardHeader>
      <CardContent className="pt-0 text-sm overflow-hidden space-y-2">
        <div
          style={{
            maxHeight: expanded ? 'none' : '0rem',
            overflow: 'hidden',
          }}
        >
          <Label name="create_event_info_body" groupName={LabelGroup.ORGANIZER_EVENT_CREATE} />
        </div>

        <button  type="button" className="underline" onClick={() => setExpanded((prev) => !prev)}>
          <Label name={expanded ? 'show_less' : 'show_more'} groupName={LabelGroup.ORGANIZER_EVENT_CREATE} />
        </button>
      </CardContent>
    </Card>
  );
}
