import { Invitation } from '../types';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';

export function InvitationStatus({ used, revoked }: Invitation) {
  if (used) {
    return (
      <span className="text-green-700 bg-green-50 text-xs p-1 px-2  rounded-full">
        <Label name="invitation_used" groupName={LabelGroup.ORGANIZER_ORGANIZATION_EDIT} />
      </span>
    );
  }
  if (revoked) {
    return (
      <span className="text-red-700 bg-red-50 p-1 text-xs  px-2  rounded-full">
        <Label name="invitation_revoked" groupName={LabelGroup.ORGANIZER_ORGANIZATION_EDIT} />
      </span>
    );
  }

  return (
    <span className="text-slate-700 bg-slate-50 p-1 px-2 text-xs  font-semibold  rounded-md">
      <Label name="invitation_pending" groupName={LabelGroup.ORGANIZER_ORGANIZATION_EDIT} />
    </span>
  );
}
