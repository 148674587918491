import { FilePen } from 'lucide-react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { LabelGroup } from '@/features/label/types';
import { Label } from '@/features/label/components/label';
import { useNavigate } from 'react-router-dom';
import { Avatar } from '@radix-ui/react-avatar';
import { AvatarFallback, AvatarImage } from '@/components/ui/avatar';

interface ActiveEditorAlertDialogProps {
  onLockup: () => void;
  editorName: string;
  isAdmin: boolean;
}

export function ActiveEditorAlertDialog({ onLockup, editorName, isAdmin }: ActiveEditorAlertDialogProps) {
  const navigate = useNavigate();

  return (
    <AlertDialog open>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle className="flex items-center">
            <FilePen className="w-6 h-6 mr-3" />
            <Label name="active_editor_alert_title" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
          </AlertDialogTitle>
          <AlertDialogDescription className="flex gap-x-2">
            <Label name="active_editor_alert_description" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />{' '}
            <div className="flex items-center">
              <Avatar className="h-6 w-6 mr-1">
                <AvatarImage />
                <AvatarFallback className="bg-brand text-white">{editorName.charAt(0).toUpperCase()}</AvatarFallback>
              </Avatar>
              {editorName}
            </div>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          {isAdmin && (
            <AlertDialogAction className="bg-amber-500" onClick={onLockup}>
              <Label name="lockup_button_text" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
            </AlertDialogAction>
          )}
          <AlertDialogAction onClick={() => navigate('/events')}>
            <Label name="go_back_button_text" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
