import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { UserType } from '@/features/user';
import { LabelGroup } from '@/features/label/types';
import { Label } from '@/features/label/components/label';
import { useFormContext } from 'react-hook-form';
import { useMe } from '@/features/auth/api/get-me';

export function ArchiveSections({ defaultCollapsed = false }: { defaultCollapsed?: boolean }) {
  const { data: user } = useMe({});
  const userType = user?.role;
  const form = useFormContext();

  const labelGroup = userType === UserType.ADMIN || userType === UserType.MANAGER ? LabelGroup.MANAGER_EVENT_HANDLING : LabelGroup.ORGANIZER_EVENT_FORM;

  return (
    <Card>
      <Accordion type="single" collapsible defaultValue={defaultCollapsed ? '' : 'archive_link'}>
        <AccordionItem className="[&[data-state=open]>.card-header]:bg-gray-medium [&[data-state=open]>.card-header]:text-white [&[data-state=open]>.card-header]:rounded-t-md" value="archive_link">
          <CardHeader className="py-3">
            <AccordionTrigger className="py-0 text-md">
              <CardTitle>
                <Label name="archive_link_title" groupName={labelGroup} />
              </CardTitle>
            </AccordionTrigger>
          </CardHeader>
          <AccordionContent>
            <CardContent className="pt-6 text-sm space-y-6">
              <CardDescription>
                <Label name="archive_link_description" groupName={labelGroup} />
              </CardDescription>
              <FormField
                control={form.control}
                name="digitalArchiveUrl"
                render={({ field }) => (
                  <FormItem className=" space-y-2">
                    <FormLabel className="font-semibold">
                      <Label name="digital_archive_url" groupName={labelGroup} />
                    </FormLabel>
                    <FormDescription>
                      <Label name="digital_archive_url_description" groupName={labelGroup} />
                    </FormDescription>
                    <FormControl>
                      <Input {...field} className="w-full max-w-full" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </CardContent>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </Card>
  );
}
