import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { changePasswordSchema } from '../data';
import { PasswordInput } from '@/components/password-input';
import { useUpdatePassword } from '../api/update-password';
import { Spinner } from '@/components/ui/spinner';
import { useToast } from '@/components/ui/use-toast';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { useMe } from '@/features/auth/api/get-me';

export function ChangePassword() {
  const { data: user } = useMe({});

  const form = useForm<z.infer<typeof changePasswordSchema>>({
    resolver: zodResolver(changePasswordSchema),
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
  });

  const { toast } = useToast();

  const { mutateAsync: updatePassword, isLoading } = useUpdatePassword({});

  async function handleSubmit(values: z.infer<typeof changePasswordSchema>) {
    try {
      if (!user) return;
      await updatePassword({ ...values, userId: user?.userId });
      form.reset();
    } catch (error) {
      toast({
        variant: 'destructive',
        title: 'old_password_does_not_match',
      });
    }
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          <Label name="change_password" groupName={LabelGroup.PROFILE} />
        </CardTitle>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form
            onKeyDown={(event) => event.key === 'Enter' && event.preventDefault()}
            onSubmit={form.handleSubmit(handleSubmit)}
            className="space-y-8"
          >
            <FormField
              control={form.control}
              name="oldPassword"
              render={({ field }) => (
                <FormItem className="max-w-sm">
                  <FormLabel>
                    <Label name="old_password" groupName={LabelGroup.PROFILE} />
                  </FormLabel>
                  <FormControl>
                    <PasswordInput {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="newPassword"
              render={({ field }) => (
                <FormItem className="max-w-sm">
                  <FormLabel>
                    <Label name="new_password" groupName={LabelGroup.PROFILE} />
                  </FormLabel>
                  <FormControl>
                    <PasswordInput {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="confirmNewPassword"
              render={({ field }) => (
                <FormItem className="max-w-sm">
                  <FormLabel>
                    <Label name="confirm_new_password" groupName={LabelGroup.PROFILE} />
                  </FormLabel>
                  <FormControl>
                    <PasswordInput {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button disabled={!form.formState.isDirty} type="submit">
              {isLoading && <Spinner className="mr-2 text-primary-foreground h-4 w-4" />}
              <Label name={isLoading ? 'updating' : 'update'} groupName={LabelGroup.GLOBAL} />
            </Button>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
}
