import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';

import { EventStatus, TEvent } from '@/features/event/types';
// import { updateEvent } from '@/features/event/api/updateEvent';

const QUERY_KEY = 'events';
const API_PATH = '/manager/savereview/';

type UpdateDTO = {
  titleComment: string;
  descriptionComment: string;
  socialIssueComment: string;
  generalComment: string;
};

export const updateReview = (data: TEvent & UpdateDTO): Promise<void> => {
  const formattedData = {
    ...data,
    languages: Array.isArray(data.languages) ? data.languages.join(';') : data.languages,
    times: data.times.map(time => {
      // Check if eventStart and eventEnd are already in ISO format
      const isEventStartISO = time.eventStart?.includes('T');
      const isEventEndISO = time?.eventEnd?.includes('T');

      // If both are in ISO format, return the original object
      if (isEventStartISO && isEventEndISO) {
        return {
          ...time,
          inserted: new Date().toISOString() // Only update the inserted date
        };
      }

      // If not, adjust the date to account for timezone offset and convert to ISO strings

      // Convert to ISO strings directly without adjusting offsets
      const eventStartISO = !isEventStartISO ? new Date(time.eventStart).toISOString() : time.eventStart;
      const eventEndISO = !isEventEndISO ? new Date(time.eventEnd).toISOString() : time.eventEnd;
      // Return a new object with the original time data plus the converted ISO strings
      return {
        ...time,
        inserted: new Date().toISOString(), // Update inserted date
        eventStart: eventStartISO,
        eventEnd: eventEndISO
      };
    })
  };

  console.log('updateReview data:', data.eventId);
  return axios.patch(
    `${API_PATH}${data.eventId}`,
    formattedData
    // {
    //   params: {
    //     titleComment: data.titleComment,
    //     descriptionComment: data.descriptionComment,
    //     socialIssueComment: data.socialIssueComment,
    //     generalComment: data.generalComment,
    //     status: data.status,
    //     returnDue: data.returnDue,
    //   },
    // }
  );
};

type UseUpdateReviewOptions = {
  eventId: number;
  config?: MutationConfig<typeof updateReview>;
};

export const useUpdateReview = ({ config, eventId }: UseUpdateReviewOptions) => {
  return useMutation({
    onMutate: async (updating: any) => {
      console.log('useUpdateReview onMutate before cancel:', updating?.eventId);

      await queryClient.cancelQueries([QUERY_KEY, updating?.eventId]);

      // If event is a draft version and is approved, remove the query because it will be deleted and this will prevent refteching the query
      if (!!updating?.draft && updating?.status === EventStatus.Approved) {
        queryClient.removeQueries([QUERY_KEY, updating?.eventId]);
      }
    },
    onSuccess: async (data: any) => {
      // If the event is the same as the updated event, refetch the query
      if (data?.eventId === eventId) {
        queryClient.invalidateQueries([QUERY_KEY, data?.eventId]);
      }

      return data;
    },
    ...config,
    mutationFn: updateReview
  });
};
