import { axios } from '@/lib/axios';

export type RegisterDTO = {
  userName: string;
  password: string;
  inviteCode?: string | null;
};

export const register = async (data: RegisterDTO) => {
  try {
    return axios.post(`/register${window.location.search}`, data);
  } catch (error) {
    throw error;
  }
};
