import _ from 'lodash';

export function convertPascalToCamel<T>(obj: any): T {
  if (Array.isArray(obj)) {
    return obj.map((item) => convertPascalToCamel(item)) as unknown as T;
  } else if (typeof obj === 'object' && obj !== null) {
    return Object.keys(obj).reduce((acc, key) => {
      const camelKey = _.camelCase(key);
      acc[camelKey] = convertPascalToCamel(obj[key]);
      return acc;
    }, {} as any);
  }
  return obj;
}
