import { validatePhoneNumber } from '@/features/misc/data/phone-number-regex';
import { z } from 'zod';

const updateUserSchema = z.object({
  userId: z.number(),
  username: z.string().refine((value) => value !== '', {
    message: 'user_name_required_validation_text',
  }),
  name: z.string().refine((value) => value !== '', {
    message: 'name_required_validation_text',
  }),
  role: z.number(),
  telephone: z
    .string()
    .refine((value) => !value || validatePhoneNumber(value), {
      message: 'signer_telephone_invalid',
    })
    .or(z.literal('')),
});

export { updateUserSchema };
