import { forwardRef, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import './sv_SE';

interface ITextEditorProps {
  value: string;
  onChange: (value: string) => void;
  config?: any;
}

export default forwardRef(({ value, onChange, config }: ITextEditorProps, _: any) => {
  const editorRef = useRef<any>(null);

  return (
    <Editor
      onInit={(_: any, editor: any) => (editorRef.current = editor)}
      value={value}
      onEditorChange={() => onChange(editorRef.current.getContent())}
      init={{
        menubar: false,
        plugins: 'link code table paste lists nonbreaking hr imagetools image numlist bullist',
        paste_as_text: true,
        toolbar: 'undo redo | fontsize | styleselect bold italic underline | fontsizeselect forecolor backcolor | alignleft aligncenter alignright | pastetext | numlist bullist | code | image | editimage',
        language: 'sv_SE',
        font_size_formats: '8px 10px 12px 14px 16px 18px 20px 22px 24px 26px 28px 30px 32px 34px 36px',
        language_url: './sv_SE.js',
        font_css: '* { font-family:  "Open sans", sans-serif; }',
        height: '300px',
        inline: false,
        skin: false,
        imagetools_toolbar: 'editimage imageoptions | alignleft aligncenter alignright',
        content_style: 'body { font-family: "Open sans", sans-serif; font-size: 14px; }',
        convert_urls: false,
        relative_urls: false,
        remove_script_host: true,
        fullpage_default_doctype: '<!DOCTYPE html>',
        fullpage_default_encoding: 'UTF-8',
        link_assume_external_targets: 'https',

        // Add custom image upload handler
     

        // Drag-and-drop or paste image support
        paste_data_images: true,

        ...config
      }}
    />
  );
});
