import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { ManagerEvent } from '../types';

const QUERY_KEY = 'events';

type GetEventsOptions = {
  assignedToMe: boolean;
};

export const getEvents = ({ assignedToMe = false }: GetEventsOptions): Promise<ManagerEvent[]> => {
  return axios.get(`/manager/event`, {
    params: {
      assignedToMe: assignedToMe
    }
  });
};

type QueryFnType = typeof getEvents;

type UseEventOption = {
  assignedToMe?: boolean;
  config?: QueryConfig<QueryFnType>;
};

export const useEvents = ({ config, assignedToMe = false }: UseEventOption) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: assignedToMe ? [QUERY_KEY, 'ASSIGNED_TO_ME'] : [QUERY_KEY],
    queryFn: () => getEvents({ assignedToMe }),
    ...config
  });
};
