import { Card, CardFooter, CardContent, CardDescription, CardTitle, CardHeader } from '@/components/ui/card';
import storage from '@/utils/storage';
import { useNavigate } from 'react-router-dom';
import { Checkbox } from '@/components/ui/checkbox';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';

export function NewOrganizationTerms() {
  const navigate = useNavigate();

  const handleAcceptTerms = () => {
    storage.setAcceptedOrganizationTerms();
    navigate('/organizations/create');
  };

  return (
    <Card className="w-full flex flex-col h-full justify-between border-0 rounded-none">
      <CardHeader>
        <CardTitle className="flex">
          <Label name="accept_organization_terms_title" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} />
        </CardTitle>
      </CardHeader>
      <CardContent className="h-auto">
        <CardDescription className="text-left text-muted-foreground">
          <Label name="accept_organization_terms_body" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} />
        </CardDescription>
      </CardContent>
      <CardFooter>
        <div className="flex items-center space-x-2">
          <Checkbox id="terms" onCheckedChange={handleAcceptTerms} />
          <label
            htmlFor="terms"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            <Label name="accept_organization_terms_label" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} />
          </label>
        </div>
      </CardFooter>
    </Card>
  );
}
