import { validatePhoneNumber } from '@/features/misc/data/phone-number-regex';
import { z } from 'zod';

const updateProfileSchema = z.object({
  userId: z.number(),
  username: z.string().refine((value) => value !== '', {
    message: 'user_name_required_validation_text',
  }),
  name: z.string().refine((value) => value !== '', {
    message: 'name_required_validation_text',
  }),
  telephone: z
    .string()
    .optional()
    .refine((value) => !value || validatePhoneNumber(value), {
      message: 'invalid_telephone_number',
    })
    .or(z.literal('')),
});

export { updateProfileSchema };
