import { Card, CardContent, CardHeader, CardTitle, CardDescription, CardFooter } from '@/components/ui/card';
import { Checkbox } from '@/components/ui/checkbox';
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Separator } from '@/components/ui/separator';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { useFormContext } from 'react-hook-form';
import { Button } from '@/components/ui/button';
import { CalendarPopover } from '@/components/ui/calendar-popver';
// import { Form } from 'react-router-dom';

export function Pricing() {
  const form = useFormContext();

  // const noCost = form.watch('noCost');
  const returnDue = Number(form.watch('returnDue') ?? 7);
  // const hasSubmitted = form.formState.submitCount > 0;
  // const status = form.watch('status');

  const handleReturnDueChange = (date: Date | undefined) => {
    if (!date) {
      return;
    }

    const returnDate = new Date(form.watch('returnDate') ?? new Date());
    const differenceInDays = Math.ceil((date.getTime() - returnDate.getTime()) / (1000 * 60 * 60 * 24));

    // If the selected date is the same as returnDate, set returnDue to 0
    if (differenceInDays === 0) {
      form.setValue('returnDue', 0);
    } else {
      form.setValue('returnDue', differenceInDays);
    }
  };

  // const handleNoCostDeadlineChange = (date: Date | undefined) => {
  //   if (!date) {
  //     return;
  //   }

  //   form.setValue(
  //     'noCostDeadline',
  //     new Date(date).toLocaleDateString('sv-SE', {
  //       year: 'numeric',
  //       month: 'numeric',
  //       day: 'numeric'
  //     }),
  //     { shouldDirty: true }
  //   );
  //   form.setValue('noCost', false, {
  //     shouldDirty: true
  //   });
  // };

  const handleNoCostChange = (value: boolean) => {
    form.setValue('noCost', value, { shouldDirty: true });
    form.setValue('noCostDeadline', null, { shouldDirty: true });
  };

  return (
    <Card className="max-w-md">
      <CardHeader>
        <CardTitle>
          <Label name="pricing_title" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
        </CardTitle>
        <CardDescription>
          <Label name="pricing_description" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-4">
        <div>
          <FormField
            control={form.control}
            name="noCost"
            render={({ field }) => (
              <FormItem className="space-y-2">
                <FormControl>
                  <div className="flex items-center space-x-2">
                    <Checkbox id="noCost" checked={field.value} onCheckedChange={handleNoCostChange} />
                    <label htmlFor="noCost" className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                      <Label name="is_free_of_charge" groupName={LabelGroup.ADMIN_LOCATION_EDIT} />
                    </label>
                  </div>
                </FormControl>
                <FormDescription>
                  <Label name="is_free_of_charge_description" groupName={LabelGroup.ADMIN_LOCATION_EDIT} />
                </FormDescription>
              </FormItem>
            )}
          />
        </div>
        {/* <div className="relative py-6 bg-white">
            <Separator />
            <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white px-2 text-sm text-muted-foreground">
              <Label name="or" groupName={LabelGroup.GLOBAL} />
            </span>
          </div>
          <FormField
            control={form.control}
            name="noCostDeadline"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>
                  <Label name="no_cost_deadline" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
                </FormLabel>
                <FormDescription>
                  <Label name="no_cost_deadline_description" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
                </FormDescription>
                <Popover>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button variant={'outline'} disabled={noCost} className={cn('w-[240px] pl-3 text-left font-normal', !field.value && 'text-muted-foreground')}>
                        {field.value !== null ? (
                          new Date(field.value).toLocaleDateString('sv-SE', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric'
                          })
                        ) : (
                          <span>
                            <Label name="select_no_cost_deadline" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
                          </span>
                        )}
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar mode="single" selected={new Date(form.watch('noCostDeadline') ?? new Date())} onSelect={handleNoCostDeadlineChange} disabled={date => date <= new Date(new Date().setDate(new Date().getDate() - 1))} initialFocus />
                    <Button variant="outline" className="w-full" onClick={() => field.onChange(null)}>
                      <Label name="clear" groupName={LabelGroup.GLOBAL} />
                    </Button>
                  </PopoverContent>
                </Popover>
                <FormMessage>
                  <Label name="free_of_charge_description" groupName={LabelGroup.ADMIN_LOCATION_EDIT} />
                </FormMessage>
              </FormItem>
            )}
          />
        </div> */}

        <Separator />
        <FormField
          control={form.control}
          name="returnDue"
          render={_ => (
            <FormItem className="flex flex-col">
              <FormLabel>
                <Label name="return_due" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
              </FormLabel>
              <FormDescription>
                <Label name="return_due_description" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
              </FormDescription>

              <CalendarPopover
                date={form.watch('returnDue') ? new Date(+new Date(form.watch('returnDate') ?? new Date()) + 1000 * 60 * 60 * 24 * returnDue) : undefined}
                mode="single"
                selected={form.watch('returnDue') ? new Date(+new Date(form.watch('returnDate') ?? new Date()) + 1000 * 60 * 60 * 24 * returnDue) : undefined}
                onSelect={handleReturnDueChange}
                disabled={date => date <= new Date(new Date().setDate(new Date().getDate() - 1))}
                initialFocus
              />
              <FormMessage>
                <Label name="free_of_charge_description" groupName={LabelGroup.ADMIN_LOCATION_EDIT} />
              </FormMessage>
            </FormItem>
          )}
        />
      </CardContent>
      <CardFooter>
        <Button type="submit">
          <Label name="save" groupName={LabelGroup.GLOBAL} />
        </Button>
      </CardFooter>
    </Card>
  );
}
