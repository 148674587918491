import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { BadgeCheck } from 'lucide-react';
import { useState } from 'react';
import { forgotPassword } from '../api/forgotPassword';
import { useToast } from '@/components/ui/use-toast';
import { label } from '@/utils/label';
import { Card } from '@/components/ui/card';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';

const formSchema = z.object({
  username: z.string().email({ message: 'username_invalid' }),
});

export function ForgotPassword() {
  const [hasSent, setHasSent] = useState(false);

  const { toast } = useToast();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: '',
    },
  });

  async function handleSubmit(values: z.infer<typeof formSchema>) {
    try {
      await forgotPassword({ username: values.username });
      setHasSent(true);
    } catch (error) {
      toast({
        variant: 'destructive',
        title: label('something_went_wrong', 'global'),
      });
    }
  }

  if (hasSent) {
    return (
      <div className=" h-screen w-full flex flex-col items-center justify-center">
        <div className="space-y-4 text-center">
          <div className="space-y-2  flex flex-col items-center">
            <BadgeCheck size={48} />
            <h3 className="text-lg font-medium">
              <Label name="forgot_password_email_sent_title" groupName={LabelGroup.FORGOT_PASSWORD} />
            </h3>
            <p>
              <Label name="forgot_password_email_sent_to" groupName={LabelGroup.FORGOT_PASSWORD} />{' '}
              <strong className="underline">{form.watch('username')} </strong>
              <Label name="forgot_password_email_sent_to_description" groupName={LabelGroup.FORGOT_PASSWORD} />
            </p>
          </div>
          {/* <div>
            <Link to="/login">
              <Button>
                <Label name="login_button_text" groupName={LabelGroup.FORGOT_PASSWORD} />
              </Button>
            </Link>
          </div> */}
        </div>
      </div>
    );
  }

  return (
    <div className=" h-screen w-full flex flex-col items-center justify-center">
      <Card className="max-w-md w-full p-6 space-y-4 h-auto bg-card shadow-md">
        <div className="space-y-2">
          <h3 className="text-lg font-medium">
            <Label name="forgot_password_title" groupName={LabelGroup.FORGOT_PASSWORD} />
          </h3>
          <Form {...form}>
              <form onKeyDown={(event) => event.key === 'Enter' && event.preventDefault()} onSubmit={form.handleSubmit(handleSubmit)} className="space-y-8">
              <FormField
                control={form.control}
                name="username"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>
                      <Label name="email" groupName={LabelGroup.FORGOT_PASSWORD} />
                    </FormLabel>
                    <FormControl>
                      <Input {...field} type="email" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button type="submit">
                <Label name="send_button_text" groupName={LabelGroup.FORGOT_PASSWORD} />
              </Button>
            </form>
          </Form>
        </div>
      </Card>
    </div>
  );
}
