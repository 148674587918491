import { RouteObject } from 'react-router-dom';
import { Layout } from '@/features/organizer/components/layout';
import { Dashboard } from '@/features/organizer/routes/dashboard';
import { Organizations } from '@/features/organizer/routes/organizations';
import { EditOrganization } from '@/features/organizer/routes/edit-organization';
import { CreateOrganization } from '@/features/organizer/routes/create-organization';
import { OrganizerEvents } from '@/features/organizer/routes/organizer-events';
import { CreateEvent } from '@/features/event/routes/create-event';
import { EditEvent } from '@/features/event/routes/edit-event';
import { Payment } from '@/features/payment/routes/payment';
import { CardPayment } from '@/features/payment/routes/card-payment';
import { InvoicePayment } from '@/features/payment/routes/invoice-payment';
import { Payments } from '@/features/payment/routes/payments';
import { PaymentConfirmation } from '@/features/payment/routes/payment-confirmation';
import { Profile } from '@/features/user/routes/profile';
import { DownloadProgram } from '@/features/organizer/routes/download-program';
import { NotFound } from '@/features/misc';
import { Login } from '@/features/auth';
import { Register } from '@/features/auth/routes/register';

export const organizerRoutes: RouteObject[] = [
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/register',
    element: <Register />,
  },
  {
    path: '/',
    element: <Layout />,
    children: [
      { path: '*', element: <Dashboard /> },
      { path: '/', element: <Dashboard /> },

      { path: '/dashboard', element: <Dashboard /> },
      { path: '/not-found', element: <NotFound /> },
      { path: '/organizations', element: <Organizations /> },
      { path: '/organizations/create', element: <CreateOrganization /> },
      { path: '/organizations/edit/:id', element: <EditOrganization /> },

      { path: '/events', element: <OrganizerEvents /> },
      { path: '/events/create', element: <CreateEvent /> },
      { path: '/events/edit/:id', element: <EditEvent /> },

      { path: '/download-program', element: <DownloadProgram /> },

      { path: '/payment', element: <Payment /> },
      { path: '/payment/card', element: <CardPayment /> },
      { path: '/payment/invoice', element: <InvoicePayment /> },
      { path: '/payments', element: <Payments /> },
      {
        path: '/payment/confirmation/:id',
        element: <PaymentConfirmation />,
      },

      { path: '/profile', element: <Profile /> },
    ],
  },
];
