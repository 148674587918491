import { LoginForm } from '@/features/auth/components/LoginForm';
import { Card, CardDescription, CardTitle } from '@/components/ui/card';
import { Separator } from '@/components/ui/separator';
import { Link } from 'react-router-dom';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';

export function Login() {
  return (
    <div className="h-screen  w-full flex flex-col items-center justify-center">
      <Card className="max-w-md w-full p-6 space-y-4 h-auto bg-card shadow-md">
        <CardTitle className="text-center text-2xl font-bold">
          <Label name="login_title" groupName={LabelGroup.LOGIN} />
        </CardTitle>
        <CardDescription className="text-left text-sm">
          <Label name="login_description" groupName={LabelGroup.LOGIN} />
        </CardDescription>
        <Separator />
        <LoginForm />
        <Separator />
        <div className="flex flex-col space-y-2 text-center">
          <Link to={'/register' + window.location.search} className="underline text-sm">
            <Label name="i_dont_have_an_account" groupName={LabelGroup.LOGIN} />
          </Link>
          <Link to="/forgot-password" className="underline text-sm">
            <Label name="forgot_password" groupName={LabelGroup.LOGIN} />
          </Link>
        </div>
      </Card>
    </div>
  );
}

{
  /* <div className="h-screen w-full grid md:grid-cols-2 items-center justify-center bg-white">
<div className="flex items-center justify-center">
  <Card className="max-w-md w-full p-6 space-y-4 h-auto bg-card shadow-md border-0 shadow-none">
    <CardTitle className="text-center text-2xl font-bold">
      <Label name="login_title" groupName={LabelGroup.LOGIN} />
    </CardTitle>
    <CardDescription className="text-center text-sm">
      <Label name="login_description" groupName={LabelGroup.LOGIN} />
    </CardDescription>
    <LoginForm />
    <div className="flex flex-col space-y-2 text-center">
      <Link to={'/register' + window.location.search} className="underline text-sm">
        <Label name="i_dont_have_an_account" groupName={LabelGroup.LOGIN} />
      </Link>
      <Link to="/forgot-password" className="underline text-sm">
        <Label name="forgot_password" groupName={LabelGroup.LOGIN} />
      </Link>
    </div>
  </Card>
</div>
<div className="relative hidden md:block h-full w-full bg-center bg-cover bg-[url(https://gogotland.se/wp-content/uploads/2023/02/P1017933-scaled.jpg)]">
  <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-70">
    <div className='text-white w-1/2 text-center space-y-2'>
      <h2 className='text-xl'>Välkommen!</h2>
      <p>
        Här administrerar du dina evenemang i Almedalsveckans officiella program. Logga in för att komma åt
        evenemangen.
      </p>
    </div>
  </div>
</div>
</div> */
}
