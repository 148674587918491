import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';
import { ArrowRight, ChevronsUpDown, PlusCircle } from 'lucide-react';
import { useOrganizerStore } from '@/stores/organizerStore';
import { useOrganizations } from '../api/getOrganizations';
import { Organization } from '../types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { cn } from '@/utils';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { HouseCheck } from 'react-bootstrap-icons';
import { useMe } from '@/features/auth/api/get-me';

export function OrganizationDropdown() {
  const navigate = useNavigate();
  const location = useLocation();

  const organizationId = useOrganizerStore((state) => state.organizationId);
  const setOrganizationId = useOrganizerStore((state) => state.setOrganizationId);

  const { data: user } = useMe({});

  const { data: organizations = [] } = useOrganizations({
    userId: user?.userId ?? 0,
    config: {
      onSuccess: (data: Organization[] = []) => {
        if (
          (data?.length > 0 && !organizationId) ||
          !data?.some((organization) => organization.organizerId === organizationId)
        ) {
          const organizationId = data[0]?.organizerId;
          setOrganizationId(organizationId);
        }

        if (!data?.length) {
          setOrganizationId(0);
        }
      },
      enabled: !!user?.userId,
    },
  });

  const toggleOrganization = (organizationId: number) => {
    setOrganizationId(organizationId);

    if (location.pathname.match(/organizations\/edit\/\d+.*/g)) {
      navigate(`/organizations/edit/${organizationId}`);
    }
    else {
      navigate('/');
    }
  };

  return (
    <div className="flex items-center w-full space-x-2 font-semibold">
      <DropdownMenu>
        <DropdownMenuTrigger className="w-full">
          <Button
            variant="outline"
            role="menu"
            className="w-full md:h-[60px] md:bg-black/30 rounded-none md:text-white md:hover:bg-black/70 md:hover:text-white border-gray-medium md:border-gray  md:rounded-none lg:w-64 flex items-center justify-between"
          >
            <div className="flex items-center">
              <HouseCheck size={20} className="mr-2" />
              <div className="truncate w-[160px] lg:w-40 text-left">
                {organizations?.find((organization) => organization?.organizerId === organizationId)?.organizerName ?? (
                  <Label name="select_organization_placeholder" groupName={LabelGroup.ORGANIZER_MENU} />
                )}
              </div>
            </div>

            <ChevronsUpDown className="w-4 h-4 ml-2" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-60 lg:w-64">
          <DropdownMenuLabel className="hover:bg-muted py-2.5 px-3 rounded-sm">
            <Link to="/organizations" className="flex justify-between items-center hover:bg-muted ">
              <Label name="my_organizations" groupName={LabelGroup.ORGANIZER_MENU} />
              <ArrowRight className="w-4 h-4 ml-2" />
            </Link>
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          {organizations
            .sort((a, b) => a.organizerName.localeCompare(b.organizerName))
            ?.map((organization) => (
              <DropdownMenuCheckboxItem
                key={organization?.organizerId}
                checked={organization?.organizerId === organizationId}
                className={cn(
                  organization?.organizerId === organizationId && 'bg-muted',
                  'py-1 pl-1 cursor-pointer hover:bg-muted break-words'
                )}
                onClick={() => toggleOrganization(organization.organizerId)}
              >
                <DropdownMenuLabel className="break-words max-w-[calc(100%_-_10px)]">
                  {organization?.organizerName}
                </DropdownMenuLabel>
              </DropdownMenuCheckboxItem>
            ))}
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={() => {
              navigate('/organizations/create');
            }}
          >
            <PlusCircle className="w-4 h-4 " />
            <DropdownMenuLabel>
              <Label name="create_organization_button_text" groupName={LabelGroup.ORGANIZER_MENU} />
            </DropdownMenuLabel>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
