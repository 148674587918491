import { Row } from '@tanstack/react-table';

import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { MoreHorizontal } from 'lucide-react';
import { Link } from 'react-router-dom';
// import { DeleteButton } from '@/components/delete-button';
// import { Separator } from '@/components/ui/separator';
// import { useDeleteLocation } from '../api/delete-location';
import { LabelGroup } from '@/features/label/types';
import { Label } from '@/features/label/components/label';
import { TLocation } from '../types';

interface DataTableRowActionsProps<TData> {
  row: Row<TData>;
}

export function LocationTableRowActions<TData>({ row }: DataTableRowActionsProps<TData & TLocation>) {
  // const { mutateAsync: deleteLocation } = useDeleteLocation({});

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="flex h-8 w-8 p-0 data-[state=open]:bg-muted">
          <MoreHorizontal className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[160px]">
        <Link to={`/locations/edit/${row.original?.locationId}`}>
          <DropdownMenuItem>
            <Label name="edit_label_table_action_text" groupName={LabelGroup.ADMIN_LOCATION_LIST} />
          </DropdownMenuItem>
        </Link>
        {/* <Separator />
        <DeleteButton
          TitleComponent={<Label name="delete_location_table_action_title" groupName={LabelGroup.ADMIN_ORGANIZATION_LIST} />}
          DescriptionComponent={
            <Label name="delete_location_table_action_description" groupName={LabelGroup.ADMIN_ORGANIZATION_LIST} />
          }
          ButtonComponent={
            <div className="text-destructive flex items-center text-sm px-2 py-1.5">
              <Label name="delete_location_table_action_text" groupName={LabelGroup.ADMIN_ORGANIZATION_LIST} />
            </div>
          }
          onDelete={() => deleteLocation({ locationId: row.original?.locationId })}
        /> */}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
