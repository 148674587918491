import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { useSettings } from '../api/getSettings';
import { tags } from '../data/tags';
import { Setting } from '../types';
import { EmailSettingItem } from './email-setting-item';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';

export function EmailSettings() {
  const { data: settings } = useSettings({});

  const emailSettings = Object.values(settings?.email || []).map((value: Setting) => {
    try {
      return {
        ...value,
        data: JSON.parse(value?.data?.toString() ?? '{}'), // Ensure data conforms to the expected structure
      };
    } catch (error) {
      return {
        ...value,
        data: value?.data?.toString(),
      };
    }
  });

  return (
    <div className="flex gap-8">
      <div className="space-y-6">
        {emailSettings.map((setting) => (
          <EmailSettingItem setting={setting} key={setting.settingId} />
        ))}
      </div>
      <Card className="w-full h-fit">
        <CardHeader>
          <CardTitle>
            <Label name="Tags" groupName={LabelGroup.ADMIN_SETTINGS} />
          </CardTitle>
          <CardDescription>
            <Label name="TagDescription" groupName={LabelGroup.ADMIN_SETTINGS} />
          </CardDescription>
        </CardHeader>
        <CardContent className="flex flex-wrap gap-2">
          {tags.map((tag) => (
            <div key={tag.id}>
              <Tooltip>
                <TooltipTrigger>
                  <kbd className="bg-accent text-muted-foreground border p-1 text-xs rounded-sm shadow-sm">
                    {tag.id}
                  </kbd>
                </TooltipTrigger>
                <TooltipContent>
                  <div>
                    <Label name={tag.description} groupName={LabelGroup.ADMIN_SETTINGS} />
                  </div>
                </TooltipContent>
              </Tooltip>
            </div>
          ))}
        </CardContent>
      </Card>
    </div>
  );
}
