import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { TLocation } from '../types';

export const getLocation = async ({ locationId }: { locationId?: number }): Promise<TLocation> => {
  return axios.get(`/location/${locationId}`);
};

type QueryFnType = typeof getLocation;

type UseLocationOptions = {
  locationId?: number;
  config?: QueryConfig<QueryFnType>;
};

export const useLocation = ({ locationId, config }: UseLocationOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['locations', locationId],
    queryFn: () => getLocation({ locationId }),
  });
};
