import { LabelGroup } from '@/features/label/types';
import { ProductType } from '../types';
import { Label } from '@/features/label/components/label';

export const productTypes = [
  {
    value: ProductType.Event,
    label: <Label name="Event" groupName={LabelGroup.ADMIN_PAYMENT} />
  },
  {
    value: ProductType.ProgramOneTime,
    label: <Label name="ProgramOneTime" groupName={LabelGroup.ADMIN_PAYMENT} />
  },
  {
    value: ProductType.ProgramYearly,
    label: <Label name="ProgramYearly" groupName={LabelGroup.ADMIN_PAYMENT} />
  }
];
