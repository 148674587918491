import { Button } from '@/components/ui/button';
import { Printer } from 'lucide-react';
import { useRef, useState } from 'react';

interface PrintWrapperProps {
  children: React.ReactNode;
}

export function PrintWrapper({ children }: PrintWrapperProps) {
  const [isPrinting, setIsPrinting] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const handlePrint = () => {
    if (containerRef.current) {
      setIsPrinting(true);

      const printWindow = window.open('', '', 'height=600,width=800');
      if (printWindow) {
        // Copy styles from the current document
        const styles = Array.from(document.head.getElementsByTagName('style'));
        const links = Array.from(document.head.getElementsByTagName('link'));

        printWindow.document.write('<html><head><title></title>');

        // Inject the copied styles
        styles.forEach((style) => {
          printWindow.document.write(style.outerHTML);
        });

        // Inject the copied link elements (such as external CSS)
        links.forEach((link) => {
          printWindow.document.write(link.outerHTML);
        });

        printWindow.document.write(`
            <style>
            @media print {
                [data-no-print] {
                  display: none !important;
                }
              }
            </style>
            `);

        printWindow.document.write('</head><body>');
        printWindow.document.write(containerRef.current.innerHTML);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
      }

      setIsPrinting(false);
    }
  };

  return (
    <div className="relative w-full" ref={containerRef}>
      <Button
        variant="outline"
        disabled={isPrinting}
        size="sm"
        data-no-print
        onClick={handlePrint}
        className="absolute top-4 right-4"
      >
        <Printer className="w-4 h-4" />
      </Button>
      {children}
    </div>
  );
}
