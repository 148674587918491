import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Payment } from '@/features/payment/types';

const QUERY_KEY = 'invoices';

export const getAllInvoices = async (): Promise<Payment[]> => {
  return axios.get(`/payment/invoice/all`);
};

type QueryFnType = typeof getAllInvoices;

type Options = {
  config?: QueryConfig<QueryFnType>;
};

export const useAllInvoices = ({ config }: Options) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: [QUERY_KEY],
    queryFn: getAllInvoices,
  });
};
