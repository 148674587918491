export function Logo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 208.59 221.1">
      <defs>
        <style>
          {`.cls-1{fill:#e2251d;}.cls-2{fill:#f7a800;}.cls-3{fill:#ea7305;}.cls-4{fill:#fff;}`}
        </style>
      </defs>
      <title>A_logo</title>
      <g id="Lager_2" data-name="Lager 2">
        <g id="Lager_1-2" data-name="Lager 1">
          <path
            className="cls-1"
            d="M2.84,221.1a2.83,2.83,0,0,1-2.72-3.65L64.36,2a2.84,2.84,0,0,1,2.72-2H84.74L104.3,71.05,63.57,219a2.82,2.82,0,0,1-2.73,2.08Z"
          />
          <path
            className="cls-2"
            d="M147.76,221.1A2.84,2.84,0,0,1,145,219l-40.72-148L123.85,0h17.66a2.83,2.83,0,0,1,2.72,2l64.24,215.42a2.83,2.83,0,0,1-2.71,3.65Z"
          />
          <path
            className="cls-3"
            d="M88.47,172.7a2.84,2.84,0,0,1-2.74-3.59l15.83-57.52a2.84,2.84,0,0,1,5.47,0l15.83,57.52a2.81,2.81,0,0,1-.48,2.47,2.84,2.84,0,0,1-2.25,1.12Z"
          />
          <path
            className="cls-4"
            d="M52.76,129a.32.32,0,0,0-.28-.13l-13,.32a.33.33,0,0,0-.32.25l-.82,2.75a.35.35,0,0,0,.13.38l10.71,7.32a.4.4,0,0,0,.32,0,.35.35,0,0,0,.21-.23l.67-2.23a.37.37,0,0,0-.14-.39l-2.84-1.87,1-3.27,3.42-.07a.35.35,0,0,0,.33-.24l.69-2.32A.35.35,0,0,0,52.76,129Zm-7.48,5L43,132.45c-.29-.19-.61-.38-.93-.57.37,0,.74,0,1.1,0l2.72,0Z"
          />
          <path
            className="cls-4"
            d="M77.43,46.24a.37.37,0,0,0-.29-.14l-13,.33a.35.35,0,0,0-.33.24L63,49.43a.35.35,0,0,0,.14.38l10.71,7.31a.32.32,0,0,0,.31,0,.32.32,0,0,0,.22-.22l.66-2.23a.34.34,0,0,0-.14-.39l-2.84-1.87,1-3.27,3.42-.07a.33.33,0,0,0,.32-.24l.69-2.32A.34.34,0,0,0,77.43,46.24Zm-7.49,5-2.26-1.52c-.29-.19-.6-.38-.92-.57.37,0,.74,0,1.1,0l2.72-.05Z"
          />
          <path
            className="cls-4"
            d="M46.87,143.52a6.75,6.75,0,0,0-4.38-3.43,5.9,5.9,0,0,0-5.19.59,7.82,7.82,0,0,0-2.95,4.61,22.49,22.49,0,0,0-.79,3.57.33.33,0,0,0,.24.37l11.65,3.47a.34.34,0,0,0,.41-.19,27.12,27.12,0,0,0,1-2.91A8.54,8.54,0,0,0,46.87,143.52Zm-2.22,4.93h0c-.09.3-.18.61-.27.86L36.48,147a7.84,7.84,0,0,1,.28-1.16,4.05,4.05,0,0,1,1.87-2.61,3.88,3.88,0,0,1,3.06-.13,4.51,4.51,0,0,1,2.83,2A4.3,4.3,0,0,1,44.65,148.45Z"
          />
          <path
            className="cls-4"
            d="M33.47,193.74a.32.32,0,0,0-.29-.13l-13,.32a.33.33,0,0,0-.32.25l-.83,2.75a.35.35,0,0,0,.14.38l10.71,7.32a.38.38,0,0,0,.31,0,.34.34,0,0,0,.22-.23l.66-2.23a.34.34,0,0,0-.14-.39L28.11,200l1-3.27,3.42-.07a.33.33,0,0,0,.32-.24l.69-2.32A.31.31,0,0,0,33.47,193.74Zm-7.49,5-2.25-1.52-.93-.57c.37,0,.74,0,1.1,0l2.72,0Z"
          />
          <path
            className="cls-4"
            d="M36.68,183.45l-2.09,7a.34.34,0,0,1-.43.23l-11.84-3.52a.34.34,0,0,1-.23-.43l.64-2.16a.35.35,0,0,1,.43-.23l9.7,2.88,1.35-4.52a.35.35,0,0,1,.43-.24l1.81.54A.34.34,0,0,1,36.68,183.45Z"
          />
          <path
            className="cls-4"
            d="M41.42,167.57l-.63,2.11a.35.35,0,0,1-.42.23l-5.08-1.25c-.87-.22-1.81-.45-2.75-.7.79.52,1.58,1.06,2.31,1.59l4.61,3.25a.35.35,0,0,1,.13.38l-.49,1.67a.35.35,0,0,1-.33.24l-5.53.08c-.85,0-1.72,0-2.58,0,.92.31,1.85.63,2.7.94L38,177.74a.35.35,0,0,1,.22.43l-.6,2a.37.37,0,0,1-.18.21.34.34,0,0,1-.28,0L25.6,176.1a.34.34,0,0,1-.21-.42l.85-2.84a.33.33,0,0,1,.32-.25l5.22-.13c1,0,2.13,0,3.18.06-.8-.49-1.75-1.09-2.7-1.75L28,167.72a.35.35,0,0,1-.13-.38l.84-2.81a.34.34,0,0,1,.41-.23l12,2.83a.32.32,0,0,1,.22.17A.36.36,0,0,1,41.42,167.57Z"
          />
          <path
            className="cls-4"
            d="M44.77,156.3l-2.12,7.14a.35.35,0,0,1-.43.24l-11.84-3.52a.35.35,0,0,1-.24-.43l2-6.88a.35.35,0,0,1,.43-.24l1.78.53a.35.35,0,0,1,.23.43L33.32,158l2.39.71,1.23-4.12a.36.36,0,0,1,.44-.24l1.75.53a.34.34,0,0,1,.23.43l-1.23,4.12,2.82.84,1.39-4.65a.34.34,0,0,1,.43-.24l1.77.53A.34.34,0,0,1,44.77,156.3Z"
          />
          <path
            className="cls-4"
            d="M56,118.71l-2.09,7a.36.36,0,0,1-.44.24l-11.84-3.53a.35.35,0,0,1-.23-.43l.65-2.16a.35.35,0,0,1,.43-.23l9.69,2.89L53.5,118a.36.36,0,0,1,.44-.24l1.8.54A.35.35,0,0,1,56,118.71Z"
          />
          <path
            className="cls-4"
            d="M58,112.53a7.51,7.51,0,0,1-1.79,3.13.32.32,0,0,1-.41,0l-1.65-1a.39.39,0,0,1-.17-.26.34.34,0,0,1,0-.14.34.34,0,0,1,.09-.15,5.92,5.92,0,0,0,1.51-2.48c.34-1.15.07-2-.74-2.21-.61-.18-1.17,0-2.21,1.39-1.52,2.05-3.08,2.8-4.78,2.3-2.2-.66-3.18-3-2.39-5.62A6.85,6.85,0,0,1,47,104.72a.35.35,0,0,1,.44,0l1.59,1a.35.35,0,0,1,.15.25.53.53,0,0,1,0,.13.38.38,0,0,1-.09.15,4.87,4.87,0,0,0-1.18,2c-.33,1.13.07,1.8.61,2s1.14-.14,2.25-1.57c1.52-2,3-2.66,4.8-2.12S59.07,109,58,112.53Z"
          />
          <path
            className="cls-4"
            d="M61.13,101.41a.35.35,0,0,1-.32.25l-13,.3a.36.36,0,0,1-.34-.45l.7-2.35a.35.35,0,0,1,.33-.24l5.86,0c1.26,0,2.33,0,3.33,0-.87-.53-1.79-1.13-2.78-1.81L50,93.81a.35.35,0,0,1-.14-.39l.69-2.29a.32.32,0,0,1,.22-.23.34.34,0,0,1,.31,0l10.62,7.61a.35.35,0,0,1,.13.38Z"
          />
          <path
            className="cls-4"
            d="M66.18,84.49l-2.13,7.14a.35.35,0,0,1-.43.23L51.78,88.34a.34.34,0,0,1-.24-.43L53.59,81A.35.35,0,0,1,54,80.8l1.78.53a.34.34,0,0,1,.23.43l-1.31,4.39,2.4.71,1.23-4.13a.35.35,0,0,1,.43-.23l1.75.52a.35.35,0,0,1,.24.43l-1.23,4.13,2.81.84,1.39-4.66a.35.35,0,0,1,.43-.23l1.77.53A.35.35,0,0,1,66.18,84.49Z"
          />
          <path
            className="cls-4"
            d="M69.11,75.29c-1.16,3.87-4.37,5.64-8.19,4.5a6.53,6.53,0,0,1-4.6-8.61,7.47,7.47,0,0,1,1.47-2.86.33.33,0,0,1,.43,0l1.58,1a.4.4,0,0,1,.16.24.43.43,0,0,1,0,.16.75.75,0,0,1-.07.12,5.7,5.7,0,0,0-1.08,2.06c-.71,2.38.37,4.26,2.89,5s4.36-.24,5.07-2.62A6,6,0,0,0,67,71.88a.38.38,0,0,1,0-.15.29.29,0,0,1,.08-.14.36.36,0,0,1,.28-.11l1.81.16a.35.35,0,0,1,.31.28A8.11,8.11,0,0,1,69.11,75.29Z"
          />
          <path
            className="cls-4"
            d="M73.67,59.34l-.75,2.53a.37.37,0,0,1-.24.23l-6,1.62.62.92,4,1.2a.35.35,0,0,1,.24.43L71,68.41a.35.35,0,0,1-.43.24L58.7,65.12a.34.34,0,0,1-.24-.43l.64-2.14a.35.35,0,0,1,.43-.23L64,63.64l-.35-.45-3.25-4.33a.35.35,0,0,1-.06-.31l.8-2.66a.34.34,0,0,1,.62-.1l3.68,5.36,7.85-2.24a.37.37,0,0,1,.35.09A.35.35,0,0,1,73.67,59.34Z"
          />
          <path
            className="cls-4"
            d="M81.34,33.6l-.66,2.23a.36.36,0,0,1-.25.23L74.31,37.6c-1.17.29-2.23.51-3.19.68,1.07.29,2.21.62,3.55,1l4.24,1.26a.34.34,0,0,1,.23.43l-.59,2a.35.35,0,0,1-.43.23L66.28,39.68a.35.35,0,0,1-.23-.43l.73-2.46a.35.35,0,0,1,.24-.23L73,35c.91-.23,1.87-.43,2.82-.59-1-.24-2-.53-3.23-.88l-4.12-1.23a.35.35,0,0,1-.24-.43l.6-2a.34.34,0,0,1,.43-.23l11.84,3.52A.34.34,0,0,1,81.34,33.6Z"
          />
          <polygon className="cls-3" points="84.74 0 104.3 71.05 123.85 0 84.74 0" />
        </g>
      </g>
    </svg>
  );
}
