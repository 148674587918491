import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { OrderRow, Payment } from '../types';

const QUERY_KEY = 'payment';

type PaymentResponse = {
  payment: Payment;
  orderRows: OrderRow[];
};

export const getPaymentConfirmation = (paymentId: number): Promise<PaymentResponse> => {
  return axios.get(`/payment/confirmation/${paymentId}`);
};

type QueryFnType = typeof getPaymentConfirmation;

type UseOptions = {
  paymentId: number;
  config?: QueryConfig<QueryFnType>;
};

export const usePaymentConfirmation = ({ config, paymentId }: UseOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: [QUERY_KEY, paymentId],
    queryFn: () => getPaymentConfirmation(paymentId),
    ...config,
  });
};
