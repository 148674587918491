import { z } from 'zod';

const emailSchema = z
  .string()
  .email()
  .refine((value) => value.includes('@'), {
    message: 'invalid_email_address',
  });

export { emailSchema };
