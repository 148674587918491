import { Input } from '@/components/ui/input';
import { useUpdateLabel } from '../api/updateLabel';
import { Label } from '../types';
import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { label as labelFn } from '@/utils/label';

interface LabelCellInputProps {
  label: Label;
  path: keyof Label;
}

export function LabelCellInput({ label, path }: LabelCellInputProps) {
  const [value, setValue] = useState<string>((label?.[path] as string) ?? '');
  const { mutateAsync: updateLabel } = useUpdateLabel({});

  const hasChanged = value !== label?.[path] && value !== '';

  const handleUpdate = () => {
    updateLabel({ ...label, groupName: path === 'labelGroup' ? value : label.labelGroup, [path]: value ?? '' });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && hasChanged) {
      handleUpdate();
    }
  };

  return (
    <div className="flex gap-x-2 w-[300px]">
      <Input onKeyDown={handleKeyDown} value={value} onChange={(e) => setValue(e.target.value)} />
      {hasChanged && <Button onClick={handleUpdate}>{labelFn('save', 'global')}</Button>}
    </div>
  );
}
