import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';

import { encryptString } from '@/utils/encrypt';
import storage from '@/utils/storage';

export type LoginCredentialsDTO = {
  username: string;
  password: string;
};

export const login = async (data: LoginCredentialsDTO) => {
  const { username, password } = data;

  const { ciphertext, key } = encryptString(`${username}|||${password}`);

  try {
    const { accessToken, refreshToken } = (await axios.post('/login' + window.location.search, {
      username: key,
      password: '',
      credentials: ciphertext,
    })) as {
      accessToken: string;
      refreshToken: string;
    };

    if (!accessToken || !refreshToken) throw new Error('No token found');

    storage.setAccessToken(accessToken);
    storage.setRefreshToken(refreshToken);

    queryClient.refetchQueries('me');
  } catch (error) {
    throw error;
  }
};
