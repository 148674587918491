import { useForm } from 'react-hook-form';
import { useUpdateUser } from '../api/update-user';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { ChangePassword } from '../components/change-password';
import { updateProfileSchema } from '../data/update-profile-schema';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { useMe } from '@/features/auth/api/get-me';
import { Skeleton } from '@/components/ui/skeleton';

export function Profile() {
  const { data: user, isLoading: isFetching } = useMe({});

  const { mutateAsync: updateUser, isLoading: isUpdating } = useUpdateUser({
    userId: user?.userId ?? 0,
  });

  const form = useForm<z.infer<typeof updateProfileSchema>>({
    resolver: zodResolver(updateProfileSchema),
    values: {
      userId: user?.userId ?? 0,
      name: user?.name ?? '',
      username: user?.username ?? '',
      telephone: user?.telephone ?? '',
    },
  });

  const handleSubmit = async (values: z.infer<typeof updateProfileSchema>) => {
    await updateUser({ ...user, ...values });
  };

  if (isFetching) {
    return (
      <div className="space-y-6  w-full mb-4">
        <Skeleton className="w-full h-96" />
        <Skeleton className="w-full h-96" />
      </div>
    );
  }

  return (
    <div className="space-y-6  w-full mb-4">
      <Card>
        <CardHeader>
          <CardTitle>
            <Label name="user_details_card_title" groupName={LabelGroup.PROFILE} />
          </CardTitle>
        </CardHeader>
        <CardContent>
          <Form {...form}>
            <form
              onKeyDown={(event) => event.key === 'Enter' && event.preventDefault()}
              onSubmit={form.handleSubmit(handleSubmit)}
              className="space-y-8"
            >
              <FormField
                control={form.control}
                name="username"
                render={({ field }) => (
                  <FormItem className="max-w-sm">
                    <FormLabel>
                      <Label name="username" groupName={LabelGroup.PROFILE} />
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="telephone"
                render={({ field }) => (
                  <FormItem className="max-w-sm">
                    <FormLabel>
                      <Label name="telephone" groupName={LabelGroup.PROFILE} />
                    </FormLabel>
                    <FormControl>
                      <Input {...field} type="telephone" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem className="max-w-sm">
                    <FormLabel>
                      <Label name="name" groupName={LabelGroup.PROFILE} />
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button disabled={isUpdating || form.formState.isSubmitting || !form.formState.isDirty} type="submit">
                <Label name={isUpdating ? 'updating' : 'update'} groupName={LabelGroup.GLOBAL} />
              </Button>
            </form>
          </Form>
        </CardContent>
      </Card>
      <ChangePassword />
    </div>
  );
}
