import { axios } from '@/lib/axios';

export type ConfirmEmailDTO = {
  token: string;
};

export const confirmEmail = async (data: ConfirmEmailDTO) => {
  try {
    return axios.put('/confirm-email', data);
  } catch (error) {
    throw error;
  }
};
