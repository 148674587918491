import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

const QUERY_KEY = 'active-year';

export const getYear = (): Promise<number> => {
  return axios.get(`/year/active`);
};

type QueryFnType = typeof getYear;

type UseSettingOption = {
  config?: QueryConfig<QueryFnType>;
};

export const useYear = ({ config }: UseSettingOption) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: [QUERY_KEY],
    queryFn: getYear,
    ...config
  });
};
