import { useOrganizerStore } from '@/stores/organizerStore';
import { usePayments } from '../api/get-payments';
import { Card } from '@/components/ui/card';
import { Link } from 'react-router-dom';
import { convertUTCtoLocal } from '@/utils/convertUtcToLocal';
import { PaymentIcon } from '../components/payment-icon';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { Button } from '@/components/ui/button';
import { OrderRow, ProductType } from '../types';
import { DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { useState } from 'react';
import { useYear } from '@/features/settings/api/get-year';
import { cn } from '@/utils';

type PaymentConfirmation = OrderRow & { title: string } & any;

export function Payments() {
  const [filterByYear, setFilterByYear] = useState<number | null>(null);
  const organizerId = useOrganizerStore(state => state.organizationId);

  const { data: activeYear, isFetched: yearIsFetched } = useYear({
    config: {
      onSuccess: data => {
        setFilterByYear(data);
      }
    }
  });

  const { data = [] } = usePayments({ organizerId, config: { enabled: !!organizerId && yearIsFetched } });

  const availableYears = data?.reduce((acc: number[], payment) => (acc?.includes(payment.payment.yearId) ? acc : [...acc, payment.payment.yearId]), []);
  const filteredPayments = data.filter(payment => payment?.productType !== ProductType.ProgramOneTime && payment?.productType !== ProductType.ProgramYearly && payment.payment.yearId === (filterByYear || activeYear));

  return (
    <div className="space-y-6 pl-2 text-base">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold">
          <Label name="payments_menu_title" groupName={LabelGroup.ORGANIZER_PAYMENT_LIST} />
        </h2>
        {availableYears?.length > 0 && (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className={cn('bg-accent')}>
                År {filterByYear}
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-30">
              <DropdownMenuLabel>
                <Label name="Year" groupName={LabelGroup.ORGANIZER_EVENT_LIST} />
              </DropdownMenuLabel>
              <DropdownMenuSeparator />
              {[...availableYears.filter(y => y !== activeYear), activeYear]?.map(year => (
                <DropdownMenuCheckboxItem key={year} className="flex items-center gap-x-2" checked={filterByYear === year} onCheckedChange={_ => setFilterByYear(year as number)}>
                  {year}
                </DropdownMenuCheckboxItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </div>
      <div className="space-y-4 md:space-y-2">
        {filteredPayments.map(({ payment, orderRowId, name, title }: PaymentConfirmation) => (
          <Card key={orderRowId} className="flex px-4 py-3 items-center justify-between">
            <div className="flex gap-2 items-start flex-col">
              <div className="w-full   md:max-w-full">
                <Link to={`/events/edit/${name.substring(0, name.indexOf('-'))}`} className="underline flex items-center font-semibold font-base">
                  {name.substring(0, name.indexOf('-'))} - {title}
                </Link>
              </div>
              <div className="font-semibold text-md flex justify-between items-center gap-x-2">
                <div className="flex items-center">
                  <PaymentIcon payType={payment.payType} />
                </div>
                <div>{payment.amount} SEK</div>
              </div>
              <div className="w-40 text-sm">{convertUTCtoLocal(payment?.payDate?.toString())}</div>

              <div className="underline text-sm w-24">
                <Link to={`/payment/confirmation/${payment.paymentId}`}>
                  <Button variant="outline" size="sm">
                    <Label name="show_payment" groupName={LabelGroup.ORGANIZER_PAYMENT_LIST} />
                  </Button>
                </Link>
              </div>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
}
