type TLocation = {
  locationId: number;
  name: string;
  sortOrder?: number;
  latitude: string;
  longitude: string;
  tag?: string | null;
  category: string;
  active: boolean;
  inserted: string;
  updated?: string;
};

enum LocationCategory {
  Custom = 'Custom',
  LOKALER_TRADGARDAR_FARTYG = 'Lokaler, trädgårdar & fartyg',
  TALARPLATS = 'Talarplats',
  MARKPLATSER = 'Markplatser',
}

export { LocationCategory };
export type { TLocation };
