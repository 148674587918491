import { EventStatus } from '../types';

export const eventStatuses = {
  [EventStatus.Draft]: 'Draft',
  [EventStatus.AwaitingPayment]: 'Awaiting_Payment',
  [EventStatus.Pending]: 'Pending',
  [EventStatus.ActionRequired]: 'Action_Required',
  [EventStatus.Approved]: 'Approved',
  [EventStatus.Rejected]: 'Rejected',
  [EventStatus.Cancelled]: 'Cancelled',
  // [EventStatus.UnPublished]: 'UnPublished',
  [EventStatus.Deleted]: 'Deleted',
} as {
  [key in EventStatus]: string;
};
