import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { ArrowRight } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { useOrganizations } from '../api/getOrganizations';
import { useMe } from '@/features/auth/api/get-me';
import organizerHero from '@/assets/media/organizer_hero.jpg';

export function OrganizationsShortcutCard() {
  const { data: user } = useMe({
    config: {
      enabled: false
    }
  });

  const { data: organizations = [] } = useOrganizations({
    userId: user?.userId ?? 0,
    config: {
      enabled: false && !!user?.userId
    }
  });

  const linkTo = organizations.length > 0 ? '/organizations' : '/organizations/create';

  return (
    <Link to={linkTo}>
      <Card className="w-full  h-full justify-between border-0 rounded-none">
        <div>
          <img src={organizerHero} alt="Organization placeholder" className="w-full h-full object-cover" />
        </div>
        <CardHeader>
          <CardTitle className="flex items-center">
            <ArrowRight className="w-6 h-6 mr-2" />
            <Label name="no_organization_created" groupName={LabelGroup.ORGANIZER_DASHBOARD} />
          </CardTitle>
        </CardHeader>
        <CardContent className="h-auto">
          <CardDescription className="text-left">
            <Label name="no_organization_created_description" groupName={LabelGroup.ORGANIZER_DASHBOARD} />
          </CardDescription>
        </CardContent>
      </Card>
    </Link>
  );
}
