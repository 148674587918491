import { useParams } from 'react-router-dom';
import { useEvent } from '@/features/admin/api/getEvent';
import { User } from '@/features/user';
import { EventLog, LogCategory } from '@/features/event/types';
import { Timeline } from '@/components/ui/timeline';

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';

import { HistoryTimelineItem } from './history-timeline-item';

export function HistoryTimeline() {
  const { id } = useParams();

  const { data: event } = useEvent({
    eventId: Number(id),
    config: {
      enabled: false
    }
  });

  const logsGroupedByDate = (event?.logs as EventLog[])
    ?.sort((a, b) => new Date(b.inserted).getTime() - new Date(a.inserted).getTime())
    ?.reduce((acc, log) => {
      const date = new Date(log.inserted).toLocaleDateString();

      if (!acc[date]) {
        acc[date] = [];
      }

      acc[date].push(log);

      return acc;
    }, {} as Record<string, (EventLog & { user?: User })[]>);

  return (
    <div className="space-y-0  pl-4">
      {Object.entries(logsGroupedByDate).map(
        ([date, logs]) =>
          logs.filter(l => l.category !== LogCategory.REVIEW && l.category !== LogCategory.REVIEW_END)?.length > 0 && (
            <Accordion type="single" key={date} collapsible defaultValue={date}>
              <div key={date}>
                <AccordionItem value={date}>
                  <AccordionTrigger>
                    <div className="flex items-center space-x-2 py-4">
                      <h2 className="text-xl font-semibold">{new Date(date).toLocaleDateString('sv-SE', { year: 'numeric', month: 'long', day: 'numeric' })}</h2>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent>
                    <div className="space-y-0 pl-16">
                      {logs.map(log => (
                        <Timeline>
                          <HistoryTimelineItem key={log.eventLogId} log={log} />
                        </Timeline>
                      ))}
                    </div>
                  </AccordionContent>
                </AccordionItem>
              </div>
            </Accordion>
          )
      )}
    </div>
  );
}
