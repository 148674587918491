import { useRef, useState } from 'react';
import { useEventOrganizers } from '../api/get-event-organizers';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Trash2 } from 'lucide-react';
import { EventOrganizer } from '../types';
import { cn } from '@/utils';
import { ListNavigator } from '@/components/list-navigator';
import useOnClickOutside from '@/hooks/use-cllick-outside';

interface OrganizerSuggestionInputProps {
  organizer: EventOrganizer;
  enableDelete?: boolean;
  onUpdate: (eventOrganizerId: number, value: string) => void;
  onDelete: () => void;
}

export function OrganizerSuggestionInput({ organizer, enableDelete, onUpdate, onDelete }: OrganizerSuggestionInputProps) {
  const [showSuggestions, setShowSuggestions] = useState(false);

  const inputRef = useRef(null);
  const suggestionListRef = useRef(null);
  const navigatorRef = useRef(null);

  const { data: suggestions = [] } = useEventOrganizers({ config: { enabled: true } });

  const filteredSuggestions = suggestions.filter(suggestion => suggestion.organizerName?.toLowerCase().includes(organizer.organizerName.toLowerCase()));

  useOnClickOutside([{ current: inputRef.current }, { current: suggestionListRef.current }, { current: navigatorRef.current }], () => setShowSuggestions(false), showSuggestions);

  return (
    <div key={organizer.eventOrganizerId} className="flex items-center relative space-x-2 w-full md:w-96">
      <Input
        ref={inputRef}
        defaultValue={organizer.organizerName}
        value={organizer.organizerName}
        onKeyDown={e => {
          if (e.key === 'Tab') {
            setShowSuggestions(false);
          }
        }}
        onChange={e => {
          onUpdate(organizer.eventOrganizerId, e.target.value);
          setShowSuggestions(true);
        }}
      />
      {
        <div
          ref={suggestionListRef}
          className={cn(
            'absolute bg-card z-10 -left-2 translate-y-2 top-full  border  border-input w-full shadow-sm rounded-none max-h-96 overflow-y-auto',

            suggestions.length > 0 && organizer.organizerName && suggestions.filter(suggestion => suggestion.organizerName?.toLowerCase().includes(organizer.organizerName.toLowerCase()))?.length > 0 && showSuggestions ? 'block' : 'hidden',
            enableDelete ? 'w-[calc(100%_-_54px)]' : 'w-full'
          )}>
          <ListNavigator inputRef={inputRef} containerRef={navigatorRef}>
            {filteredSuggestions?.map(suggestion => (
              <li
                className=" text-sm mx-1 py-2 px-2 rounded-md data-[state='on']:bg-muted hover:bg-accent cursor-pointer data-[state='on']:outline-zinc-100"
                key={suggestion.organizerName}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    onUpdate(organizer.eventOrganizerId, suggestion.organizerName);
                    setShowSuggestions(false);
                  }
                }}
                onClick={event => {
                  event.stopPropagation();
                  onUpdate(organizer.eventOrganizerId, suggestion.organizerName);
                  setShowSuggestions(false);
                }}>
                {suggestion.organizerName}
              </li>
            ))}
          </ListNavigator>
        </div>
      }
      {enableDelete && (
        <Button variant="ghost" type="button" onClick={() => onDelete()}>
          <Trash2 className="w-4 h-4" />
        </Button>
      )}
    </div>
  );
}
