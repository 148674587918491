import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useToast } from '@/components/ui/use-toast';
import { label } from '@/utils/label';
import { Label, LabelGroup } from '../types';

const QUERY_KEY = 'labels';
const API_PATH = '/label';

export type UpdateLabelDTO = {
  labelName: string;
  languageCode: string;
  value: string;
  groupName: string;
};

export const updateLabel = ({ value, ...data }: UpdateLabelDTO): Promise<Label> => {
  return axios.put(
    API_PATH,
    {
      value,
    },
    { params: { ...data } }
  );
};

type UseUpadteLabelOptions = {
  config?: MutationConfig<typeof updateLabel>;
};

export const useUpdateLabel = ({ config }: UseUpadteLabelOptions) => {
  const { toast } = useToast();

  return useMutation({
    onMutate: async (_: any) => {
      await queryClient.cancelQueries([QUERY_KEY]);

      const prev = queryClient.getQueryData<Label[]>([QUERY_KEY]);

      // queryClient.setQueryData([QUERY_KEY], [...(prev || []), next.data]);

      return { prev };
    },
    onError: (_, __, context: any) => {
      if (context?.prev) {
        queryClient.setQueryData([QUERY_KEY], context.prev);
      }
      toast({
        variant: 'destructive',
        title: label('something_went_wrong', LabelGroup.GLOBAL),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEY]);
      queryClient.refetchQueries([QUERY_KEY]);

      toast({
        variant: 'success',
        title: label('label_updated', LabelGroup.ADMIN_LABEL),
      });
    },
    ...config,
    mutationFn: updateLabel,
  });
};
