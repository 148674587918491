import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Organization } from '../types';

const QUERY_KEY = 'organizations';

export const getAllOrganizations = (): Promise<Organization[]> => {
  return axios.get(`/organizer/all`);
};

type QueryFnType = typeof getAllOrganizations;

type UseOrganizationOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useAllOrganizations = ({ config }: UseOrganizationOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: [QUERY_KEY],
    queryFn: getAllOrganizations,
    ...config,
  });
};
