import { cn } from '@/utils';
import { EventStatus } from '../types';
import { CalendarX, CheckCircle, PenIcon, PenLine, Send, Wallet2, X } from 'lucide-react';
import { UserType } from '@/features/user';
import { LabelGroup } from '@/features/label/types';
import { Label } from '@/features/label/components/label';
import { ExclamationCircle, InfoCircle } from 'react-bootstrap-icons';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { useMe } from '@/features/auth/api/get-me';

interface StatusStepperProps {
  status?: EventStatus | undefined;
  isDraft?: boolean;
}

const titles = {
  [EventStatus.Draft]: 'Draft',
  [EventStatus.AwaitingPayment]: 'Awaiting_Payment',
  [EventStatus.Pending]: 'Pending',
  // [EventStatus.Review]: 'Review',
  [EventStatus.ActionRequired]: 'Action_Required',
  [EventStatus.Approved]: 'Approved',
  [EventStatus.Rejected]: 'Rejected',
  [EventStatus.Cancelled]: 'Cancelled',
  [EventStatus.UnPublished]: 'UnPublished',
} as const;

const icons = {
  [EventStatus.Draft]: <PenIcon className="w-4 h-4" />,
  [EventStatus.AwaitingPayment]: <Wallet2 className="w-4 h-4" />,
  [EventStatus.Pending]: <Send className="w-4 h-4" />,
  [EventStatus.ActionRequired]: <ExclamationCircle className="w-4 h-4" />,
  [EventStatus.Approved]: <CheckCircle className="w-4 h-4" />,
  [EventStatus.Rejected]: <X className="w-4 h-4" />,
  // [EventStatus.Review]: <PenSquare className="w-4 h-4" />,
  [EventStatus.Cancelled]: <CalendarX className="w-4 h-4" />,
  [EventStatus.UnPublished]: <X className="w-4 h-4" />,
} as const;

// const afterColor = {
//   [EventStatus.Draft]:
//     'after:content-[""] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-2 after:inline-block dark:after:border-gray-800',
//   [EventStatus.AwaitingPayment]:
//     'after:content-[""] after:w-full after:h-1 after:border-b after:border-amber-200 after:border-2 after:inline-block dark:after:border-amber-800',
//   [EventStatus.Pending]:
//     'after:content-[""] after:w-full after:h-1 after:border-b after:border-blue-200 after:border-2 after:inline-block dark:after:border-blue-800',
//   [EventStatus.ActionRequired]:
//     'after:content-[""] after:w-full after:h-1 after:border-b after:border-amber-200 after:border-2 after:inline-block dark:after:border-amber-800',
//   [EventStatus.Approved]:
//     'after:content-[""] after:w-full after:h-1 after:border-b after:border-green-200 after:border-2 after:inline-block dark:after:border-green-800',
//   [EventStatus.Rejected]:
//     'after:content-[""] after:w-full after:h-1 after:border-b after:border-red-200 after:border-2 after:inline-block dark:after:border-red-800',
//   [EventStatus.Review]:
//     'after:content-[""] after:w-full after:h-1 after:border-b after:border-sky-200 after:border-2 after:inline-block dark:after:border-sky-800',
//   [EventStatus.Cancelled]:
//     'after:content-[""] after:w-full after:h-1 after:border-b after:border-red-200 after:border-2 after:inline-block dark:after:border-red-800',
//   [EventStatus.UnPublished]:
//     'after:content-[""] after:w-full after:h-1 after:border-b after:border-red-200 after:border-2 after:inline-block dark:after:border-red-800',
// };

// const colors = {
//   [EventStatus.Draft]: 'bg-gray-50 text-gray-900 border-gray-200',
//   [EventStatus.AwaitingPayment]: 'bg-amber-50 text-amber-900 border-amber-200',
//   [EventStatus.Pending]: 'bg-blue-50 text-blue-900 border-blue-200',
//   [EventStatus.ActionRequired]: 'bg-amber-50 text-amber-900 border-amber-200',
//   [EventStatus.Approved]: 'bg-green-50 text-green-900 border-green-200',
//   [EventStatus.Rejected]: 'bg-red-50 text-red-900 border-red-200',
//   [EventStatus.Review]: 'bg-sky-100 text-sky-600 border-sky-200',
//   [EventStatus.Cancelled]: 'bg-red-50 text-red-900 border-red-200',
//   [EventStatus.UnPublished]: 'bg-red-50 text-red-900 border-red-200',
// } as const;

const BEFORE_REVIEW_STATUES = [
  EventStatus.Draft,
  EventStatus.AwaitingPayment,
  EventStatus.Pending,
  EventStatus.Approved,
];

const AFTER_REVIEW_STATUES = [EventStatus.Draft, EventStatus.Pending, EventStatus.ActionRequired, EventStatus.Approved];
const APPROVED_STATUSES = [EventStatus.Draft, EventStatus.Pending, EventStatus.Approved];
const REJECTED_STATUSES = [EventStatus.Draft, EventStatus.Pending, EventStatus.Rejected];
const CANCELLED_STATUSES = [EventStatus.Pending, EventStatus.Approved, EventStatus.Cancelled];
const ABORTED_STATUSES = [EventStatus.Pending, EventStatus.Approved, EventStatus.UnPublished];

const CHANGE_STATUSES_REJECTED = [
  EventStatus.Pending,
  EventStatus.Approved,
  EventStatus.Pending,
  EventStatus.Rejected,
  EventStatus.Approved,
];

const CHANGE_STATUSES_ACTION_REQUIRED = [
  EventStatus.Approved,
  EventStatus.Pending,
  EventStatus.ActionRequired,
  EventStatus.Approved,
];

const statuses = {
  [EventStatus.Draft]: BEFORE_REVIEW_STATUES,
  [EventStatus.AwaitingPayment]: BEFORE_REVIEW_STATUES,
  [EventStatus.Pending]: BEFORE_REVIEW_STATUES,
  [EventStatus.ActionRequired]: AFTER_REVIEW_STATUES,
  [EventStatus.Approved]: APPROVED_STATUSES,
  [EventStatus.Rejected]: REJECTED_STATUSES,
  [EventStatus.Cancelled]: CANCELLED_STATUSES,
  [EventStatus.UnPublished]: ABORTED_STATUSES,
  [EventStatus.Deleted]: [],
};

export function StatusStepper({ status, isDraft }: StatusStepperProps) {
  if (status === undefined) return null;

  const isMobile = window.innerWidth < 768;

  const { data: user } = useMe({});
  const userType = user?.role;

  const labelGroup =
    userType === UserType.ADMIN || userType === UserType.MANAGER
      ? LabelGroup.MANAGER_EVENT_STATUSES
      : LabelGroup.ORGANIZER_EVENT_STATUSES;

  let filterStatuses = isMobile
    ? statuses?.[status]?.filter((value) => status <= value)?.splice(0, 2)
    : statuses?.[status];

  if (isDraft) {
    if (status === EventStatus.ActionRequired) {
      filterStatuses = CHANGE_STATUSES_ACTION_REQUIRED;
    } else if (status === EventStatus.Rejected) {
      filterStatuses = CHANGE_STATUSES_REJECTED;
    }
  }

  return (
    <div className="flex items-center w-full justify-center pb-28 pt-12 overflow-hidden">
      <ol className="flex items-center mx-auto w-full px-8">
        {filterStatuses?.map((value, index) => (
          <li
            key={value}
            className={cn(
              'flex  items-center',
              index !== filterStatuses?.length - 1 &&
                "after:content-[''] after:w-full after:h-1 after:border-b after:border-2 after:inline-block ",
              filterStatuses?.length !== 1 && index !== filterStatuses?.length - 1 ? ' w-screen' : 'w-full',
              (status > value && !isMobile) ||
                (value === EventStatus.Approved && isDraft && index !== filterStatuses?.length - 1)
                ? 'after:content-[""] after:w-full after:h-1 after:bg-brand-medium after:border-0 after:inline-block '
                : 'after:content-[""] after:w-full after:h-1 after:bg-zinc-200 after:border-0 after:inline-block '
            )}
          >
            <span
              className={cn(
                'flex relative items-center justify-center   bg-brand-light rounded-full h-8 w-8  shrink-0 border',
                status === value
                  ? 'bg-brand text-white'
                  : status < value
                  ? value === EventStatus.Approved && isDraft && index !== filterStatuses?.length - 1
                    ? 'bg-brand text-white opacity-40'
                    : 'bg-zinc-100 text-gray-400 opacity-40'
                  : 'bg-brand text-white opacity-50'
              )}
            >
              <div>
                {isDraft && value === EventStatus.Pending && filterStatuses[index - 1] === EventStatus.Approved ? (
                  <PenLine className="w-4 h-4" />
                ) : (
                  icons?.[value as keyof typeof icons]
                )}
              </div>
              <span
                className="top-14 text-foreground
            left-1/2
            -translate-x-1/2
  
           w-32 absolute text-center text-sm flex flex-col  items-center gap-1 font-semibold"
              >
                <Popover>
                  <PopoverTrigger>
                    <InfoCircle className="w-4 h-4" />
                  </PopoverTrigger>
                  <PopoverContent className="w-fit text-sm">
                    <p>
                      <Label
                        name={titles?.[value as keyof typeof titles] + '_status_tooltip_text'}
                        groupName={labelGroup}
                      />
                    </p>
                  </PopoverContent>
                </Popover>
                {isDraft && value === EventStatus.Pending && filterStatuses[index - 1] === EventStatus.Approved ? (
                  <Label name="status_change" groupName={labelGroup} />
                ) : (
                  <Label name={titles?.[value as keyof typeof titles]} groupName={labelGroup} />
                )}
              </span>
            </span>
          </li>
        ))}
      </ol>
    </div>
  );
}
