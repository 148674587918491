import { TermsOfAgreement } from '@/components/terms-of-agreement';
import { CardDescription } from '@/components/ui/card';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';

interface DownloadProgramTermsProps {
  onAcceptTerms: () => void;
}

export function DownloadProgramTerms({ onAcceptTerms }: DownloadProgramTermsProps) {
  return (
    <div className="h-auto space-y-4 terms-section">
      <CardDescription className="text-left w-full lg:w-1/2  ">
        <Label name="accept_download_program_terms_body" groupName={LabelGroup.ORGANIZER_DOWNLOAD_PROGRAM} />
      </CardDescription>
      <div className="w-full lg:w-1/2  ">
        <TermsOfAgreement
          accepted={false}
          onAccept={() => onAcceptTerms()}
          LabelComponent={
            <Label name="accept_download_program_terms_label" groupName={LabelGroup.ORGANIZER_DOWNLOAD_PROGRAM} />
          }
          BodyComponent={
            <div className="[&>button]:max-w-full text-sm break-words">
              <Label name="downlod_program_terms_body" groupName={LabelGroup.ORGANIZER_DOWNLOAD_PROGRAM} />
            </div>
          }
        />
      </div>
    </div>
  );
}
