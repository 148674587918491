import { Summary } from '../components/(dashboard)/summary';
import { MyEvents } from '../components/(dashboard)/my-events';

export function Dashboard() {
  return (
    <div className='flex-col flex w-full gap-4'>
      <div className="flex gap-4 h-fit w-fit">
        <Summary />
        <MyEvents />
      </div>
      <div>
      </div>
    </div>
  );
}
