import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { EventPerson } from '../types';

const QUERY_KEY = 'event_persons';

export const getEventPersons = (): Promise<EventPerson[]> => {
  return axios.get(`/event/persons`);
};

type QueryFnType = typeof getEventPersons;

type UseOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useEventPersons = ({ config }: UseOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: [QUERY_KEY],
    queryFn: () => getEventPersons(),
    ...config,
  });
};
