import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { TimelineConnector, TimelineHeader, TimelineItem, TimelineTime, TimelineTitle } from '@/components/ui/timeline';
import { EventLog, LogManagerAssigned } from '@/features/event/types';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { convertUTCtoLocal } from '@/utils/convertUtcToLocal';
import { formateTime } from '@/utils/formatTime';
import { ClockIcon, UserPlus } from 'lucide-react';
import { convertPascalToCamel } from '@/utils/convert-pascal-to-camel-case';
import { cn } from '@/utils';
import { UserType } from '@/features/user';

export function ManagerAssignedTimelineItem({ log }: { log: EventLog }) {
  const { logText, inserted, user } = log;

  const data = convertPascalToCamel<LogManagerAssigned>(JSON.parse(logText ?? '{}'));
  const createdAtTime = formateTime(convertUTCtoLocal(inserted));

  return (
    <TimelineItem>
      <TimelineConnector className="bg-gray-medium/20" />
      <TimelineHeader>
        <UserPlus className={'-translate-x-2 flex items-center rounded-md gap-x-2 bg-sky-500 w-8 h-8 p-2 text-white'} />
        <TimelineTitle>
          <div className="flex gap-x-2 items-center">
            <Avatar className="h-8 w-8 text-sm">
              <AvatarFallback
                className={cn(
                  'font-semibold bg-gray-medium text-white',
                  user?.userType=== UserType.ORGANIZER ? 'bg-brand-medium text-brand': 'bg-brand'
                )}
              >
                {data?.username?.substring(0, 2)?.toUpperCase()}
              </AvatarFallback>
            </Avatar>
            {data?.username} <Label name="assigned_as_manager" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
          </div>
        </TimelineTitle>
        <TimelineTime className="translate-x-24 md:-translate-x-20 flex items-center gap-x-2">
          <ClockIcon className="w-4 h-4" />
          {createdAtTime}
        </TimelineTime>
      </TimelineHeader>
    </TimelineItem>
  );
}
