import { Button } from '@/components/ui/button';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { queryClient } from '@/lib/react-query';
import { useAssignManagersToAllOrganizationEvents } from '../api/assign-managers-to-all-organization-events';
import { useManagers } from '../api/getManagers';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { useState } from 'react';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { CheckIcon, UserPlus2 } from 'lucide-react';
import { useToast } from '@/components/ui/use-toast';
import { label } from '@/utils/label';

interface AssignManagerToAllOrganizationEventsDialogProps {
  organizerId: number;
}

export function AssignManagerToAllOrganizationEventsDialog({
  organizerId,
}: AssignManagerToAllOrganizationEventsDialogProps) {
  const [open, setOpen] = useState(false);
  const [selectedManager, setSelectedManager] = useState<number | null>(null);

  const { toast } = useToast();

  const { mutateAsync: updateManager } = useAssignManagersToAllOrganizationEvents({
    config: {
      onSuccess: () => {
        queryClient.invalidateQueries('events');
        queryClient.refetchQueries('events');

        toast({ variant: 'success', title: label('manager_assigned', LabelGroup.MANAGER_EVENT_HANDLING) });
        setOpen(false);
      },
    },
  });

  const { data: managers } = useManagers({});

  return (
    <Dialog open={open} onOpenChange={() => setOpen((prev) => !prev)}>
      <DialogTrigger asChild>
        <Button variant="outline">
          <UserPlus2 className="h-4 w-4 mr-2" />
          <Label
            name="assign_manager_to_organization_events_button_text"
            groupName={LabelGroup.MANAGER_EVENT_HANDLING}
          />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            <Label name="assign_manager_to_organization_events" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
          </DialogTitle>
          <DialogDescription>
            <Label
              name="assing_manager_to_organization_events_description"
              groupName={LabelGroup.MANAGER_EVENT_HANDLING}
            />
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-2">
          {managers?.map((manager) => (
            <Button
              key={manager.managerId}
              variant="outline"
              className="w-full flex justify-between"
              onClick={() => setSelectedManager(manager.managerId === selectedManager ? null : manager.managerId)}
            >
              <div className="flex items-center">
                <Avatar className="h-6 w-6 mr-2">
                  <AvatarImage />
                  <AvatarFallback className="bg-brand text-white">
                    {manager?.username?.charAt(0)?.toUpperCase()}
                  </AvatarFallback>
                </Avatar>
                {manager.username}
              </div>
              {selectedManager === manager.managerId && <CheckIcon className="w-4 h-4 text-emerald-500" />}
            </Button>
          ))}
        </div>
        <DialogFooter className="flex justify-end gap-x-2">
          <Button type="button" variant="outline" onClick={() => setOpen(false)}>
            <Label name="cancel" groupName={LabelGroup.GLOBAL} />
          </Button>
          <Button
            onClick={() =>
              selectedManager &&
              updateManager({
                data: {
                  organizerId,
                  managerId: selectedManager,
                },
              })
            }
          >
            <Label name="update" groupName={LabelGroup.GLOBAL} />
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
