import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { useAllInvoices } from '../api/get-all-invoices';
import { exportInvoiceFile } from '../api/export-invoice-file';
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { formatDate } from '@/utils/formatDate';

export function ExportInvoiceFile() {
  const { data: invoices = [] } = useAllInvoices({});

  const total = invoices?.reduce((acc, invoice) => (acc += invoice.amount), 0);

  return (
    <Card className="max-w-4xl">
      <CardHeader>
        <CardTitle>
          <Label name="export_invoice_file_title" groupName={LabelGroup.ADMIN_INVOICE} />
        </CardTitle>
        <CardDescription>
          <Label name="export_invoice_file_description" groupName={LabelGroup.ADMIN_INVOICE} />
        </CardDescription>
      </CardHeader>
      <CardContent>
        {invoices?.length > 0 ? (
          <Table>
            <TableCaption>
              <Label name="export_invoice_file_instructions" groupName={LabelGroup.ADMIN_INVOICE} />
            </TableCaption>
            <TableHeader>
              <TableRow>
                <TableHead>
                  <Label name="payment_id" groupName={LabelGroup.ADMIN_INVOICE} />{' '}
                </TableHead>
                <TableHead>
                  <Label name="pay_date" groupName={LabelGroup.ADMIN_INVOICE} />
                </TableHead>
                <TableHead>
                  <Label name="payment_description" groupName={LabelGroup.ADMIN_INVOICE} />
                </TableHead>
                <TableHead className="text-right">
                  <Label name="amount" groupName={LabelGroup.ADMIN_INVOICE} />
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {invoices?.map((invoice) => (
                <TableRow key={invoice.paymentId}>
                  <TableCell className="font-medium">{invoice.paymentId}</TableCell>
                  <TableCell>{formatDate(invoice.payDate?.toString())}</TableCell>
                  <TableCell>{invoice.payDescription}</TableCell>
                  <TableCell className="text-right">{invoice.amount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={3}>
                  <Label name="total" groupName={LabelGroup.ADMIN_INVOICE} />
                </TableCell>
                <TableCell className="text-right font-bold">{total}</TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        ) : (
          <span className="text-sm text-muted-foreground">
            <Label name="nothing_new_to_export" groupName={LabelGroup.ADMIN_INVOICE} />
          </span>
        )}
      </CardContent>

      <CardFooter>
        <Button disabled={invoices.length === 0} onClick={() => exportInvoiceFile()}>
          <Label name="export_button_text" groupName={LabelGroup.ADMIN_INVOICE} />
        </Button>
      </CardFooter>
    </Card>
  );
}
