import { axios } from '@/lib/axios';

export type ForgetPasswordDTO = {
  username: string;
};

export const forgotPassword = async (data: ForgetPasswordDTO) => {
  try {
    return axios.post(`/forgot-password/${data.username}`);
  } catch (error) {
    throw error;
  }
};
