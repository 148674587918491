import { axios } from '@/lib/axios';
import fileSaver from 'file-saver';

type FileType = 'csv' | 'xlsx' | 'xml' | 'json';

export const exportFile = async (type?: FileType): Promise<void> => {
  return axios
    .get(`/export/${type}`, {
      responseType: type === 'xlsx' ? 'arraybuffer' : 'blob',
      headers: (type === 'xml' && { 'Content-Type': 'application/xml' }) || {},
    })
    .then((response: any) => {
      const mimeType = (() => {
        switch (type) {
          case 'xlsx':
            return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
          case 'csv':
            return 'text/csv;charset=utf-8;';
          case 'xml': {
            return 'text/xml;charset=utf-8;';
          }
          case 'json':
            return 'application/json;charset=utf-8;';
          default:
            return 'text/plain;charset=utf-8;';
        }
      })();

      const blob = new Blob([response], { type: mimeType });

      const fileEnding = (() => {
        switch (type) {
          case 'xlsx':
            return 'xlsx';
          case 'csv':
            return 'txt';
          case 'xml':
            return 'xml';
          case 'json':
            return 'json';
          default:
            return 'txt';
        }
      })();

      fileSaver.saveAs(blob, `program.${fileEnding}`);
    });
};
