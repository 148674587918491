const storagePrefix = 'almedalen_app';

const storage = {
  hasAcceptedAccountTerms: () => {
    return !!window.localStorage.getItem(`${storagePrefix}create-account-terms-accepted`);
  },
  setAcceptedAccountTerms: () => {
    window.localStorage.setItem(`${storagePrefix}create-account-terms-accepted`, 'true');
  },
  hasAcceptedOrganizationTerms: () => {
    return !!window.localStorage.getItem(`${storagePrefix}create-organization-terms-accepted`);
  },
  setAcceptedOrganizationTerms: () => {
    window.localStorage.setItem(`${storagePrefix}create-organization-terms-accepted`, 'true');
  },
  getAccessToken: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}token`) as string);
  },
  setAccessToken: (token: string) => {
    window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  clearAccessToken: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
  },
  setRefreshToken: (token: string) => {
    window.localStorage.setItem(`${storagePrefix}refreshToken`, JSON.stringify(token));
  },
  getRefreshToken: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}refreshToken`) as string);
  },
  clearRefreshToken: () => {
    window.localStorage.removeItem(`${storagePrefix}refreshToken`);
  },

  getLastestUrl: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}lastestUrl`) as string);
  },
  setRedirectUrl: (url: string) => {
    window.localStorage.setItem(`${storagePrefix}redirect-url`, JSON.stringify(url));
  },
  clearRedirectUrl: () => {
    window.localStorage.removeItem(`${storagePrefix}redirect-url`);
  },
  clearUser: () => {
    window.localStorage.removeItem(`${storagePrefix}user`);
  },
  getRedirectUrl: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}redirect-url`) as string);
  },
  getClientId: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}clientId`) as string);
  },
};

export default storage;
