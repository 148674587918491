import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { OrderRow } from '../types';

const QUERY_KEY = 'order-rows';

export const getOrderRows = (organizerId: number): Promise<OrderRow[]> => {
  return axios.get(`/payment/order-rows/${organizerId}`);
};

type QueryFnType = typeof getOrderRows;

type UseOptions = {
  organizerId: number;
  config?: QueryConfig<QueryFnType>;
};

export const useOrderRows = ({ config, organizerId }: UseOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: [QUERY_KEY, organizerId],
    queryFn: () => getOrderRows(organizerId),
    ...config,
  });
};



