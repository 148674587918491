import { useParams } from 'react-router-dom';
import { useEvent } from '../api/getEvent';
import { useOrganization } from '@/features/organizer/api/getOrganization';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { Telephone } from 'react-bootstrap-icons';

export function OrganizerDetails() {
  const { id } = useParams();

  const { data: event } = useEvent({
    eventId: Number(id),
    config: {
      enabled: false,
    },
  });

  const { data: organizer } = useOrganization({
    organizerId: Number(event?.organizerId),
    config: {
      
      enabled: !!event?.organizerId,
    },
  });

  return (
    <Card className="w-fit">
      <CardHeader>
        <CardTitle>
          {/* <Label name="organizer_details" groupName={LabelGroup.MANAGER_EVENT_HANDLING} /> */}
          {organizer?.organizerName}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <span className='text-sm text-muted-foreground'>
            <Label name="organizer_users" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
          </span>
          <div className="divide-input divide-y border w-fit  rounded-md text-sm">
            {organizer?.users?.map(({ user, mainContact }) => (
              <div className="flex items-center gap-2 p-4">
                <Avatar className="h-8 w-8 ">
                  <AvatarFallback className="font-semibold bg-gray-medium text-white">
                    {user?.username?.substring(0, 2)?.toUpperCase() ?? 'U'}
                  </AvatarFallback>
                </Avatar>
                {user?.username} •
                <div className="flex gap-1 items-center">
                  <Telephone className="w-3 h-3" />
                  {user?.telephone}
                </div>
                {mainContact && (
                  <>
                    • <Label name="main_contact" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
