import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useToast } from '@/components/ui/use-toast';
import { label } from '@/utils/label';
import { User, UserType } from '../types';
import { LabelGroup } from '@/features/label/types';

const QUERY_KEY = 'users';
const API_PATH = '/user';

export type UpdateUserDTO = {
  userId: number;
  username: string;
  password?: string;
  name?: string;
  telephone?: string;
  role?: UserType;
  locked?: boolean;
  failedLoginAttempts?: number;
  emailVerified?: boolean;
  updated?: string;
  inserted?: string;
};

export const updateUser = (data: UpdateUserDTO): Promise<User> => {
  return axios.put(API_PATH, data);
};

type UseUpdateUserOptions = {
  userId: number;
  config?: MutationConfig<typeof updateUser>;
};

export const useUpdateUser = ({ config, userId }: UseUpdateUserOptions) => {
  const { toast } = useToast();

  return useMutation({
    onMutate: async (next: any) => {
      await queryClient.cancelQueries([QUERY_KEY]);

      const prev = queryClient.getQueryData<User[]>([QUERY_KEY]);

      queryClient.setQueryData([QUERY_KEY], [...(prev || []), next.data]);

      return { prev };
    },
    onError: (_, __, context: any) => {
      if (context?.prev) {
        queryClient.setQueryData([QUERY_KEY, userId], context.prev);
      }
      toast({
        variant: 'destructive',
        title: label('something_went_wrong', LabelGroup.GLOBAL),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEY]);

      queryClient.invalidateQueries([QUERY_KEY, userId]);
      queryClient.refetchQueries([QUERY_KEY, userId]);

      toast({
        variant: 'success',
        title: label('user_updated_snackbar_text', LabelGroup.ADMIN_USER_EDIT),
      });
    },
    ...config,
    mutationFn: updateUser,
  });
};
