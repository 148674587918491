import { FormDescription, FormLabel } from '@/components/ui/form';
import { EventOrganizer } from '../types';
import { Button } from '@/components/ui/button';
import { Plus } from 'lucide-react';
import { UserType } from '@/features/user';
import { LabelGroup } from '@/features/label/types';
import { Label } from '@/features/label/components/label';
import { OrganizerSuggestionInput } from './organizer-suggestion-input';
import { useMe } from '@/features/auth/api/get-me';
import { Asterisk } from '@/components/ui/asterisk';

interface OrganizerInputProps {
  form: any;
  field: any;
}

const DEFAULT_ORGANIZER = {
  eventOrganizerId: -1,
  organizerName: '',
};

export function EventOrganizerInput({ field, form }: OrganizerInputProps) {
  const eventId = form.getValues('eventId');

  const organizers = (field?.value?.length === 0 ? [{ ...DEFAULT_ORGANIZER, eventId }] : field.value) ?? [];

  const handleAddEventOrganizer = () => {
    const newEventOrganizerIds =
      form
        .getValues('organizers')
        .filter((eventOrganizer: EventOrganizer) => eventOrganizer.eventOrganizerId < 0)
        .map((eventOrganizer: EventOrganizer) => eventOrganizer.eventOrganizerId) || [];

    const newEventOrganizerId = newEventOrganizerIds.length > 0 ? (Math.min(...newEventOrganizerIds) ?? 0) - 1 : -2;

    field.onChange([...(organizers ?? []), { ...DEFAULT_ORGANIZER, eventOrganizerId: newEventOrganizerId, eventId }]);
  };

  const handleDeleteOrganizer = (eventOrganizerId: number) => {
    field.onChange(organizers?.filter((organizer: any) => organizer.eventOrganizerId !== eventOrganizerId));
  };

  const handleUpdateOrganizer = (eventOrganizerId: number, value: string) => {
    const valueCopy = [...organizers];
    const index = organizers?.findIndex((organizer: any) => organizer.eventOrganizerId === eventOrganizerId);

    if (index === -1) return console.error('EventOrganizerId not found');

    valueCopy[index].organizerName = value;
    field.onChange(valueCopy);
  };

  const { data: user } = useMe({});
  const userType = user?.role;

  const labelGroup =
    userType === UserType.ADMIN || userType === UserType.MANAGER
      ? LabelGroup.MANAGER_EVENT_HANDLING
      : LabelGroup.ORGANIZER_EVENT_FORM;

  return (
    <div className="space-y-2" id="event_organizer">
      <FormLabel className='font-semibold'>
        <Label name="organizer_name_title" groupName={labelGroup} />
        <Asterisk className="ml-2" />
      </FormLabel>
      <FormDescription>
        <Label name="organizer_name_description" groupName={labelGroup} />
      </FormDescription>
      <div className="space-y-4">
        {organizers?.map((organizer: any) => (
          <OrganizerSuggestionInput
            key={organizer.eventOrganizerId}
            organizer={organizer}
            onUpdate={handleUpdateOrganizer}
            enableDelete={organizers.length > 1}
            onDelete={() => handleDeleteOrganizer(organizer.eventOrganizerId)}
          />
        ))}
        <Button type="button" variant="outline" onClick={handleAddEventOrganizer}>
          <Plus className="w-4 h-4 mr-2" />
          <Label name="add_organizer_button_text" groupName={labelGroup} />
        </Button>
      </div>
    </div>
  );
}
