import { useParams } from 'react-router-dom';
import { useOrganization } from '@/features/organizer/api/getOrganization';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { DataTable } from '@/components/ui/data-table';
import { columns } from '../components/event-columns';
import { ManagerEvent } from '../types';
import { Row } from '@tanstack/react-table';
import { getKeyByValue } from '@/utils/get-key-by-value';
import { EventStatus } from '@/features/event/types';
import { label } from '@/utils/label';
import { EventFilters } from '../components/(filters)/event-filters';
import { DataTableSkeleton } from '@/components/data-table-skeleton';
import { useEvents } from '../api/getEvents';
import { usePayments } from '@/features/payment/api/get-payments';
import { OrderRow, PayType } from '@/features/payment/types';
import { paymentColumns } from '@/features/payment/components/payment-columns';
import { EditOrganization } from '@/features/organizer/routes/edit-organization';
import { AssignManagerToAllOrganizationEventsDialog } from '../components/assign-manager-to-all-organization-events-dialog';

export function OrganizerDetails() {
  const { id } = useParams();

  const { data: organizer } = useOrganization({
    organizerId: Number(id),
    config: {
      enabled: !!id
    }
  });

  const { data: events = [], isLoading } = useEvents({
    assignedToMe: false,
    config: {
      enabled: !!id
    }
  });

  const { data: payments = [] } = usePayments({
    organizerId: Number(id),
    config: {
      enabled: !!id
    }
  });

  const filteredData = events?.filter(event => event.organizerId === Number(id));

  return (
    <div className="space-y-6 flex flex-col h-full">
      <div className="flex w-full items-center justify-between">
        <div>
          <div className="flex gap-x-1 items-center">
            <h3 className="text-xl font-medium">{organizer?.organizerName}</h3>
          </div>
        </div>
      </div>
      <div className="space-y-2">
        <Tabs defaultValue="events" className="w-full">
          <TabsList>
            <TabsTrigger value="events">
              <Label name="organizer_events_tab_text" groupName={LabelGroup.MANAGER_EVENT_LIST} />
            </TabsTrigger>
            <TabsTrigger value="details">
              <Label name="organizer_details_tab_text" groupName={LabelGroup.MANAGER_EVENT_LIST} />
            </TabsTrigger>
            <TabsTrigger value="payments">
              <Label name="organizer_payments_tab_text" groupName={LabelGroup.MANAGER_EVENT_LIST} />
            </TabsTrigger>
          </TabsList>
          <TabsContent value="details">
            <div className="max-w-3xl pb-8">
              <EditOrganization />
            </div>
          </TabsContent>
          <TabsContent value="events">
            {!isLoading ? (
              <DataTable
                columns={columns}
                data={filteredData}
                columnVisibility={{
                  category: false,
                  organizerContactEmail: false
                }}
                config={{
                  maxHeight: 'calc(100lvh - 290px)',
                  getSearchFields: row => [
                    (row as Row<ManagerEvent>).original.eventId.toString(),
                    (row as Row<ManagerEvent>).original.title,
                    (row as Row<ManagerEvent>).original.organizerName,
                    (row as Row<ManagerEvent>)?.original.organizerContactEmail ?? '',
                    label(getKeyByValue(EventStatus, (row as Row<ManagerEvent>).original.status as EventStatus) ?? '', LabelGroup.MANAGER_EVENT_STATUSES)
                  ]
                }}
                Actions={() => <AssignManagerToAllOrganizationEventsDialog organizerId={Number(id)} />}
                FacetedFilters={EventFilters}
              />
            ) : (
              <DataTableSkeleton />
            )}
          </TabsContent>
          <TabsContent value="payments">
            {!isLoading ? (
              <DataTable
                columns={paymentColumns}
                data={payments}
                columnVisibility={{
                  organizerName: false
                }}
                config={{
                  maxHeight: 'calc(100lvh - 290x)',
                  getSearchFields: row => [
                    (row as Row<OrderRow>).original?.name.toString(),
                    (row as Row<OrderRow>).original.payment?.payDate.toString(),
                    (row as Row<OrderRow>).original.payment?.paymentId.toString(),
                    label(getKeyByValue(PayType, (row as Row<OrderRow>).original.payment?.payType) ?? '', LabelGroup.ADMIN_PAYMENT),
                    (row as Row<OrderRow>).original?.amount.toString()
                  ]
                }}
              />
            ) : (
              <DataTableSkeleton />
            )}
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
}
