import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useUpdateLabel } from '../api/updateLabel';
import { useParams } from 'react-router-dom';
import { useLabel } from '../api/getLabel';
import { Card, CardContent } from '@/components/ui/card';
import { Label } from '../components/label';
import { LabelGroup } from '../types';
import TextEditor from '@/components/tinyMCE/TextEditor';
import { labelGroups } from '../data/label-groups';

const formSchema = z.object({
  labelId: z.number(),
  languageCode: z.string(),
  groupName: z.string(),
  labelName: z.string(),
  value: z.string(),
});

export function EditLabel() {
  const { id } = useParams();
  const { mutateAsync: updateLabel } = useUpdateLabel({});

  const data = useLabel({
    labelId: Number(id),
    config: {
      enabled: !!id,
    },
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    values: {
      labelId: data?.labelId || 0,
      languageCode: data?.languageCode || '',
      groupName: data?.labelGroup || '',
      labelName: data?.labelName || '',
      value: data?.value || '',
    },
  });

  async function handleSubmit(values: z.infer<typeof formSchema>) {
    await updateLabel({ ...values } as any);
  }

  return (
    <div className="space-y-6 flex flex-col ">
      <div className="space-y-2">
        <Card className="max-w-5xl">
          <CardContent className="p-6">
            <Form {...form}>
                <form onKeyDown={(event) => event.key === 'Enter' && event.preventDefault()} onSubmit={form.handleSubmit(handleSubmit)} className="space-y-8">
                <FormField
                  control={form.control}
                  name="labelName"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormLabel>
                        <Label name="label_name" groupName={LabelGroup.ADMIN_LABEL} />
                      </FormLabel>
                      <FormControl>
                        <Input disabled {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="groupName"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormLabel>
                        <Label name="label_group_name" groupName={LabelGroup.ADMIN_LABEL} />
                      </FormLabel>
                      <FormControl>
                        <Input {...field} disabled value={labelGroups.find((group) => group.group === field.value)?.name} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="languageCode"
                  render={({ field }) => (
                    <FormItem className="w-[200px]">
                      <FormLabel>
                        <Label name="label_language_code" groupName={LabelGroup.ADMIN_LABEL} />
                      </FormLabel>
                      <Select disabled onValueChange={field.onChange} defaultValue={field.value}>
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Select a verified email to display" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {[
                            {
                              code: 'swe',
                              name: 'Swedish',
                            },
                            {
                              code: 'eng',
                              name: 'English',
                            },
                          ].map((language) => (
                            <SelectItem key={language.code} value={language.code}>
                              {language.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="value"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormLabel>
                        <Label name="label_value" groupName={LabelGroup.ADMIN_LABEL} />
                      </FormLabel>
                      <FormControl>
                        <div className="border border-input rounded-md min-h-96 p-2">
                          <TextEditor {...field} />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Button type="submit">
                  <Label name="update" groupName={LabelGroup.ADMIN_LABEL} />
                </Button>
              </form>
            </Form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
