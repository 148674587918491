import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { TEvent as Event } from '../types';

const QUERY_KEY = 'previous-events';

export const getEventsFromPreviousYears = (organizerId: number): Promise<Event[]> => {
  return axios.get(`/event/all/previous-years/${organizerId}`);
};

type QueryFnType = typeof getEventsFromPreviousYears;

type UseEventOption = {
  organizerId: number;
  config?: QueryConfig<QueryFnType>;
};

export const useEventsFromPreviousYears = ({ config, organizerId }: UseEventOption) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: [QUERY_KEY, organizerId],
    queryFn: () => getEventsFromPreviousYears(organizerId),
    ...config,
  });
};
