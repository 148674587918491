import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { useSettings } from '@/features/settings/api/getSettings';
import { useEffect, useState } from 'react';
import { useLocations } from '@/features/location/api/getLocations';
import { LocationCategory } from '@/features/location/types';
import { EventCategory } from '../../types';
import { Checkbox } from '@/components/ui/checkbox';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
// import { Separator } from '@/components/ui/separator';
// import { GoogleMap } from '../(map)/google-map';
import { UserType } from '@/features/user';
import { LabelGroup } from '@/features/label/types';
import { Label } from '@/features/label/components/label';
import { Combobox } from '@/components/ui/combobox';
import { Asterisk } from '@/components/ui/asterisk';
import { useMe } from '@/features/auth/api/get-me';
import { Separator } from '@/components/ui/separator';
import { GoogleMap } from '../(map)/google-map';
import { Textarea } from '@/components/ui/textarea';

interface LocationSectionProps {
  form: any;
  defaultCollapsed?: boolean;
}

export function LocationSection({ form, defaultCollapsed = false }: LocationSectionProps) {
  const [locationType, setLocationType] = useState<string>('');
  // const [streetAddress, setStreetAddress] = useState<string>('');

  const { data: settings = {} } = useSettings({});
  const { data: locations = [] } = useLocations({ active: true });

  const placeTypes = settings?.placeType?.filter(({ value }) => (form.watch('category') === EventCategory.TRAFFPUNKT && value === LocationCategory.TALARPLATS ? false : true));

  // const streetAddresses = settings.streetAddress ?? [];

  const groupedLocations = [...locations.sort((a, b) => a.name.localeCompare(b.name)).filter(location => (locationType === LocationCategory.LOKALER_TRADGARDAR_FARTYG ? location?.category === '' : location?.category === locationType))];

  const selectedLocation = locations.find(location => location?.locationId?.toString() === form.watch('locationId')?.toString());

  // const filterStreetAddresses = ({ name, category }: TLocation) => {
  //   if (category !== locationType) return false;

  //   const streetNames = streetAddress.split(',');

  //   const isMatching = streetNames?.some((streetName) => {
  //     const targetStreetName = name?.replace(/\d/g, '');
  //     const sourceStreetName = streetName?.replace(/\d/g, '');

  //     console.log(targetStreetName, sourceStreetName);
  //     return targetStreetName?.toLowerCase().includes(sourceStreetName?.toLowerCase());
  //   });
  // };

  useEffect(() => {
    if (form.watch('locationId') && !locationType) {
      const location = locations.find(({ locationId }) => locationId === form.watch('locationId'));
      const category = location?.category === '' || location?.category === 'Custom' ? LocationCategory.LOKALER_TRADGARDAR_FARTYG : location?.category;
      setLocationType(category ?? '');
    }
  }, [form, locations]);

  useEffect(() => {
    if (form.watch('category') && locationType && form.watch('category') === EventCategory.TRAFFPUNKT && locationType === LocationCategory.TALARPLATS) {
      form.setValue('locationId', null, { shouldDirty: true });
      setLocationType(placeTypes[0]?.value ?? '');
    }
  }, [form, locationType, placeTypes]);

  const { data: user } = useMe({});
  const userType = user?.role;

  const labelGroup = userType === UserType.ADMIN || userType === UserType.MANAGER ? LabelGroup.MANAGER_EVENT_HANDLING : LabelGroup.ORGANIZER_EVENT_FORM;

  const currentLocation = locations.find(location => location?.locationId?.toString() === form.watch('locationId')?.toString());

  return (
    <div className="space-y-6">
      <Card>
        <Accordion type="single" collapsible defaultValue={defaultCollapsed ? '' : 'location'}>
          <AccordionItem className="[&[data-state=open]>.card-header]:bg-gray-medium [&[data-state=open]>.card-header]:text-white [&[data-state=open]>.card-header]:rounded-t-md" value="location">
            <CardHeader className="py-3">
              <AccordionTrigger className="text-md py-0">
                <CardTitle id="event_location">
                  <Label name="event_location_title" groupName={labelGroup} />
                  <Asterisk className="ml-2 asterisk" />
                </CardTitle>
              </AccordionTrigger>
            </CardHeader>

            <AccordionContent>
              <CardContent className="text-sm space-y-6 pt-6">
                <CardDescription className="font-semibold">
                  <Label name="event_location_description_title" groupName={labelGroup} />
                  <Asterisk className="ml-2" />
                </CardDescription>
                <div className="space-y-6">
                  <div className="space-y-6">
                    <FormItem className="flex items-start flex-col md:flex-row  md:items-center gap-4">
                      <FormLabel className="w-48 font-semibold">
                        <Label name="location_type" groupName={labelGroup} />
                      </FormLabel>
                      <Select onValueChange={setLocationType} value={locationType}>
                        <SelectTrigger className="mt-[0_!important] w-full max-w-96 text-left">
                          <SelectValue placeholder={<Label name="select_location_type_placeholder" groupName={labelGroup} />} />
                        </SelectTrigger>
                        <SelectContent>
                          {placeTypes
                            ?.sort((a, b) => a.value.localeCompare(b.value))
                            ?.map(({ value }) => (
                              <SelectItem key={value} value={value}>
                                {value}
                              </SelectItem>
                            ))}
                        </SelectContent>
                      </Select>
                    </FormItem>

                    <FormField
                      control={form.control}
                      name="locationId"
                      render={({ field }) => (
                        <>
                          {locationType === LocationCategory.MARKPLATSER && (
                            <>
                              {/* <FormItem className="flex items-start flex-col md:flex-row  md:items-center gap-4">
                                <FormLabel className="w-48 font-semibold">
                                  <Label name="select_location_category" groupName={labelGroup} />
                                </FormLabel>
                                <Select onValueChange={(value) => setStreetAddress(value)} value={streetAddress}>
                                  <SelectTrigger className="mt-[0_!important] w-full max-w-96">
                                    <SelectValue />
                                  </SelectTrigger>
                                  <SelectContent className="w-full max-w-96">
                                    {streetAddresses
                                      ?.sort((a, b) => a.value.localeCompare(b.value))
                                      ?.map(({ value }) => (
                                        <SelectItem key={value} value={value}>
                                          {value}
                                        </SelectItem>
                                      ))}
                                  </SelectContent>
                                </Select>
                              </FormItem> */}
                              {/* {streetAddress && ( */}
                              <FormItem className="flex items-start flex-col md:flex-row  md:items-center gap-4">
                                <FormLabel className="w-48 font-semibold">
                                  <Label name="select_ground_location" groupName={labelGroup} />
                                </FormLabel>
                                <div className="w-full max-w-96 relative">
                                  <Combobox
                                    value={field.value}
                                    popoverContentClassName="w-full max-w-96 w-96"
                                    onChange={value => field.onChange(Number(value))}
                                    groups={groupedLocations?.reduce(
                                      (acc, location) => {
                                        if (acc[location.name?.replace(/\d/g, '')?.split(',')[0]]) {
                                          acc[location.name?.replace(/\d/g, '')?.split(',')[0]].push({
                                            value: location.locationId,
                                            label: location.name
                                          });
                                        } else {
                                          acc[location.name?.replace(/\d/g, '')?.split(',')[0]] = [
                                            {
                                              value: location.locationId,
                                              label: location.name
                                            }
                                          ];
                                        }

                                        return acc;
                                      },
                                      {} as Record<
                                        string,
                                        {
                                          value: number;
                                          label: string;
                                        }[]
                                      >
                                    )}
                                    PlaceholderComponent={<Label name="select_location_placeholder" groupName={labelGroup} />}
                                  />
                                </div>
                              </FormItem>
                            </>
                          )}
                          {locationType === LocationCategory.LOKALER_TRADGARDAR_FARTYG && locations.find(location => location?.locationId === field.value)?.category !== 'Custom' && (
                            <FormItem className="flex items-start flex-col md:flex-row  md:items-center gap-4">
                              <FormLabel className="w-48 font-semibold">
                                <Label name="select_garden_location" groupName={labelGroup} />
                              </FormLabel>
                              <div className="w-full max-w-96 relative">
                                <Combobox
                                  value={field.value}
                                  popoverContentClassName="w-full max-w-96 w-96"
                                  onChange={value => field.onChange(Number(value))}
                                  options={groupedLocations
                                    .filter(({ name }) => !!name)
                                    .map(({ name, locationId }) => ({
                                      label: name,
                                      value: locationId
                                    }))}
                                  PlaceholderComponent={<Label name="select_location_placeholder" groupName={labelGroup} />}
                                />
                              </div>
                            </FormItem>
                          )}
                          {locationType === LocationCategory.TALARPLATS && (
                            <FormItem className="flex items-start flex-col md:flex-row  md:items-center gap-4">
                              <FormLabel className="w-48 font-semibold">
                                <Label name="select_speaker_spot" groupName={labelGroup} />
                              </FormLabel>
                              <Select onValueChange={value => field.onChange(Number(value))} value={field?.value?.toString()}>
                                <SelectTrigger className="mt-[0_!important] [&>span]:text-left w-full max-w-96">
                                  <SelectValue placeholder={<Label name="select_speaker_spot_placeholder" groupName={labelGroup} />} />
                                </SelectTrigger>
                                <SelectContent>
                                  {groupedLocations?.map(({ locationId, name }) => (
                                    <SelectItem key={locationId} value={locationId.toString()}>
                                      {name}
                                    </SelectItem>
                                  ))}
                                </SelectContent>
                              </Select>
                            </FormItem>
                          )}
                        </>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="locationDescription"
                      render={({ field }) => (
                        <FormItem className="flex items-start flex-col md:flex-row  md:items-start gap-4">
                          <FormLabel className="w-48 mt-3 font-semibold">
                            <Label name="event_location_description" groupName={labelGroup} />
                          </FormLabel>
                          <FormControl className="w-full max-w-96">
                            <div className="space-y-2">
                              <Textarea {...field}  maxLength={255}/>
                              <FormDescription className="text-muted-foreground italic">
                                <Label name="event_location_description_caption" groupName={labelGroup} />
                              </FormDescription>
                              <FormDescription className="italic">
                                <Label name="you_have_letters_left" groupName={labelGroup} /> {255 - (field?.value?.length ?? 0)} <Label name="letters_left" groupName={labelGroup} />
                              </FormDescription>
                            </div>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <FormField
                    control={form.control}
                    name="locationId"
                    render={({ field }) => (
                      <FormItem className="flex items-center space-y-0 gap-x-4">
                        <FormLabel className="w-48 flex items-center font-semibold">
                          <Label name="location_is_confirmed_later" groupName={labelGroup} />
                        </FormLabel>
                        <FormControl>
                          <Checkbox
                            id="ConfirmLocationLater"
                            checked={field.value === -100}
                            onCheckedChange={checked => {
                              field.onChange(!checked ? null : -100);
                              setLocationType('');
                            }}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />

                  {form.watch('locationId') !== -100 && (
                    <div className="space-y-4">
                      <Separator />
                      <FormField
                        control={form.control}
                        name="locationId"
                        render={({ field }) => (
                          <div className="h-full  w-full">
                            <GoogleMap
                              customLocationDisabled={locationType !== 'Lokaler, trädgårdar & fartyg'}
                              onChange={locationId => field.onChange(Number(locationId))}
                              isCustomLocation={locations.find(location => location?.locationId === field.value)?.category === ''}
                              position={
                                selectedLocation
                                  ? {
                                      lat: Number(selectedLocation.latitude),
                                      lng: Number(selectedLocation.longitude)
                                    }
                                  : form.watch('locationId')
                                  ? {
                                      lat: currentLocation ? Number(currentLocation.latitude) : 0,
                                      lng: currentLocation ? Number(currentLocation.longitude) : 0
                                    }
                                  : null
                              }
                            />
                          </div>
                        )}
                      />
                    </div>
                  )}
                </div>
              </CardContent>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </Card>
    </div>
  );
}
