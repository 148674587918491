import { FormControl, FormDescription, FormField, FormItem, FormLabel } from '@/components/ui/form';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { label } from '@/utils/label';
import { Input } from '@/components/ui/input';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { UserType } from '@/features/user';
import { LabelGroup } from '@/features/label/types';
import { Label } from '@/features/label/components/label';
import { useFormContext } from 'react-hook-form';
import { useMe } from '@/features/auth/api/get-me';

export function WebLinksSections({ defaultCollapsed = false }: { defaultCollapsed?: boolean }) {
  const { data: user } = useMe({});
  const userType = user?.role;

  const form = useFormContext();

  const labelGroup = userType === UserType.ADMIN || userType === UserType.MANAGER ? LabelGroup.MANAGER_EVENT_HANDLING : LabelGroup.ORGANIZER_EVENT_FORM;

  return (
    <Card>
      <Accordion type="single" collapsible defaultValue={defaultCollapsed ? '' : 'contact'}>
        <AccordionItem className="[&[data-state=open]>.card-header]:bg-gray-medium [&[data-state=open]>.card-header]:text-white [&[data-state=open]>.card-header]:rounded-t-md" value="contact">
          <CardHeader className="py-3">
            <AccordionTrigger className="text-md py-0">
              <CardTitle>
                <Label name={'event_homepage_title'} groupName={labelGroup} />
              </CardTitle>
            </AccordionTrigger>
          </CardHeader>
          <AccordionContent>
            <CardContent className="pt-6 text-sm space-y-6">
              <CardDescription>
                <Label name={'event_homepage_description'} groupName={labelGroup} />
              </CardDescription>
              <div className="space-y-4">
                <FormField
                  control={form.control}
                  name="url1"
                  render={({ field }) => (
                    <FormItem className="w-full max-w-full">
                      <FormControl>
                        <Input {...field} className="w-full" placeholder="Ex: https://google.com" />
                      </FormControl>
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="url2"
                  render={({ field }) => (
                    <FormItem className="w-full max-w-full">
                      <FormControl>
                        <Input {...field} className="w-full" placeholder="Ex: https://google.com" />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="url3"
                  render={({ field }) => (
                    <FormItem className="w-full max-w-full">
                      <FormControl>
                        <Input {...field} className="w-full" placeholder="Ex: https://google.com" />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>
              <FormField
                control={form.control}
                name="facebookUrl"
                render={({ field }) => (
                  <FormItem className="w-full max-w-full">
                    <FormLabel className="font-semibold">
                      <Label name={'event_facebook_url'} groupName={labelGroup} />
                    </FormLabel>
                    <FormDescription>
                      <Label name={'event_facebook_url_description'} groupName={labelGroup} />
                    </FormDescription>
                    <FormControl>
                      <Input {...field} className="w-full" />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="xurl"
                render={({ field }) => (
                  <FormItem className="w-full max-w-full">
                    <FormLabel className="font-semibold">
                      <Label name={'event_xurl'} groupName={labelGroup} />
                    </FormLabel>
                    <FormDescription>
                      <Label name={'event_xurl_description'} groupName={labelGroup} />
                    </FormDescription>
                    <FormControl>
                      <Input {...field} placeholder={label('event_xurl_placeholder', labelGroup)} className="w-full" />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="linkedInUrl"
                render={({ field }) => (
                  <FormItem className="w-full max-w-full">
                    <FormLabel className="font-semibold">
                      <Label name={'event_linked_in'} groupName={labelGroup} />
                    </FormLabel>
                    <FormDescription>
                      <Label name={'event_linked_in_description'} groupName={labelGroup} />
                    </FormDescription>
                    <FormControl>
                      <Input {...field} className="w-full" />
                    </FormControl>
                  </FormItem>
                )}
              />
            </CardContent>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </Card>
  );
}
