import { Card, CardContent, CardFooter } from '@/components/ui/card';
import { Separator } from '@/components/ui/separator';
import { Fragment } from 'react/jsx-runtime';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { useOrderRows } from '../api/get-order-rows';
import { useOrganizerStore } from '@/stores/organizerStore';

export function PaymentPreConfirmation() {
  const organizerId = useOrganizerStore((state) => state.organizationId);

  const { data: orderRows, isLoading } = useOrderRows({
    organizerId: organizerId,
    config: {
      enabled: !!organizerId,
    },
  });

  if (isLoading) return <div>Loading...</div>;

  const total = orderRows?.reduce((acc, or) => (acc += or.amount + or.vatAmount), 0);
  const vatAmount = orderRows?.reduce((acc, or) => (acc += or.vatAmount), 0);

  return (
    <Card style={{ overflow: 'hidden' }}>
      <CardContent>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(6, 1fr)',
            color: 'var(--muted-foreground)',
            fontWeight: 600,
            textTransform: 'uppercase',
            fontSize: '0.75rem',
          }}
        >
          <div style={{ gridColumn: 'span 3', padding: '1rem 0' }}>
            <Label name="event" groupName={LabelGroup.ORGANIZER_PAYMENT_RECEIPT} />
          </div>
          <div style={{ gridColumn: 'span 1', padding: '1rem 0', textAlign: 'right' }}>
            <Label name="quantity" groupName={LabelGroup.ORGANIZER_PAYMENT_RECEIPT} />
          </div>
          <div style={{ gridColumn: 'span 1', padding: '1rem 0', textAlign: 'right' }}>
            <Label name="priceExVat" groupName={LabelGroup.ORGANIZER_PAYMENT_RECEIPT} />
          </div>
          <div style={{ gridColumn: 'span 1', padding: '1rem 0', textAlign: 'right' }}>
            <Label name="priceIncVat" groupName={LabelGroup.ORGANIZER_PAYMENT_RECEIPT} />
          </div>
        </div>
        <Separator />
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', fontWeight: 600, fontSize: '0.875rem' }}>
          {orderRows?.map((or) => (
            <Fragment key={or.orderRowId}>
              <div style={{ gridColumn: 'span 3', padding: '1rem 0' }}>{or.name}</div>
              <div style={{ gridColumn: 'span 1', padding: '1rem 0', textAlign: 'right' }}>1</div>
              <div style={{ gridColumn: 'span 1', padding: '1rem 0', textAlign: 'right' }}>{or.amount}</div>
              <div style={{ gridColumn: 'span 1', padding: '1rem 0', textAlign: 'right' }}>
                {or.amount + or.vatAmount}
              </div>
            </Fragment>
          ))}
        </div>
      </CardContent>
      <Separator />
      <CardFooter
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(6, 1fr)',
          color: 'var(--muted-foreground)',
          gap: '2rem',
          alignItems: 'flex-start',
        }}
      >
        <div style={{ textTransform: 'uppercase', fontWeight: 600, fontSize: '0.875rem', gridColumn: 'span 3' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '1rem 0' }}>
            <p style={{ color: 'var(--muted-foreground)', fontSize: '0.75rem' }}>
              <Label name="subtotal" groupName={LabelGroup.ORGANIZER_PAYMENT_RECEIPT} />
            </p>
            <p>{(total ?? 0) - (vatAmount ?? 0)} SEK</p>
          </div>
          <Separator />
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '1rem 0' }}>
            <p style={{ color: 'var(--muted-foreground)', fontSize: '0.75rem' }}>
              <Label name="vat" groupName={LabelGroup.ORGANIZER_PAYMENT_RECEIPT} />
            </p>
            <p>{vatAmount} SEK</p>
          </div>
          <Separator />
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1rem 0' }}>
            <p style={{ color: 'var(--muted-foreground)' }}>
              <Label name="total" groupName={LabelGroup.ORGANIZER_PAYMENT_RECEIPT} />
            </p>
            <p style={{ fontSize: '1.125rem', fontWeight: 700, color: 'var(--primary)' }}>{total} SEK</p>
          </div>
        </div>
      </CardFooter>
    </Card>
  );
}
