import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { User } from '@/features/user';
import { setUser } from '@sentry/browser';

const QUERY_KEY = 'me';

export const getWhoAmI = (): Promise<User> => {
  return axios.get('/whoami');
};

type QueryFnType = typeof getWhoAmI;

type UseUserOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useMe = ({ config }: UseUserOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: [QUERY_KEY],
    queryFn: () => getWhoAmI(),
    onSuccess: data => {
      if (data) {
        setUser({
          ...data
        });
      }
    },
    onError: _ => {
      // Optionally clear user context on error
      setUser(null); // Clear user context if the request fails
    },
    ...config
  });
};
