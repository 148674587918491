import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useToast } from '@/components/ui/use-toast';
import { label } from '@/utils/label';
import { User } from '../types';
import { LabelGroup } from '@/features/label/types';

const QUERY_KEY = 'users';
const API_PATH = '/user';

export type CreateUserDTO = {
  username: string;
  name?: string;
  telephone?: string;
  userRole?: number;
};

export const createUser = (data: CreateUserDTO): Promise<User> => {
  return axios.post(API_PATH, data);
};

type UseCreateUserOptions = {
  config?: MutationConfig<typeof createUser>;
};

export const useCreateUser = ({ config }: UseCreateUserOptions) => {
  const { toast } = useToast();

  return useMutation({
    onMutate: async (next: any) => {
      await queryClient.cancelQueries([QUERY_KEY]);

      const prev = queryClient.getQueryData<User[]>([QUERY_KEY]);

      queryClient.setQueryData([QUERY_KEY], [...(prev || []), next.data]);

      return { prev };
    },
    onError: (_, __, context: any) => {
      if (context?.previousUsers) {
        queryClient.setQueryData([QUERY_KEY], context.previousUsers);
      }
      toast({
        variant: 'destructive',
        title: label('something_went_wrong', LabelGroup.GLOBAL),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEY]);
      queryClient.refetchQueries([QUERY_KEY]);

      toast({
        variant: 'success',
        title: label('user_created_snackbar_text', LabelGroup.ADMIN_USER_CREATE),
      });
    },
    ...config,
    mutationFn: createUser,
  });
};
