import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { User } from '../types';

const QUERY_KEY = 'users';

export const getUser = (userId: number): Promise<User> => {
  return axios.get(`/user/${userId}`);
};

type QueryFnType = typeof getUser;

type UseUserOptions = {
  userId: number;
  config?: QueryConfig<QueryFnType>;
};

export const useUser = ({ config, userId }: UseUserOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: [QUERY_KEY, userId],
    queryFn: () => getUser(userId),
    ...config,
  });
};
