import { useOrganizerStore } from '@/stores/organizerStore';
import { useCreateTransaction } from '../api/create-transaction';
import { useEffect, useState } from 'react';
import { completeTransaction } from '../api/complete-payment';
import { NothingToPay } from '../components/nothing-to-pay';

export function CardPayment() {
  const [hasNothingToPay, setHasNothingToPay] = useState(false);
  const organizationId = useOrganizerStore(state => state.organizationId);

  const initScript = (url: string) => {
    const scriptSource = localStorage.getItem('payex-checkout-url') ? JSON.parse(localStorage.getItem('payex-checkout-url') as string) : url;

    if (document.getElementById('payex-checkout-script')) {
      return;
    }

    const script = document.createElement('script');
    script.id = 'payex-checkout-script';
    script.src = scriptSource;

    script.onload = () => {
      (window as any).payex.hostedView
        .checkout({
          container: {
            checkout: 'checkout-container'
          },
          culture: 'sv-Se',
          onOutOfViewRedirect: ({ redirectUrl }: any) => {
            if (!redirectUrl || redirectUrl === '') {
              alert('Something went wrong, please try again later.');
              return;
            }
            localStorage.setItem('payex-checkout-url', JSON.stringify(url));
            window.location.href = redirectUrl + '#post';
          },
          onPaid: ({ paymentOrder }: any) => {
            localStorage.removeItem('payex-checkout-url');
            completeTransaction(paymentOrder.id);
          }
        })
        .open();
    };

    document.head.appendChild(script);
  };

  useEffect(() => {
    if (localStorage.getItem('payex-checkout-url')) {
      initScript('');
    }
  }, []);


  useCreateTransaction({
    organizationId,
    config: {
      enabled: !!organizationId && organizationId > 0 && !localStorage.getItem('payex-checkout-url'),
      onSuccess: checkoutUrl => {
        if (checkoutUrl === '') {
          setHasNothingToPay(true);
        } else {
          initScript(checkoutUrl);
        }
      }
    }
  });

  if (hasNothingToPay) {
    return <NothingToPay />;
  }

  return (
    <div>
      <div id="checkout-container"></div>
    </div>
  );
}
