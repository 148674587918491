import { cn } from '@/utils';
import { AlertTriangle, CalendarX, CheckCircle, PenIcon, Send, Trash, Wallet, X } from 'lucide-react';
import { EventStatus } from '../types';
import { Label } from '@/components/ui/label';
import { Badge } from '@/components/ui/badge';
import { label } from '@/utils/label';
import { LabelGroup } from '@/features/label/types';

interface EventStatusProps {
  status: number;
  className?: string;
  count?: number;
}

const labels = {
  [EventStatus.Draft]: 'Draft',
  [EventStatus.ActionRequired]: 'ActionRequired',
  [EventStatus.AwaitingPayment]: 'AwaitingPayment',
  [EventStatus.Pending]: 'Pending',
  [EventStatus.Approved]: 'Approved',
  [EventStatus.Rejected]: 'Rejected',
  // [EventStatus.Review]: 'Review',
  [EventStatus.Cancelled]: 'Cancelled',
  // [EventStatus.UnPublished]: 'UnPublished',
  [EventStatus.Deleted]: 'Deleted',
} as const;

const colors = {
  [EventStatus.Draft]: 'bg-zinc-100 text-gray-900',
  [EventStatus.ActionRequired]: 'bg-amber-100 text-amber-900',
  [EventStatus.AwaitingPayment]: 'bg-orange-100 text-amber-900',
  [EventStatus.Pending]: 'bg-sky-100 text-sky-900',
  [EventStatus.Approved]: 'bg-green-100 text-green-900',
  [EventStatus.Rejected]: 'bg-red-100 text-red-900',
  // [EventStatus.Review]: 'bg-sky-100 text-sky-900',
  [EventStatus.Cancelled]: 'bg-red-100 text-red-900',
  // [EventStatus.UnPublished]: 'bg-red-100 text-red-900',
  [EventStatus.Deleted]: 'bg-red-100 text-red-900',
} as const;

const invertedColors = {
  [EventStatus.Draft]: 'bg-zinc-600 text-white',
  [EventStatus.ActionRequired]: 'bg-amber-600 text-white',
  [EventStatus.AwaitingPayment]: 'bg-orange-600 text-white',
  [EventStatus.Pending]: 'bg-sky-600 text-white',
  [EventStatus.Approved]: 'bg-green-600 text-white',
  [EventStatus.Rejected]: 'bg-red-600 text-white',
  // [EventStatus.Review]: 'bg-sky-600 text-white',
  [EventStatus.Cancelled]: 'bg-red-600 text-white',
  // [EventStatus.UnPublished]: 'bg-red-600 text-white',
  [EventStatus.Deleted]: 'bg-red-600 text-white',
} as const;

const icons = {
  [EventStatus.Draft]: <PenIcon className="w-4 h-4 text-gray-600 " />,
  [EventStatus.ActionRequired]: <AlertTriangle className="w-4 h-4 text-amber-600 " />,
  [EventStatus.AwaitingPayment]: <Wallet className="w-4 h-4 text-orange-600" />,
  [EventStatus.Pending]: <Send className="w-4 h-4 text-sky-600" />,
  [EventStatus.Approved]: <CheckCircle className="w-4 h-4 text-green-600 " />,
  [EventStatus.Rejected]: <X className="w-4 h-4 text-destructive " />,
  // [EventStatus.Review]: <FilePen className="w-4 h-4 text-sky-600 " />,
  [EventStatus.Cancelled]: <CalendarX className="w-4 h-4 text-destructive " />,
  // [EventStatus.UnPublished]: <X className="w-4 h-4 text-destructive " />,
  [EventStatus.Deleted]: <Trash className="w-4 h-4 text-destructive" />,
} as const;

export function EventStatusBadge({ status, className, count }: EventStatusProps) {
  return (
    <Badge className={cn(colors[status as keyof typeof colors], 'space-x-2 text-sm rounded-md p-2 py-2', className)}>
      {icons?.[status as keyof typeof icons]}
      <Label className="font-semibold text-xs lg:text-sm">
        {label(labels?.[status as keyof typeof labels], LabelGroup.ORGANIZER_EVENT_STATUSES)}
      </Label>
      {!!count && count > 0 && (
        <span
          className={cn(
            'font-medium ml-1 bg-gold/20 bg-white rounded-full  text-[10px] flex items-center justify-center min-w-4 h-4 p-1',
            invertedColors[status as keyof typeof invertedColors]
          )}
        >
          {count}
        </span>
      )}
    </Badge>
  );
}
