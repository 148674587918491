import { createLabel } from '@/features/label/api/createLabel';
import { ENV } from '@/config';
import { Label } from '@/features/label/types';
import { queryClient } from '@/lib/react-query';
import { stripHTML } from './strip-html';

export function label(name: string, groupName: string = 'DELETE') {
  if (!name) return name;

  const labels = queryClient.getQueryData<
    (Label & {
      isCreating?: boolean;
    })[]
  >(['labels']);

  const label = (labels ?? []).find(
    (label: Label) =>
      label?.labelName.toLowerCase() === name.toLowerCase() &&
      label?.labelGroup?.toLowerCase() === groupName?.toLowerCase()
  );

  const isCreating = label?.isCreating;

  if (!label && labels !== undefined && !isCreating && ENV === 'debug') {
    // Use useMutation to handle the creation of a label

    queryClient.setQueryData(
      ['labels'],
      [
        ...labels,
        {
          labelName: name,
          languageCode: 'swe',
          value: name,
          groupName: groupName,
          isCreating: true,
        },
      ]
    );

    createLabel({
      data: {
        labelName: name,
        languageCode: 'swe',
        value: name,
        groupName: groupName,
      },
    }).then((response) => {
      queryClient.setQueryData(
        ['labels'],
        labels.map((label) => (label.labelName === name ? response : label))
      );
    });
  }

  return label ? stripHTML(label.value) : `${name} (${groupName})`;
}
