import { Card, CardContent, CardDescription, CardTitle, CardHeader } from '@/components/ui/card';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { useConfirmTerms } from '../api/confirm-terms';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { TermsOfAgreement } from '@/components/terms-of-agreement';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Organization } from '../types';
import { Separator } from '@/components/ui/separator';

const formSchema = z.object({
  acceptedUserTerms: z.boolean().refine(value => value === true, {
    message: 'accept_user_terms_validation_text'
  }),
  acceptedPersonalDataTerms: z.boolean().refine(value => value === true, {
    message: 'accept_person_data_terms_validation_text'
  })
});

export function NewMainContactTerms({ organizer }: { organizer: Organization }) {
  const { mutateAsync: acceptTerms } = useConfirmTerms({
    organizerId: organizer.organizerId
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      acceptedUserTerms: false,
      acceptedPersonalDataTerms: false
    }
  });

  async function handleSubmit() {
    await acceptTerms({ organizerId: organizer.organizerId });
  }

  return (
    <div className="space-y-6 flex flex-col w-full h-full">
      <div className="space-y-2 overflow-y-auto flex-grow">
        <Card>
          <CardHeader>
            <CardTitle>
              <Label name="confirm_organization_terms_title" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} />
            </CardTitle>
            <CardDescription>
              <Label name="confirm_organization__terms_description" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} />
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-6">
            <div className="grid md:grid-cols-2 gap-4">
              <div className="w-full space-y-2">
                <Label name="organizer_name" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} />

                <Input value={organizer.organizerName} maxLength={32} disabled readOnly />
              </div>

              <div className="w-full space-y-2">
                <Label name="org_no" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} />

                <Input value={organizer.orgNo} disabled readOnly />
              </div>
            </div>
            <Separator className="my-2" />
            <div className="grid md:grid-cols-2 gap-4">
              <div className="w-full space-y-2">
                <Label name="signer_name" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} />

                <Input disabled readOnly value={organizer.signerName} maxLength={500} />
              </div>

              <div className="w-full space-y-2">
                <Label name="signer_email" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} />

                <Input disabled readOnly value={organizer.signerEmail} />
              </div>
            </div>
            <div className="grid md:grid-cols-2 gap-4">
              <div className="w-full space-y-2">
                <Label name="signer:telephone" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} />

                <Input disabled readOnly value={organizer.signerTelephone} />
              </div>
            </div>
            <Form {...form}>
              <form onKeyDown={event => event.key === 'Enter' && event.preventDefault()} onSubmit={form.handleSubmit(handleSubmit)} className="space-y-8">
                <FormField
                  control={form.control}
                  name="acceptedUserTerms"
                  render={({ field }) => (
                    <FormItem className="lg:w-1/2 lg:pr-2 w-full">
                      <FormLabel>
                        <Label name="user_terms_title" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} />
                      </FormLabel>
                      <FormControl>
                        <TermsOfAgreement
                          accepted={field.value}
                          onAccept={() => field.onChange(true)}
                          LabelComponent={<Label name="user_terms_label" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} />}
                          BodyComponent={
                            <div className="[&>button]:max-w-full text-sm break-words">
                              <Label name="user_terms_body" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} />
                            </div>
                          }
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="acceptedPersonalDataTerms"
                  render={({ field }) => (
                    <FormItem className="lg:w-1/2 lg:pr-2  w-full">
                      <FormLabel>
                        <Label name="personal_data_terms_title" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} />
                      </FormLabel>
                      <FormControl>
                        <TermsOfAgreement
                          accepted={field.value}
                          onAccept={() => field.onChange(true)}
                          LabelComponent={<Label name="personal_data_terms_label" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} />}
                          BodyComponent={
                            <div className="[&>button]:max-w-full text-sm break-words">
                              <Label name="personal_data_terms_body" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} />
                            </div>
                          }
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Button disabled={form.formState.isSubmitting || !form.formState.isDirty || !form.formState.isValid} type="submit">
                  <Label name="confirm" groupName={LabelGroup.ORGANIZER_ORGANIZATION_CREATE} />
                </Button>
              </form>
            </Form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
