import { DataTableFacetedFilter } from '@/components/ui/data-table-faceted-filter';
import { activities, categories } from '../data/data';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';

interface LocationFiltersProps {
  table: any;
}

export function LocationFilters({ table }: LocationFiltersProps) {
  return (
    <div className="space-x-2 mx-2">
      <DataTableFacetedFilter
        column={table.getColumn('active')}
        TitleComponent={<Label name="active" groupName={LabelGroup.ADMIN_LOCATION_LIST} />}
        options={activities}
      />
      <DataTableFacetedFilter
        column={table.getColumn('category')}
        TitleComponent={<Label name="category" groupName={LabelGroup.ADMIN_LOCATION_LIST} />}
        options={categories}
      />
    </div>
  );
}
