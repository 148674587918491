import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { Setting } from '../types';

const QUERY_KEY = 'settings';

export const updateSetting = (data: Setting): Promise<void> => {
  return axios.put(`/settings`, { ...data });
};

type UseOptions = {
  config?: MutationConfig<typeof updateSetting>;
};

export const useUpdateSetting = ({ config }: UseOptions) => {
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEY]);
      queryClient.refetchQueries([QUERY_KEY]);
    },
    ...config,
    mutationFn: updateSetting,
  });
};
