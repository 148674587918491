import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Manager } from '../types';
import { useManagers } from '../api/getManagers';
import { Button } from '@/components/ui/button';
import { Check, UserPlus2 } from 'lucide-react';
import { cn } from '@/utils';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';

interface ManagerDropdownProps {
  onChange: (manager: Manager) => void;
  value: number;
}

export function ManagerDropdown({ onChange, value }: ManagerDropdownProps) {
  const { data: managers } = useManagers({});

  const managerName = managers?.find((manager) => manager.managerId === value)?.name;
  const managerUsername = managers?.find((manager) => manager.managerId === value)?.username;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="sm" className={cn('flex data-[state=open]:bg-muted  justify-start')}>
          {managers?.some((manager) => manager.managerId === value) ? (
            <Avatar className="h-6 w-6 mr-2">
              <AvatarImage />
              <AvatarFallback className="bg-brand text-white">
                {(managerName || managerUsername)?.charAt(0)?.toUpperCase()}
              </AvatarFallback>
            </Avatar>
          ) : (
            <UserPlus2 className="h-4 w-4 mr-2" />
          )}
          {(managerName || managerUsername) ?? (
            <Label name="assign_manager" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
          )}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>
          <Label name="assign_managers_list_title" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        {managers?.map((manager) => (
          <DropdownMenuItem
            disabled={value === manager.managerId}
            key={manager.managerId}
            className={cn(value === manager.managerId ? 'bg-muted' : 'pl-8')}
            onClick={() => onChange(manager)}
          >
            {value === manager.managerId && <Check className="h-4 w-4 mr-2" />}
            {manager.username}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
