import { useState, useEffect, useRef } from 'react';

const useIsOverflow: () => [boolean, React.MutableRefObject<any>] = () => {
  const [isOverflow, setIsOverflow] = useState(false);
  const ref = useRef<any>(null);

  const checkOverflow = () => {
    const element = ref?.current;
    if (element) {
      const hasOverflow = element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
      setIsOverflow(hasOverflow);
    }
  };

  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    // Create observers
    const resizeObserver = new ResizeObserver(checkOverflow);
    const mutationObserver = new MutationObserver(checkOverflow);

    // Observe size changes
    resizeObserver.observe(element);

    // Observe content changes
    mutationObserver.observe(element, {
      childList: true,
      subtree: true,
      characterData: true,
    });

    // Initial check
    checkOverflow();

    return () => {
      resizeObserver.disconnect();
      mutationObserver.disconnect();
    };
  }, []);

  return [isOverflow, ref];
};

export { useIsOverflow };
