import { Row } from '@tanstack/react-table';

import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { MoreHorizontal } from 'lucide-react';
import { Link } from 'react-router-dom';
import { User } from '../types';
import { DeleteButton } from '@/components/delete-button';
import { useDeleteUser } from '../api/delete-user';
import { Separator } from '@/components/ui/separator';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';

interface DataTableRowActionsProps<TData> {
  row: Row<TData>;
}

export function UserTableRowActions<TData>({ row }: DataTableRowActionsProps<TData & User>) {
  const { mutateAsync: deleteUser } = useDeleteUser({});

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="flex h-8 w-8 p-0 data-[state=open]:bg-muted">
          <MoreHorizontal className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[160px]">
        <Link to={`/users/${row.original?.userId}`}>
          <DropdownMenuItem>
            <Label name="edit_user_table_action_text" groupName={LabelGroup.ADMIN_USER_LIST} />
          </DropdownMenuItem>
        </Link>
        <Separator />
        <DeleteButton
          TitleComponent={<Label name="delete_user_title" groupName={LabelGroup.ADMIN_USER_LIST} />}
          DescriptionComponent={<Label name="delete_user_description" groupName={LabelGroup.ADMIN_USER_LIST} />}
          ButtonComponent={
            <div className="text-destructive flex items-center text-sm px-2 py-1.5">
              <Label name="delete_user_table_action_text" groupName={LabelGroup.ADMIN_USER_LIST} />
            </div>
          }
          onDelete={() => deleteUser({ userId: row.original?.userId })}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
