import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { EmailSettings as TEmailSettings, Setting } from '../types';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useUpdateSetting } from '../api/update-setting';
import { useToast } from '@/components/ui/use-toast';
import { label } from '@/utils/label';
import TinyMCE from '@/components/tinyMCE';

interface EmailSettingItemProps {
  setting: Setting & {
    data: TEmailSettings;
  };
}

const schema = z.object({
  settingId: z.number(),
  name: z.string(),
  value: z.string(),
  sortOrder: z.number(),
  data: z.object({
    subject: z.string(),
    body: z.string(),
  }),
});

export function EmailSettingItem({ setting }: EmailSettingItemProps) {
  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    values: {
      ...setting,
    },
  });

  const settingMutation = useUpdateSetting({});
  const { toast } = useToast();

  const handleSubmit = async (values: z.infer<typeof schema>) => {
    try {
      await settingMutation.mutateAsync({ ...values, data: JSON.stringify(values.data) });

      toast({
        variant: 'success',
        title: label('setting_updated', LabelGroup.ADMIN_SETTINGS),
      });
    } catch (error) {
      toast({
        variant: 'destructive',
        title: label('setting_update_failed', LabelGroup.ADMIN_SETTINGS),
      });
      console.error('Error updating settings', error);
    }
  };

  return (
    <Form {...form}>
      <form
        onKeyDown={(event) => event.key === 'Enter' && event.preventDefault()}
        onSubmit={form.handleSubmit(handleSubmit)}
        className="space-y-8"
      >
        <Card className="w-[600px]">
          <CardHeader>
            <CardTitle>
              <Label name={setting.value} groupName={LabelGroup.ADMIN_SETTINGS} />
            </CardTitle>
            <CardDescription>
              <Label name={setting.value + '_description'} groupName={LabelGroup.ADMIN_SETTINGS} />
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-2">
            <FormField
              control={form.control}
              name="data.subject"
              render={({ field }) => (
                <FormItem className="max-w-sm">
                  <FormLabel>
                    <Label name="subject" groupName={LabelGroup.ADMIN_SETTINGS} />
                  </FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="data.body"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>
                    <Label name="body" groupName={LabelGroup.ADMIN_SETTINGS} />
                  </FormLabel>
                  <FormControl className="font-base">
                    {/* <Textarea {...field} className="h-96 rounded-none" /> */}
                    <TinyMCE
                      onChange={field.onChange}
                      value={field.value}
                      config={{
                        inline: false,
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </CardContent>
          <CardFooter>
            <Button disabled={!form.formState.isDirty} type="submit">
              <Label name="Save" groupName={LabelGroup.GLOBAL} />
            </Button>
          </CardFooter>
        </Card>
      </form>
    </Form>
  );
}
