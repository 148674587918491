import React, { useEffect, useRef, useState } from 'react';

interface ListNavigatorProps {
  children: React.ReactNode;
  inputRef: React.RefObject<HTMLInputElement>;
  containerRef: React.RefObject<HTMLDivElement>;
}

export const ListNavigator: React.FC<ListNavigatorProps> = ({ children, inputRef, containerRef }) => {
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const focusableElements = useRef<(HTMLElement | null)[]>([]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.target && (e.target === inputRef.current || (e.target as HTMLElement).closest('.list-navigator'))) {
        switch (e.key) {
          case 'ArrowUp':
            e.preventDefault();
            setFocusedIndex(prev => Math.max(-1, prev - 1));
            break;
          case 'ArrowDown':
            e.preventDefault();
            setFocusedIndex(prev => Math.min(focusableElements.current.length - 1, prev + 1));
            break;
          default:
            break;
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (focusedIndex >= 0 && focusedIndex < focusableElements.current.length && focusableElements.current[focusedIndex]) {
      focusableElements.current[focusedIndex]?.focus();
    } else if (focusedIndex === -1) {
      // inputRef.current?.focus();
    }
  }, [focusedIndex]);

  const handleChildRef = (index: number, ref: HTMLElement | null) => {
    if (ref) {
      focusableElements.current[index] = ref;
    }
  };

  return (
    <div ref={containerRef} className="list-navigator [&>li]:list-none">
      {React.Children.map(children, (child, index) =>
        React.isValidElement(child)
          ? React.cloneElement(child, {
              ...(typeof child.type === 'string' ? { tabIndex: index + 1 } : {}), // Start from 1 to allow input to be focused with Tab
              ...('ref' in child ? { ref: (node: any) => handleChildRef(index, node) } : {}),
              ...(index === focusedIndex ? { 'data-state': 'on' } : {})
            })
          : child
      )}
    </div>
  );
};
