import { DataTableFacetedFilter } from '@/components/ui/data-table-faceted-filter';
import { labelGroups } from '../data/label-groups';
import { LabelGroup } from '../types';
import { Label } from './label';

interface LocationFiltersProps {
  table: any;
}

export function LabelFilters({ table }: LocationFiltersProps) {
  return (
    <div className="space-x-2 mx-2">
      <DataTableFacetedFilter
        column={table?.getColumn('labelGroup')}
        TitleComponent={<Label name="labelGroup" groupName={LabelGroup.ADMIN_LABEL} />}
        options={labelGroups.map(({ name, group }: any) => ({ label: name, value: group }))}
      />
    </div>
  );
}
