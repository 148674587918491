import { Skeleton } from '@/components/ui/skeleton';

export function MangeEventSkeleton() {
  return (
    <div className="w-full h-[calc(100lvh_-_86px)] overflow-hidden space-y-6 p-6">
      <div className="flex  items-center justify-between w-full ">
        <Skeleton className="h-8 w-40" />
        <div className="flex gap-x-2 items-center">
          <Skeleton className="h-10 w-40" />
          <Skeleton className="h-10 w-40" />
          <Skeleton className="h-10 w-20" />
        </div>
      </div>

      <div className="flex  gap-8 items-start justify-start h-screen w-full ">
        <div className="w-2/5  justify-end hidden h-full lg:flex ">
          <Skeleton className="w-full h-4/5" />
        </div>

        <div className="max-h-[calc(100lvh_-_60px)] overflow-y-hidden flex-col w-full flex ">
          <div className="space-y-2  max-w-full">
            <Skeleton className="h-[800px] w-full" />
            <Skeleton className="h-48 w-full" />
            <Skeleton className="h-48 w-full" />
          </div>
        </div>
      </div>
    </div>
  );
}
