import { Skeleton } from './ui/skeleton';

export function DataTableSkeleton() {
  return (
    <div className="w-full h-screen space-y-6 py-4">
      <div className="flex justify-between items-center w-full  h-18">
        <div className="flex items-center w-full h-full gap-x-2">
          <Skeleton className="h-10 w-96" />
          <Skeleton className="h-10 w-20 " />
          <Skeleton className="h-10 w-20" />
        </div>
        <Skeleton className="h-10 w-20" />
      </div>
      <div className="w-full">
        <Skeleton className="h-48 w-full" />
      </div>
      <div className="flex justify-between items-center w-full pt-2  h-18 px-4">
        <Skeleton className="h-4 w-10" />
        <div className="flex items-center h-full gap-x-12">
          <div className="flex gap-x-2 items-center">
            <Skeleton className="h-5 w-10 " />
            <Skeleton className="h-8 w-20" />
          </div>
          <Skeleton className="h-5 w-10 " />
          <div className="flex gap-x-2">
            <Skeleton className="h-8 w-8" />
            <Skeleton className="h-8 w-8 " />
            <Skeleton className="h-8 w-8" />
            <Skeleton className="h-8 w-8" />
          </div>
        </div>
      </div>
    </div>
  );
}
