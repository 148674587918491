import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { TEvent as Event, EventTime } from '../types';
import { useToast } from '@/components/ui/use-toast';
import { label } from '@/utils/label';
import { LabelGroup } from '@/features/label/types';

const QUERY_KEY = 'event';

export type UpdateEventDTO = {} & any;

export const updateEvent = (data: UpdateEventDTO): Promise<Event> => {
  const times = data.times.reduce((acc: EventTime[], time: EventTime) => {
    if (time.eventStart && time.eventEnd) {
      // Check if eventStart and eventEnd are already in ISO format
      const isEventStartISO = time.eventStart.includes('T');
      const isEventEndISO = time.eventEnd.includes('T');

      // If both are in ISO format, add the object with updated inserted date
      if (isEventStartISO && isEventEndISO) {
        acc.push({
          ...time,
          inserted: new Date().toISOString() // Only update the inserted date
        });
        return acc;
      }

      // Parse eventStart and eventEnd, and convert to ISO strings
      const eventStartISO = !isEventStartISO ? new Date(time.eventStart).toISOString() : time.eventStart;
      const eventEndISO = !isEventEndISO ? new Date(time.eventEnd).toISOString() : time.eventEnd;

      // Add the new object with updated eventStart, eventEnd, and inserted fields
      acc.push({
        ...time,
        eventStart: eventStartISO,
        eventEnd: eventEndISO,
        inserted: new Date().toISOString()
      });
    } else {
      // If no valid eventStart and eventEnd, just push the original item
      acc.push(time);
    }

    return acc;
  }, []);

  const formattedData = {
    ...data,
    languages: Array.isArray(data.languages) ? data.languages.join(';') : data.languages,
    times
  };

  return axios.put(`/event`, formattedData);
};

type UseUpdateThemeOptions = {
  eventId: number;
  organizerId: number;
  config?: MutationConfig<typeof updateEvent>;
};

export const useUpdateEvent = ({ config, eventId,organizerId }: UseUpdateThemeOptions) => {
  const { toast } = useToast();

  return useMutation({
    onMutate: async (updating: any) => {
      await queryClient.cancelQueries([QUERY_KEY, updating?.eventId]);

      const previous = queryClient.getQueryData<Event>([QUERY_KEY, updating?.eventId]);

      queryClient.setQueryData([QUERY_KEY, updating?.eventId], {
        ...previous,
        ...updating,
        id: updating.eventId
      });

      return { previous };
    },
    onError: (_, __, context: any) => {
      if (context?.previous) {
        queryClient.setQueryData([QUERY_KEY, context.previous.id], context.previous);
      }

      const message = _?.response?.data ? label(_?.response?.data?.toString(), LabelGroup.ORGANIZER_EVENT_FORM) : label('something_went_wrong', LabelGroup.GLOBAL);

      toast({
        variant: 'destructive',
        title: message
      });
    },
    onSuccess: async data => {
      if (eventId !== data?.eventId) {
        queryClient.removeQueries([QUERY_KEY, eventId]);
      }
      queryClient.invalidateQueries(['events', organizerId]);
    },
    ...config,
    mutationFn: updateEvent
  });
};
