import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useCreateLabel } from '../api/createLabel';
import { Card, CardContent } from '@/components/ui/card';
import { useNavigate } from 'react-router-dom';
import { Label } from '../components/label';
import { LabelGroup } from '../types';

const formSchema = z.object({
  languageCode: z.string(),
  groupName: z.string(),
  labelName: z.string(),
  value: z.string(),
});

export function CreateLabel() {
  const { mutateAsync: createLabel, isLoading } = useCreateLabel({});
  const navigate = useNavigate();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      languageCode: 'swe',
    },
  });

  async function handleSubmit(values: z.infer<typeof formSchema>) {
    try {
      const newLabel = await createLabel({ ...values } as any);
      navigate(`/labels/edit/${newLabel.labelId}`);
    } catch (error) {}
  }

  return (
    <div className="space-y-6 flex flex-col p-6">
      <div className="space-y-2">
        <Card className="max-w-lg">
          <CardContent className="p-6">
            <Form {...form}>
                <form onKeyDown={(event) => event.key === 'Enter' && event.preventDefault()} onSubmit={form.handleSubmit(handleSubmit)} className="space-y-8">
                <FormField
                  control={form.control}
                  name="labelName"
                  render={({ field }) => (
                    <FormItem className="w-[250px]">
                      <FormLabel>
                        <Label name="label_name" groupName={LabelGroup.ADMIN_LABEL} />
                      </FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="groupName"
                  render={({ field }) => (
                    <FormItem className="w-[250px]">
                      <FormLabel>
                        <Label name="label_group_name" groupName={LabelGroup.ADMIN_LABEL} />
                      </FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="languageCode"
                  render={({ field }) => (
                    <FormItem className="w-[250px]">
                      <FormLabel>
                        <Label name="label_language_code" groupName={LabelGroup.ADMIN_LABEL} />
                      </FormLabel>
                      <Select onValueChange={field.onChange} defaultValue={field.value}>
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {[
                            { code: 'swe', name: 'Svenska' },
                            { code: 'eng', name: 'English' },
                          ].map((language) => (
                            <SelectItem key={language.code} value={language.code}>
                              {language.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="value"
                  render={({ field }) => (
                    <FormItem className="w-[250px]">
                      <FormLabel>
                        <Label name="label_value" groupName={LabelGroup.ADMIN_LABEL} />
                      </FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Button disabled={isLoading || form.formState.isSubmitting || !form.formState.isDirty} type="submit">
                  <Label
                    name={isLoading ? 'creating' : 'create_label_button_text'}
                    groupName={LabelGroup.ADMIN_LABEL}
                  />
                </Button>
              </form>
            </Form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
