import * as React from 'react';
import { CheckIcon, PlusCircleIcon } from 'lucide-react';
import { Column } from '@tanstack/react-table';

import { cn } from '@/utils';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Separator } from '@/components/ui/separator';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';

interface DataTableFacetedFilterProps<TData, TValue> {
  column?: Column<TData, TValue>;
  TitleComponent?: React.ReactNode | string;
  title?: string;
  options: {
    label: string | React.ReactNode;
    value: string | number | boolean;
    icon?: React.ComponentType<{ className?: string }>;
  }[];
}

export function DataTableFacetedFilter<TData, TValue>({
  column,
  TitleComponent,
  title,
  options,
}: DataTableFacetedFilterProps<TData, TValue>) {
  const facets = column?.getFacetedUniqueValues();
  const selectedValues = new Set(column?.getFilterValue() as string[]);

  selectedValues.forEach((value: string | number | boolean) => {
    const stringValue = typeof value === 'boolean' ? value.toString() : value;
    selectedValues.add(stringValue as string);
  });

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" size="sm" className="h-10 border-dashed">
          <PlusCircleIcon className="mr-2 h-4 w-4" />
          {TitleComponent}
          {selectedValues?.size > 0 && (
            <>
              <Separator orientation="vertical" className="mx-2 h-4" />
              <Badge variant="secondary" className="rounded-sm px-1 font-normal lg:hidden">
                {selectedValues.size}
              </Badge>
              <div className="hidden space-x-1 lg:flex">
                {selectedValues.size > 2 ? (
                  <Badge variant="secondary" className="rounded-sm px-1 font-normal">
                    <span className="mr-1">{selectedValues.size}</span>
                    <Label name="selected_filters" groupName={LabelGroup.GLOBAL} />
                  </Badge>
                ) : (
                  options
                    .filter((option) => selectedValues.has(option.value?.toString()))
                    .map((option) => (
                      <Badge variant="secondary" key={option.value?.toString()} className="rounded-sm px-1 font-normal">
                        {option.label}
                      </Badge>
                    ))
                )}
              </div>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-fit p-0" align="start">
        <Command>
          <CommandInput placeholder={title} />
          <CommandList>
            <CommandEmpty>
              <Label name="no_filters" groupName={LabelGroup.GLOBAL} />
            </CommandEmpty>
            <CommandGroup>
              {options.map((option) => {
                const isSelected = selectedValues.has(option.value?.toString());
                return (
                  <div className="relative">
                    <CommandItem
                      className="w-full relative mr-10"
                      key={option.value?.toString()}
                      onSelect={() => {
                        if (isSelected) {
                          selectedValues.delete(option.value.toString());
                        } else {
                          selectedValues.add(option.value.toString());
                        }
                        const filterValues = Array.from(selectedValues);
                        column?.setFilterValue(filterValues.length ? filterValues : undefined);
                      }}
                    >
                      <div
                        className={cn(
                          'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                          isSelected ? 'bg-primary text-primary-foreground' : 'opacity-50 [&_svg]:invisible'
                        )}
                      >
                        <CheckIcon className={cn('h-4 w-4')} />
                      </div>
                      {option.icon && <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />}
                      <span>{option.label}</span>
                    </CommandItem>
                    {facets?.get(option.value) && (
                      <span className="ml-auto absolute top-1/2 -translate-y-1/2 right-2 flex h-4 w-4 items-center justify-center font-mono text-xs">
                        {facets.get(option.value)}
                      </span>
                    )}
                  </div>
                );
              })}
            </CommandGroup>
            {selectedValues.size > 0 && (
              <>
                <CommandSeparator />
                <CommandGroup>
                  <CommandItem
                    onSelect={() => column?.setFilterValue(undefined)}
                    className="justify-center text-center"
                  >
                    <Label name="clear_filters" groupName={LabelGroup.GLOBAL} />
                  </CommandItem>
                </CommandGroup>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
