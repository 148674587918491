import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { ArrowRight } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import eventHero from '@/assets/media/event_hero.jpg';

export function EventsShortcutCard() {
  return (
    <Link to="/events">
      <Card className="w-full h-full border-0 rounded-none">
        <div>
          <img
            src={eventHero}
            alt="Organization placeholder"
            className="w-full h-full object-cover"
          />
        </div>
        <CardHeader>
          <CardTitle className="flex items-center">
            <ArrowRight className="w-6 h-6 mr-2" />
            <Label name="no_event_created_title" groupName={LabelGroup.ORGANIZER_DASHBOARD} />
          </CardTitle>
        </CardHeader>
        <CardContent className="h-auto">
          <CardDescription className="text-left ">
            <Label name="no_event_created_description" groupName={LabelGroup.ORGANIZER_DASHBOARD} />
          </CardDescription>
        </CardContent>
      </Card>
    </Link>
  );
}
