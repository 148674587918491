import { DataTable } from '@/components/ui/data-table';
import { Separator } from '@/components/ui/separator';
import { useLocations } from '../api/getLocations';
import { columns } from '../components/location-columns';
import { LocationFilters } from '../components/location-filters';
import { Row } from '@tanstack/react-table';
import { TLocation } from '../types';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Plus } from 'lucide-react';

export function Locations() {
  const { data: locations = [] } = useLocations({});

  return (
    <div>
      <div className="flex items-center justify-between pb-4">
        <h2 className="text-lg font-semibold">
          <Label name="locations_page_title" groupName={LabelGroup.ADMIN_LOCATION_LIST} />
        </h2>
        <Link to="/locations/create">
          <Button className="ml-4">
            <Plus className="h-4 w-4 mr-1" />
            <Label name="create_location_button_text" groupName={LabelGroup.ADMIN_LOCATION_LIST} />
          </Button>
        </Link>
      </div>
      <Separator />
      <div>
        <DataTable
          columns={columns}
          data={locations}
          FacetedFilters={LocationFilters}
          config={{
            getSearchFields: (row) => [
              (row as Row<TLocation>).original.name,
              (row as Row<TLocation>).original.category,
            ],
          }}
        />
      </div>
    </div>
  );
}
