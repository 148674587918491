import { RouteObject, createBrowserRouter, RouterProvider } from 'react-router-dom';

import { organizerRoutes } from './organizer';
import { publicRoutes } from './public';
import { adminRoutes } from './admin';
import { UserType } from '@/features/user';
import { managerRoutes } from './manager';
import { useMe } from '@/features/auth/api/get-me';
import storage from '@/utils/storage';

const userRoutes: {
  [key in UserType]: RouteObject[];
} = {
  [UserType.ADMIN]: adminRoutes,
  [UserType.MANAGER]: managerRoutes,
  [UserType.ORGANIZER]: organizerRoutes,
} as const;

export const AppRoutes = () => {
  // const user = storage.getUser() as User | null;
  const { data: user, isLoading } = useMe({ config: { enabled: !!storage.getAccessToken() } });

  if (isLoading) {
    return null;
  }

  //TODO check token expiration here

  const routes: RouteObject[] =
    user && userRoutes?.[user?.role as keyof typeof userRoutes]
      ? userRoutes[user?.role as keyof typeof userRoutes]
      : publicRoutes;

  const router = createBrowserRouter([...routes]);

  return <RouterProvider router={router} />;
};
