import { CreditCard, File, HelpCircle } from 'lucide-react';
import { PayType } from '../types';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { SwishIcon } from '@/components/swish-icon';

export function PaymentIcon({ payType }: { payType: PayType }) {
  switch (payType) {
    case PayType.Card:
      return (
        <div className="flex items-center gap-x-2 border border-input text-sm px-2 rounded-md py-1">
          <CreditCard className="w-4 h-4 text-muted-foreground" />
          <Label name="payed_with_card" groupName={LabelGroup.ORGANIZER_PAYMENT} />
        </div>
      );

    case PayType.Invoice:
      return (
        <div className="flex items-center gap-x-2 border border-input text-sm px-2 rounded-md py-1">
          <File className="w-4 h-4 text-muted-foreground" />
          <Label name="payed_with_invoice" groupName={LabelGroup.ORGANIZER_PAYMENT} />
        </div>
      );
    case PayType.Swish:
      return (
        <div className="flex items-center border border-input text-sm px-1 rounded-md py-1">
          <SwishIcon className="w-16 h-6 text-muted-foreground mr-0" />
        </div>
      );
    default:
      return (
        <div className="flex items-center gap-x-2 border border-input text-xs px-2 rounded-md py-1">
          <HelpCircle className="w-4 h-4 text-muted-foreground" />
          <Label name="payed_with_unknown" groupName={LabelGroup.ORGANIZER_PAYMENT} />
        </div>
      );
  }
}
