export function isDateNotDue(lastDate: string) {
  if (!lastDate) return false;

  // Strip time by setting hours, minutes, seconds, and milliseconds to zero
  const inputDate = +new Date(
    new Date(lastDate?.toString()).toLocaleDateString('sv-SE', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    })
  );

  const currentDate = +new Date(
    new Date().toLocaleDateString('sv-SE', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    })
  );

  return inputDate > currentDate;
}
