import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Checkbox } from '@/components/ui/checkbox';
import { Separator } from '@/components/ui/separator';
import { CheckCircle } from 'lucide-react';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { checkEnvironmentDiplomaQualification } from '../../utils/check-environment-diploma-qualification';
import { PRODUCTS_AND_MARKETING_OPTIONS, WASTE_AND_RECYCLING_OPTIONS, FOOD_OPTIONS, SERVICE_OPTIONS } from '../../data/environment-diploma-constants';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { UserType } from '@/features/user';
import { useMe } from '@/features/auth/api/get-me';

interface DigitalEventSectionProps {
  form: any;
  defaultCollapsed?: boolean;
}

export function EnvironmentDiplomaSection({ form, defaultCollapsed = false }: DigitalEventSectionProps) {
  const hasSelected = [...PRODUCTS_AND_MARKETING_OPTIONS, ...WASTE_AND_RECYCLING_OPTIONS, ...FOOD_OPTIONS, ...SERVICE_OPTIONS].some(option => form.watch(option));

  const { data: user } = useMe({});
  const userType = user?.role;

  const labelGroup = userType === UserType.ADMIN || userType === UserType.MANAGER ? LabelGroup.MANAGER_EVENT_HANDLING : LabelGroup.ORGANIZER_EVENT_FORM;

  const { isQualified: isEnvironmentDiplomaApproved, isProductsAndMarketingApproved, isWasteAndRecyclingApproved, isServiceApproved, isFoodApproved } = checkEnvironmentDiplomaQualification(form.getValues());

  const clearSelection = () => {
    PRODUCTS_AND_MARKETING_OPTIONS.forEach(option => {
      form.setValue(option, false);
    });
    WASTE_AND_RECYCLING_OPTIONS.forEach(option => {
      form.setValue(option, false);
    });
    FOOD_OPTIONS.forEach(option => {
      form.setValue(option, false);
    });
    SERVICE_OPTIONS.forEach(option => {
      form.setValue(option, false);
    });
  };

  const handleFoodChange = (value: boolean, optionName: string) => {
    if (optionName === 'environmentFood' && value === true && form.getValues('environmentNoFood') === true) {
      form.setValue('environmentNoFood', false, {
        shouldDirty: true
      });
    } else if (optionName === 'environmentNoFood' && value === true && form.getValues('environmentFood') === true) {
      FOOD_OPTIONS.filter((option: string) => option !== 'environmentNoFood').forEach((option: string) => {
        form.setValue(option, false);
      });
    }

    form.setValue(optionName, value);
  };

  const isNotEnvironmentDiplomaApproved = !hasSelected;

  const foodOptions = form.watch('environmentFood') ? FOOD_OPTIONS : FOOD_OPTIONS.filter((_, index) => index < 2);

  return (
    <div className="space-y-6">
      <Card>
        <Accordion type="single" collapsible defaultValue={defaultCollapsed ? '' : 'environment_diploma'}>
          <AccordionItem className="[&[data-state=open]>.card-header]:bg-gray-medium [&[data-state=open]>.card-header]:text-white [&[data-state=open]>.card-header]:rounded-t-md" value="environment_diploma">
            <CardHeader className="py-3">
              <AccordionTrigger className="py-0 text-md">
                <CardTitle>
                  <Label name="environment_diploma_title" groupName={labelGroup} />
                </CardTitle>
              </AccordionTrigger>
            </CardHeader>
            <AccordionContent>
              <CardContent className="pt-6 text-sm space-y-6">
                <CardDescription>
                  <Label name="environment_diploma_subtitle" groupName={labelGroup} />
                </CardDescription>
                <div className="space-y-4">
                  <FormLabel className="flex items-center  font-semibold">
                    <Label name="products_and_marketing_title" groupName={labelGroup} />
                    {isProductsAndMarketingApproved && <CheckCircle className="w-4 h-4 ml-2 text-emerald-500" />}
                  </FormLabel>
                  <FormItem>
                    <div className="space-y-6">
                      {PRODUCTS_AND_MARKETING_OPTIONS.map(item => (
                        <FormField
                          key={item}
                          control={form.control}
                          name={item}
                          render={({ field }) => {
                            return (
                              <FormItem key={item} className="flex flex-row items-start space-x-3 space-y-0">
                                <FormControl>
                                  <Checkbox className="mt-0.5" checked={field.value} onCheckedChange={checked => field.onChange(checked)} />
                                </FormControl>
                                <div>
                                  <FormLabel className="font-normal">
                                    <Label name={`event_${item}_title`} groupName={labelGroup} />
                                  </FormLabel>
                                </div>
                              </FormItem>
                            );
                          }}
                        />
                      ))}
                    </div>
                    <FormMessage />
                  </FormItem>
                </div>
                <Separator />
                <div className="space-y-6">
                  <FormLabel className="flex items-center  font-semibold">
                    <Label name="waste_and_resycle_title" groupName={labelGroup} />
                    {isWasteAndRecyclingApproved && <CheckCircle className="w-4 h-4 ml-2 text-emerald-500" />}
                  </FormLabel>
                  <FormItem>
                    <div className="space-y-6">
                      {WASTE_AND_RECYCLING_OPTIONS.map(item => (
                        <FormField
                          key={item}
                          control={form.control}
                          name={item}
                          render={({ field }) => {
                            return (
                              <FormItem key={item} className="flex flex-row items-start space-x-3 space-y-0">
                                <FormControl>
                                  <Checkbox className="mt-0.5" checked={field.value} onCheckedChange={checked => field.onChange(checked)} />
                                </FormControl>
                                <div>
                                  <FormLabel className="font-normal">
                                    <Label name={`event_${item}_title`} groupName={labelGroup} />
                                  </FormLabel>
                                </div>
                              </FormItem>
                            );
                          }}
                        />
                      ))}
                    </div>
                    <FormMessage />
                  </FormItem>
                </div>
                <Separator />
                <div className="space-y-6">
                  <FormLabel className="flex items-center font-semibold">
                    <Label name="food_title" groupName={labelGroup} />
                    {isFoodApproved && <CheckCircle className="w-4 h-4 ml-2 text-emerald-500" />}
                  </FormLabel>
                  <FormItem>
                    <div className="space-y-6">
                      {foodOptions.map(item => (
                        <FormField
                          key={item}
                          control={form.control}
                          name={item}
                          render={({ field }) => {
                            return (
                              <FormItem key={item} className="flex flex-row items-start space-x-3 space-y-0">
                                <FormControl>
                                  <Checkbox className="mt-0.5" checked={field.value} onCheckedChange={checked => handleFoodChange(!!checked, field.name)} />
                                </FormControl>
                                <div>
                                  <FormLabel className="font-normal">
                                    <Label name={`event_${item}_title`} groupName={labelGroup} />
                                  </FormLabel>
                                </div>
                              </FormItem>
                            );
                          }}
                        />
                      ))}
                    </div>
                    <FormMessage />
                    <FormDescription>
                      <Label name="food_description" groupName={labelGroup} />
                    </FormDescription>
                  </FormItem>
                </div>
                <Separator />
                <div className="space-y-6">
                  <FormLabel className="flex items-center  font-semibold">
                    <Label name="service_title" groupName={labelGroup} />
                    {isServiceApproved && <CheckCircle className="w-4 h-4 ml-2 text-emerald-500" />}
                  </FormLabel>
                  <FormItem>
                    <div className="space-y-6">
                      {SERVICE_OPTIONS.map(item => (
                        <FormField
                          key={item}
                          control={form.control}
                          name={item}
                          render={({ field }) => {
                            return (
                              <FormItem key={item} className="flex flex-row items-start space-x-3 space-y-0">
                                <FormControl>
                                  <Checkbox className="mt-0.5" checked={field.value} onCheckedChange={checked => field.onChange(checked)} />
                                </FormControl>
                                <div>
                                  <FormLabel className="font-normal">
                                    <Label name={`event_${item}_title`} groupName={labelGroup} />
                                  </FormLabel>
                                </div>
                              </FormItem>
                            );
                          }}
                        />
                      ))}
                    </div>
                    <FormMessage />
                  </FormItem>
                </div>
                <Separator />
              </CardContent>

              <div className="flex flex-row items-start space-x-3 space-y-0 px-6 pt-0 pb-6">
                <FormControl>
                  <Checkbox className="mt-0.5" checked={!hasSelected} onCheckedChange={_ => clearSelection()} />
                </FormControl>
                <div>
                  <FormLabel className="font-normal">
                    <Label name="event_is_not_diploma_approved_title" groupName={labelGroup} />
                  </FormLabel>
                </div>
              </div>

              {(hasSelected || isNotEnvironmentDiplomaApproved) && (
                <CardFooter className="font-semisemibold">
                  {isEnvironmentDiplomaApproved ? (
                    <FormDescription className="flex items-center text-emerald-600">
                      <CheckCircle className="w-4 h-4 mr-2 text-emerald-600" />
                      <Label name="environment_diploma_approved_description" groupName={labelGroup} />
                    </FormDescription>
                  ) : (
                    <FormDescription className="flex items-center">
                      <Label name="environment_diploma_not_approved_description" groupName={labelGroup} />
                    </FormDescription>
                  )}
                </CardFooter>
              )}
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </Card>
    </div>
  );
}
