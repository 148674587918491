export const tags = [
  {
    id: '[EventName]',
    description: 'tag_event_name_description',
  },
  {
    id: '[EventId]',
    description: 'tag_event_id_description',
  },
  {
    id: '[EventUrl]',
    description: 'tag_event_url_description',
  },
  {
    id: '[Domain]',
    description: 'tag_domain_description',
  },
  {
    id: '[Token]',
    description: 'tag_token_description',
  },
  {
    id: '[OrganizerName]',
    description: 'tag_organizer_name_description',
  },
  {
    id: '[InviteCode]',
    description: 'tag_invite_code_description',
  },
  {
    id: '[InviteMessage]',
    description: 'tag_invite_message_description',
  },
  {
    id: '[Amount]',
    description: 'tag_amount_description',
  },
  {
    id: '[TransactionId]',
    description: 'tag_transaction_id_description',
  },
  {
    id: '[CardNo]',
    description: 'tag_card_no_description',
  },
  {
    id: '[PhoneNumber]',
    description: 'tag_card_no_description',
  },
  {
    id: '[PayDate]',
    description: 'tag_pay_date_description',
  },
  {
    id: '[OrderList]',
    description: 'tag_order_list_description',
  },
];
