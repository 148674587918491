import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { TimelineConnector, TimelineHeader, TimelineItem, TimelineTime, TimelineTitle } from '@/components/ui/timeline';
import { EventLog } from '@/features/event/types';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { UserType } from '@/features/user';
import { cn } from '@/utils';
import { convertUTCtoLocal } from '@/utils/convertUtcToLocal';
import { formateTime } from '@/utils/formatTime';
import { ClockIcon, Plus } from 'lucide-react';

export function ChangeDraftTimelineItem({ log: { user, inserted } }: { log: EventLog }) {
  const createdAtTime = formateTime(convertUTCtoLocal(inserted));

  return (
    <TimelineItem>
      <TimelineConnector className="bg-gray-medium/20" />
      <TimelineHeader>
        <Plus className={'-translate-x-2 flex items-center rounded-md gap-x-2 bg-pink-500 w-8 h-8 p-2 text-white'} />
        <TimelineTitle>
          <div className="flex gap-x-2 items-center">
            <Avatar className="h-8 w-8 text-sm">
              <AvatarFallback
                className={cn(
                  'font-semibold bg-gray-medium text-white',
                  user?.userType === UserType.ORGANIZER ? 'bg-brand-medium text-brand' : 'bg-brand'
                )}
              >
                {user?.username?.substring(0, 2)?.toUpperCase() ?? 'U'}
              </AvatarFallback>
            </Avatar>
            {user?.username ?? <Label name="deleted_user" groupName={LabelGroup.GLOBAL} />}{' '}
            <Label name="draft_created" groupName={LabelGroup.MANAGER_EVENT_STATUSES} />
          </div>
        </TimelineTitle>
        <TimelineTime className="translate-x-24 md:-translate-x-20 flex items-center gap-x-2">
          <ClockIcon className="w-4 h-4" />
          {createdAtTime}
        </TimelineTime>
      </TimelineHeader>
    </TimelineItem>
  );
}
