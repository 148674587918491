import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
// import { PaymentOrder } from '../types';

const QUERY_KEY = 'transaction';

export const createTransaction = async ({ organizationId }: { organizationId?: number }): Promise<string> => {
  return axios.get(`/payment/${organizationId}`);
};

type QueryFnType = typeof createTransaction;

type UseOptions = {
  organizationId?: number;
  config?: QueryConfig<QueryFnType>;
};

export const useCreateTransaction = ({ organizationId, config }: UseOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: [QUERY_KEY, organizationId],
    queryFn: () => createTransaction({ organizationId }),
  });
};
