import { Table } from '@tanstack/react-table';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';

interface DataTablePaginationProps<TData> {
  table: Table<TData>;
  size: number;
}

export function DataTablePagination<TData>({ size }: DataTablePaginationProps<TData>) {
  return (
    <div className="flex items-center justify-between p-4 px-4">
      <div className="flex-1 text-sm text-muted-foreground">
        {size}{" "}
        <Label name="rows_showing" groupName={LabelGroup.GLOBAL} />{' '}
      </div>
    </div>
  );
}
