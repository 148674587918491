import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { TLocation } from '../types';
import { useToast } from '@/components/ui/use-toast';
import { label } from '@/utils/label';
import { LabelGroup } from '@/features/label/types';

const QUERY_KEY = 'locations';

export type CreateLocationDTO = {
  name: string;
  latitude: string;
  longitude: string;
  category: string;
  active: boolean;
};

export const createLocation = (data: CreateLocationDTO): Promise<TLocation> => {
  return axios.post(`/location`, data);
};

type UseCreateLocationOptions = {
  config?: MutationConfig<typeof createLocation>;
};

export const useCreateLocation = ({ config }: UseCreateLocationOptions) => {
  const { toast } = useToast();

  return useMutation({
    onError: (_, __, ___: any) => {
      toast({
        variant: 'destructive',
        title: label('something_went_wrong', LabelGroup.GLOBAL),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEY]);
      queryClient.refetchQueries([QUERY_KEY]);

      toast({
        variant: 'success',
        title: label('location_created', LabelGroup.ADMIN_LOCATION_EDIT),
      });
    },
    ...config,
    mutationFn: (data: any) => createLocation(data),
  });
};
