import { AlertTriangle } from 'lucide-react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from './ui/alert-dialog';
import { LabelGroup } from '@/features/label/types';
import { Label } from '@/features/label/components/label';

interface DeleteButtonProps {
  onConfirm: () => void;
  ButtonComponent: React.ReactNode;
  TitleComponent: React.ReactNode | string;
  DescriptionComponent: React.ReactNode | string;
}

export function ConfirmButton({ onConfirm, ButtonComponent, TitleComponent, DescriptionComponent }: DeleteButtonProps) {
  return (
    <AlertDialog>
      <AlertDialogTrigger className="text-destructive">{ButtonComponent}</AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle className="flex items-center">
            <AlertTriangle className="w-6 h-6 mr-3" />
            {TitleComponent}
          </AlertDialogTitle>
          <AlertDialogDescription>{DescriptionComponent}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>
            <Label name="cancel_button_text" groupName={LabelGroup.GLOBAL} />
          </AlertDialogCancel>
          <AlertDialogAction className="bg-amber-500" onClick={onConfirm}>
            <Label name="confirm_button_text" groupName={LabelGroup.GLOBAL} />
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
