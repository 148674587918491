import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { AlertTriangle } from 'lucide-react';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { label } from '@/utils/label';
import { scrollToMiddle } from '../utils/scroll-to-middle';

interface FormAnchorErrorListProps {
  errors: {
    message: string;
    id: string;
    section: any
  }[];
}

export function ValidationAnchorList({ errors }: FormAnchorErrorListProps) {
  return (
    <Alert className="bg-amber-50 [&>svg]:text-amber-500 border-amber-200 text-amber-900">
      <AlertTriangle className="h-4 w-4 mt-1 ml-1" />
      <AlertTitle>
        <Label name={'remedy_issues'} groupName={LabelGroup.ORGANIZER_EVENT_FORM} />
      </AlertTitle>
      <AlertDescription className="flex flex-col py-2">
        <ul className="list-disc ">
          {errors.map(validation => (
            <li className="underline cursor-pointer" role="navigation" key={validation.id + validation.message} onClick={() => scrollToMiddle(validation.section)}>
              {label(validation.message, LabelGroup.ORGANIZER_EVENT_FORM)}
              {/* <Label name={validation.message} groupName={LabelGroup.ORGANIZER_EVENT_FORM} /> */}
            </li>
          ))}
        </ul>
      </AlertDescription>
    </Alert>
  );
}
