import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';
import { EventStatus } from '@/features/event/types';
import { AlertTriangle, CalendarX, CheckCircle, ChevronDown, Pen, Send, Trash, X } from 'lucide-react';
import { cn } from '@/utils';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { Wallet } from 'react-bootstrap-icons';
import { eventStatuses } from '@/features/event/data/statuses';

interface EventStatusDropDownProps {
  field: any;
  disabled?: boolean;
  isChange?: boolean;
}

const icons = {
  [EventStatus.Draft]: <Pen className="w-4 h-4 text-slate-600 " />,
  [EventStatus.AwaitingPayment]: <Wallet className="w-4 h-4 text-orange-600 " />,
  [EventStatus.ActionRequired]: <AlertTriangle className="w-4 h-4 text-amber-600 " />,
  [EventStatus.Approved]: <CheckCircle className="w-4 h-4 text-green-600 " />,
  [EventStatus.Rejected]: <X className="w-4 h-4 text-destructive " />,
  // [EventStatus.Review]: <FilePen className="w-4 h-4 text-sky-600 " />,
  [EventStatus.Pending]: <Send className="w-4 h-4 text-sky-600" />,
  // [EventStatus.UnPublished]: <XCircle className="w-4 h-4 text-red-600" />,
  [EventStatus.Cancelled]: <CalendarX className="w-4 h-4 text-red-600" />,
  [EventStatus.Deleted]: <Trash className="w-4 h-4 text-red-600" />,
} as const;

const BASE_STATUSES = [
  EventStatus.Draft,
  EventStatus.AwaitingPayment,
  // EventStatus.Review,
  EventStatus.ActionRequired,
  EventStatus.Pending,
  EventStatus.Approved,
  EventStatus.Rejected,
  EventStatus.Cancelled,
  // EventStatus.UnPublished,
  EventStatus.Deleted,
];

const CHANGE_STATUSES = [EventStatus.Pending, EventStatus.Rejected,  EventStatus.Approved, EventStatus.Deleted];

export function EventStatusDropDown({ field, disabled, isChange }: EventStatusDropDownProps) {

  const statusList = isChange ? CHANGE_STATUSES : BASE_STATUSES;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger disabled={disabled} className="w-full">
        <Button
          variant="outline"
          size="sm"
          type="button"
          className={cn('   w-full flex items-center justify-between ', disabled && 'hover:bg-card cursor-default')}
        >
          <div className="flex items-center gap-x-2 ">
            {icons?.[field.value as keyof typeof icons]}
            <Label
              name={eventStatuses?.[field.value as keyof typeof eventStatuses]}
              groupName={LabelGroup.MANAGER_EVENT_STATUSES}
            />
          </div>
          {!disabled && <ChevronDown className="h-4 w-4" />}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end">
        {statusList?.map((status) => (
          <DropdownMenuItem
            key={status.toString()}
            className={cn('flex items-center gap-x-2', field.value === status && 'bg-muted')}
            onClick={() => field.onChange(status)}
          >
            {icons?.[status as keyof typeof icons]}
            <DropdownMenuLabel>
              <Label
                name={eventStatuses?.[status as keyof typeof eventStatuses]}
                groupName={LabelGroup.MANAGER_EVENT_STATUSES}
              />
            </DropdownMenuLabel>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
