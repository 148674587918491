import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useToast } from '@/components/ui/use-toast';
import { label } from '@/utils/label';
import { LabelGroup } from '@/features/label/types';

const QUERY_KEY = 'organizations';

export const confirmTerms = ({ organizerId }: { organizerId: number }): Promise<void> => {
  return axios.post(`/organizer/confirm/${organizerId}`);
};

type UseConfirmTermsOptions = {
  organizerId: number;
  config?: MutationConfig<typeof confirmTerms>;
};

export const useConfirmTerms = ({ config, organizerId }: UseConfirmTermsOptions) => {
  const { toast } = useToast();

  return useMutation({
    onError: (_, __, ___: any) => {
      toast({
        variant: 'destructive',
        title: label('something_went_wrong', LabelGroup.GLOBAL)
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEY, organizerId]);
      queryClient.refetchQueries([QUERY_KEY, organizerId]);
    },
    ...config,
    mutationFn: (_: any) =>
      confirmTerms({
        organizerId
      })
  });
};
