import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Manager } from '../types';

const QUERY_KEY = 'managers';

export const getManagers = (): Promise<Manager[]> => {
  return axios.get(`/manager`);
};

type QueryFnType = typeof getManagers;

type UseOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useManagers = ({ config }: UseOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: [QUERY_KEY],
    queryFn: getManagers,
    ...config,
  });
};
