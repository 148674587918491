import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';

import { useToast } from '@/components/ui/use-toast';
import { User } from '../types';
import { label } from '@/utils/label';
import { LabelGroup } from '@/features/label/types';

const QUERY_KEY = 'users';

export const deleteEvent = ({ userId }: { userId: number }) => {
  return axios.delete(`/user/${userId}`);
};

type DeleteUserOptions = {
  config?: MutationConfig<typeof deleteEvent>;
};

export const useDeleteUser = ({ config }: DeleteUserOptions) => {
  const { toast } = useToast();

  return useMutation({
    onMutate: async (deletedUser) => {
      await queryClient.cancelQueries([QUERY_KEY, deletedUser.userId]);

      const previousUsers = queryClient.getQueryData<User[]>([QUERY_KEY]);

      queryClient.setQueryData(
        [QUERY_KEY],
        previousUsers?.filter((user) => user?.userId !== deletedUser.userId)
      );

      return { previousUsers };
    },
    onError: (_, __, context: any) => {
      if (context?.previousUsers) {
        queryClient.setQueryData([QUERY_KEY], context.previousUsers);
      }
      toast({
        variant: 'destructive',
        title: label('something_went_wrong', LabelGroup.GLOBAL),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEY]);
      queryClient.refetchQueries([QUERY_KEY]);

      toast({
        variant: 'success',
        title: label('user_deleted_successfully', LabelGroup.ADMIN_USER_LIST),
      });
    },
    ...config,
    mutationFn: ({ userId }) => deleteEvent({ userId }),
  });
};
