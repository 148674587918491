import { EventStatus, TEvent } from '../../types';
import { AlertTriangle } from 'lucide-react';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';

interface CancelledEventAlertProps {
  event: TEvent;
}

export function CancelledEventAlert({ event }: CancelledEventAlertProps) {
  return (
    event?.status === EventStatus.Cancelled && (
      <div className="bg-red-50 space-y-0 flex p-3  border-red-300  border gap-x-2 items-center text-red-900">
        <AlertTriangle className="h-4 w-4   stroke-red-500" />
        <Label name="cancelled" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />
      </div>
    )
  );
}
