import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { EventOrganizer } from '../types';

const QUERY_KEY = 'event_organizers';

export const getEventOrganizers = (): Promise<EventOrganizer[]> => {
  return axios.get(`/event/organizers`);
};

type QueryFnType = typeof getEventOrganizers;

type UseOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useEventOrganizers = ({ config }: UseOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: [QUERY_KEY],
    queryFn: () => getEventOrganizers(),
    ...config,
  });
};
