import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useToast } from '@/components/ui/use-toast';
import { label } from '@/utils/label';
import { LabelGroup } from '@/features/label/types';

const QUERY_KEY = 'organizations';

export const deleteOrganizationUser = ({ organizerUserId }: { organizerUserId: number }) => {
  return axios.delete(`/organizer/user/${organizerUserId}`);
};

type UseDeleteOrganizationUserOptions = {
  config?: MutationConfig<typeof deleteOrganizationUser>;
};

export const useDeleteOrganizationUser = ({ config }: UseDeleteOrganizationUserOptions) => {
  const { toast } = useToast();

  return useMutation({
    onError: (_, __, ___: any) => {
      toast({
        variant: 'destructive',
        title: label('SomethingWentWrong', LabelGroup.GLOBAL),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEY]);
      queryClient.refetchQueries([QUERY_KEY]);

      toast({
        variant: 'success',
        title: label('organization_user_deleted', LabelGroup.ORGANIZER_ORGANIZATION_EDIT),
      });
    },
    ...config,
    mutationFn: ({ organizerUserId }) => deleteOrganizationUser({ organizerUserId }),
  });
};
