import { Button } from '@/components/ui/button';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { useNavigate } from 'react-router-dom';

export function CreateEventDueInfoCard() {
  const navigate = useNavigate();

  return (
    <Card className="max-w-md md:max-w-2xl mx-auto my-8">
      <CardHeader>
        <CardTitle className="text-md flex items-center">
          <Label name="create_event_due_info_title" groupName={LabelGroup.ORGANIZER_EVENT_CREATE} />
        </CardTitle>
      </CardHeader>
      <CardContent className="text-sm pb-2 overflow-hidden space-y-2">
        <Label name="create_event_due_info_body" groupName={LabelGroup.ORGANIZER_EVENT_CREATE} />
      </CardContent>
      <CardFooter>
        <Button onClick={() => navigate(-1)} size="sm" variant="outline" className="mt-4">
          <Label name="go_back" groupName={LabelGroup.ORGANIZER_EVENT_CREATE} />
        </Button>
      </CardFooter>
    </Card>
  );
}
