import { axios } from '@/lib/axios';
import { QueryConfig, queryClient } from '@/lib/react-query';
import { Label } from '../types';

const QUERY_KEY = 'labels';

export const getLabel = async ({ labelId }: { labelId?: number }): Promise<Label> => {
  if (!labelId) {
    return Promise.reject('labelId is required');
  }
  return axios.get(`/label/${labelId}`);
};

type QueryFnType = typeof getLabel;

type UseLabelOptions = {
  labelId?: number;
  config?: QueryConfig<QueryFnType>;
};

export const useLabel = ({ labelId }: UseLabelOptions) => {
  return (
    queryClient.getQueryData<Label[]>([QUERY_KEY])?.find((label: Label) => label.labelId === labelId) ?? {
      labelId: 0,
      labelName: '',
      labelGroup: '',
      value: '',
      languageCode: '',
    }
  );
};
