export const InfoIcon = () => (
  <div className="w-[80px] h-[80px]">
    <svg xmlns="http://www.w3.org/2000/svg" id="info_icon" data-name="Lager 2" viewBox="0 0 110 110">
      <g id="Lager_1-2" data-name="Lager 1">
        <g id="Group_1219" data-name="Group 1219">
          <circle id="Ellipse_187" data-name="Ellipse 187" cx="55" cy="55" r="55" fill="#074785" />
          <g id="i" style={{ isolation: 'isolate' }}>
            <text fill="#fff" fontFamily="'Museo Slab', 'Museo Slab-900'" fontWeight="900" fontSize="80px" transform="translate(41 82)" style={{ isolation: 'isolate' }}>
              <tspan x="0" y="0">
                i
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  </div>
);
