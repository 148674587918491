type PaymentOrder = {
  paymentOrder: any;
  operations: any;
};

type Payment = {
  paymentId: number;
  organizerId: number;
  yearId: number;
  payDate: Date;
  payType: PayType;
  amount: number;
  payDescription: string;
};

type OrderRow = {
  orderRowId: number;
  organizerId: number;
  name: string;
  amount: number;
  vatAmount: number;
  paymentId: number;
  inserted: Date;
  payment: Payment;
  productType: ProductType;
};

enum ProductType {
  Event = 0,
  ProgramYearly = 1,
  ProgramOneTime = 2,
}

enum PayType {
  Card = 0,
  Swish = 1,
  Invoice = 2,
  Other = 3,
}

export { PayType, ProductType };
export type { PaymentOrder, Payment, OrderRow };
