import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { usePaymentConfirmation } from '../api/get-payment-confirmation';
import { useNavigate, useParams } from 'react-router-dom';
import { Separator } from '@/components/ui/separator';
import { convertUTCtoLocal } from '@/utils/convertUtcToLocal';
import { PayType } from '../types';
import { Fragment } from 'react/jsx-runtime';
import { useOrganization } from '@/features/organizer/api/getOrganization';
import { PrintWrapper } from '@/features/misc/components/print-wrapper';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { SwishIcon } from '@/components/swish-icon';
import { Button } from '@/components/ui/button';

export function PaymentConfirmation() {
  const { id } = useParams();

  const navigate = useNavigate();

  const {
    data: confirmation,
    isLoading,
    isFetched
  } = usePaymentConfirmation({
    paymentId: Number(id),
    config: { enabled: !!id }
  });

  const { data: organization } = useOrganization({
    organizerId: confirmation?.payment?.organizerId ?? 0,
    config: { enabled: isFetched && !!confirmation?.payment?.organizerId }
  });

  if (isLoading && confirmation) return <div>Loading...</div>;

  const total = confirmation?.orderRows?.reduce((acc, or) => (acc += or.amount + or.vatAmount), 0);
  const payDate = confirmation?.payment?.payDate && convertUTCtoLocal(confirmation?.payment?.payDate?.toString());
  const vatAmount = confirmation?.orderRows?.reduce((acc, or) => (acc += or.vatAmount), 0);
  // const organizerName = organization?.users?.find(({ mainContact }) => mainContact)?.user?.name;

  const paidWith = (() => {
    switch (confirmation?.payment?.payType) {
      case PayType.Card:
        return 'card';
      case PayType.Invoice:
        return 'invoice';
      case PayType.Swish:
        return 'swish';
      default:
        return '';
    }
  })();

  const goBackPath = (() => {
    if (window.location.hash === '#post') {
      return '/events';
    }

    return '/payments';
  })();

  return (
    <PrintWrapper>
      <Card style={{ overflow: 'hidden' }}>
        <CardHeader style={{ marginBottom: '0.5rem' }}>
          <Button onClick={() => navigate(goBackPath)} className="w-fit mb-2" size="sm" variant="outline" data-no-print>
            <Label name="go_back" groupName={LabelGroup.ORGANIZER_PAYMENT_RECEIPT} />
          </Button>
          <CardTitle style={{ fontWeight: 600, fontSize: '1.875rem', display: 'flex', justifyContent: 'space-between' }}>
            <h2 style={{ fontWeight: 600 }}>
              {confirmation?.payment?.payType === PayType.Card || confirmation?.payment?.payType === PayType.Swish ? <Label name="receipt" groupName={LabelGroup.ORGANIZER_PAYMENT_RECEIPT} /> : <Label name="invoice_basis" groupName={LabelGroup.ORGANIZER_PAYMENT_RECEIPT} />}
            </h2>
          </CardTitle>
          <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '0.875rem' }}>
            <p style={{ fontWeight: 600, color: 'var(--muted-foreground)', display: 'flex', flexDirection: 'column' }}>
              {payDate}
              {/* <span style={{ fontWeight: '600' }}>{organizerName}</span> */}
              <span style={{ fontStyle: 'italic', color: 'var(--muted-foreground)' }}>
                <Label name="payment_id" groupName={LabelGroup.ORGANIZER_PAYMENT_RECEIPT} /> <strong>{confirmation?.payment?.paymentId}</strong>
              </span>
            </p>

            <p style={{ fontWeight: 600, color: 'var(--muted-foreground)', display: 'flex', alignItems: 'center' }}>
              <Label name={`paid_with_${paidWith}`} groupName={LabelGroup.ORGANIZER_PAYMENT_RECEIPT} />
              {confirmation?.payment?.payType === PayType.Swish && <SwishIcon style={{ width: '5rem', height: '5rem', marginRight: '0.5rem' }} />}
            </p>
          </div>
        </CardHeader>
        <Separator />
        <CardContent>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(6, 1fr)',
              color: 'var(--muted-foreground)',
              fontWeight: 600,
              textTransform: 'uppercase',
              fontSize: '0.75rem'
            }}>
            <div style={{ gridColumn: 'span 3', padding: '1rem 0' }}>
              <Label name="event" groupName={LabelGroup.ORGANIZER_PAYMENT_RECEIPT} />
            </div>
            <div style={{ gridColumn: 'span 1', padding: '1rem 0', textAlign: 'right' }}>
              <Label name="quantity" groupName={LabelGroup.ORGANIZER_PAYMENT_RECEIPT} />
            </div>
            <div style={{ gridColumn: 'span 1', padding: '1rem 0', textAlign: 'right' }}>
              <Label name="priceExVat" groupName={LabelGroup.ORGANIZER_PAYMENT_RECEIPT} />
            </div>
            <div style={{ gridColumn: 'span 1', padding: '1rem 0', textAlign: 'right' }}>
              <Label name="priceIncVat" groupName={LabelGroup.ORGANIZER_PAYMENT_RECEIPT} />
            </div>
          </div>
          <Separator />
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', fontWeight: 600, fontSize: '0.875rem' }}>
            {confirmation?.orderRows?.map(or => (
              <Fragment key={or.orderRowId}>
                <div style={{ gridColumn: 'span 3', padding: '1rem 0' }}>{or.name}</div>
                <div style={{ gridColumn: 'span 1', padding: '1rem 0', textAlign: 'right' }}>1</div>
                <div style={{ gridColumn: 'span 1', padding: '1rem 0', textAlign: 'right' }}>{or.amount}</div>
                <div style={{ gridColumn: 'span 1', padding: '1rem 0', textAlign: 'right' }}>{or.amount + or.vatAmount}</div>
              </Fragment>
            ))}
          </div>
        </CardContent>
        <Separator />
        <CardFooter
          style={{
            display: 'grid',
            gridTemplateColumns: window.innerWidth > 768 ? 'repeat(6, 1fr)' : 'repeat(3, 1fr)',
            color: 'var(--muted-foreground)',
            gap: '2rem',
            alignItems: 'flex-start'
          }}>
          <div style={{ gridColumn: 'span 3', padding: '1rem 0' }}>
            {confirmation?.payment.payType === PayType.Invoice && (
              <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', fontSize: '0.875rem' }}>
                <span style={{ textTransform: 'uppercase', fontWeight: 600 }}>
                  <Label name="invoice_details" groupName={LabelGroup.ORGANIZER_PAYMENT_RECEIPT} />
                </span>
                <Separator />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>{organization?.invoiceName}</span>
                  <span>{organization?.invoiceOrgNo}</span>
                  <span>
                    {organization?.invoiceAddress1}, {organization?.invoiceZip}, {organization?.invoiceCity}
                  </span>
                  <span>{organization?.invoiceEmail}</span>
                  <span>{organization?.invoiceRef}</span>
                </div>
              </div>
            )}
          </div>
          <div style={{ textTransform: 'uppercase', fontWeight: 600, fontSize: '0.875rem', gridColumn: 'span 3' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '1rem 0' }}>
              <p style={{ color: 'var(--muted-foreground)', fontSize: '0.75rem' }}>
                <Label name="subtotal" groupName={LabelGroup.ORGANIZER_PAYMENT_RECEIPT} />
              </p>
              <p>{(total ?? 0) - (vatAmount ?? 0)} SEK</p>
            </div>
            <Separator />
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '1rem 0' }}>
              <p style={{ color: 'var(--muted-foreground)', fontSize: '0.75rem' }}>
                <Label name="vat" groupName={LabelGroup.ORGANIZER_PAYMENT_RECEIPT} />
              </p>
              <p>{vatAmount} SEK</p>
            </div>
            <Separator />
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1rem 0' }}>
              <p style={{ color: 'var(--muted-foreground)' }}>
                <Label name="total" groupName={LabelGroup.ORGANIZER_PAYMENT_RECEIPT} />
              </p>
              <p style={{ fontSize: '1.125rem', fontWeight: 700, color: 'var(--primary)' }}>{total} SEK</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1rem 0' }}>
              <p style={{ color: 'var(--muted-foreground)', fontWeight: 500 }}>
                <Label name="almedalen_payment_signature" groupName={LabelGroup.ORGANIZER_PAYMENT_RECEIPT} />
              </p>
            </div>
          </div>
        </CardFooter>
        {confirmation?.payment?.payType !== PayType.Invoice && (
          <>
            <Separator />
            <div
              style={{
                padding: '1rem 1.5rem'
              }}>
              <p style={{ color: 'var(--muted-foreground)', fontSize: '0.875rem' }}>
                <Label name="almedalen_payment_description" groupName={LabelGroup.ORGANIZER_PAYMENT_RECEIPT} />
              </p>
            </div>
          </>
        )}
      </Card>
    </PrintWrapper>
  );
}
