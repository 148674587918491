import { Alert, AlertTitle } from '@/components/ui/alert';
import { EventStatus, TEvent } from '../../types';
import { Clock } from 'lucide-react';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { formatDate } from '@/utils/formatDate';

interface ReturnDueProps {
  event: TEvent;
}

export function ReturnDue({ event }: ReturnDueProps) {


  if (!event?.returnDate || !event?.returnDue || !(event?.status === EventStatus.ActionRequired || event?.status === EventStatus.Rejected || event?.status === EventStatus.Draft) || event.noCost) {
    return null;
  }

  const adjustedDate = new Date(new Date(event.returnDate).getTime() + (event.returnDue ?? 0) * 24 * 60 * 60 * 1000);

  const formattedDate = formatDate(adjustedDate.toISOString());
  const result = formattedDate.substring(0, 11);

  return (
    <Alert className="bg-amber-50 text-amber-900  border border-amber-300 rounded-none">
      <Clock className="h-4 w-4 mt-1  stroke-amber-600" />
      <AlertTitle>
        <Label name="return_due" groupName={LabelGroup.ORGANIZER_EVENT_FORM} /> {result}
      </AlertTitle>
    </Alert>
  );
}
