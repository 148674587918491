import { Organization } from '@/features/organizer/types';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useSendInvitation } from '../api/sendInvitation';
import { Avatar } from '@radix-ui/react-avatar';
import { AvatarFallback } from '@/components/ui/avatar';
import { DeleteButton } from '@/components/delete-button';
import { Redo2, Trash2, X } from 'lucide-react';
import { Separator } from '@/components/ui/separator';
import { useRevokeInvitation } from '../api/revokeInvitation';
import { useDeleteInvitation } from '../api/deleteInvitation';
import { useInvitations } from '../api/getInvitations';
import { InvitationStatus } from './InvitationStatus';
import { useDeleteOrganizationUser } from '@/features/organizer/api/deleteOrganizationUser';
import { Spinner } from '@/components/ui/spinner';
import { useUpdateMainContact } from '../../organizer/api/update-main-contact';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { organizationRoles } from '@/features/organizer/data/data';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { useMe } from '@/features/auth/api/get-me';
import { UserType } from '@/features/user';

interface InvitationFormProps {
  organization: Organization;
}

const formSchema = z.object({
  recipientEmail: z.string().email({ message: 'invalid_email_validation_text' }),
  messageText: z.string().optional(),
});

export function OrganizationUsers({ organization }: InvitationFormProps) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    values: {
      recipientEmail: '',
      messageText: '',
    },
  });

  const organizationId = organization?.organizerId;

  const { mutateAsync: sendInvitation, isLoading: isSending } = useSendInvitation({
    organizationId: organization.organizerId,
  });

  const { mutateAsync: revokeInvitation } = useRevokeInvitation({
    organizationId,
  });

  const { mutateAsync: deleteOrganizer } = useDeleteOrganizationUser({});
  const { mutateAsync: deleteInvitation } = useDeleteInvitation({});
  const { mutateAsync: updateMainContact } = useUpdateMainContact({});

  const { data: invitations } = useInvitations({
    organizationId,
    config: {
      enabled: !!organizationId,
    },
  });

  async function handleSubmit(values: z.infer<typeof formSchema>) {
    try {
      await sendInvitation({ ...values });
      form.reset();
    } catch (error) {}
  }

  const pendingInvitations = invitations?.filter((i) => !i.used) ?? [];

  const { data: user } = useMe({});

  const userId = user?.userId;
  const isAdmin = user?.role === UserType.MANAGER || user?.role === UserType.ADMIN;

  const isOwner =
    isAdmin ||
    organization?.users?.some(({ userId: _userId, mainContact }) => userId === _userId && mainContact) ||
    organization?.users?.length === 1;

  return (
    <div className="space-y-4">
      <Card className="p-0">
        <CardHeader>
          <CardTitle>
            <Label name="organizers" groupName={LabelGroup.ORGANIZER_ORGANIZATION_EDIT} />
          </CardTitle>
          <CardDescription>
            <Label name="invite_users_description" groupName={LabelGroup.ORGANIZER_ORGANIZATION_EDIT} />
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Form {...form}>
            <form
              onKeyDown={(event) => event.key === 'Enter' && event.preventDefault()}
              onSubmit={form.handleSubmit(handleSubmit)}
              className="flex flex-col md:flex-row items-center gap-4 md:gap-2 "
            >
              <FormField
                control={form.control}
                name="recipientEmail"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormControl>
                      <Input placeholder="example@gmail.com" type="email" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button type="submit" size="sm" className="md:w-fit w-full" disabled={!form.formState.isDirty}>
                {isSending && <Spinner className="mr-2 text-muted-foreground h-4 w-4" />}
                <Label
                  name={isSending ? 'sending' : 'send_invitation_button_text'}
                  groupName={LabelGroup.ORGANIZER_ORGANIZATION_EDIT}
                />
              </Button>
            </form>
          </Form>
        </CardContent>
        <Separator />
        <div className="w-full divide-y divide-input">
          {organization?.users?.map(({ user, organizerUserId, mainContact }) => (
            <div className="flex w-full justify-between h-16 px-6">
              <div className="flex space-x-2 items-center">
                <Avatar key={user.userId} className="size-8 w-8 font-semibold text-xs">
                  <AvatarFallback className="bg-purple-500 text-white border-card border-2 ">
                    {user.username?.[0].toUpperCase()}
                  </AvatarFallback>
                </Avatar>
                <span className="text-sm font-semibold">{user.username}</span>
              </div>
              {isOwner && organization.users?.length > 1 && (
                <div className="flex items-center gap-x-2">
                  <div>
                    <Select
                      value={mainContact.toString()}
                      disabled={mainContact}
                      onValueChange={() =>
                        updateMainContact({
                          organizerUserId,
                        })
                      }
                    >
                      <SelectTrigger className="w-64">
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent>
                        {organizationRoles.map(({ title, value }) => (
                          <SelectItem key={title.toString()} value={value.toString()}>
                            <Label name={'org_role_' + title} groupName={LabelGroup.ORGANIZER_ORGANIZATION_EDIT} />
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                  <DeleteButton
                    onDelete={() => deleteOrganizer({ organizerUserId })}
                    TitleComponent={
                      <Label
                        name="delete_organizer_confirmation_title"
                        groupName={LabelGroup.ORGANIZER_ORGANIZATION_LIST}
                      />
                    }
                    DescriptionComponent={
                      <Label
                        name="delete_organizer_confirmation_description"
                        groupName={LabelGroup.ORGANIZER_ORGANIZATION_LIST}
                      />
                    }
                    ButtonComponent={
                      <Button variant="ghost" className="w-fit">
                        <Trash2 className="w-4" />
                      </Button>
                    }
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </Card>
      {pendingInvitations?.length > 0 && (
        <Card>
          <CardHeader>
            <CardTitle>
              <Label name="pending_invitations" groupName={LabelGroup.ORGANIZER_ORGANIZATION_EDIT} />
            </CardTitle>
          </CardHeader>
          <Separator />
          <div className="w-full divide-y divide-input">
            {pendingInvitations?.map((invitation) => (
              <div
                key={invitation.invitationId}
                className="flex  flex-col md:flex-row justify-between items-start md:items-center gap-4 h-fit md:h-16  p-6 md:px-6"
              >
                <div className="flex flex-col md:flex-row gap-2 items-start md:items-center">
                  <div className="flex  space-x-2 justify-start  items-center">
                    <Avatar className="size-8 w-8 font-semibold text-xs">
                      <AvatarFallback className="bg-zinc-100  text-zinc-500 border-input border-2 border-dotted ">
                        ?
                      </AvatarFallback>
                    </Avatar>
                    <span className="font-semibold text-sm">{invitation.recipient}</span>
                  </div>
                  <InvitationStatus {...invitation} />
                </div>
                <div className="flex gap-x-2">
                  {!invitation.used && !invitation.revoked && (
                    <>
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() =>
                          sendInvitation({
                            recipientEmail: invitation.recipient,
                            resend: true,
                            invitationId: invitation.invitationId,
                          })
                        }
                      >
                        <Redo2 className="h-4 w-4 mr-1" />
                        <Label
                          name="resend_invitation_button_text"
                          groupName={LabelGroup.ORGANIZER_ORGANIZATION_EDIT}
                        />
                      </Button>
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() =>
                          revokeInvitation({
                            invitationId: invitation.invitationId,
                          })
                        }
                      >
                        <X className="h-4 w-4 mr-1" />
                        <Label
                          name="revoke_invitation_button_text"
                          groupName={LabelGroup.ORGANIZER_ORGANIZATION_EDIT}
                        />
                      </Button>
                    </>
                  )}
                  {invitation.revoked && (
                    <DeleteButton
                      onDelete={() => deleteInvitation({ invitationId: invitation.invitationId })}
                      TitleComponent={
                        <Label
                          name="delete_invitation_confirmation_title"
                          groupName={LabelGroup.ORGANIZER_ORGANIZATION_EDIT}
                        />
                      }
                      DescriptionComponent={
                        <Label
                          name="delete_invitation_confirmation_description"
                          groupName={LabelGroup.ORGANIZER_ORGANIZATION_EDIT}
                        />
                      }
                      ButtonComponent={
                        <Button variant="outline" size="sm">
                          <Trash2 className="h-4 w-4 mr-1" />
                          <Label
                            name="delete_invitation_button_text"
                            groupName={LabelGroup.ORGANIZER_ORGANIZATION_EDIT}
                          />
                        </Button>
                      }
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </Card>
      )}
    </div>
  );
}
