import { Button } from '@/components/ui/button';
import { Card, CardContent, CardFooter, CardHeader, CardTitle, CardDescription } from '@/components/ui/card';
import { Label } from '@/features/label/components/label';
import { useState } from 'react';
import { useYears } from '../api/get-years';
import { LabelGroup } from '@/features/label/types';
import { Checkbox } from '@/components/ui/checkbox';
import { useUpdateYear } from '../api/update-year';
import { Separator } from '@/components/ui/separator';
import { Spinner } from '@/components/ui/spinner';
import { ConfirmButton } from '@/components/confirm-button';
import { label } from '@/utils/label';

export function YearSettings() {
  const [activeYear, setActiveYear] = useState<number | null>(null);

  const { data: years = [] } = useYears({
    config: {
      onSuccess: (data) => {
        

        if (!activeYear && data?.find(({ active }) => active)) {
          setActiveYear(data?.find(({ active }) => active)?.yearId as number);
        }
      },
    },
  });

  const { mutateAsync: updateYear, isLoading: isUpdating } = useUpdateYear({});

  const hasChanged = activeYear !== years.find(({ active }) => active)?.yearId;

  return (
    <>
      <Card className="w-[600px]">
        <CardHeader>
          <CardTitle>
            <Label name="year_settings_title" groupName={LabelGroup.ADMIN_SETTINGS} />
          </CardTitle>
          <CardDescription>
            <Label name="year_settings_description" groupName={LabelGroup.ADMIN_SETTINGS} />
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-2">
          <div className="space-y-4 pb-4">
            {years.map(({ yearId }) => (
              <div className="flex items-center space-x-2">
                <Checkbox
                  id={yearId.toString()}
                  checked={activeYear === yearId}
                  disabled={activeYear === yearId}
                  onCheckedChange={(checked) => setActiveYear(checked ? yearId : null)}
                />
                <label
                  htmlFor={yearId.toString()}
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  {yearId}
                </label>
              </div>
            ))}
          </div>
          <Separator />
          <CardDescription className="italic text-muted-foreground">
            <Label name="year_settings_caption" groupName={LabelGroup.ADMIN_SETTINGS} />
          </CardDescription>
        </CardContent>

        <CardFooter>
          <ConfirmButton
            onConfirm={() => updateYear(activeYear as number)}
            TitleComponent={<Label name="confirm_year_update_title" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />}
            DescriptionComponent={
              <div
                dangerouslySetInnerHTML={{
                  __html: label('confirm_year_update_description', LabelGroup.ORGANIZER_EVENT_FORM)
                    ?.replace(
                      '[prevYear]',
                      '<span class="font-bold text-primary">' +
                        (years.find(({ active }) => active)?.yearId?.toString() || '') +
                        '</span>'
                    )
                    ?.replace(
                      '[newYear]',
                      '<span class="font-bold text-primary">' + (activeYear?.toString() || '') + '</span>'
                    ) as string,
                }}
              />
            }
            ButtonComponent={
              <Button type="button" disabled={!hasChanged || isUpdating}>
                {isUpdating && <Spinner className="mr-2 text-white h-4 w-4" />}
                <Label name="confirm_year_update_button_text" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />
              </Button>
            }
          />
        </CardFooter>
      </Card>
    </>
  );
}
