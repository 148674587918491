import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { OrderRow } from '../types';

const QUERY_KEY = 'payments';

export const getPayments = (): Promise<OrderRow[]> => {
  return axios.get(`/payment/all`);
};

type QueryFnType = typeof getPayments;

type UseOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useAllPayments = ({ config }: UseOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: [QUERY_KEY],
    queryFn: () => getPayments(),
    ...config,
  });
};
