import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { Card, CardContent } from '@/components/ui/card';
import {
  TimelineConnector,
  TimelineContent,
  TimelineDescription,
  TimelineHeader,
  TimelineItem,
  TimelineTime,
  TimelineTitle,
} from '@/components/ui/timeline';
import { EventLog, LogComment } from '@/features/event/types';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { UserType } from '@/features/user';
import { cn } from '@/utils';
import { convertPascalToCamel } from '@/utils/convert-pascal-to-camel-case';
import { convertUTCtoLocal } from '@/utils/convertUtcToLocal';
import { formateTime } from '@/utils/formatTime';
import { ClockIcon } from 'lucide-react';
import { ChatSquareText } from 'react-bootstrap-icons';

export function CommentTimelineItem({ log: { user, logText, inserted , category} }: { log: EventLog }) {
  const data = convertPascalToCamel<LogComment>(JSON.parse(logText ?? '{}'));

  const createdAtTime = formateTime(convertUTCtoLocal(inserted));

  return (
    <TimelineItem>
      <TimelineConnector className="bg-gray-medium/20" />
      <TimelineHeader>
        <ChatSquareText
          className={'-translate-x-2 flex items-center rounded-md gap-x-2 bg-red-500 w-8 h-8 p-2 text-white'}
        />
        <TimelineTitle>
          <div className="flex gap-x-2 items-center">
            <Avatar className="h-8 w-8 text-sm">
              <AvatarFallback
                className={cn(
                  'font-semibold bg-gray-medium text-white',
                  user?.role === UserType.ORGANIZER ? 'bg-brand-medium' : 'bg-brand'
                )}
              >
                {user?.username?.substring(0, 2)?.toUpperCase() ?? 'U'}
              </AvatarFallback>
            </Avatar>
            {user?.username ?? <Label name="deleted_user" groupName={LabelGroup.GLOBAL} />}{' '}
            {category?.startsWith('Draft') && (
              <span className="text-xs bg-sky-500 text-white p-1 rounded-md">
                <Label name="draft_version" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
              </span>
            )}
            <Label name={'commented_on_field_' + data.field} groupName={LabelGroup.MANAGER_EVENT_HANDLING} />{' '}
          </div>
        </TimelineTitle>
        <TimelineTime className="translate-x-24 md:-translate-x-20 flex items-center gap-x-2">
          <ClockIcon className="w-4 h-4" />
          {createdAtTime}
        </TimelineTime>
      </TimelineHeader>
      <TimelineContent className="ml-8">
        <TimelineDescription>
          <Card>
            <CardContent className="p-4 italic">{data.text}</CardContent>
          </Card>
        </TimelineDescription>
      </TimelineContent>
    </TimelineItem>
  );
}
