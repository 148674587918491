import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Organization } from '../types';

const QUERY_KEY = 'organizations';

export const getOrganization = (organizerId: number): Promise<Organization> => {
  return axios.get(`/organizer/${organizerId}`);
};

type QueryFnType = typeof getOrganization;

type UseOrganizationOptions = {
  organizerId: number;
  config?: QueryConfig<QueryFnType>;
};

export const useOrganization = ({ config, organizerId }: UseOrganizationOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: [QUERY_KEY, organizerId],
    queryFn: () => getOrganization(organizerId),
    ...config,
  });
};
