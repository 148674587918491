import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { label } from '@/utils/label';
import { Input } from '@/components/ui/input';
import { Checkbox } from '@/components/ui/checkbox';
import { Separator } from '@/components/ui/separator';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { Asterisk } from '@/components/ui/asterisk';
import { UserType } from '@/features/user';
import { LabelGroup } from '@/features/label/types';
import { Label } from '@/features/label/components/label';
import { useFormContext } from 'react-hook-form';
import { useMe } from '@/features/auth/api/get-me';

export function ContactSection({ defaultCollapsed = false }: { defaultCollapsed?: boolean }) {
  const { data: user } = useMe({});
  const userType = user?.role;

  const form = useFormContext();

  const labelGroup = userType === UserType.ADMIN || userType === UserType.MANAGER ? LabelGroup.MANAGER_EVENT_HANDLING : LabelGroup.ORGANIZER_EVENT_FORM;

  return (
    <div className="space-y-6">
      <Card>
        <Accordion type="single" collapsible defaultValue={defaultCollapsed ? '' : 'contact'}>
          <AccordionItem className="[&[data-state=open]>.card-header]:bg-gray-medium [&[data-state=open]>.card-header]:text-white [&[data-state=open]>.card-header]:rounded-t-md" value="contact">
            <CardHeader className="py-3">
              <AccordionTrigger className="text-md py-0">
                <CardTitle>
                  <Label name={'contact_and_home_title'} groupName={labelGroup} />
                  <Asterisk className="ml-2 asterisk" />
                </CardTitle>
              </AccordionTrigger>
            </CardHeader>
            <AccordionContent>
              <CardContent className="pt-6 text-sm space-y-6">
                <CardDescription>
                  <Label name={'contact_and_home_subtitle'} groupName={labelGroup} />
                </CardDescription>
                <div className="w-full grid  gap-4">
                  <div className="space-y-6">
                    <h4>
                      <Label name={'contact_person1'} groupName={labelGroup} />
                    </h4>
                    <FormField
                      control={form.control}
                      name="contactPerson1Name"
                      render={({ field }) => (
                        <FormItem id="contact_person1_name">
                          <FormLabel className="font-semibold">
                            <Label name={'event_contact_person1Name'} groupName={labelGroup} />
                            <Asterisk className="ml-2" />
                          </FormLabel>
                          <FormControl>
                            <Input {...field} className="w-full" maxLength={100} />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="contactPerson1Title"
                      render={({ field }) => (
                        <FormItem id="contact_person1_title">
                          <FormLabel className="font-semibold">
                            <Label name={'event_contact_person1Title'} groupName={labelGroup} />
                          </FormLabel>
                          <FormControl>
                            <Input {...field} className="w-full" maxLength={100} />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="contactPerson1Organization"
                      render={({ field }) => (
                        <FormItem id="contact_person1_organization">
                          <FormLabel className="font-semibold">
                            <Label name={'event_contact_person1_organization'} groupName={labelGroup} />
                            <Asterisk className="ml-2" />
                          </FormLabel>
                          <FormControl>
                            <Input {...field} className="w-full" maxLength={100} />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="contactPerson1Telephone"
                      render={({ field }) => (
                        <FormItem id="contact_person1_telephone">
                          <FormLabel className="font-semibold">
                            <Label name={'event_contact_person1_telephone'} groupName={labelGroup} />
                            <Asterisk className="ml-2" />
                          </FormLabel>
                          <FormControl>
                            <Input {...field} placeholder={label('contact_person1_telephone_placeholder', labelGroup)} className="w-full" maxLength={100} />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="contactPerson1Email"
                      render={({ field }) => (
                        <FormItem id="contact_person1_email">
                          <FormLabel className="font-semibold">
                            <Label name={'event_contact_person1_email'} groupName={labelGroup} />
                            <Asterisk className="ml-2" />
                          </FormLabel>
                          <FormControl>
                            <Input {...field} className="w-full" maxLength={100} />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="space-y-6">
                    <h4>
                      <Label name={'contact_person2'} groupName={labelGroup} />
                    </h4>
                    <FormField
                      control={form.control}
                      name="contactPerson2Name"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel className="font-semibold">
                            <Label name={'event_contact_person2Name'} groupName={labelGroup} />
                          </FormLabel>
                          <FormControl>
                            <Input autoComplete="contactPerson2" {...field} className="w-full" maxLength={100} />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="contactPerson2Title"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel className="font-semibold">
                            <Label name={'event_contact_person2Title'} groupName={labelGroup} />
                          </FormLabel>
                          <FormControl>
                            <Input {...field} className="w-full" maxLength={100} />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="contactPerson2Organization"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel className="font-semibold">
                            <Label name={'event_contact_person2_organization'} groupName={labelGroup} />
                          </FormLabel>
                          <FormControl>
                            <Input {...field} className="w-full" maxLength={100} />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="contactPerson2Telephone"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel className="font-semibold">
                            <Label name={'event_contact_person2_telephone'} groupName={labelGroup} />
                          </FormLabel>
                          <FormControl>
                            <Input {...field} placeholder={label('contact_person2_telephone_placeholder', labelGroup)} className="w-full" maxLength={100} />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="contactPerson2Email"
                      render={({ field }) => (
                        <FormItem id="contact_person1_email">
                          <FormLabel className="font-semibold">
                            <Label name={'event_contact_person2_email'} groupName={labelGroup} />
                          </FormLabel>
                          <FormControl>
                            <Input {...field} className="w-full" maxLength={100} />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
                <Separator />
                <FormItem className="max-w-lg place-items-center justify-items-start ">
                  <div className="flex space-x-6">
                    <FormField
                      control={form.control}
                      name="showEmail"
                      render={({ field }) => {
                        return (
                          <FormItem className="flex flex-row items-center space-x-3 space-y-0">
                            <FormControl>
                              <Checkbox checked={field.value} onCheckedChange={() => field.onChange(!field.value)} />
                            </FormControl>
                            <FormLabel className="font-normal">
                              <Label name={'event_show_email'} groupName={labelGroup} />
                            </FormLabel>
                          </FormItem>
                        );
                      }}
                    />
                  </div>
                  <FormMessage />
                </FormItem>

                <FormItem className="max-w-lg place-items-center justify-items-start ">
                  <div className="flex space-x-6">
                    <FormField
                      control={form.control}
                      name="showTelephone"
                      render={({ field }) => {
                        return (
                          <FormItem className="flex flex-row items-center space-x-3 space-y-0">
                            <FormControl>
                              <Checkbox checked={field.value} onCheckedChange={() => field.onChange(!field.value)} />
                            </FormControl>
                            <FormLabel className="font-normal">
                              <Label name={'event_show_telephone'} groupName={labelGroup} />
                            </FormLabel>
                          </FormItem>
                        );
                      }}
                    />
                  </div>
                  <FormMessage />
                </FormItem>
              </CardContent>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </Card>
    </div>
  );
}
