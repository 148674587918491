import { passwordSchema } from '@/features/auth/data/password-schema';
import { z } from 'zod';

const changePasswordSchema = z
  .object({
    oldPassword: passwordSchema,
    newPassword: passwordSchema,
    confirmNewPassword: passwordSchema
  })
  .refine(data => data.newPassword === data.confirmNewPassword, {
    message: 'password_does_not_match',
    path: ['confirmNewPassword']
  });

export { changePasswordSchema };
