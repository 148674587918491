import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Label } from '../types';

const QUERY_KEY = 'labels';

export const getLabels = ({ languageCode = 'swe' }: { languageCode?: string }): Promise<Label[]> => {
  return axios.get(`/label/group?languageCode=${languageCode}`);
};

type QueryFnType = typeof getLabels;

type UseLabelOptions = {
  languageCode?: string;
  config?: QueryConfig<QueryFnType>;
};

export const useLabels = ({ config, languageCode = 'swe' }: UseLabelOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: [QUERY_KEY],
    queryFn: () =>
      getLabels({
        languageCode
      }),

    ...config
  });
};
