import { label } from '@/utils/label';
import { LocationCategory } from '../types';
import { LabelGroup } from '@/features/label/types';
import { Label } from '@/features/label/components/label';

export const activities = [
  {
    value: true,
    label: label('location_active', LabelGroup.ADMIN_LOCATION_EDIT),
  },
  {
    value: false,
    label: label('location_inactive', LabelGroup.ADMIN_LOCATION_EDIT),
  },
];

export const categories = [
  {
    value: '',
    label: <Label name={LocationCategory.LOKALER_TRADGARDAR_FARTYG} groupName={LabelGroup.ADMIN_LOCATION_EDIT} />,
  },
  {
    value: LocationCategory.TALARPLATS,
    label: <Label name="speaker_place" groupName={LabelGroup.ADMIN_LOCATION_EDIT} />,
  },
  {
    value: LocationCategory.MARKPLATSER,
    label: <Label name={LocationCategory.MARKPLATSER} groupName={LabelGroup.ADMIN_LOCATION_EDIT} />,
  },
];
