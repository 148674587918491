import { RouteObject } from 'react-router-dom';
import { Dashboard } from '@/features/admin/routes/dashboard';
import { Layout } from '@/features/admin/components/Layout';
import { ManagerEvents } from '@/features/admin/routes/manager-events';
import { ManageEvent } from '@/features/admin/routes/manage-event';
import { Profile } from '@/features/user/routes/profile';
import { Users } from '@/features/user/routes/users';
import { User } from '@/features/user/routes/user';
import { CreateUser } from '@/features/user/routes/create-user';
import { Labels } from '@/features/label/routes/Labels';
import { CreateLabel } from '@/features/label/routes/CreateLabel';
import { EditLabel } from '@/features/label/routes/edit-label';
import { Locations } from '@/features/location/routes/Locations';
import { EditLocation } from '@/features/location/routes/EditLocation';
import { Settings } from '@/features/settings/routes/settings';
import { Organizers } from '@/features/admin/routes/organizers';
import { OrganizerDetails } from '@/features/admin/routes/organizer-details';
import { ExportInvoiceFile } from '@/features/invoice/routes/export-invoice-file';
import { Payments } from '@/features/admin/routes/payments';
import { ArchivedEventsRoute } from '@/features/archive/routes/archived-events';
import { CreateLocation } from '@/features/location/routes/create-location';

export const adminRoutes: RouteObject[] = [
  {
    path: '/',
    element: <Layout />,
    children: [
      { path: '/', element: <Dashboard /> },
      { path: '/dashboard', element: <Dashboard /> },

      { path: '*', element: <Dashboard /> },

      { path: '/events', element: <ManagerEvents /> },
      { path: '/events/manage/:id', element: <ManageEvent /> },
      // { path: '/events/preview/:id', element: <ManageEvent />}

      { path: '/users', element: <Users /> },
      { path: '/users/:id', element: <User /> },
      { path: '/users/create', element: <CreateUser /> },

      { path: '/labels', element: <Labels /> },
      { path: '/labels/create', element: <CreateLabel /> },
      { path: '/labels/edit/:id', element: <EditLabel /> },

      { path: '/organizations', element: <Organizers /> },
      { path: '/organizations/:id', element: <OrganizerDetails /> },
      { path: '/profile', element: <Profile /> },

      { path: '/invoice-export', element: <ExportInvoiceFile /> },

      { path: '/settings', element: <Settings /> },

      { path: '/payments', element: <Payments /> },
      {
        path: '/archive/events',
        element: <ArchivedEventsRoute />
      },

      { path: '/locations', element: <Locations /> },
      { path: '/locations/edit/:id', element: <EditLocation /> },
      { path: '/locations/create', element: <CreateLocation /> }
    ]
  }
];
