import { ExcelIcon } from '@/components/excel-icon';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { getExcelExportByFilters } from '@/features/statistic/api/get-excel-export-by-filters';
import { ExportFilters } from '@/features/statistic/types';
import { useState } from 'react';

export function ExportByFilterDialog() {
  const [filters, setFilters] = useState<ExportFilters>({
    eventId: true,
    inserted: true,
    updated: true,
    category: true,
    returnDate: true,
    title: true,
    day: true,
    status: true,
    startTime: true,
    endTime: true,
    eventType: true,
    organizationType: true,
    topic: true,
    topic2: true,
    location: true,
    lat: true,
    long: true,
    locationDescription: true,
    accessability: true,
    languages: true,
    socialIssue: true,
    description: true,
    persons: true,
    organizers: true,
    contactPerson1Name: true,
    contactPerson1Title: true,
    contactPerson1Org: true,
    contactPerson1Phone: true,
    contactPerson1Email: true,
    contactPerson2Name: true,
    contactPerson2Title: true,
    contactPerson2Org: true,
    contactPerson2Phone: true,
    contactPerson2Email: true,
    urls: true,
    facebookUrl: true,
    xUrl: true,
    linkedInUrl: true,
    digitalStream: true,
    eventLink: true,
    noFood: true,
    certified: true,
    streamService: true,
    digitalStreamUrl: true,
    digitalMeetingUrl: true,
    digitalMeetingDescription: true,
    digitalArchiveUrl: true,
    environment: true
  });

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline">
          <ExcelIcon />
          <Label name="export_by_filters" groupName={LabelGroup.MANAGER_DASHBOARD} />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>
            <Label name="export_by_filters_title" groupName={LabelGroup.MANAGER_DASHBOARD} />
          </DialogTitle>
          <DialogDescription>
            <Label name="export_by_filters_description" groupName={LabelGroup.MANAGER_DASHBOARD} />
          </DialogDescription>
        </DialogHeader>
        <ScrollArea className="max-h-96 overflow-y-auto">
          <div className="grid gap-4 py-4 ">
            {Object.entries(filters).map(([key, value]) => (
              <div className="flex items-center space-x-2">
                <Checkbox id={key} checked={value} onCheckedChange={checked => setFilters(prev => ({ ...prev, [key]: checked }))} />
                <label htmlFor={key} className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                  <Label name={'filter_' + key} groupName={LabelGroup.MANAGER_DASHBOARD} />
                </label>
              </div>
            ))}
          </div>
        </ScrollArea>
        <DialogFooter>
          <Button variant="outline" onClick={() => getExcelExportByFilters(filters)}>
            <ExcelIcon />
            <Label name="export_by_filters" groupName={LabelGroup.MANAGER_DASHBOARD} />
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
