import { ColumnDef } from '@tanstack/react-table';

import { DataTableColumnHeader } from '@/components/ui/data-table-column-header';
import { OrderRow, ProductType } from '../types';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { formatDate } from '@/utils/formatDate';
import { PaymentIcon } from './payment-icon';
import { Link } from 'react-router-dom';

export const paymentColumns: ColumnDef<OrderRow & { organizerName?: string }>[] = [
  {
    accessorKey: 'name',
    header: ({ column }) => <DataTableColumnHeader column={column} TitleComponent={<Label name="EventId" groupName={LabelGroup.ADMIN_PAYMENT} />} />,
    filterFn: (row, id, value) => {
      return value.toString().includes(row.getValue(id)?.toString());
    },
    cell: ({ row }) => {
      return (
        <span className="font-bold">
          {row.original?.productType !== ProductType.ProgramOneTime && row.original?.productType !== ProductType.ProgramYearly ? (
            row.original.name?.substring(0, row.original.name?.indexOf('-'))
          ) : (
            <Label name={Object.keys(ProductType).find(key => ProductType[key as keyof typeof ProductType] === row.original.productType) ?? ''} groupName={LabelGroup.ADMIN_PAYMENT} />
          )}
        </span>
      );
    }
  },
  {
    accessorKey: 'organizerName',

    header: ({ column }) => <DataTableColumnHeader column={column} TitleComponent={<Label name="OrganizerName" groupName={LabelGroup.ADMIN_PAYMENT} />} />,
    filterFn: (row, id, value) => {
      return value.toString().includes(row.getValue(id)?.toString());
    },
    cell: ({ row }) => {
      return (
        <Link className="underline" to={`/organizations/${row.original.payment.organizerId}`}>
          {row.original?.organizerName}
        </Link>
      );
    }
  },
  {
    accessorKey: 'payment.payDate',

    header: ({ column }) => <DataTableColumnHeader column={column} TitleComponent={<Label name="PayDate" groupName={LabelGroup.ADMIN_PAYMENT} />} />,
    filterFn: (row, id, value) => {
      return value.toString().includes(row.getValue(id)?.toString());
    },
    cell: ({ row }) => {
      return formatDate(row.original.payment.payDate?.toString());
    }
  },
  {
    accessorKey: 'amount',
    header: ({ column }) => <DataTableColumnHeader column={column} TitleComponent={<Label name="Amount" groupName={LabelGroup.ADMIN_PAYMENT} />} />,
    filterFn: (row, id, value) => {
      return value.toString().includes(row.getValue(id)?.toString());
    }
  },
  {
    accessorKey: 'productType',
    header: ({ column }) => <DataTableColumnHeader column={column} TitleComponent={<Label name="ProductType" groupName={LabelGroup.ADMIN_PAYMENT} />} />,
    filterFn: (row, id, value) => {
      return value.toString().includes(row.getValue(id)?.toString());
    },
    enableHiding: true,
  },
  {
    accessorKey: 'payment.payType',
    header: ({ column }) => <DataTableColumnHeader column={column} TitleComponent={<Label name="PayType" groupName={LabelGroup.ADMIN_PAYMENT} />} />,
    filterFn: (row, id, value) => {
      return value.toString().includes(row.getValue(id)?.toString());
    },
    cell: ({ row }) => <div className="w-fit min-h-8">{row?.original.amount !== 0 && <PaymentIcon payType={row.original.payment?.payType} />}</div>
  }
];
