import { DataTableFacetedFilter } from '@/components/ui/data-table-faceted-filter';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { useAllOrganizations } from '@/features/organizer/api/get-all-organizations';
import { productTypes } from '@/features/payment/data/product-types';

interface PaymentFiltersProps {
  table: any;
}

export function PaymentFilters({ table }: PaymentFiltersProps) {
  const { data: organizations = [] } = useAllOrganizations({});

  const organizers = [...new Set(organizations.map(org => org.organizerName))];

  return (
    <div className="space-x-2 mx-2">
      <DataTableFacetedFilter column={table.getColumn('productType')} TitleComponent={<Label name="productType" groupName={LabelGroup.MANAGER_EVENT_LIST} />} options={productTypes} />
      <DataTableFacetedFilter
        column={table.getColumn('organizerName')}
        TitleComponent={<Label name="organizer" groupName={LabelGroup.MANAGER_EVENT_LIST} />}
        options={
          organizers?.map(organizer => ({
            value: organizer,
            label: organizer
          })) || []
        }
      />
    </div>
  );
}
