import { EventComment } from '@/features/event/types';
import { create } from 'zustand';

type EventStore = {
  comments?: EventComment[];
  isAssigned: boolean;
  setComments: (comments: EventComment[]) => void;
  setIsAssigned: (isAssigned: boolean) => void;
};

export const useEventStore = create<EventStore>((set) => ({
  comments: [],
  isAssigned: false,
  setComments: (comments: EventComment[]) => set((state) => ({ ...state, comments })),
  setIsAssigned: (isAssigned: boolean) => set((state) => ({ ...state, isAssigned })),
}));
