import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
// import { useToast } from '@/components/ui/use-toast';
// import { label } from '@/utils/label';
// import { LabelGroup } from '@/features/label/types';

const QUERY_KEY = 'event';

export type SendEventResponse = {
  paymentRequired: boolean;
};

export const sendEvent = (eventId: number): Promise<SendEventResponse> => {
  return axios.patch(`/event/${eventId}`);
};

type UseOptions = {
  eventId: number;
  config?: MutationConfig<typeof sendEvent>;
};

export const useSendEvent = ({ config, eventId }: UseOptions) => {
  // const { toast } = useToast();

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEY, eventId]);

      // toast({
      //   variant: 'success',
      //   title: label('event_sent_successfully', LabelGroup.ORGANIZER_EVENT_FORM),
      // });
    },
    ...config,
    mutationFn: (eventId: number) => sendEvent(eventId),
  });
};
