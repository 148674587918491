import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { ArrowRight } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import imageHero from '@/assets/media/program_hero.jpg';

export function DownloadProgramShortCut() {
  return (
    <Link to="/download-program">
      <Card className="w-full h-full justify-between border-0 rounded-none ">
        <div>
          <img src={imageHero} alt="Organization placeholder" className="w-full h-full object-cover" />
        </div>
        <CardHeader className="flex items-start">
          <CardTitle className="flex items-center">
            <ArrowRight className="w-6 h-6 mr-2" />
            <Label name="download_program_title" groupName={LabelGroup.ORGANIZER_DASHBOARD} />
          </CardTitle>
        </CardHeader>
        <CardContent className="h-auto">
          <CardDescription className="text-left ">
            <Label name="download_program_description" groupName={LabelGroup.ORGANIZER_DASHBOARD} />
          </CardDescription>
        </CardContent>
      </Card>
    </Link>
  );
}
