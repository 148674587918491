import { EventComment } from '@/features/event/types';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';

type CommentListItemProps = {
  comment: EventComment & {
    author: string;
    inserted: string;
  };
};

export function CommentListItem({ comment }: CommentListItemProps) {
  return (
    <div className="p-4 space-y-2 bg-card rounded-md shadow-sm">
      <div className="flex items-center font-semibold gap-x-2 text-sm">
        <Avatar className=" h-6 w-6">
          <AvatarFallback className="bg-brand text-white  text-sm uppercase">{comment.author[0]}</AvatarFallback>
        </Avatar>
        {comment.author}
        <span className="font-normal text-muted-foreground text-xs">{comment.inserted}</span>
      </div>
      <div className="ml-8 text-sm">{comment.logText.text}</div>
    </div>
  );
}
