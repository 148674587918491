import teleLoopSvg from '../assets/accessability_icon/tele_loop.svg';
import digitalSvg from '../assets/accessability_icon/digital.svg';
import signLanguageSvg from '../assets/accessability_icon/sign_language.svg';
import wheelchairSvg from '../assets/accessability_icon/wheel_chair.svg';
import wheelchairToiletSvg from '../assets/accessability_icon/wheel_chair_toilet.svg';
import visualInterpretationSvg from '../assets/accessability_icon/visual_interpretation.svg';
import text from '../assets/accessability_icon/text.svg';
import environmentDiplomaSvg from '../assets/accessability_icon/environment_diploma.svg';
import streamingSvg from '../assets/accessability_icon/websandning.svg';

const icon = {
  teleLoop: teleLoopSvg,
  digital: digitalSvg,
  signLanguage: signLanguageSvg,
  wheelchair: wheelchairSvg,
  wheelchairToilet: wheelchairToiletSvg,
  visualInterpretation: visualInterpretationSvg,
  text: text,
  environmentDiploma: environmentDiplomaSvg,
  streaming: streamingSvg,
} as const;

export function AccessabilityIcon({ type }: { type: keyof typeof icon }) {
  return <img src={icon[type]} alt={type} className="w-5 h-5" />;
}
