import { FormField, FormItem } from '@/components/ui/form';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { EventOrganizerInput } from '../EventOrganizerInput';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { UserType } from '@/features/user';
import { LabelGroup } from '@/features/label/types';
import { Label } from '@/features/label/components/label';
import { useFormContext } from 'react-hook-form';
import { useMe } from '@/features/auth/api/get-me';
import { Asterisk } from '@/components/ui/asterisk';

export function OrganizerSection({ defaultCollapsed = false }: { defaultCollapsed?: boolean }) {
  const { data: user } = useMe({});
  const userType = user?.role;
  const form = useFormContext();

  const labelGroup = userType === UserType.ADMIN || userType === UserType.MANAGER ? LabelGroup.MANAGER_EVENT_HANDLING : LabelGroup.ORGANIZER_EVENT_FORM;

  return (
    <div className="space-y-6">
      <Card>
        <Accordion type="single" collapsible defaultValue={defaultCollapsed ? '' : 'event_organizer'}>
          <AccordionItem className="[&[data-state=open]>.card-header]:bg-gray-medium [&[data-state=open]>.card-header]:text-white [&[data-state=open]>.card-header]:rounded-t-md" value="event_organizer">
            <CardHeader className="py-3">
              <AccordionTrigger className="py-0 text-md">
                <CardTitle>
                  <Label name={'event_organizers_title'} groupName={labelGroup} />
                  <Asterisk className="ml-2 asterisk" />
                </CardTitle>
              </AccordionTrigger>
            </CardHeader>
            <AccordionContent>
              <CardContent className="pt-6 text-sm space-y-6">
                <FormField
                  control={form.control}
                  name="organizers"
                  render={({ field }) => (
                    <FormItem>
                      <EventOrganizerInput form={form} field={field} />
                    </FormItem>
                  )}
                />
              </CardContent>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </Card>
    </div>
  );
}
