import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { Link } from 'react-router-dom';
import storage from '@/utils/storage';
import { Label } from '@/features/label/components/label';
import { UserType } from '../types';
import { LabelGroup } from '@/features/label/types';
import { useMe } from '@/features/auth/api/get-me';
import { logout } from '@/features/auth/api/logout';

export function UserProfileDropdown() {
  const { data: user } = useMe({ config: { enabled: !!storage.getAccessToken() } });

  const username = user?.username;
  const userType = user?.role;

  const labelGroup =
    userType === UserType.ADMIN || userType === UserType.MANAGER ? LabelGroup.ADMIN_MENU : LabelGroup.ORGANIZER_MENU;

  return (
    <div>
      <DropdownMenu>
        <DropdownMenuTrigger>
          <Avatar>
            <AvatarFallback className="font-semibold bg-gray-medium text-white">
              {username?.substring(0, 2)?.toUpperCase()}
            </AvatarFallback>
          </Avatar>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <Link to={`/profile`}>
            <DropdownMenuItem>
              <Label name="profile_menu_item_text" groupName={labelGroup} />
            </DropdownMenuItem>
          </Link>
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={logout}>
            <Label name="logout_menu_item_text" groupName={labelGroup} />
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
