import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from '@/components/ui/alert-dialog';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { useLocation, useNavigate } from 'react-router-dom';

export interface ConfirmOptions {
  title?: string;
  subtitle?: string;
  confirmText?: string;
  cancelText?: string;
  silent?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export interface ConfirmContext {
  setBlocked: (blocked: boolean) => void;
  setConfirmOptions: (options: ConfirmOptions) => void;
}

const ConfirmCtx = createContext<ConfirmContext | null>(null);

export const useConfirm = (options: ConfirmOptions, shouldBlock: boolean) => {
  const context = useContext(ConfirmCtx);

  if (!context) {
    throw new Error('useConfirm must be used within a ConfirmProvider');
  }

  const { setBlocked, setConfirmOptions } = context;

  useEffect(() => {
    setBlocked(shouldBlock);
    setConfirmOptions(options);
  }, [options, shouldBlock]);

  return context;
};

interface ConfirmProviderProps {
  children: React.ReactNode;
}

export const ConfirmProvider = ({ children }: ConfirmProviderProps) => {
  const [confirmOptions, setConfirmOptions] = useState<ConfirmOptions | null>(null);
  const [open, setOpen] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const [nextPath, setNextPath] = useState<string | null>(null);

  const location = useLocation();

  const navigate = useNavigate();

  const handleConfirm = () => {
    setOpen(false);
    setBlocked(false);
    confirmOptions?.onConfirm?.();
    navigate(nextPath || window.location.pathname);
    // window.location.pathname = nextPath || window.location.pathname;

    setNextPath(null);
  };

  const handleCancel = () => {
    setOpen(false);
    confirmOptions?.onCancel?.();
  };

  const handleLinkOnClick = (ev: MouseEvent, link: HTMLAnchorElement) => {
    if (link?.href && link.target !== '_blank' && blocked !== undefined && blocked) {
      ev.preventDefault();

      const url = new URL(link.href);
      const pathname = url.pathname;

      if (!confirmOptions?.silent) {
        setOpen(true);
        setNextPath(pathname);
      } else {
        confirmOptions?.onConfirm?.();
        navigate(pathname);
      }
    }
  };

  useEffect(() => {
    const allLinks = Array.from(document.querySelectorAll('a')); // Avoid re-querying unnecessarily
    const handleClick = (ev: MouseEvent) => handleLinkOnClick(ev, ev.currentTarget as HTMLAnchorElement);

    // Add listeners only if not already attached
    allLinks.forEach(link => link.addEventListener('click', handleClick));

    // Cleanup to remove old listeners
    return () => {
      allLinks.forEach(link => link.removeEventListener('click', handleClick));
    };
  }, [blocked, confirmOptions]);

  useEffect(() => {
    const handlePopState = () => {
      setOpen(false);
      setBlocked(false);
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    setOpen(false);
    setBlocked(false);
  }, [location.pathname]);

  const contextValue = useMemo(
    () => ({
      setBlocked,
      setConfirmOptions
    }),
    []
  );

  return (
    <ConfirmCtx.Provider value={contextValue}>
      {/* Dialog rendering */}
      <AlertDialog open={open}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              <Label name={confirmOptions?.title ?? 'unsaved_changes_title'} groupName={LabelGroup.GLOBAL} />
            </AlertDialogTitle>
            <AlertDialogDescription>
              <Label name="unsaved_changes_description" groupName={LabelGroup.GLOBAL} />
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={handleCancel}>
              <Label name={confirmOptions?.cancelText || 'Stay'} groupName={LabelGroup.GLOBAL} />
            </AlertDialogCancel>
            <AlertDialogAction onClick={handleConfirm}>
              <Label name={confirmOptions?.cancelText || 'Leave'} groupName={LabelGroup.GLOBAL} />
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      {children}
    </ConfirmCtx.Provider>
  );
};
