import { useEffect, useState, useCallback, FormEvent } from 'react';
import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { Command, CommandGroup, CommandInput, CommandItem, CommandList } from '@/components/ui/command';
import { LabelGroup } from '@/features/label/types';
import { Label } from '@/features/label/components/label';

interface Props {
  onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void;
  center?: { lat: number; lng: number } | null;
}

// This is a custom built autocomplete component using the "Autocomplete Service" for predictions
// and the "Places Service" for place details
export const AutocompleteInput = ({ onPlaceSelect, center }: Props) => {
  const map = useMap();
  const places = useMapsLibrary('places');

  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompleteSessionToken
  const [sessionToken, setSessionToken] = useState<google.maps.places.AutocompleteSessionToken>();

  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service
  const [autocompleteService, setAutocompleteService] = useState<google.maps.places.AutocompleteService | null>(null);

  // https://developers.google.com/maps/documentation/javascript/reference/places-service
  const [placesService, setPlacesService] = useState<google.maps.places.PlacesService | null>(null);

  const [predictionResults, setPredictionResults] = useState<Array<google.maps.places.AutocompletePrediction>>([]);

  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    if (!places || !map) return;

    setAutocompleteService(new places.AutocompleteService());
    setPlacesService(new places.PlacesService(map));
    setSessionToken(new places.AutocompleteSessionToken());

    return () => setAutocompleteService(null);
  }, [map, places]);

  const fetchPredictions = useCallback(
    async (inputValue: string) => {
      if (!autocompleteService || !inputValue) {
        setPredictionResults([]);
        return;
      }

      const request = { input: inputValue, sessionToken };
      const response = await autocompleteService.getPlacePredictions(request);

      setPredictionResults(response.predictions);
    },
    [autocompleteService, sessionToken]
  );

  const onInputChange = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      const value = (event.target as HTMLInputElement)?.value;

      setInputValue(value);
      fetchPredictions(value);
    },
    [fetchPredictions]
  );

  useEffect(() => {
    if (!center) return;
    try {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: center }, (results, status) => {
        if (status === 'OK' && results?.[0]) {
          const route = results[0].address_components?.find((component) => component.types.includes('route'));
          const streetNumber = results[0].address_components?.find((component) =>
            component.types.includes('street_number')
          );
          const formatted_address = `${route?.long_name ?? ''} ${streetNumber?.long_name ?? ''}`;

          setInputValue(formatted_address);
        }
      });
    } catch (error) {}
  }, [center]);

  const handleSuggestionSelect = (placeId: string) => {
    if (!places) return;
    const detailRequestOptions = {
      placeId,
      fields: ['geometry', 'name', 'formatted_address'],
      sessionToken,
    };

    const detailsRequestCallback = (placeDetails: google.maps.places.PlaceResult | null) => {
      onPlaceSelect(placeDetails);

      setPredictionResults([]);
      setInputValue(placeDetails?.formatted_address ?? '');
      setSessionToken(new places.AutocompleteSessionToken());
    };

    placesService?.getDetails(detailRequestOptions, detailsRequestCallback);
  };

  return (
    <div className="w-full relative">
      <Command className="border border-b-0">
        <CommandInput value={inputValue} onInput={(event: FormEvent<HTMLInputElement>) => onInputChange(event)} />
        {predictionResults.length > 0 && (
          <CommandList className=" border-b  absolute top-11 left-0 bg-card w-full z-20 border rounded-tr-0 rounded-tl-0 rounded-br-md rounded-bl-md">
            <CommandGroup heading={<Label name="matches" groupName={LabelGroup.GLOBAL} />}>
              {predictionResults.map(({ place_id, description }) => {
                return (
                  <CommandItem
                    onSelect={() => handleSuggestionSelect(place_id)}
                    key={description + place_id}
                    className="hover:bg-muted cursor-pointer"
                  >
                    {description}
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </CommandList>
        )}
      </Command>
    </div>
  );
};
