import { DataTable } from '@/components/ui/data-table';
import { Separator } from '@/components/ui/separator';
import { columns } from '@/features/label/components/label-columns';
import { useLabels } from '../api/getLabels';
import { Row } from '@tanstack/react-table';
import { Label as TLabel, LabelGroup } from '../types';
import { Label } from '../components/label';
import { LabelFilters } from '../components/label-filters';
import { labelGroups } from '../data/label-groups';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Info, Search } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';

export function Labels() {
  const { data: labels = [] } = useLabels({
    languageCode: 'swe',
    config: {
      refetchOnWindowFocus: false,
    },
  });

  return (
    <div>
      <div className="flex items-start space-y-4 flex-col justify-between pb-4">
        <h2 className="text-xl font-semibold">
          <Label name="labels" groupName={LabelGroup.ADMIN_LABEL} />
        </h2>
        <Popover>
          <PopoverTrigger>
            <Button variant="outline" className="">
              <Info className="h-4 w-4 mr-2" /> Jag hittar inte den text jag vill ändra på...
            </Button>
          </PopoverTrigger>
          <PopoverContent className='p-0' >
            <Alert className="w-fit">
              <Search className="h-4 w-4" />
              <AlertTitle>Hitta systemtexter på sidor</AlertTitle>
              <AlertDescription>
                För att se vilken grupp en text tillhör kan du hålla in{' '}
                <kbd className="bg-secondary p-0.5 rounded-md border border-input text-xs">Ctrl</kbd> +{' '}
                <kbd className="bg-secondary p-0.5 rounded-md border border-input text-xs">Shift</kbd> och hålla musmarkören
                över texten.
              </AlertDescription>
            </Alert>
          </PopoverContent>
        </Popover>
      </div>
      <Separator />
      <div>
        <DataTable
          columns={columns}
          data={labels}
          FacetedFilters={LabelFilters}
          config={{
            maxHeight: 'calc(100lvh - 320px)',
            disableExport: true,
            getSearchFields: (row) => [
              (row as Row<TLabel>).original.labelName,
              labelGroups.find((group) => group.group === (row as Row<TLabel>).original.labelGroup)?.name ??
                (row as Row<TLabel>).original.labelGroup,
              (row as Row<TLabel>).original.value,
            ],
          }}
        />
      </div>
    </div>
  );
}
