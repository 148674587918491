import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Organization } from '../types';

const QUERY_KEY = 'organizations';

export const getOrganizations = (): Promise<Organization[]> => {
  return axios.get(`/organizer`);
};

type QueryFnType = typeof getOrganizations;

type UseOrganizationOptions = {
  userId: number;
  config?: QueryConfig<QueryFnType>;
};

export const useOrganizations = ({ userId, config }: UseOrganizationOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: [QUERY_KEY, userId],
    queryFn: getOrganizations,
    ...config,
  });
};
