import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Invitation } from '../types';

const QUERY_KEY = 'invitations';

export const getOrganizations = (organizationId: number): Promise<Invitation[]> => {
  return axios.get(`/api/invitation/all/${organizationId}`);
};

type QueryFnType = typeof getOrganizations;

type UseOrganizationOptions = {
  organizationId: number;
  config?: QueryConfig<QueryFnType>;
};

export const useInvitations = ({ config, organizationId }: UseOrganizationOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: [QUERY_KEY, organizationId],
    queryFn: () => getOrganizations(organizationId),
    ...config,
  });
};
