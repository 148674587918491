import { AppRoutes } from './routes';
import { AppProvider } from './providers/app';
import { Toaster } from '@/components/ui/toaster';

function App() {
  return (
    <AppProvider>
      <Toaster />
      <AppRoutes />
    </AppProvider>
  );
}

export default App;
