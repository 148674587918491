import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { cn } from '@/utils';

export function Layout() {
  return (
    <div className="min-h-screen flex flex-col overflow-x-hidden">
      <main className={cn('flex-1 md:pl-6')}>
        <div className={cn('p-4 sm:p-6 w-full max-w-2xl md:max-w-4xl mx-auto')}>
          <Suspense fallback={<div />}>
            <Outlet />
          </Suspense>
        </div>
      </main>
    </div>
  );
}
