import * as z from 'zod';

import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useToast } from '../../../components/ui/use-toast';
import { useState } from 'react';
import { Spinner } from '../../../components/ui/spinner';
import { register } from '../api/register';
import { Card, CardDescription, CardTitle } from '@/components/ui/card';
import { Separator } from '@/components/ui/separator';
import { Link, useLocation } from 'react-router-dom';
import { label } from '@/utils/label';
import { Check, MailCheck, X } from 'lucide-react';
import { passwordSchema } from '../data/password-schema';
import { debounce } from 'lodash';
import { checkUsername } from '@/features/user/api/check-username';
import { PasswordInput } from '@/components/password-input';
import { emailSchema } from '../data/email-schema';
import { LabelGroup } from '@/features/label/types';
import { Label } from '@/features/label/components/label';
import { NewAccountTerms } from './new-account-terms';

const formSchema = z
  .object({
    userName: z.string().email('username_invalid'),
    confirmEmail: z.string().email('username_invalid'),
    password: passwordSchema,
    confirmPassword: passwordSchema,
    inviteCode: z.string().nullable().optional(),
    usernameVerified: z.boolean().refine(value => value !== null, {
      message: 'username_verified_required_validation_text'
    })
  })
  .refine(data => data.userName === data.confirmEmail, {
    message: 'email_does_not_match',
    path: ['confirmEmail']
  })
  .refine(data => data.password === data.confirmPassword, {
    message: 'password_does_not_match',
    path: ['confirmPassword']
  });
export function Register() {
  const [isLoading, setIloading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [hasAcceptedAccountTerms, setHasAcceptedAccountTerms] = useState(false);

  const { toast } = useToast();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      userName: '',
      password: '',
      inviteCode: searchParams.get('inviteCode') || null
    }
  });

  async function handleSubmit(values: z.infer<typeof formSchema>) {
    try {
      setIloading(true);

      await register({ ...values });

      toast({
        title: label('registration_successful', LabelGroup.REGISTER),
        variant: 'success',
        duration: 9000
      });
      setEmailSent(true);
      // navigate('/login');
    } catch (error: any) {
      const message = error?.response?.data?.message || label('registration_failed', LabelGroup.REGISTER);
      toast({
        title: message,
        variant: 'destructive',
        duration: 9000
      });
    } finally {
      setIloading(false);
    }
  }

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    form.setValue('userName', e.target.value, { shouldDirty: true });
    checkUsernameAvailability(e.target.value);
  };

  const checkUsernameAvailability = debounce(async (username: string) => {
    // Replace this URL with your actual API endpoint
    if (username === '' || !username) return;

    const { available } = await checkUsername(username);

    const isEmail = emailSchema.safeParse(username);

    if (!isEmail.success) {
      return;
    }

    if (available === false) {
      form.setError('userName', {
        type: 'manual',
        message: 'username_not_available_validation_text'
      });
      form.setValue('usernameVerified', false, { shouldDirty: true });
    } else if (!isEmail.success || username.length < 1) {
      form.setError('userName', {
        type: 'manual',
        message: 'username_invalid'
      });
      form.setValue('usernameVerified', false, { shouldDirty: true });
    } else {
      form.clearErrors('userName');
      form.clearErrors('usernameVerified');
      form.setValue('usernameVerified', true, { shouldDirty: true });
    }
  }, 1000);

  if (!hasAcceptedAccountTerms) {
    return <NewAccountTerms onAccept={setHasAcceptedAccountTerms} />;
  }

  if (emailSent) {
    return (
      <div className="h-screen w-full flex flex-col items-center justify-center">
        <div className="space-y-4 text-center">
          <div className="space-y-2  flex flex-col items-center">
            <MailCheck className="w-8 h-8" />
            <h3 className="text-lg font-medium">
              <Label name="confirmation_email_sent_description" groupName={LabelGroup.REGISTER} />
            </h3>
          </div>
          {/* <div>
            <Link to={'/login?' + searchParams}>
              <Button>
                <Label name="login_button_text" groupName={LabelGroup.REGISTER} />
              </Button>
            </Link>
          </div> */}
        </div>
      </div>
    );
  }

  return (
    <div className="h-auto w-full flex flex-col items-center justify-center">
      <div className="flex flex-col w-full justify-center items-center py-6">
        <Card className="max-w-md w-full p-6 space-y-4 h-auto bg-card shadow-md">
          <CardTitle className="text-center text-2xl font-bold">
            <Label name="register_title" groupName={LabelGroup.REGISTER} />
          </CardTitle>
          <CardDescription className="text-center text-sm">
            <Label name="already_have_an_account" groupName={LabelGroup.REGISTER} />
            <Link to="/login" className="underline ml-1 text-foreground">
              <Label name="login_here" groupName={LabelGroup.REGISTER} />
            </Link>
          </CardDescription>
          <Separator />
          <Form {...form}>
            <form onKeyDown={event => event.key === 'Enter' && event.preventDefault()} onSubmit={form.handleSubmit(handleSubmit)} className="space-y-8">
              <FormField
                control={form.control}
                name="userName"
                render={({ field }) => (
                  <FormItem className="w-full relative">
                    <FormLabel>
                      <Label name="email" groupName={LabelGroup.REGISTER} />
                    </FormLabel>
                    <FormControl>
                      <Input {...field} useDebounce={false} onChange={handleUsernameChange} autoComplete="username" />
                    </FormControl>
                    <FormMessage />
                    {form.watch('usernameVerified') ? <Check className="absolute w-4 h-4 right-4 top-9 text-green-500" /> : form.watch('usernameVerified') === false ? <X className="absolute w-4 h-4 right-4 top-9 text-red-500" /> : null}
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="confirmEmail"
                render={({ field }) => (
                  <FormItem className="w-full relative">
                    <FormLabel>
                      <Label name="confirm_email" groupName={LabelGroup.REGISTER} />
                    </FormLabel>
                    <FormControl>
                      <Input {...field} useDebounce={false} autoComplete="username" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>
                      <Label name="password" groupName={LabelGroup.REGISTER} />
                    </FormLabel>
                    <FormControl>
                      <PasswordInput {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="confirmPassword"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>
                      <Label name="confirm_password" groupName={LabelGroup.REGISTER} />
                    </FormLabel>
                    <FormControl>
                      <PasswordInput {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="space-y-2">
                <Button disabled={isLoading || !form.watch('usernameVerified')} size="sm" className="w-full flex justify-center items-center" type="submit">
                  <span className="flex items-center justify-center">
                    {isLoading && <Spinner className="mr-2 text-primary-foreground w-4 h-4" />}
                    {isLoading ? label('regestering_button_text', LabelGroup.REGISTER) : label('register_button_text', LabelGroup.REGISTER)}
                  </span>
                </Button>
                <FormDescription>
                  <Label name="by_clicking_register_you_agree_to_our_terms_and_conditions" groupName={LabelGroup.REGISTER} />
                </FormDescription>
              </div>
            </form>
          </Form>
        </Card>
      </div>
    </div>
  );
}
