import { cn } from '@/utils';
import { useAssignManager } from '../api/assignManager';
import { ManagerEvent } from '../types';
import { ManagerDropdown } from './ManagerDropdown';
import { TEvent } from '@/features/event/types';

interface AssignManagerProps {
  event?: ManagerEvent | TEvent;
  className?: string;
}

export function AssignManager({ event, className }: AssignManagerProps) {
  const assignManagerMutation = useAssignManager({});

  if (!event) return null;

  return (
    <div className={cn(className)}>
      <ManagerDropdown
        onChange={(manager) => {
          assignManagerMutation.mutate({
            data: {
              eventId: event.eventId,
              managerId: manager.managerId,
            },
          });
        }}
        value={event.assignedUserId || 0}
      />
    </div>
  );
}
