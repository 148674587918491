import { Card } from '@/components/ui/card';
import { Checkbox } from '@/components/ui/checkbox';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';

interface NewAccountTermsProps {
  onAccept: (value: boolean) => void;
}

export function NewAccountTerms({ onAccept }: NewAccountTermsProps) {
  return (
    <div className="h-auto w-full flex flex-col items-center justify-center">
      <div className="flex flex-col w-full justify-center items-center py-6">
        <Card className="max-w-md w-full p-6 space-y-4 h-auto bg-card shadow-md">
          <div className="space-y-4 text-center">
            <div className="space-y-2  flex flex-col items-center text-sm text-left">
              <Label name="new_account_terms_title" groupName={LabelGroup.REGISTER} />
            </div>
            <div>
              <div className="flex items-center space-x-2">
                <Checkbox id="terms" onCheckedChange={onAccept} />
                <label
                  htmlFor="terms"
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  <Label name="accept_new_account_terms" groupName={LabelGroup.REGISTER} />
                </label>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}
