import { EventCategory, EventStatus } from '@/features/event/types';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { label } from '@/utils/label';

export const statuses = [
  {
    value: EventStatus.Draft,
    label: <Label name="event_status_draft" groupName={LabelGroup.MANAGER_EVENT_STATUSES} />,
  },
  {
    value: EventStatus.AwaitingPayment,
    label: <Label name="event_status_awaiting_payment" groupName={LabelGroup.MANAGER_EVENT_STATUSES} />,
  },
  {
    value: EventStatus.Pending,
    label: <Label name="event_status_pending" groupName={LabelGroup.MANAGER_EVENT_STATUSES} />,
  },
  // {
  //   value: EventStatus.Review,
  //   label: <Label name="event_status_review" groupName={LabelGroup.MANAGER_EVENT_STATUSES} />,
  // },
  {
    value: EventStatus.ActionRequired,
    label: <Label name="event_status_action_required" groupName={LabelGroup.MANAGER_EVENT_STATUSES} />,
  },
  {
    value: EventStatus.Approved,
    label: <Label name="event_status_approved" groupName={LabelGroup.MANAGER_EVENT_STATUSES} />,
  },
  {
    value: EventStatus.Rejected,
    label: <Label name="event_status_rejected" groupName={LabelGroup.MANAGER_EVENT_STATUSES} />,
  },
  // {
  //   value: EventStatus.UnPublished,
  //   label: label('event_status_unpublished', LabelGroup.MANAGER_EVENT_STATUSES),
  // },
  {
    value: EventStatus.Cancelled,
    label: <Label name="event_status_cancelled" groupName={LabelGroup.MANAGER_EVENT_STATUSES} />,
  },
];

export const managerEventStatuses = [
  {
    value: EventStatus.Pending,
    label: <Label name="event_status_pending" groupName={LabelGroup.MANAGER_EVENT_STATUSES} />,
  },

  {
    value: EventStatus.ActionRequired,
    label: <Label name="event_status_action_required" groupName={LabelGroup.MANAGER_EVENT_STATUSES} />,
  },
  {
    value: EventStatus.Approved,
    label: <Label name="event_status_approved" groupName={LabelGroup.MANAGER_EVENT_STATUSES} />,
  },
  {
    value: EventStatus.Rejected,
    label: <Label name="event_status_rejected" groupName={LabelGroup.MANAGER_EVENT_STATUSES} />,
  },
];

export const categories = [
  {
    value: EventCategory.HETA_STOLEN,
    label: label('event_category_heta_stolen', LabelGroup.MANAGER_EVENT_LIST),
  },
  {
    value: EventCategory.SEMINARIUM,
    label: label('event_category_heta_lost', LabelGroup.MANAGER_EVENT_LIST),
  },
  {
    value: EventCategory.TRAFFPUNKT,
    label: label('event_category_heta_found', LabelGroup.MANAGER_EVENT_LIST),
  },
];
