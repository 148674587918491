import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useToast } from '@/components/ui/use-toast';
import { label } from '@/utils/label';
import { ManagerEvent } from '../types';
import { LabelGroup } from '@/features/label/types';

const QUERY_KEY = 'events';
const API_PATH = '/manager/assign/';

type UpdateDto = {
  data: {
    eventId: number;
    managerId: number;
  };
};

type AssignManagerResponse = {
  eventId: number;
  id: number;
};

export const assignManager = ({ data }: UpdateDto): Promise<AssignManagerResponse> => {
  return axios.patch(
    `${API_PATH}${data.eventId}`,
    {},
    {
      params: {
        managerId: data.managerId,
      },
    }
  );
};

type UseUpdateOptions = {
  config?: MutationConfig<typeof assignManager>;
};

export const useAssignManager = ({ config }: UseUpdateOptions) => {
  const { toast } = useToast();

  return useMutation({
    onSuccess: (data: AssignManagerResponse) => {
      queryClient.invalidateQueries([QUERY_KEY]);
      queryClient.refetchQueries([QUERY_KEY]);

      const currentEvents = queryClient.getQueryData<ManagerEvent[]>([QUERY_KEY]);

      if (currentEvents) {
        const updatedCurrentEvents = currentEvents.map((event) =>
          event.eventId === data.eventId ? { ...event, assignedUserId: data.id } : event
        );

        queryClient.setQueryData([QUERY_KEY], updatedCurrentEvents);
      }

      toast({ variant: 'success', title: label('manager_assigned', LabelGroup.MANAGER_EVENT_HANDLING) });
    },
    ...config,
    mutationFn: assignManager,
  });
};
