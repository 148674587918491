import { z } from 'zod';

const orgNoRegex =
  /^(?!(556382[\-]?7327))(\d{6}[\-]?\d{4})$|(?:\b(?:19|20)?\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[-+]?\d{4}\b)$/;

const orgNoSchema = z
  .string()
  .max(32, {
    message: 'org_no_max_length',
  })
  .refine((value) => orgNoRegex.test(value) || value === '00000000-0000', {
    message: 'invalid_org_no',
  });

export { orgNoSchema };
