type Label = {
  labelId: number;
  labelName: string;
  labelGroup: string;
  value: string;
  languageCode: string;
};

enum LabelGroup {
  ADMIN_MENU = 'admin.menu',
  ADMIN_USER = 'admin.user',
  ADMIN_LABEL = 'admin.label',
  ADMIN_LOCATION_LIST = 'admin.location.list',
  ADMIN_LOCATION_EDIT = 'admin.location.edit',
  ADMIN_USER_LIST = 'admin.user.list',
  ADMIN_USER_EDIT = 'admin.user.edit',
  ADMIN_USER_CREATE = 'admin.user.create',
  ADMIN_ORGANIZATION_LIST = 'admin.organization.list',
  ADMIN_PAYMENT = 'admin.payment',

  ADMIN_SETTINGS = 'admin.settings',
  ADMIN_INVOICE = 'admin.invoice',

  MANAGER_DASHBOARD = 'manager.dashboard',
  MANAGER_EVENT_HANDLING = 'manager.event.handling',
  MANAGER_EVENT_LIST = 'manager.event.list',
  MANAGER_EVENT_STATUSES = 'manager.event_statuses',

  ORGANIZER_MENU = 'organizer.menu',
  ORGANIZER_DASHBOARD = 'organizer.dashboard',
  ORGANIZER_ORGANIZATION_LIST = 'organizer.organization.list',
  ORGANIZER_ORGANIZATION_CREATE = 'organizer.organization.create',
  ORGANIZER_ORGANIZATION_JOIN = 'organizer.organization.join',
  ORGANIZER_ORGANIZATION_EDIT = 'organizer.organization.edit',

  ORGANIZER_DOWNLOAD_PROGRAM = 'organizer.download_program',

  ORGANIZER_EVENT_LIST = 'organizer.event.list',
  ORGANIZER_EVENT_CREATE = 'organizer.event.create',
  ORGANIZER_EVENT_FORM = 'organizer.event.form',
  ORGANIZER_EVENT_STATUSES = 'organizer.event_statuses',

  ORGANIZER_PAYMENT = 'organizer.payment',
  ORGANIZER_PAYMENT_LIST = 'organizer.payment.list',
  ORGANIZER_PAYMENT_RECEIPT = 'organizer.payment.receipt',

  VALIDATION = 'validation',

  PROFILE = 'profile',
  LOGIN = 'login',
  REGISTER = 'register',
  FORGOT_PASSWORD = 'forgot_password',
  CONFIRM_EMAIL = 'confirm_email',
  RESTORE_PASSWORD = 'restore_password',

  GLOBAL = 'global',
}

export { LabelGroup };
export type { Label };
