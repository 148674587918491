import { AccessabilitySection } from '@/features/event/components/(sections)/accessability-section';
import { BasicDetailsSection } from '@/features/event/components/(sections)/basic-details-section';
import { ContentSection } from '@/features/event/components/(sections)/content-section';
import { DigitalEventSection } from '@/features/event/components/(sections)/digital-event-section';
import { EnvironmentDiplomaSection } from '@/features/event/components/(sections)/environment-diploma-section';
import { LocationSection } from '@/features/event/components/(sections)/location-section';
import { OrganizerSection } from '@/features/event/components/(sections)/organizer-section';
import { PreviewSection } from '@/features/event/components/(sections)/preview-section';
import { StreamingSection } from '@/features/event/components/(sections)/streaming-sections';
import { WebLinksSections } from '@/features/event/components/(sections)/web-links-section';
import { ArchiveSections } from '@/features/event/components/(sections)/archive-section';
import { CategorySection } from '@/features/event/components/(sections)/category-section';
import { ContactSection } from '@/features/event/components/(sections)/contact-section';
import { EventPersonSection } from '@/features/event/components/(sections)/event-person-section';

export const sections = [
  {
    title: 'preview_and_send',
    id: 'preview-and-send-section',
    component: PreviewSection,
  },
  {
    title: 'category',
    id: 'category-section',
    component: CategorySection,
  },
  {
    title: 'content',
    id: 'content-section',
    component: ContentSection,
  },
  {
    title: 'basic_details',
    id: 'basic-details-section',
    component: BasicDetailsSection,
  },
  {
    title: 'event_location',
    id: 'location-section',
    component: LocationSection,
  },
  {
    title: 'organizers',
    id: 'organizer-section',
    component: OrganizerSection,
  },
  {
    title: 'persons',
    id: 'persons-section',
    component: EventPersonSection,
  },
  {
    title: 'contact',
    id: 'contact-section',
    component: ContactSection,
  },
  {
    title: 'web-links',
    id: 'web-links-section',
    component: WebLinksSections,
  },
  {
    title: 'streaming',
    id: 'streaming-section',
    component: StreamingSection,
  },
  {
    title: 'digital_event',
    id: 'digital-event-section',
    component: DigitalEventSection,
  },
  {
    title: 'archive',
    id: 'archive-section',
    component: ArchiveSections,
  },

  {
    title: 'accessability',
    id: 'accessability-section',
    component: AccessabilitySection,
  },

  {
    title: 'environment_diploma',
    id: 'environment-diploma-section',
    component: EnvironmentDiplomaSection,
  },
];
