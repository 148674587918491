import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { Organization } from '../types';
import { useToast } from '@/components/ui/use-toast';
import { label } from '@/utils/label';
import { LabelGroup } from '@/features/label/types';

const QUERY_KEY = 'organizations';

export type CreateOrganizationDTO = {
  data: {
    organizerName: string;
    signerName: string;
    signerEmail: string;
    signerTelephone: string;
    orgNo: string;
  };
};

export const createOrganization = ({ data }: CreateOrganizationDTO): Promise<Organization> => {
  return axios.post(`/organizer`, data);
};

type UseCreateOrganizationOptions = {
  config?: MutationConfig<typeof createOrganization>;
};

export const useCreateOrganization = ({ config }: UseCreateOrganizationOptions) => {
  const { toast } = useToast();

  return useMutation({
    onMutate: async (next) => {
      await queryClient.cancelQueries([QUERY_KEY]);

      const prev = queryClient.getQueryData<Organization[]>([QUERY_KEY]);

      queryClient.setQueryData([QUERY_KEY], [...(prev || []), next.data]);

      return { prev };
    },
    onError: (_, __, context: any) => {
      if (context?.prev) {
        queryClient.setQueryData([QUERY_KEY], context.prev);
      }
      toast({
        variant: 'destructive',
        title: label('something_went_wrong', LabelGroup.GLOBAL),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEY]);
      queryClient.refetchQueries([QUERY_KEY]);

      toast({
        variant: 'success',
        title: label('organization_created', LabelGroup.ORGANIZER_ORGANIZATION_CREATE),
      });
    },
    ...config,
    mutationFn: (data: any) =>
      createOrganization({
        data,
      }),
  });
};
