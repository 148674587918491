import { useEffect } from "react";

export default function useOnClickOutside(
  refs: Array<React.MutableRefObject<any>>,
  handler: Function,
  bool?: boolean,
  blockDialog: boolean = true
) {
  useEffect(() => {
    if (bool !== undefined && !bool) return () => null;

    const listener = (event: MouseEvent | TouchEvent) => {
      if (refs.some((r) => r.current?.contains(event.target))) return;

      if (blockDialog) {
        const dialogElement = document.querySelector('.dialog') as Node;
        if (dialogElement && dialogElement.contains(event.target as Node)) return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [refs, handler, bool]);
}
