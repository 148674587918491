import { useSettings } from '@/features/settings/api/getSettings';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import DOMPurify from 'dompurify';
import { parse } from 'marked';
import { InfoIcon } from '@/components/icons/info';
import { cn } from '@/utils';
import { WarningIcon } from '@/components/icons/warning';

type TBanner = {
  hidden: boolean;
  message: string;
  title: string;
  icon: 'info' | 'warning' | null;
  severity: 'info' | 'warning' | null;
};

const style = {
  info: 'bg-[#C5E0F7] text-[#0a355e]',
  warning: 'bg-brand text-white'
} as const;

const icon = {
  info: <InfoIcon />,
  warning: <WarningIcon />
} as const;

export function Banner() {
  const { data: settings = {} } = useSettings({});

  const bannerSetting = settings?.banner?.[0] ?? null;

  const banner: TBanner | null = bannerSetting ? JSON.parse((bannerSetting.data as string) ?? '{}') : null;

  if (banner?.hidden || !banner) {
    return null;
  }

  return (
    <Alert className={cn('rounded-none flex py-8', style[banner.severity as keyof typeof style])}>
      <div className="mx-12"> {banner.icon ? icon[banner.severity as keyof typeof icon] : null}</div>
      <div>
        <AlertTitle className="ml-2 font-header text-2xl font-semibold">{banner?.title}</AlertTitle>
        <AlertDescription className="ml-2">
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(parse(banner?.message ?? '') as string) }} />
        </AlertDescription>
      </div>
    </Alert>
  );
}
