import { axios } from '@/lib/axios';

export type RestorePasswordDTO = {
  password: string;
  token: string;
};

export const restorePassword = async (data: RestorePasswordDTO) => {
  try {
    return axios.put('/restore-password', data);
  } catch (error) {
    throw error;
  }
};
